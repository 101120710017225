// Packages
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Redux Operations
import { loaderOperations } from "../../state/features/loader";
// Helpers, Utils etc.
import trackingDetails from "../../helpers/orderTracking";

interface IOrderStatusBarProps {
  order: any;
  showLoader: any;
}

function OrderStatusBar(props: IOrderStatusBarProps) {
  const goTo = (url: string) => {
    props.showLoader();
    window.location.href = url;
  };
  const order_type =
    props.order.order_type || props.order.order_details.order_type;

  const dateFromString = (dateString: string) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <div
      className="current-order-status"
      id='status-bar'
      onClick={() => goTo("/m/trackorder")}
    >
      <div>
        <p className="order-status">
          <FormattedMessage
            id={`order.${order_type}.${props.order.status}.tracking_title`}
            defaultMessage={`Order is ${trackingDetails[props.order.status][order_type].title.toLowerCase()}`}
          />
        </p>
        <p className="order-number">
          <FormattedMessage
            id="order.order_number_with_hash"
            defaultMessage="Order# {orderNumber}"
            values={{
              orderNumber: props.order.order_number
            }}
          />
        </p>
        <p className="ordered-date">
          <FormattedMessage
            id="order.ordered_at"
            defaultMessage="Ordered {orderDate}"
            values={{
              orderDate: dateFromString(props.order.created_at)
            }}
          />
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let order = state.orderInProgress;
  return { order };
};

const mapDispatchToProps = {
  showLoader: loaderOperations.showLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusBar);
