import actions from "./actions";

const updateLoader = actions.updateLoader;
const resetLoader = actions.resetLoader;
const showLoader = actions.showLoader;
const hideLoader = actions.hideLoader;

export default {
  updateLoader,
  resetLoader,
  showLoader,
  hideLoader,
};
