import React, { useState, useEffect, ReactNode, useMemo } from "react";
import { CheckoutScreenContext } from "../../contexts/checkoutScreenContext";
import { useDispatch, useSelector } from "react-redux";
import { sessionOperations } from "../../state/features/session";
import { useHistory } from "react-router-dom";
import { RootState } from "../../state/store";

interface CheckoutScreenProviderProps {
  children: ReactNode;
}

const CheckoutScreenProvider = ({
  children,
}: CheckoutScreenProviderProps) => {
  const [mpgsAddonPaymentType, setMpgsAddonPaymentType] = useState<
    string | null
  >(null);
  const [showOrderPlacedPopup, setShowOrderPlacedPopup] = useState(false);
  const [earnedLoyalty, setEarnedLoyalty] = useState<string | null>(null);
  const [amountBreakdowns, setAmountBreakdowns] = useState<any>({
    subTotal: 0,
    total: 0,
    taxTotal: 0,
    discountTotal: 0,
    loyaltyTotal: 0,
    tip: 0,
    surchargeBreakDown: [],
  });
  const { orderInProgress } = useSelector((state: RootState) => state);
  const { id: userId } = useSelector((state: RootState) => state.user);
  const { has_tips: tipsEnabled } = useSelector(
    (state: RootState) => state.company
  );
  const { decimal_precision: decimalPrecision } = useSelector(
    (state: RootState) => state.company
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const updateMpgsAddonPaymentType = (paymentType: string | null) => {
    setMpgsAddonPaymentType(paymentType);
  };

  const updateOrderAmountBreakdowns = (amountBreakdowns: any) => {
    setAmountBreakdowns(amountBreakdowns);
  };

  const resetCart = () => {
    localStorage.cartItems = JSON.stringify([]);
    dispatch(sessionOperations.clearSession());
  };

  const handlePaymentSuccess = () => {
    resetCart();
    setShowOrderPlacedPopup(true);
  };

  const performRedirection = (reason: string) => {
    switch (reason) {
      case "failed":
        setTimeout(() => {
          window.location.href = "/checkout?payment_status=failed";
        }, 5000);
        break;
    }
  };

  const handlePaymentFailure = () => {
    performRedirection("failed");
  };

  const updateEarnedLoyalty = (earnedLoyalty: string) => {
    setEarnedLoyalty(earnedLoyalty);
  };

  const checkoutPageConfigs = useMemo(() => {
    let showPaymentMethods = true;
    let showTips = tipsEnabled;
    return { showPaymentMethods, showTips };
  }, [
    tipsEnabled,
    userId,
    amountBreakdowns,
  ]);

  return (
    <CheckoutScreenContext.Provider
      value={{
        earnedLoyalty,
        showOrderPlacedPopup,
        mpgsAddonPaymentType,
        checkoutPageConfigs,
        updateMpgsAddonPaymentType,
        handlePaymentSuccess,
        handlePaymentFailure,
        updateEarnedLoyalty,
        updateOrderAmountBreakdowns,
      }}
    >
      {children}
    </CheckoutScreenContext.Provider>
  );
};

export default CheckoutScreenProvider;
