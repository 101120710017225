// Packages
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import PastOrderDetailsPopup from "../pastOrderDetailsPopup";
import EmailInvoicePopup from "../emailInvoicePopup";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { invoiceOperations } from "../../state/features/invoice";
// Helpers, Utils etc.
import {
  translatedName,
} from "../../helpers/translations";

interface IPastOrderWidgetProps {
  pastOrder: any;
  userEmail: string;
  alert: any;
  error: any;
  reorderhandler: any;
  sendInvoiceToRegisteredEmail: any;
}

function PastOrderWidget(props: IPastOrderWidgetProps) {
  const { pastOrder, reorderhandler } = props;
  const [show, setShow] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [hideOrderDetails, setHideOrderDetails] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (show && (props.alert || props.error)) {
      setHideOrderDetails(true);
      setShow(false);
    }
    if (hideOrderDetails && !props.alert && !props.error) {
      setShow(true);
      setHideOrderDetails(false);
    }
  }, [props.alert, props.error]);

  const dateFromString = (dateString: string) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const handleOrderDetailsPopup = () => {
    setShow(true);
  };

  const onPopupClose = () => {
    setShow(false);
  };

  const onSendInvoiceEmailClick = () => {
    if (props.userEmail) {
      props.sendInvoiceToRegisteredEmail(pastOrder.order_number);
    } else {
      setShowEmailPopup(true);
      if (show) {
        setHideOrderDetails(true);
        setShow(false);
      }
    }
  };

  const hideEmailPopup = () => {
    setShowEmailPopup(false);
    if (hideOrderDetails) {
      setShow(true);
      setHideOrderDetails(false);
    }
  }

  return (
    <div className="section nested past-order-wrapper" key={pastOrder.id}>
      <div className="past-order" key={pastOrder.order_number}>
        <div className="order-details">
          <p className="order-id">{pastOrder.order_number}</p>
          <p className="order-date">{dateFromString(pastOrder.created_at)}</p>
          <p className="order-item">
            <span className="item-name">
              {pastOrder.order_details.items[0].quantity}x{" "}
                {translatedName(pastOrder.order_details.items[0], intl.locale)}
            </span>
            {pastOrder.order_details.items.length > 1 && (
              <span className="count">
                <FormattedMessage
                  id="my_account.item_count_mode"
                  defaultMessage="{itemCount} more"
                  values={{
                    itemCount: pastOrder.order_details.items.length
                  }}
                />
              </span>
            )}
          </p>
        </div>
        <div className="actions">
          <button
            onClick={handleOrderDetailsPopup}
            className="small-button btn btn-sm btn-secondary"
          >
            <FormattedMessage
              id="global.details"
              defaultMessage="Details"
            />
          </button>

          <div className="dropdown">
            <button
              className="small-button btn btn-sm btn-secondary"
              type="button"
              id="invoiceDropDownButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FormattedMessage
                id="my_account.invoice"
                defaultMessage="Invoice"
              />
              <FontAwesomeIcon className="ml-1" size="1x" icon={faCaretDown} />
            </button>
            <div className="dropdown-menu invoice-dropdown" aria-labelledby="dropdownMenuButton">
              <Link to={`/invoices/${pastOrder.order_number}.pdf`} target="_blank" className="dropdown-item invoice-dropdown-item">
                <FormattedMessage
                  id="my_account.invoice.print"
                  defaultMessage="Print Invoice"
                />
              </Link>
              <Link to={`/invoices/${pastOrder.order_number}.pdf?download=true`} target="_blank" className="dropdown-item invoice-dropdown-item">
                <FormattedMessage
                  id="my_account.invoice.download"
                  defaultMessage="Download Invoice"
                />
              </Link>
              <button
                className="dropdown-item invoice-dropdown-item"
                onClick={() => onSendInvoiceEmailClick(pastOrder.order_number)}
              >
                <FormattedMessage
                  id="my_account.invoice.send_via_email"
                  defaultMessage="Send Invoice via Email"
                />
              </button>
            </div>
          </div>

          <button
            className="small-button btn btn-sm btn-primary"
            onClick={() => reorderhandler(pastOrder)}
          >
            <FormattedMessage
              id="my_account.re_order"
              defaultMessage="Re-Order"
            />
          </button>
        </div>
        {show && (
          <PastOrderDetailsPopup
            onClose={onPopupClose}
            pastOrder={pastOrder}
            reorderhandler={reorderhandler}
            onSendInvoiceEmailClick={onSendInvoiceEmailClick}
          />
        )}

        {showEmailPopup && (
          <EmailInvoicePopup
            orderNumber={pastOrder.order_number}
            onClose={hideEmailPopup}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let userEmail = state.user.email;
  let alert = state.alert;
  let error = state.error;

  return {
    userEmail,
    alert,
    error,
  };
};

const mapDispatchToProps = {
  sendInvoiceToRegisteredEmail: invoiceOperations.sendInvoiceToRegisteredEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastOrderWidget);
