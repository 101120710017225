// Packages
import React from "react";
import { useIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import restaurantClosedImage from "../../images/closed.png";

interface IRestaurantCloseBanner{
  storeTimings: any;
}

function RestaurantCloseBanner(props: IRestaurantCloseBanner) {
  const {
    openingTime,
    closingTime,
    openingDay,
  } = props.storeTimings;

  // Localisation object
  const intl = useIntl();

  return (
    <div className="closed-banner">
      <div className="image-container">
        <img src={restaurantClosedImage} />
      </div>
      <p className="message">
        {!openingDay ? (
          <FormattedMessage
            id="store_timings.store_closed_come_back_later"
            defaultMessage="Sorry, we are closed. Please come back later"
          />
        ) : openingDay != "Today" ? (
          <FormattedMessage
            id="store_timings.store_closed_with_next_opening_time"
            defaultMessage="Sorry, we are closed today. The store will reopen on {openingDay}, {openingTime}"
            values={{
              openingDay: openingTime.toLocaleString(intl.locale, { weekday: "long" }),
              openingTime: openingTime.toLocaleTimeString(intl.locale, { hour: '2-digit', minute: '2-digit' }),
            }}
          />
        ) : (
          <FormattedMessage
            id="store_timings.store_closed_with_timings"
            defaultMessage="Sorry, we are closed. Please come back later. Store timings: {openingTime} to {closingTime}"
            values={{
              openingTime: openingTime.toLocaleTimeString(intl.locale, { hour: '2-digit', minute: '2-digit' }),
              closingTime: closingTime.toLocaleTimeString(intl.locale, { hour: '2-digit', minute: '2-digit' }),
            }}
          />
        )}
      </p>
    </div>
  );
};

export default RestaurantCloseBanner;
