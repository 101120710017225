import React, { useEffect, useRef, useState } from "react";

interface IGenericPaymentTypeWrapperProps {
  orderPayload: any;
  togglePlaceOrderButton: (status: boolean) => void;
  buildGenericPaymentsPayload: any;
  userDetails: any;
  paymentDetails: any;
}

function GenericPaymentTypeWrapper(props: IGenericPaymentTypeWrapperProps) {
  const [payloadData, setPayloadData] = useState("");
  const [paymentURL, setPaymentURL] = useState("");

  const genericFormRef = useRef<HTMLFormElement>(null);

  const fetchPaymentsPayload = () => {
    props
      .buildGenericPaymentsPayload(
        props.orderPayload,
        props.userDetails,
        props.paymentDetails
      )
      .then((response: any) => {
        if (!response.error && response?.payload?.data?.payment_payload) {
          setPayloadData(response.payload.data.payment_payload);
          setPaymentURL(response.payload.data.payment_url);
        } else {
          props.togglePlaceOrderButton(true);
        }
      });
  };

  useEffect(() => {
    if (payloadData && paymentURL && genericFormRef.current) {
      genericFormRef.current?.submit();
    }
  }, [payloadData, paymentURL]);

  return (
    <div>
      <button
        type="submit"
        id="genericPaymentsCheckoutButton"
        className="hidden"
        onClick={fetchPaymentsPayload}
      ></button>
      {payloadData && paymentURL ? (
        <form
          method="post"
          action={paymentURL}
          name="genericTransaction"
          id="genericPaymentTransaction"
          ref={genericFormRef}
        >
          <input name="payment_token" type="hidden" value={payloadData} />
        </form>
      ) : null}
    </div>
  );
}

export default GenericPaymentTypeWrapper;
