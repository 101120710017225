import * as pageTypes from "../page/types";
import { combineReducers } from "redux";
import { availableCategories } from "../../../helpers/categorySchedule";

const categoriesReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return availableCategories(action.payload.data.menu.categories || []);

    default: return state;
  }
};

const taxesReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return action.payload.data.menu.taxes || [];

    default: return state;
  }
};

const surchargesReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return action.payload.data.menu.surcharges || [];

    default: return state;
  }
};

const taxInclusiveReducer = ( state = true, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return action.payload.data.menu.is_tax_inclusive || false;

    default: return state;
  }
};

const outOfStockItemsReducer = (state = [], action: any) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return action.payload.data.out_of_stock_items || [];

    default: return state;
  }
}

const reducer = combineReducers({
  categories: categoriesReducer,
  taxes: taxesReducer,
  surcharges: surchargesReducer,
  is_tax_inclusive: taxInclusiveReducer,
  out_of_stock_items: outOfStockItemsReducer,
});

export default reducer;
