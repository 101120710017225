import * as types from "./types";

const fetchSignInConfigurations = () => ({
  type: types.FETCH_SIGN_IN_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/mobile/sign_in"
    }
  }
});

const fetchOrderDetailsConfigurations = () => ({
  type: types.FETCH_ORDER_DETAILS_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/mobile/order_details"
    }
  }
});

export default {
  fetchSignInConfigurations,
  fetchOrderDetailsConfigurations,
};
