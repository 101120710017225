// Category schedule related functions

const isCategoryAvailableNow = (timeSlots: any, currentTime: any) => {
  let startDate = new Date(currentTime);
  let endDate = new Date(currentTime);
  for (let j = 0; j < timeSlots.length; j++) {
    let startTime = timeSlots[j].start_time.split(':');
    let endTime = timeSlots[j].end_time.split(':');
    startDate.setHours(startTime[0]);
    startDate.setMinutes(startTime[1]);
    endDate.setHours(endTime[0]);
    endDate.setMinutes(endTime[1]);
    if (
      currentTime >= startDate.getTime() && currentTime <= endDate.getTime()
    ) {
      return true;
    }
  }
  return false;
}

const isCategoryAvailableToday = (
  schedules: any,
  day: string,
  currentTime: any
) => {
  for (let i = 0; i < schedules.length; i++) {
    let schedule = schedules[i]
    if (
      schedule.days.days.includes(day) &&
      isCategoryAvailableNow(schedule.time_slots, currentTime)
    ) {
      return true;
    }
  }
  return false;
}

const availableCategories = (categories: any) => {
  let currentDate = new Date();
  let currentTime = currentDate.getTime();
  // Adding 1 day as "Day" data comes from Sapaad as 1 to 7 instead of 0 to 6
  let currentDay = (parseInt(currentDate.getDay()) + 1).toString();
  return (
    categories.filter((category: any) => {
      if (category.schedules.length) {
        return (
          isCategoryAvailableToday(category.schedules, currentDay, currentTime)
        );
      }
      return true;
    })
  );
}

const findUnschedulableItems = (scheduledTime: any, itemsInCart: any, menuCategories: any) => {
  return itemsInCart.filter((itemInCart: any) => {
    let categoryContainingItem = menuCategories.find((menuCategory: any) => {
      return menuCategory.items.some((categoryItem: any) => {
        if (menuCategory.has_subcategory && categoryItem.items) {
          return categoryItem.items.some((subcategoryItem: any) => {
            return subcategoryItem.id === itemInCart.id;
          });
        } else {
          return categoryItem.id === itemInCart.id;
        }
      });
    });

    if (categoryContainingItem && categoryContainingItem.schedules.length) {
      let scheduledDay = (parseInt(scheduledTime.getDay()) + 1).toString();
      // Return true if item is not available in the specified date and time
      return !isCategoryAvailableToday(categoryContainingItem.schedules, scheduledDay, scheduledTime.getTime());
    }

    // Return false to filter out items without any schedule
    return false;
  });
};

export {
  availableCategories,
  findUnschedulableItems,
};
