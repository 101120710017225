import * as types from "./types";

const addressesReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case types.FETCH_ADDRESSES_SUCCESS:
      return action.payload.data;

    default: return state;
  }
};

const reducer = addressesReducer;

export default reducer;
