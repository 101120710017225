import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import InlineLoader from "../inlineLoader";

interface propTypes {
  totalAmount: number;
  fetchMyFatoorahPaymentModes: (amount: number) => any;
  selectedPaymentMode: (paymentMode: any) => any;
}

const MyFatoorahPaymentSelectionWrapper = (props: propTypes) => {
  const [loading, setLoading] = useState(false);
  const [myFatoorahPaymentModes, setMyFatoorahPaymentModes] = useState<any[]>(
    []
  );
  const [selectedMyFatoorahPaymentMode, setSelectedMyFatoorahPaymentMode] =
    useState<any>(null);

  useEffect(() => {
    fetchPaymentModes();
  }, []);

  const fetchPaymentModes = () => {
    setLoading(true);
    props
      .fetchMyFatoorahPaymentModes(props.totalAmount)
      .then((response: any) => {
        setLoading(false);
        if (!response.error) {
          setMyFatoorahPaymentModes(response.payload.data);
          setSelectedMyFatoorahPaymentMode(response.payload.data[0]?.id);
        } else {
          setLoading(false);
        }
      });
  };

  const onMyFatoorahPaymentSelect = (paymentMode: any) => {
    setSelectedMyFatoorahPaymentMode(paymentMode.id);
    props.selectedPaymentMode(paymentMode.id)
  };

  return (
    <div className="myFatoorah-payment-wrapper">
      {loading ? (
        <div className="loader">
          <InlineLoader />
        </div>
      ) : (
        <div className="selections">
          {myFatoorahPaymentModes.length > 1 && (
            <Fragment>
              <small>Choose your payment</small>
              {myFatoorahPaymentModes.map((pm) => (
                <ul>
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="myFatoorahSelectedPaymentMode"
                        checked={selectedMyFatoorahPaymentMode === pm.id}
                        onClick={() => onMyFatoorahPaymentSelect(pm)}
                      />
                      {pm.title}
                    </label>
                  </li>
                </ul>
              ))}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  fetchMyFatoorahPaymentModes:
    paymentTypeOperations.fetchMyFatoorahPaymentModes,
};

export default connect(
  null,
  mapDispatchToProps
)(MyFatoorahPaymentSelectionWrapper);
