import * as types from "./types";

const makeTipPayment = (path: string, tipPayload: any) => ({
  type: types.MAKE_TIP_PAYMENT,
  payload: {
    request: {
      method: "post",
      url: path,
      data: tipPayload,
    }
  }
});

export default {
  makeTipPayment,
};
