import * as types from "./types";

const updateError = (error: any) => ({
  type: types.UPDATE_ERROR,
  payload: error,
});

const resetError = () => ({
  type: types.RESET_ERROR,
});

export default { updateError, resetError };
