import * as types from "./types";

const fetchAddresses = () => ({
  type: types.FETCH_ADDRESSES,
  payload: {
    request: {
      method: "get",
      url: "/users/addresses",
    }
  }
});

const createAddress = (address: any) => ({
  type: types.CREATE_ADDRESS,
  payload: {
    request: {
      method: "post",
      url: "/users/addresses",
      data: {
        delivery_address: address.delivery_addresses,
        latitude: address.latitude,
        longitude: address.longitude
      }
    }
  }
});

const updateAddress = (address: any) => ({
  type: types.UPDATE_ADDRESS,
  payload: {
    request: {
      method: "put",
      url: `/users/addresses/${address.address_id}`,
      data: {
        sapaad_delivery_address_id: address.address_id,
        delivery_address: address.delivery_addresses,
        latitude: address.latitude,
        longitude: address.longitude
      }
    }
  }
});

const deleteAddress = (address: any) => ({
  type: types.DELETE_ADDRESS,
  payload: {
    request: {
      method: "delete",
      url: `/users/addresses/${address.address_id}`,
      data: {
        sapaad_delivery_address_id: address.address_id
      }
    }
  }
});

export default {
  fetchAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
};
