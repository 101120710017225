// Packages
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { userOperations } from "../../state/features/user";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import {
  updateItemWithCartIdInCart,
  removeItemWithCartId,
  outOfStockItemsList,
} from "../../helpers/cartFunctions";
import {
  formatDecimal,
  calculateAmountsAndBreakdowns,
} from "../../helpers/itemCalculations";
import {
  translatedName,
} from "../../helpers/translations";
import OutOfStockWarning from "../outOfStockWarning";

interface IOrdersDropdownProps {
  cart: any;
  setCart: any;
  company: any;
  user: any;
  setUser: any;
  menu: any;
  orderType: any;
  areaId: any;
  deliveryAreas: any;
  recentOrder: any;
}

function OrdersDropdown(props: IOrdersDropdownProps) {
  const [cartItems, setCartItems] = useState([] as any);
  const [subTotal, setSubTotal] = useState(0);
  const [amountToReachMinOrderValue, setAmountToReachMinOrderValue] = useState(0);

  const intl = useIntl();

  useEffect(() => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      props.cart.items,
      props.menu,
      props.orderType,
      null
    );
    setCartItems(props.cart.items);
    setSubTotal(amountBreakdowns.subTotal);
    if (props.deliveryAreas && props.orderType === "delivery") {
      checkMinOrderValue(amountBreakdowns.subTotal);
    }
  }, [props.cart, props.areaId]);

  const checkMinOrderValue = (totalAmount: any) => {
    let deliveryArea = props.deliveryAreas.find(
      (area: any) => area.id === props.areaId
    );
    if (Number(totalAmount) < Number(deliveryArea?.min_order_value)) {
      setAmountToReachMinOrderValue(
        Number(deliveryArea.min_order_value) - Number(totalAmount)
      );
    } else {
      setAmountToReachMinOrderValue(0)
    }
  };

  const proceedToCheckout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!props.user.phone_number || !props.user.dialing_code) {
      e.preventDefault();

      let user = props.user;
      user.proceed_to_checkout = true;
      props.setUser(user);
    }
  };

  const incrementCount = (item: any) => {
    if(props.menu.out_of_stock_items.includes(item.id)){
      return;
    }
    item.count = item.count + 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const decrementCount = (item: any) => {
    if(props.menu.out_of_stock_items.includes(item.id)){
      return;
    }
    item.count = item.count - 1;
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const removeItem = (item: any) => {
    removeItemWithCartId(item, props.cart.items, props.setCart);
  };

  const changeItemCount = (count: any, item: any) => {
    item.count = parseInt(count);
    updateItemWithCartIdInCart(item, props.cart.items, props.setCart);
  };

  const checkItemCount = (count: any, item: any) => {
    count = parseInt(count);
    if (!count || count < 0) {
      removeItemWithCartId(item, props.cart.items, props.setCart);
    }
  };

  const outOfStockCartItems = useMemo(()=>{
    return outOfStockItemsList(cartItems, props.menu.out_of_stock_items)
  }, [cartItems, props.menu.out_of_stock_items]);

  return (
    <div className="orders-dropdown-section">
      <div className="out-of-stock-message">
        {outOfStockCartItems.length ? (
          <OutOfStockWarning outOfStockItems={outOfStockCartItems} />
        ) : null}
      </div>
      <div className={`${outOfStockCartItems.length ? "with-out-of-stock" : ""} order-items`}>
        {cartItems.map((cartItem: any, index: number) => {
          return (
            <div className="item" key={index}>
              <p
                className={`${
                  props.menu.out_of_stock_items.includes(cartItem.id)
                    ? "out-of-stock-item-name"
                    : ""
                } item-name`}
              >
                {translatedName(cartItem, intl.locale)}
              </p>
              <div className="item-count">
                <input
                  type="number"
                  className="count"
                  value={cartItem.count || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeItemCount(e.target.value, cartItem);
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    checkItemCount(e.target.value, cartItem);
                  }}
                />
                <div className="count-control">
                  <FontAwesomeIcon
                    onClick={() => incrementCount(cartItem)}
                    icon={faChevronUp}
                    className="count-icon"
                  />
                  <FontAwesomeIcon
                    onClick={() => decrementCount(cartItem)}
                    icon={faChevronDown}
                    className={
                      cartItem.count > 1 ? "count-icon" : "count-icon disabled"
                    }
                  />
                </div>
              </div>
              <p className="item-price">
                {props.company.currency}{" "}
                {formatDecimal(cartItem.sub_total || 0)}
              </p>
              <div onClick={() => removeItem(cartItem)} className="delete-icon">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="amount-section">
        <p>
          <FormattedMessage
            id="global.uppercase.total"
            defaultMessage="TOTAL"
          />
        </p>
        <p>
          {props.company.currency} {formatDecimal(subTotal)}
        </p>
      </div>
      {outOfStockCartItems.length || props.recentOrder ? (
        <div className="button btn btn-secondary disabled w-100">
          <FormattedMessage id="global.checkout" defaultMessage="Checkout" />
        </div>
      ) : (
        <Link
          to="/checkout"
          id="goToCheckoutFromCartBtn"
          className={
            amountToReachMinOrderValue
              ? "button btn btn-secondary disabled w-100"
              : "button btn btn-primary w-100"
          }
          onClick={proceedToCheckout}
        >
          {amountToReachMinOrderValue ? (
            <FormattedMessage
              id="checkout.add_more_to_checkout"
              defaultMessage="Add {currency} {amount} more to Checkout"
              values={{
                currency: props.company.currency,
                amount: formatDecimal(amountToReachMinOrderValue),
              }}
            />
          ) : (
            <FormattedMessage id="global.checkout" defaultMessage="Checkout" />
          )}
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let user = state.user || {};
  let menu = state.menu;
  let cart = state.cart;
  let orderType = state.session.order_type;
  let areaId = state.session.area_id;
  let deliveryAreas = state.outlet?.delivery_areas;
  let recentOrder = state.recentOrder;

  return {
    company,
    user,
    cart,
    menu,
    orderType,
    areaId,
    deliveryAreas,
    recentOrder
  };
};

const mapDispatchToProps = {
  setUser: userOperations.setUser,
  setCart: cartOperations.setCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDropdown);
