import * as pageTypes from "../page/types";

const transformedLoyaltyConfig = (config: any) => {
  config.delivery = { enabled: config.enabled_for_delivery };
  config.pickup = { enabled: config.enabled_for_pickup };
  delete config.enabled_for_delivery;
  delete config.enabled_for_pickup;

  return config;
}

const loyaltyConfigReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS:
      return (
        transformedLoyaltyConfig(action.payload.data.company.loyalty_details)
      );

    default: return state;
  }
};

const reducer = loyaltyConfigReducer;

export default reducer;
