import * as types from "./types";
import * as pageTypes from "../page/types";
import * as userTypes from "../user/types";
import * as tipTypes from "../tips/types";

const orderReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS:
      return action.payload.data.order;
    case types.PLACE_ORDER_SUCCESS:
    case tipTypes.MAKE_TIP_PAYMENT_SUCCESS:
      return action.payload.data;
    case types.UPDATE_ORDER:
      return {
        ...action.payload,
      };
    case userTypes.FETCH_ORDER_IN_PROGRESS_SUCCESS:
      return action.payload.data;

    default: return state;
  }
};

const reducer = orderReducer;

export default reducer;
