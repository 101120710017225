// Packages
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
// Icons, Images etc.
import facebookLogo from "../../images/facebook.svg";
import instagramLogo from "../../images/instagram.svg";
import twitterLogo from "../../images/twitter.svg";
// Redux Operations
import { RootState } from "../../state/store";

const FooterMobile = () => {
  const { company } = useSelector((state: RootState) => state);

  const socialMediaSettings = company?.social_media_settings;

  const socialLinkOnClickHandler = (socialMediaLink: string) => {
    window.open(socialMediaLink, '_blank');
  };

  return (
    <div className="footer-container-mobile">
      <hr />
      {socialMediaSettings ? (
        <div className="social-icons">
          {socialMediaSettings.facebook_url ? (
            <img
              src={facebookLogo}
              className="icon"
              onClick={() => {
                socialLinkOnClickHandler(`https://facebook.com/${socialMediaSettings.facebook_url}`)
              }}
            />
          ) : false}
          {socialMediaSettings.instagram_url ? (
            <img
              src={instagramLogo}
              className="icon"
              onClick={() => {
                socialLinkOnClickHandler(`https://instagram.com/${socialMediaSettings.instagram_url}`)
              }}
            />
          ) : false}
          {socialMediaSettings.twitter_url ? (
            <img
              src={twitterLogo}
              className="icon"
              onClick={() => {
                socialLinkOnClickHandler(`https://twitter.com/${socialMediaSettings.twitter_url}`)
              }}
            />
          ) : false}
        </div>
      ) : false}
    </div>
  );
};

export default FooterMobile;
