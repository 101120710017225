// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import InlineLoader from "../../components/inlineLoader";
import SavedAddressWidget from "../savedAddressWidget";
// Redux Operations
import { addressesOperations } from "../../state/features/addresses";

interface ISavedAddressesProps {
  addresses: any;
  fetchAddresses: any;
}

function SavedAddresses(props: ISavedAddressesProps) {
  const [loading, handleLoading] = useState(true);

  useEffect(() => {
    props.fetchAddresses().then(() => {
      handleLoading(false);
    });
  }, []);

  const reloadAddresses = () => {
    handleLoading(true);
    props.fetchAddresses().then(() => {
      handleLoading(false);
    });
  }

  return (
    <div className="section saved-address-list">
      <p className="section-heading with-action">
        <FormattedMessage
          id="my_account.saved_addresses"
          defaultMessage="Saved Addresses"
        />
      </p>
      {loading ? (
        <InlineLoader />
      ) : props.addresses && props.addresses.length ? (
        props.addresses.map((address: any, index: any) => {
          return (
            <SavedAddressWidget
              key={index}
              address={address}
              reloadAddresses={reloadAddresses}
            />
          )
        })
      ) : (
        <div className='noaddress'>
          <FormattedMessage
            id="my_account.no_address_found"
            defaultMessage="No addresses found."
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let addresses = state.addresses;
  return {
    addresses,
  };
};

const mapDispatchToProps = {
  fetchAddresses: addressesOperations.fetchAddresses
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedAddresses);
