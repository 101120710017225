import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { IntlProvider } from 'react-intl';

// Import translation data for each supported locale
import EnglishLocale from "../locales/en.json";
import ArabicLocale from "../locales/ar.json";
import MandarinLocale from "../locales/zh.json";
import MalayLocale from "../locales/ms.json";

// Used to send locale data and methods to update locale data in all components
export const LocaleContext = React.createContext();

// Details of supported locales
export const SUPPORTED_LOCALES = {
  en: {
    name: "English", locale: EnglishLocale
  },
  ar: {
    name: "Arabic", locale: ArabicLocale
  },
  zh: {
    name: "Mandarin", locale: MandarinLocale
  },
  ms: {
    name: "Malay", locale: MalayLocale
  }
}

interface ILocaleWrapperProps {
  localeData: string;
}

const LocaleWrapper = (props: ILocaleWrapperProps) => {
  const [locale, setLocale] = useState(localStorage.userLocale || "en");
  const [messages, setMessages] = useState(SUPPORTED_LOCALES[localStorage.userLocale]?.locale || EnglishLocale);

  useEffect(() => {
    if (props.localeData) {
      if (localStorage.userLocale) {
        let validLocale = props.localeData.locales?.includes(localStorage.userLocale)
        updateLocale(
          validLocale ? localStorage.userLocale : props.localeData.default
        );
      } else {
        updateLocale(props.localeData.default);
      }
    }
  }, [props.localeData]);

  const updateLocale = (userLocale: any) => {
    if (Object.keys(SUPPORTED_LOCALES).includes(userLocale)) {
      setLocale(userLocale);
      setMessages(SUPPORTED_LOCALES[userLocale].locale);
      localStorage.setItem("userLocale", userLocale);
    } else {
      setLocale("en");
      setMessages(EnglishLocale);
      localStorage.removeItem("userLocale");
    }

    // Update RTL or LTR based on selected locale
    document.getElementsByTagName("html")[0].setAttribute("lang", userLocale);

    if (userLocale == "ar") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("body")[0].classList.add("rtl");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("body")[0].classList.remove("rtl");
    }
  };

  return (
    <LocaleContext.Provider value={{ locale, updateLocale }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>

  );
}

const mapStateToProps = (state: any) => {
  let localeData = state.company.locale_data;

  return {
    localeData,
  };
};

export default connect(mapStateToProps)(LocaleWrapper);
