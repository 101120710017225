// Packages
import React from "react";
import { useIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import restaurantClosedImage from "../../images/closed.png";

interface IStoreCloseBanner {
  storeTimings: any;
}

function StoreCloseBanner(props: IStoreCloseBanner) {
  const {
    openingTime,
    closingTime,
    openingDay,
  } = props.storeTimings;

  // Localisation object
  const intl = useIntl();

  return (
    <div className="store-closed-banner">
      <div className="image-container">
        <img src={restaurantClosedImage} />
      </div>
      <p className="message">
        <span>
          {!openingDay ? (
            <React.Fragment>
              <FormattedMessage
                id="store_timings.store_closed"
                defaultMessage="Sorry, we are closed."
              /> <br />
              <FormattedMessage
                id="store_timings.come_back_later"
                defaultMessage="Please come back later"
              />
            </React.Fragment>
          ) : openingDay != "Today" ? (
            <React.Fragment>
              <FormattedMessage
                id="store_timings.store_closed_today"
                defaultMessage="Sorry, we are closed today."
              /> <br />
              <FormattedMessage
                id="store_timings.come_back_later_with_next_opening_time"
                defaultMessage="The store will reopen on {openingDay}, {openingTime}"
                values={{
                  openingDay: openingTime.toLocaleString(intl.locale, { weekday: "long" }),
                  openingTime: openingTime.toLocaleTimeString(intl.locale, { hour: '2-digit', minute: '2-digit' }),
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormattedMessage
                id="store_timings.store_closed"
                defaultMessage="Sorry, we are closed."
              /> <br />
              <FormattedMessage
                id="store_timings.come_back_later_with_timings"
                defaultMessage="Please come back later. Store timings: {openingTime} to {closingTime}"
                values={{
                  openingTime: openingTime.toLocaleTimeString(intl.locale, { hour: '2-digit', minute: '2-digit' }),
                  closingTime: closingTime.toLocaleTimeString(intl.locale, { hour: '2-digit', minute: '2-digit' }),
                }}
              />
            </React.Fragment>
          )}
        </span>
      </p>
    </div>
  );
}

export default StoreCloseBanner;
