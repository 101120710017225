import actions from "./actions";

const fetchAddresses = actions.fetchAddresses;
const createAddress = actions.createAddress;
const updateAddress = actions.updateAddress;
const deleteAddress = actions.deleteAddress;

export default {
  fetchAddresses,
  createAddress,
  updateAddress,
  deleteAddress,
};
