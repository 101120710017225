// Packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import Header from "../../components/header";
// Redux Operations
import { pageOperations } from "../../state/features/page";
// Helpers, Utils etc.
import countryCodes from "../../helpers/countryCodes";

interface ITermsOfServiceProps {
  company: any;
  paymentTypes: any;
  fetchTermsOfServiceConfigurations: any;
}

function TermsOfService(props: ITermsOfServiceProps) {
  const [countryName, setCountryName] = useState("");
  const [omisePayment, setOmisePayment] = useState(null);
  let sectionNumber = 1;

  useEffect(() => {
    props.fetchTermsOfServiceConfigurations();
  }, []);

  useEffect(() => {
    if (props.company.country_code) {
      let country = countryCodes.find((countryObj: any) => {
        return countryObj.code == props.company.country_code;
      });
      if (country && country.name) {
        setCountryName(country.name);
      }
    }
  }, [props.company.country_code]);

  useEffect(() => {
    const omisePType = props.paymentTypes.find((pType: any) => {
      return pType.payment_type == 'omise';
    });
    setOmisePayment(omisePType);
  }, [props.paymentTypes]);

  return (
    <React.Fragment>
      <Header />
      <div className="tos-page">
        <div className="container">
          <h1 className="text-center">
            <FormattedMessage
              id="terms_of_service.header"
              defaultMessage="Terms of Service of {companyName}"
              values={{
                companyName: props.company.name
              }}
            />
          </h1>

          <h5>
            <FormattedMessage
              id="terms_of_service.disclaimer.header"
              defaultMessage="DISCLAIMER"
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.disclaimer.line_1"
              defaultMessage="While all reasonable care has been taken in the preparation of the Terms and Condition in this website, we do not warrant the completeness or adequacy of the information. We do not warrant that this website, or any advertisement in this website or any attachment to it, is free from computer viruses. We have no control over the content of the websites maintained by third parties, which may be accessible through websites maintained by us. We do not make any representation or warranty with respect to such websites or any information."
            />
          </p>
          <p>
            <FormattedMessage
              id="terms_of_service.disclaimer.line_2"
              defaultMessage="Minors under the age of 18 shall are prohibited to register as a User of this website and are not allowed to transact or use the website."
            />
          </p>
          <p>
            <FormattedMessage
              id="terms_of_service.disclaimer.line_3"
              defaultMessage="By making a purchase or accessing the website of {companyName} (“Restaurant”), you agree to the following Terms and Conditions. It is your own responsibility to ensure that you are fully aware of all of these Terms and Conditions when making a purchase on <aTag>{websiteURL}</aTag>."
              values={{
                companyName: props.company.name,
                websiteURL: props.company.website_url,
                aTag: (text) => <a href={props.company.website_url}>{text}</a>,
              }}
            />
          </p>

          <h5>
            <FormattedMessage
              id="terms_of_service.terms_of_use.header"
              defaultMessage="TERMS OF USE"
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.terms_of_use.line_1"
              defaultMessage="Please read the following terms and conditions very carefully as your use of {companyName} Website and services offered by {companyName} are subject to your acceptance of and compliance with the following terms and conditions."
              values={{
                companyName: props.company.name
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="terms_of_service.terms_of_use.line_2"
              defaultMessage={"By browsing, accessing, subscribing to or using any of our services you agree that you have read, understood and are bound by the terms. In these terms, references to \"website\" shall mean the {companyName} website, \"you\", \"user\" shall mean the end user accessing the website, its contents and using the services offered through {companyName}, \"Service Providers\" mean independent third party service providers, and \"we\", \"us\" and \"our\" shall mean {companyName} and its affiliates."}
              values={{
                companyName: props.company.name
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="terms_of_service.terms_of_use.line_3"
              defaultMessage="{companyName} reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without notification. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Website following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these terms, {companyName} grants you a personal, non-exclusive, non-transferable, limited privilege to access and use the website and services offered by {companyName}."
              values={{
                companyName: props.company.name
              }}
            />
          </p>

          <h5>
            <FormattedMessage
              id="terms_of_service.intended_audience_and_use.header"
              defaultMessage="{sectionNumber}. INTENDED AUDIENCE AND USE"
              values={{
                sectionNumber: sectionNumber
              }}
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.intended_audience_and_use.line_1"
              defaultMessage="{companyName} provides this website for the lawful use of its customers, employees, and members of the general public who are over the age of 18. {companyName} makes no representation that the materials and products contained or displayed on this website are appropriate or available for use and access from all locations. Those who access the website from other locations other than {countryName} do so at their own risk and are responsible for compliance with applicable local laws."
              values={{
                companyName: props.company.name,
                countryName: countryName
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="terms_of_service.intended_audience_and_use.line_2"
              defaultMessage="Registering at {companyName} is free service and you will receive a username and password upon completing the website's registration process or completing a transaction. You are responsible for maintaining the confidentiality of the username and password, and are fully responsible for all activities that occur under your account."
              values={{
                companyName: props.company.name
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="terms_of_service.intended_audience_and_use.line_3"
              defaultMessage="By completing the registration process and creating an account, You agree to"
            />
          </p>
          <ol type="a">
            <li>
              <FormattedMessage
                id="terms_of_service.intended_audience_and_use.line_3.point_1"
                defaultMessage="(a)  Immediately notify {companyName} for any unauthorized use of your account or any other breach of security, and"
                values={{
                  companyName: props.company.name
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="terms_of_service.intended_audience_and_use.line_3.point_2"
                defaultMessage="(b)  Ensure that you exit from your account at the end of each session. {companyName} cannot and will not be liable for any loss or damage arising from your failure to comply with this requirement. You agree that if you provide any information that is untrue, inaccurate, not current or incomplete, {companyName} has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the Terms of Use, {companyName} has the right to indefinitely suspend or terminate or block access of your account with {companyName} and refuse to provide you with access to the website."
                values={{
                  companyName: props.company.name
                }}
              />
            </li>
          </ol>
          <p>
            <FormattedMessage
              id="terms_of_service.intended_audience_and_use.line_4"
              defaultMessage="If you are a non registered user, you agree that upon reviewing the order at the website a user account will be auto created for you and the access to the same will be communicated to your email address provided."
            />
          </p>

          <h5>
            <FormattedMessage
              id="terms_of_service.user_conduct_and_rules.header"
              defaultMessage="{sectionNumber}. USER CONDUCT AND RULES"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.user_conduct_and_rules.line_1"
              defaultMessage="You agree and undertake to use the website strictly abiding by the following principles:"
            />
          </p>
          <ol>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_1"
                defaultMessage="(a)  You will not upload, publish, host, transmit, display, modify, update or share any information that, in the sole discretion of {companyName}."
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <ol type='i'>
              <li>
                <FormattedMessage
                  id="terms_of_service.user_conduct_and_rules.line_1.point_1.sub_point_1"
                  defaultMessage="Is grossly racial, harmful, sexual, harassing, defamatory, obscene, pornographic or otherwise unlawful in any manner whatsoever."
                />
              </li>
              <li>
                <FormattedMessage
                  id="terms_of_service.user_conduct_and_rules.line_1.point_1.sub_point_2"
                  defaultMessage="Involves an illegal or unauthorized use of copyrighted work."
                />
              </li>
              <li>
                <FormattedMessage
                  id="terms_of_service.user_conduct_and_rules.line_1.point_1.sub_point_3"
                  defaultMessage="Is not belonging to you or to which you have no rights."
                />
              </li>
              <li>
                <FormattedMessage
                  id="terms_of_service.user_conduct_and_rules.line_1.point_1.sub_point_4"
                  defaultMessage="Refers to any website or URL that contains material that is inappropriate for {companyName}."
                  values={{
                    companyName: props.company.name
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="terms_of_service.user_conduct_and_rules.line_1.point_1.sub_point_5"
                  defaultMessage="Contains software viruses, computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource."
                />
              </li>
            </ol>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_2"
                defaultMessage="(b) You will not promote or try to gain unauthorized access or exceed the scope of authorized access to {companyName} and its connected network;"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_3"
                defaultMessage="(c) You will not interfere with another user's use of the {companyName} website;"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_4"
                defaultMessage="(d) You will not violate any law currently in force in {countryName} or outside;"
                values={{
                  countryName: countryName
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_5"
                defaultMessage="(e) You will not attempt or otherwise impersonate another person during the use of {companyName} website;"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_6"
                defaultMessage="(f) You will not engage in or forward chain letters, surveys, contests, pyramid schemes with any association to {companyName};"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_7"
                defaultMessage="(g) You will not use any automatic device, program, or methodology including but not limited to “deep- link”, “page-scrape”, “robot”, “spider” or other such means, or any similar manual process, to access, acquire or copy any portion of the {companyName} website or any content within;"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_8"
                defaultMessage="(h) You will not attempt to gain unauthorized access to any portion or feature of {companyName} website, or any other systems or networks connected to {companyName} website;"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_9"
                defaultMessage="(i) You will not use the website or any content for any purpose that is unlawful or prohibited by these Terms of Use;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_10"
                defaultMessage="(j) You will not violate any of the terms and conditions of this agreement or any other terms and conditions contained elsewhere within {companyName} website, and;"
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.user_conduct_and_rules.line_1.point_11"
                defaultMessage="(k) You will indemnify, defend and hold harmless {companyName} and its owner, licensee, affiliates, and employees from and against any and all liabilities, claims, costs and damages, (including legal fees and disbursements in connection therewith and interest chargeable thereon) result from any breach of this Terms of Use, Privacy Policy and other policies, or your violation of any law, rules or regulations or the rights of a third party."
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
          </ol>

          <h5>
            <FormattedMessage
              id="terms_of_service.responsibility_of_contributors.header"
              defaultMessage="{sectionNumber}. RESPONSIBILITY OF CONTRIBUTORS"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>

          <p>
            <FormattedMessage
              id="terms_of_service.responsibility_of_contributors.line_1"
              defaultMessage="If you operate a blog, comment on a blog, post material to the website, post links on the website, or otherwise make (or allow any third party to make) material available by means of the website (any such material, “Content”), You are entirely responsible for the content of, and any harm resulting from, that Content. That is the case regardless of whether the Content in question constitutes text, graphics, an audio file, or computer software. By making Content available, you represent and warrant that:"
            />
          </p>
          <ol>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_1"
                defaultMessage="(a) the downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of any third party;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_2"
                defaultMessage="(b) if your employer has rights to intellectual property you create, you have either (i) received permission from your employer to post or make available the Content, including but not limited to any software, or (ii) secured from your employer a waiver as to all rights in or to the Content;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_3"
                defaultMessage="(c) you have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_4"
                defaultMessage="(d) the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_5"
                defaultMessage="(e) the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to Third Party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_6"
                defaultMessage="(f) the Content is not pornographic, does not contain threats or incite violence towards individuals or entities, and does not violate the privacy or publicity rights of any third party;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_7"
                defaultMessage="(g) your blog is not getting advertised via unwanted electronic messages such as spam links on newsgroups, email lists, other blogs and websites, and similar unsolicited promotional methods;"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_8"
                defaultMessage="(h) your blog is not named in a manner that misleads your readers into thinking that you are another person or company. For example, your blog’s URL or name is not the name of a person other than yourself or company other than your own; and"
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.responsibility_of_contributors.line_1.point_9"
                defaultMessage="(i) you have, in the case of Content that includes computer code, accurately categorized and/or described the type, nature, uses and effects of the materials."
              />
            </p>
          </ol>

          <h5>
            <FormattedMessage
              id="terms_of_service.general_terms.header"
              defaultMessage="{sectionNumber}. GENERAL TERMS"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <ol>
            <p>
              <strong>
                <FormattedMessage
                  id="terms_of_service.general_terms.line_1"
                  defaultMessage="(a) Online orders and Payments."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_1.point_1"
                defaultMessage="Any online order placed by you to purchase a particular product or services for a stated price, which may or may not include delivery or other charges. These prices are subject to change without notice. If you make a payment for our products or services on our website, the details you are asked to submit will be provided directly to our payment provider via a secured connection. The cardholder must retain a copy of transaction records and Merchant policies and rules for refuting any transaction."
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_1.point_2"
                defaultMessage="Any dispute or claim arising out of or in connection with this website or payment shall be governed and construed in accordance with the applicable laws and regulations of the {countryName} or jurisdiction."
                values={{
                  countryName: countryName
                }}
              />
            </p>
            <p>
              <strong>
                <FormattedMessage
                  id="terms_of_service.general_terms.line_2"
                  defaultMessage="(b) Delivery of your Order."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_2.point_1"
                defaultMessage="The logistics of delivery shall be handled by us. At the time of placing an order, an estimated time of delivery depending on your location would also be shared with you along with a contact number for tracking status of your order. Please note that we work our best to ensure that you receive your order as fast as we can but sometimes due to external factors like traffic, strikes etc., your order might take a while longer."
              />
            </p>
            <p>
              <strong>
                <FormattedMessage
                  id="terms_of_service.general_terms.line_3"
                  defaultMessage="(c) Cancellation and refunds."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_3.point_1"
                defaultMessage="Orders cannot be cancelled by customers after it is being confirmed by us and the payment has been processed. However, in the unlikely event of an item on your order being unavailable, we will contact you on the phone number or email address provided to us at the time of placing the order and inform you of such unavailability. In such event we will refund you the price of the unavailable item. We reserve the sole right to cancel your order in the following circumstances (i) in the event of the designated address following outside the delivery zone offered by us (ii) failure due to reasons beyond our control (iii) unavailability of all items ordered by you at the time of online ordering."
              />
            </p>
            <p>
              <strong>
                <FormattedMessage
                  id="terms_of_service.general_terms.line_4"
                  defaultMessage="(d) Fees and Payments to Purchased Services."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_4.point_1"
                defaultMessage="You will pay all fees specified in Purchase Order Forms. Except as otherwise specified herein or in a Purchase Order Form, (i) fees are based on Product and Services purchased by you and not the actual usage, (ii) payment obligations are non-cancellable and fees are non-refundable, (iii) All prices are indicated in {currency}, including VAT, GST or applicable taxes. Any sales incurred in a currency other than {currency} shall be converted into {currency} using Current standard exchange rate methodology as consistently applied in its external (iv) promotional prices may apply. These prices are valid only during a specific period. No entitlement to these prices may be invoked before or after the specific period, (vi) {companyName} cannot be held to any price indicators that are clearly incorrect, for example, as a result of obvious printing errors or typesetting."
                values={{
                  companyName: props.company.name,
                  currency: props.company.currency,
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_4.point_2"
                defaultMessage="Methods of payment we accept: Cash, MasterCard, American Express, VISA, net banking and other approved payment methods. You will not receive confirmation of your definitive booking until your payments has been approved."
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.general_terms.line_4.point_3"
                defaultMessage="At the end of the payment process, You will be presented with a receipt on-screen containing your order number. This order number should be quoted for any enquiries you may have relating to Your order. The page can be printed for your reference."
              />
            </p>
          </ol>

          <h5>
            <FormattedMessage
              id="terms_of_service.links_to_third_party_website.header"
              defaultMessage="{sectionNumber}. LINKS TO THIRD PARTY WEBSITE"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.links_to_third_party_website.line_1"
              defaultMessage="This website may provide links to third-party websites or resources. These links are provided solely as a convenience for you. {companyName} has no control over these third-party websites and resources and makes no endorsement or representation about these websites, or the accuracy of the information and materials, advertising, products, services, or the results that you may obtain by using them. If you decide to access such third-party websites, you acknowledge that you do so entirely at your own risk."
              values={{
                companyName: props.company.name
              }}
            />
          </p>

          {omisePayment ? (
            <React.Fragment>
              <h5>
                <FormattedMessage
                  id="terms_of_service.data_collection_and_privacy.header"
                  defaultMessage="{sectionNumber}. DATA COLLECTION AND PRIVACY"
                  values={{
                    sectionNumber: sectionNumber += 1
                  }}
                />
              </h5>
              <p>
                <FormattedMessage
                  id="terms_of_service.data_collection_and_privacy.line_1"
                  defaultMessage="{companyName} is committed to protecting the privacy of your personally identifiable information."
                  values={{
                    companyName: props.company.name,
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="terms_of_service.data_collection_and_privacy.line_2"
                  defaultMessage="By accessing this website or by making a purchase, the User hereby consents, expresses and agrees that he/she has read and fully understands the Privacy Policy of {companyName} in respect of the website."
                  values={{
                    companyName: props.company.name,
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="terms_of_service.data_collection_and_privacy.line_3"
                  defaultMessage="The user further consents that the terms and contents of such a Privacy Policy are acceptable to the user."
                />
              </p>
              <ol>
                <p>
                  <strong>
                    <FormattedMessage
                      id="terms_of_service.data_collection_and_privacy.information_collected.header"
                      defaultMessage="(a) Information Collected"
                    />
                  </strong>
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.information_collected.line_1"
                    defaultMessage="When making a purchase or accessing the website of {companyName}, we collect certain details in relation to processing the order including (but not limited to) the following: your name, nationality and contact information (email, phone, fax) for the purposes of providing our services to you."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
              </ol>
              <ol>
                <p>
                  <strong>
                    <FormattedMessage
                      id="terms_of_service.data_collection_and_privacy.use_and_care.header"
                      defaultMessage="(b) Use and Care for Information"
                    />
                  </strong>
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.use_and_care.line_1"
                    defaultMessage="{companyName} values your privacy."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.use_and_care.line_2"
                    defaultMessage="Disclosure of information is limited only to parties connected to fulfilling the service."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.use_and_care.line_3"
                    defaultMessage="{companyName} does not sell, rent, or otherwise trade any personal information from our guests to any party for marketing purposes."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.use_and_care.line_4"
                    defaultMessage="{companyName} also reserves the right to disclose personal information only if required to do so by law, or by court order, or by law enforcement or governmental authorities, or in good faith belief that such disclosure is necessary to protect the rights or properties of {companyName} users or the public."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
              </ol>
              <ol>
                <p>
                  <strong>
                    <FormattedMessage
                      id="terms_of_service.data_collection_and_privacy.data_security.header"
                      defaultMessage="(c) Data Security"
                    />
                  </strong>
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.data_security.line_1"
                    defaultMessage="We, at {companyName} give the utmost importance to keeping your data secure at all times."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.data_security.line_2"
                    defaultMessage="All data in relation to you collected by {companyName} shall be stored till such time you continue to use the services of {companyName}."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.data_security.line_3"
                    defaultMessage="In the event you cease to use any services of {companyName} for a continuous period of 12 (twelve) months then Restaurant shall remove and delete your data from its database."
                    values={{
                      companyName: props.company.name,
                    }}
                  />
                </p>
              </ol>
              <ol>
                <p>
                  <strong>
                    <FormattedMessage
                      id="terms_of_service.data_collection_and_privacy.cookies_collection.header"
                      defaultMessage="(d) Cookies Collection"
                    />
                  </strong>
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.cookies_collection.line_1"
                    defaultMessage="Restaurant may use technologies such as “cookies” in order to record session information, collect visit and access details to our web pages that enable us to continue to improve our services and provide better and useful features to our site users/guests."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.cookies_collection.line_2"
                    defaultMessage="Cookies are pieces of information that are normally used by many websites for record-keeping purposes that can help sites remember, for example, your preferences."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.cookies_collection.line_3"
                    defaultMessage="A cookie, in and of itself does not personally identify any user/guest."
                  />
                </p>
              </ol>
              <ol>
                <p>
                  <strong>
                    <FormattedMessage
                      id="terms_of_service.data_collection_and_privacy.dispute.header"
                      defaultMessage="(e) Dispute"
                    />
                  </strong>
                </p>
                <p>
                  <FormattedMessage
                    id="terms_of_service.data_collection_and_privacy.dispute.line_1"
                    defaultMessage="For any concerns relating to the privacy policy, you may contact ({contactName}) at ({contactEmail})."
                    values={{
                      contactName: omisePayment['contact_name'],
                      contactEmail: omisePayment['contact_email'],
                    }}
                  />
                </p>
              </ol>
            </React.Fragment>
          ) : null}

          <h5>
            <FormattedMessage
              id="terms_of_service.privacy_policy.header"
              defaultMessage="{sectionNumber}. PRIVACY POLICY"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.privacy_policy.line_1"
              defaultMessage="{companyName} is committed to protecting the privacy of your personally identifiable information. Please consult our Privacy Policy on {websiteURL} for more information. By accessing this website or by making a purchase, the User hereby consents, expresses and agrees that he/she has read and fully understands the Privacy Policy of {companyName} in respect of the website. The user further consents that the terms and contents of such a Privacy Policy are acceptable to the user."
              values={{
                companyName: props.company.name,
                websiteURL: props.company.website_url,
              }}
            />
          </p>

          <h5>
            <FormattedMessage
              id="terms_of_service.intellectual_property_rights.header"
              defaultMessage="{sectionNumber}. INTELLECTUAL PROPERTY RIGHTS"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <ol>
            <p>
              <strong>
                <FormattedMessage
                  id="terms_of_service.intellectual_property_rights.line_1"
                  defaultMessage="(a) Use of Website Content."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.intellectual_property_rights.line_1.point_1"
                defaultMessage="We, our logo and contents (notably data, images, movies, soundtracks, information, illustrations, logos, brands, etc.) displayed on our website {websiteURL} is exclusive intellectual property of {companyName} and respective publishers of the content. We have copyright over its unique software and compilation used in working of this website. You shall not reproduce, copy, modify, adapt, represent, circulate or alter any portion of the website for any use, whether commercial or personal, without express written consent from us.  You may not frame or utilize framing technologies to enclose any trademark, logo, or proprietary information (including images, text, page layout, or form) of us and our affiliates without our express written consent. You may not use any metatags or any other ‘hidden text’ utilizing our name or trademarks without our express written consent. Any unauthorized use terminates the permission granted by us to you forthwith and would further attract legal action against the violator. The elements presented in this website are liable to modification without notice and are presented without any express or tacit guarantee and are not considered as giving any rights of compensation."
                values={{
                  companyName: props.company.name,
                  websiteURL: props.company.website_url,
                }}
              />
            </p>
            <p>
              <strong>
                <FormattedMessage
                  id="terms_of_service.intellectual_property_rights.line_2"
                  defaultMessage="(b) Trademark Information."
                />
              </strong>
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.intellectual_property_rights.line_2.point_1"
                defaultMessage="{companyName} trademarks may not be used in connection with any product or service that is not {companyName} in any manner that is likely to cause confusion among customers or in any manner that disparages or discredits {companyName}. All other trademarks not owned by {companyName} or its affiliates that appear on this Website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by {companyName} or its affiliates."
                values={{
                  companyName: props.company.name
                }}
              />
            </p>
          </ol>

          <h5>
            <FormattedMessage
              id="terms_of_service.warranties_and_liability.header"
              defaultMessage="{sectionNumber}. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <ol>
            <p>
              <FormattedMessage
                id="terms_of_service.warranties_and_liability.line_1"
                defaultMessage={"(a) This website is provided by us on an \"as is\" and \"as available\" basis."}
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.warranties_and_liability.line_2"
                defaultMessage="(b) We make no representations or warranties of any kind, express or implied, as to the operation of this website or the information, content, materials, or products shown on this website. You expressly agree that your use of this website is at your sole risk."
              />
            </p>
            <p>
              <FormattedMessage
                id="terms_of_service.warranties_and_liability.line_3"
                defaultMessage="(c) We do not warrant that the website, its servers, or email sent from them are free of virus or other harmful components. We will not be liable for any damages of any kind arising from the use of this website, including, but not limited to direct, indirect, incidental, punitive and consequential damages."
              />
            </p>
          </ol>

          <h5>
            <FormattedMessage
              id="terms_of_service.govering_law.header"
              defaultMessage="{sectionNumber}. GOVERNING LAW"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>

          <p>
            <FormattedMessage
              id="terms_of_service.govering_law.line_1"
              defaultMessage="These terms and conditions will be construed only in accordance with the laws of {countryName}. In respect of all matters/disputes arising out of, in connection with or in relation to these terms and conditions or any other conditions on this website, only the competent Courts at {countryName} shall have jurisdiction, to the exclusion of all other courts."
              values={{
                countryName: countryName
              }}
            />
          </p>

          <h5>
            <FormattedMessage
              id="terms_of_service.effectiveness.header"
              defaultMessage="{sectionNumber}. EFFECTIVENESS"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.effectiveness.line_1"
              defaultMessage="These Terms of Use are effective when posted."
            />
          </p>

          <h5>
            <FormattedMessage
              id="terms_of_service.prevailing_language.header"
              defaultMessage="{sectionNumber}. PREVAILING LANGUAGE"
              values={{
                sectionNumber: sectionNumber += 1
              }}
            />
          </h5>
          <p>
            <FormattedMessage
              id="terms_of_service.prevailing_language.line_1"
              defaultMessage="This Agreement has been executed in Arabic and English language. In case of any discrepancy between the Arabic and the English versions of the Agreement, the English version shall prevail."
            />
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company || {};
  let paymentTypes = state.paymentTypes || [];

  return {
    company,
    paymentTypes,
  };
};

const mapDispatchToProps = {
  fetchTermsOfServiceConfigurations: pageOperations.fetchTermsOfServiceConfigurations,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);
