// Packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Pusher from "pusher-js";
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
// Components
import TopHeader from "../../mobileComponents/topHeader";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faMotorcycle,
  faMoneyBillWave,
  faCreditCard,
  faCashRegister,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { orderInProgressOperations } from "../../state/features/orderInProgress";
// Helpers, Utils etc.
import trackingDetails from "../../helpers/orderTracking";
import { formatDecimal } from "../../helpers/itemCalculations";
import { obtainEtaDisplayText } from "../../helpers/utils";
import {
  CARD_ON_DELIVERY,
  CASH_ON_DELIVERY,
} from "../../helpers/constants";
import {
  translatedName,
} from "../../helpers/translations";
import RiderTipsWidgetMobile from "../../mobileComponents/riderTipsWidgetMobile";
import PaymentSelectPopupMobile from "../../mobileComponents/paymentSelectPopupMobile";

let pusher;

interface ITrackOrderScreenProps {
  order: any;
  company: any;
  contactNumber: string;
  tipsEnabled: boolean;
  fetchTrackingConfigurations: any;
  updateOrder: any;
}

function TrackOrderScreen(props: ITrackOrderScreenProps) {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [redirectionUrl, setRedirectionUrl] = useState("");
  const [customerArrivedEvent, setCustomerArrivedEvent] = useState(null as any);
  const [riderAssignedEvent, setRiderAssignedEvent] = useState(null as any);
  const [riderOnTheWayEvent, setRiderOnTheWayEvent] = useState(null as any);
  const [tipCollectedEvent, setTipCollectedEvent] = useState(null as any);
  const [showRiderTipsWidget, setShowRiderTipsWidget] = useState(false);
  const [riderTip, setRiderTip] = useState(null as any);
  const [showPaymentSelectPopup, setShowPaymentSelectPopup] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    props.fetchTrackingConfigurations().then((response: any) => {
      if (response.error && response.error.response.status == 404) {
        setRedirectionUrl("/");
      }
    });
  }, []);

  useEffect(() => {
    if (props.order.id) {
      bindToPusherChannel(props.order.id);
    }
  }, [props.order.id]);

  useEffect(() => {
    if (props.order.status_updates) {
      let customerArrivedEvent, riderAssignedEvent, riderOnTheWayEvent, tipCollectedEvent;
      customerArrivedEvent = props.order.status_updates.find((statusUpdate: any) => {
        return statusUpdate.event == "marked_as_customer_arrived";
      });
      setCustomerArrivedEvent(customerArrivedEvent);
      riderAssignedEvent = props.order.status_updates.find((statusUpdate: any) => {
        return statusUpdate.event == "rider_assigned";
      });
      tipCollectedEvent = props.order.status_updates.find((statusUpdate: any) => {
        return statusUpdate.event == "tip_collected";
      });
      if (![CARD_ON_DELIVERY, CASH_ON_DELIVERY].includes(props.order.payment?.payment_type)){
        setShowRiderTipsWidget(props.tipsEnabled && riderAssignedEvent && !tipCollectedEvent);
      } else {
        setShowRiderTipsWidget(false);
      }
      setRiderAssignedEvent(riderAssignedEvent);
      setTipCollectedEvent(tipCollectedEvent);
      riderOnTheWayEvent = props.order.status_updates.find((statusUpdate: any) => {
        return statusUpdate.event == "rider_on_the_way";
      });
      setRiderOnTheWayEvent(riderOnTheWayEvent);
    }
  }, [props.order.status_updates]);

  const bindToPusherChannel = (orderId: any) => {
    let element = document.getElementById("root") as any;
    let pusherId = element?.dataset.pusherId;
    const pusher = new Pusher(pusherId, {});
    const channel = pusher.subscribe(`track_order_${orderId}`);
    if (channel) {
      channel.bind("status_updated", (data: any) => {
        let order = props.order;
        order.status = data.status;
        order.eta = data.eta;
        if (data.status_updates) {
          order.status_updates = data.status_updates;
        }
        props.updateOrder(order);
      });
    }
  };

  const toggleOrderDetails = () => {
    setShowOrderDetails(!showOrderDetails);
    let element = document.getElementById("order-details");
    let position = element!.getBoundingClientRect()!.top;
    document.getElementById("root")?.scrollTo({
      top: position - 90,
      behavior: "smooth",
    });
  };

  const addRiderTip = (tipValue: number) => {
    setRiderTip(tipValue);
    setShowPaymentSelectPopup(true);
  }

  const removeRiderTip = () => {
    setRiderTip(null);
  }

  const { order, company, contactNumber } = props;
  return (
    <React.Fragment>
      {redirectionUrl ? (
        <Redirect to={redirectionUrl} />
      ) : (
        <div className="track-order-screen">
          <div className="order-status-section">
            <p className="order-number">
              <FormattedMessage
                id="tracking.order_number_status"
                defaultMessage="Order #{orderNumber} status"
                values={{
                  orderNumber: order.order_number
                }}
              />
            </p>
            <p className="order-status">
              {order.status ? (
                <FormattedMessage
                  id={`order.${order.order_type}.${order.status}.title`}
                  defaultMessage={trackingDetails[order.status][order.order_type].title}
                />
              ) : false}
            </p>
            <div className="status-image">
              {order && order.status ? (
                <img
                  src={trackingDetails[order.status][order.order_type].image}
                />
              ) : (
                false
              )}
            </div>

            {order.status != "rejected" ? (
              <div className="status-progress">
                <div
                  className={`progress ${
                    [
                      "unconfirmed",
                      "scheduled",
                      "confirmed",
                      "on_the_way",
                      "delivered",
                    ].includes(order.status)
                      ? "active"
                      : ""
                  }`}
                ></div>
                {order.scheduled_at ? (
                  <div
                    className={`progress ${
                      [
                        "scheduled",
                        "confirmed",
                        "on_the_way",
                        "delivered",
                      ].includes(order.status)
                        ? "active"
                        : ""
                    }`}
                  ></div>
                ) : (
                  false
                )}
                <div
                  className={`progress ${
                    ["confirmed", "on_the_way", "delivered"].includes(
                      order.status
                    )
                      ? "active"
                      : ""
                  }`}
                ></div>
                {order.order_type == "delivery" ? (
                  <div
                    className={`progress ${
                      ["on_the_way", "delivered"].includes(order.status)
                        ? "active"
                        : ""
                    }`}
                  ></div>
                ) : null}
                <div
                  className={`progress ${
                    order.status == "delivered" ? "active" : ""
                  }`}
                ></div>
              </div>
            ) : (
              <div className="status-progress">
                <div className="progress active"></div>
              </div>
            )}
            <p className={
              `status-info ${
                [
                  "scheduled",
                  "confirmed",
                  "on_the_way",
                ].includes(order.status) ? "with-eta" : ""
              }`
            }>
              {order.order_type == "delivery" && order.status == "on_the_way" && riderAssignedEvent ? (
                <FormattedMessage
                  id="tracking.rider_on_the_way"
                  defaultMessage="<strong>{riderName}</strong> will be handling your delivery today."
                  values={{
                    riderName: riderAssignedEvent.rider_name,
                    strong: (text) => <strong>{text}</strong>,
                  }}
                />
              ) : order.status ? (
                <FormattedMessage
                  id={`order.${order.order_type}.${order.status}.text`}
                  defaultMessage={trackingDetails[order.status][order.order_type].message}
                />
              ) : false}
            </p>
            {
              [
                "scheduled",
                "confirmed",
                "on_the_way",
              ].includes(order.status) ? (
                <p className="eta-info">
                  {order.order_type == "delivery" && order.status == "on_the_way" && riderAssignedEvent ? (
                    <FormattedMessage
                      id="tracking.rider_on_the_way_eta"
                      defaultMessage="Your food will arrive by <strong>{eta}</strong>."
                      values={{
                        eta: obtainEtaDisplayText(order.eta, intl.formatMessage),
                        strong: (text) => <strong>{text}</strong>,
                      }}
                    />
                  ) : order.order_type == "delivery" ? (
                    <FormattedMessage
                      id="tracking.your_estimated_food_arrival_is"
                      defaultMessage="Your food will arrive by <strong>{eta}</strong>"
                      values={{
                        eta: obtainEtaDisplayText(order.eta, intl.formatMessage),
                        strong: (text) => <strong>{text}</strong>,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="tracking.your_estimated_pickup_time_is"
                      defaultMessage="Your estimated food pickup time is <strong>{eta}</strong>"
                      values={{
                        eta: obtainEtaDisplayText(order.eta, intl.formatMessage),
                        strong: (text) => <strong>{text}</strong>,
                      }}
                    />
                  )}
                </p>
            ) : null}

            {order.order_type == "delivery" && order.status == "on_the_way" && riderOnTheWayEvent ? (
              <Link
                to="/m/rider-tracking"
                id="goToDriverTrackingBtn"
                className="button btn btn-primary px-4 arrived-btn my-3"
              >
                <FormattedMessage
                  id="tracking.driver_live_tracker"
                  defaultMessage="Track Your Order"
                />
              </Link>
            ) : false}

            {contactNumber ? (
              <p className="contact-details">
                <FormattedMessage
                  id="tracking.for_any_concerns_you_can_call"
                  defaultMessage="For questions on your order, you may call <spanTag> {contactNumber}</spanTag>"
                  values={{
                    contactNumber: contactNumber,
                    spanTag: (text) => <span> {text}</span>,
                  }}
                />
              </p>
            ) : null}
          </div>
          {order.status == "on_the_way" && showRiderTipsWidget && (
            <RiderTipsWidgetMobile
              subTotal={formatDecimal(order.sub_total)}
              riderName={riderAssignedEvent.rider_name}
              orderStatus={order.status}
              removeRiderTip={() => removeRiderTip()}
              addRiderTip={(tip: number) => addRiderTip(tip)}
            />
          )}
          <div
            className={
              showOrderDetails
                ? "order-details-section-footer show"
                : "order-details-section-footer hide"
            }
          >
            <p className="sub-heading" onClick={toggleOrderDetails}>
              <FormattedMessage
                id="tracking.see_order_details"
                defaultMessage="Show Order Details"
              />
              <span>
                <FontAwesomeIcon
                  icon={showOrderDetails ? faCaretDown : faCaretUp}
                />
              </span>
            </p>
            <div
              className={
                showOrderDetails
                  ? "order-details-section"
                  : "order-details-section"
              }
              id="order-details"
            >
              <div className="delivery-location-details">
                {order.order_type != "pickup" ? (
                  <FontAwesomeIcon
                    size="2x"
                    className="delivery-icon"
                    icon={faMotorcycle}
                  />
                ) : null}

                <div className="location-details">
                  <p className="name">{order.delivery_area}</p>
                  <p className="other-details">
                    {order.complete_address
                      ? order.complete_address
                          .map((line: any) => {
                            return line.value;
                          })
                          .join(", ")
                      : false}
                  </p>
                </div>
              </div>
              <div className="order-items">
                {order.items &&
                  order.items.map((item: any) => {
                    return (
                      <div className="item" key={item.id}>
                        <div className="item-count">
                          {item.quantity}
                        </div>
                        <div className="item-name">
                          <span>
                            {translatedName(item, intl.locale)}
                          </span>
                        </div>
                        <div className="item-price">
                          {formatDecimal(item.sub_total)}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="total-price">
                <label className="label">
                  <FormattedMessage
                    id="global.subtotal"
                    defaultMessage="Subtotal"
                  />
                </label>
                <p className="amount">
                  {company.currency} {formatDecimal(order.sub_total)}
                </p>
              </div>
              {order.tips ? (
                <div className="total-price">
                  <label className="label">
                    <FormattedMessage
                      id="global.tip"
                      defaultMessage="Tip"
                    />
                  </label>
                  <p className="amount">
                    {company.currency} {formatDecimal(order.tips)}
                  </p>
                </div>
              ) : null}
              {order.surcharges_breakdown && order.surcharges_breakdown.length
                ? order.surcharges_breakdown.map((surcharge: any) => {
                    return (
                      <div className="total-price" key={surcharge.name}>
                        <label className="label">{surcharge.name}</label>
                        <p className="amount">
                          {company.currency} {formatDecimal(surcharge.amount)}
                        </p>
                      </div>
                    );
                  })
                : false}
              {order.discounts && order.discounts.length ? (
                <div className="total-price">
                  <label className="label">{order.discounts[0].name}</label>
                  <p className="amount">
                    -{company.currency}{" "}
                    {formatDecimal(order.discounts[0].amount)}
                  </p>
                </div>
              ) : (
                false
              )}
              {order.redeemed_loyalty ? (
                <div className="total-price">
                  <label className="label">
                    <FormattedMessage
                      id="global.loyalty"
                      defaultMessage="Loyalty"
                    />
                  </label>
                  <p className="amount">
                    -{company.currency} {formatDecimal(order.redeemed_loyalty)}
                  </p>
                </div>
              ) : (
                false
              )}
              {!order.is_tax_inclusive && order.tax_total > 0 ? (
                <div className="total-price">
                  <label className="label">
                    <FormattedMessage
                      id="global.tax"
                      defaultMessage="Tax"
                    />
                  </label>
                  <p className="amount">
                    {company.currency} {formatDecimal(order.tax_total)}
                  </p>
                </div>
              ) : (
                false
              )}
              <div className="total-price">
                <label className="label">
                  <FormattedMessage
                    id="global.total"
                    defaultMessage="Total"
                  />
                </label>
                <p className="amount">
                  {company.currency} {formatDecimal(order.total_amount)}
                </p>
              </div>
              {order.note?.user && order.note.user.split(" *** ")[0] ? (
                <div className="order-notes">
                  <label>
                    <FormattedMessage
                      id="tracking.order_notes"
                      defaultMessage="Order notes"
                    />:
                  </label>
                  <p className="notes">{order.note.user.split(" *** ")[0]}</p>
                </div>
              ) : null}
              {order.pickup_mode == "curbside" && order.note?.meta ? (
                <div className="order-notes">
                  <label>
                    <FormattedMessage
                      id="tracking.curbside_identification"
                      defaultMessage="Curbside Identification"
                    />:
                  </label>
                  <p className="notes">{order.note.meta}</p>
                </div>
              ) : null}
              {order.payment ? (
                <div className="payment-details">
                  <FontAwesomeIcon
                    icon={
                      [CASH_ON_DELIVERY].includes(order.payment.payment_type)
                                ? faMoneyBillWave
                                : faCreditCard
                    }
                    size="2x"
                    className="payment-icon"
                  />
                  <p className="payment-type">{order.payment.name}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {riderTip && showPaymentSelectPopup && (
        <PaymentSelectPopupMobile
          tipAmount={riderTip}
          onClose={() => setShowPaymentSelectPopup(false)}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let order = state.orderInProgress || {};
  let company = state.company;
  let contactNumber = state.outlet.call_now_message;
  let tipsEnabled = state.company.tip_settings?.has_tips && state.company.tip_settings?.type == "post_order";

  return {
    order,
    company,
    contactNumber,
    tipsEnabled,
  };
};

const mapDispatchToProps = {
  fetchTrackingConfigurations: pageOperations.fetchTrackingConfigurations,
  updateOrder: orderInProgressOperations.updateOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackOrderScreen);
