// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';

interface IQpayCardSectionWrapperProps {
  savedCards: any;
  updateCardToken: any;
}

function QpayCardSectionWrapper(props: IQpayCardSectionWrapperProps) {
  const [selectedCardToken, setSelectedCardToken] = useState(null);

  useEffect(() => {
    if (props.savedCards.length > 0) {
      updateCardToken(props.savedCards[0]?.token);
    }
  }, [props.savedCards]);

  const updateCardToken = (token: any) => {
    setSelectedCardToken(token);
    props.updateCardToken(token);
  }

  return (
    <React.Fragment>
      {props.savedCards.length ? (
        <div className="payment-subsection">
          <div className="selections">
            {props.savedCards.map((savedCard: any, index: any) => {
              return(
                <ul key={index} >
                  <li>
                    <label>
                      <input
                        type="radio"
                        name="qpaySavedCard"
                        onChange={() => updateCardToken(savedCard.token)}
                        checked={selectedCardToken == savedCard.token}
                      />
                      {savedCard.card_no}
                    </label>
                  </li>
                </ul>
              )
            })}
            <ul>
              <li>
                <label>
                  <input
                    type="radio"
                    name="qpayNewCard"
                    onChange={() => updateCardToken(null)}
                    checked={!selectedCardToken}
                  />
                  <FormattedMessage
                    id="payment.use_a_new_card"
                    defaultMessage="Use a new card"
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let savedCards = state.user.metadata?.saved_cards?.qpay || [];

  return {
    savedCards
  };
};

export default connect(mapStateToProps)(QpayCardSectionWrapper);
