import preparingImage from "../images/orderTracking/preparing.gif";
import receivedImage from "../images/orderTracking/received.gif";
import onTheWayImage from "../images/orderTracking/delivery.gif";
import deliveredImage from "../images/orderTracking/delivered.gif";
import rejectedImage from "../images/orderTracking/rejected.gif";
import scheduledImage from "../images/orderTracking/scheduled.gif";
import collectedImage from "../images/orderTracking/collected.gif";
import readyToCollectImage from "../images/orderTracking/ready-4-collection.png";

const trackingDetails = {
  "unconfirmed": {
    "delivery": {
      title: "Order Received",
      message: "The restaurant has received your order.",
      image: receivedImage
    },
    "pickup": {
      title: "Order Received",
      message: "The restaurant has received your order.",
      image: receivedImage
    },
  },
  "rejected": {
    "delivery": {
      title: "Rejected",
      message: "Sorry, your order is declined.",
      image: rejectedImage
    },
    "pickup": {
      title: "Rejected",
      message: "Sorry, your order is declined.",
      image: rejectedImage
    },
  },
  "scheduled": {
    "delivery": {
      title: "Scheduled",
      message: "We've scheduled your order",
      image: scheduledImage
    },
    "pickup": {
      title: "Scheduled",
      message: "We've scheduled your order",
      image: scheduledImage
    },
  },
  "confirmed": {
    "delivery": {
      title: "Preparing",
      message: "Your order is being prepared",
      image: preparingImage
    },
    "pickup": {
      title: "Preparing",
      message: "Your order is being prepared",
      image: preparingImage
    },
  },
  "on_the_way": {
    "delivery": {
      title: "On the way",
      message: "Your food is out for delivery.",
      image: onTheWayImage
    },
    "pickup": {
      title: "Ready",
      message: "Your food is ready for collection.",
      image: readyToCollectImage
    },
  },
  "delivered": {
    "delivery": {
      title: "Delivered",
      message: "Yay! Your food has been delivered.",
      image: deliveredImage
    },
    "pickup": {
      title: "Collected",
      message: "Yay! Your food has been delivered.",
      image: collectedImage
    },
  },
} as any;

export default trackingDetails;
