// Packages
import React, { useEffect, useContext } from "react";
// Context
import { EnvContext } from "../../../../components/envContext";

interface ICheckboxReCaptchaEnterpriseProps {
  onCaptchaTokenChange: () => void;
  onCaptchaExpired: () => void;
  onCaptchaErrored: () => void;
  onCaptchaWidgetIDChange: () => void;
}

function CheckboxReCaptchaEnterprise(props: ICheckboxReCaptchaEnterpriseProps) {
  const { googleRecaptchaSiteKey } = useContext(EnvContext);

  useEffect(() => {
    if (googleRecaptchaSiteKey) {
      const widgetID = grecaptcha.enterprise.render('google-recaptcha-widget', {
        "sitekey": googleRecaptchaSiteKey,
        "callback": props.onCaptchaTokenChange,
        "expired-callback": props.onCaptchaExpired,
        "error-callback": props.onCaptchaErrored
      });

      props.onCaptchaWidgetIDChange(widgetID);
    }
  }, [googleRecaptchaSiteKey]);

  return (
    <div
      id="google-recaptcha-widget"
      className="reCaptchaContainer"
    />
  )
}

export default CheckboxReCaptchaEnterprise;
