// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import MenuItemMobile from "../../mobileComponents/menuItemMobile";
import CartSnackbar from "../../mobileComponents/cartSnackbar";
import NoMenuAvailable from "../../mobileComponents/noMenuAvailable";
import HomeBannerMobile from "../../mobileComponents/homeBannerMobile";
import FooterMobile from "../../mobileComponents/footerMobile";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { cartOperations } from "../../state/features/cart";
import { errorOperations } from "../../state/features/error";
// Helpers, Utils etc.
import { deepCopyFunction } from "../../helpers/utils";
import { calculateAmountsAndBreakdowns } from "../../helpers/itemCalculations";
import { validateCartItems } from "../../helpers/cartFunctions";
import { handleCategoryScrollSpy } from "./utils";
import {
  translatedName,
} from "../../helpers/translations";

interface IHomeScreenProps {
  menu: any;
  cart: any;
  company: any;
  pageLoading: any;
  outlet_id: any;
  storeOpen: boolean;
  showAreaSelect: boolean;
  fetchHomeConfigurations: any;
  setCart: any;
  updateError: any;
}
interface IHomeScreenState {
  headCategory: string;
  orderType: string;
  categories: any;
  categoryBarPosition: number;
  enableSearchbar: boolean;
  searchKeyword: string;
  searchResults: any;
  categoryScrollSpyObj: any;
}

class HomeScreen extends Component<IHomeScreenProps, IHomeScreenState> {
  constructor(props: IHomeScreenProps) {
    super(props);
    this.state = {
      headCategory: "",
      orderType: "delivery",
      categories: [],
      categoryBarPosition: 0,
      enableSearchbar: false,
      searchKeyword: "",
      searchResults: { itemCount: 0, categoryCount: 0 },
      categoryScrollSpyObj: {},
    };
  }

  componentDidMount() {
    this.props.fetchHomeConfigurations().then(() => {
      let categories = this.fetchUpdatedCategories(this.props.menu.categories);
      this.setState({ categories, headCategory: categories[0]?.name }, () =>
        this.updateItemsInCart(
          validateCartItems(
            this.state.categories,
            JSON.parse(localStorage.cartItems || "[]"),
            this.itemsNotInMenuError
          )
        )
      );
      this.updateComponentHeights();
      document
        .getElementById("close")
        ?.addEventListener("click", this.handleAnnouncementReset);
      localStorage.precision = this.props.company.decimal_precision;
      let categoryScrollSpyObj = handleCategoryScrollSpy(
        this.state.categoryScrollSpyObj
      );
      this.setState({ categoryScrollSpyObj });
    });
  }

  handleAnnouncementReset = () => {
    this.updateComponentHeights(true);
    if (this.state.categoryScrollSpyObj.scrollSpyHandler) {
      this.state.categoryScrollSpyObj.scrollSpyHandler.dispose();
    }
    let categoryScrollSpyObj = handleCategoryScrollSpy(
      this.state.categoryScrollSpyObj
    );
    this.setState({ categoryScrollSpyObj });
  };

  updateComponentHeights = (resetAnnouncment = false) => {
    let headerHeight,
      announcementHeight,
      statusBarHeight,
      inProgressStatusHeight;
    headerHeight = document.getElementById("header")?.clientHeight || 0;
    announcementHeight =
      document.getElementById("general-annoucement")?.clientHeight || 0;
    statusBarHeight = document.getElementById("status-bar")?.clientHeight || 0;
    inProgressStatusHeight =
      document.getElementById("in-progress-status")?.clientHeight || 0;
    if (resetAnnouncment) {
      announcementHeight = 0;
    }
    let categoryBarPosition =
      headerHeight +
      announcementHeight +
      statusBarHeight +
      inProgressStatusHeight;
    document.documentElement.style.setProperty(
      "--top-position",
      `${categoryBarPosition}px`
    );
    this.setState({ categoryBarPosition });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.cart !== this.props.cart) {
      let updatedCategories = this.fetchUpdatedCategories(
        this.state.categories
      );
      this.setState({ categories: updatedCategories });
    }
  }

  updateItemsInCart = (cartItems: any) => {
    let cart = calculateAmountsAndBreakdowns(
      cartItems,
      this.props.menu,
      this.props.orderType,
      null
    ) as any;
    cart.items = cartItems;

    this.props.setCart(cart);
  };

  itemsNotInMenuError = () => {
    this.props.updateError({
      title: <FormattedMessage id="error.oops" defaultMessage="Oops!" />,
      message: <FormattedMessage id="error.items_in_cart_not_in_menu" defaultMessage="Items in cart are not part of current available menu." />,
    });
  }

  fetchUpdatedCategories(categories: any) {
    let countInCart: number;
    categories = deepCopyFunction([...categories]);
    categories = categories.map((category: any) => {
      category.items = category.items.map((categoryItem: any) => {
        countInCart = this.props.cart.items
          .filter((cartItem: any) => {
            return categoryItem.id === cartItem.id;
          })
          .reduce((count: number, cartItem: any) => {
            return (count += cartItem.count);
          }, 0);
        if (categoryItem.items?.length) {
          categoryItem.items = categoryItem.items.map((itemInGroup: any) => {
            let countItemInGroupInCart = 0;
            countInCart = this.props.cart.items
              .filter((cartItem: any) => {
                return itemInGroup.id === cartItem.id;
              })
              .reduce((count: number, cartItem: any) => {
                countItemInGroupInCart += cartItem.count;
                return (count += cartItem.count);
              }, countInCart);
            itemInGroup.count = countItemInGroupInCart;
            return itemInGroup;
          });
        }
        categoryItem.count = countInCart;
        return categoryItem;
      });
      return category;
    });
    return categories;
  }

  onCategoryMenuClick(category: any) {
    var topHeaderSection = document.getElementById("top-header");
    var topHeaderSectionHeight = topHeaderSection
      ? topHeaderSection.clientHeight
      : 0;

    var catSection = document.getElementById("horizontal-category");
    var catSectionHeight = catSection ? catSection.clientHeight : 0;

    var offset = topHeaderSectionHeight + catSectionHeight;
    let categoryScroller = document.getElementById("cat-" + category.id);

    let position = categoryScroller.getBoundingClientRect().top - offset + 2;

    window.scrollBy({
      top: position,
      behavior: "smooth",
    });

    var elemPos = 0;
  }

  onItemClick(itemPosition: number) {
    let categoryBarHeight =
      document.getElementsByClassName("category-nav-wrapper")[0]
        ?.clientHeight || 0;
    let position =
      itemPosition - categoryBarHeight - this.state.categoryBarPosition;
    window.scrollBy({
      top: position,
      behavior: "smooth",
    });
  }

  componentWillUnmount() {
    document
      .getElementById("close")
      ?.removeEventListener("click", this.handleAnnouncementReset);
    window.removeEventListener("scroll", {});
    if (this.state.categoryScrollSpyObj.scrollSpyHandler) {
      this.state.categoryScrollSpyObj.scrollSpyHandler.dispose();
    }
  }

  onSearch(value: any) {
    let searchKeyword = value;
    let filteringCategories = this.fetchUpdatedCategories(
      this.props.menu.categories
    );
    let filteredItemsCount = 0;
    let filteredCategories = filteringCategories.filter((category: any) => {
      category.items = category.items.filter((item: any) => {
        if (item.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
          filteredItemsCount++;
          return true;
        }
        if (item.items?.length) {
          let groupItemCount =
            item.items.reduce((count: number, itemInGroup: any) => {
              if (
                itemInGroup
                  .name.toLowerCase().includes(searchKeyword.toLowerCase())
              ) {
                count++;
              }
              return count;
            }, 0);
          filteredItemsCount += groupItemCount;
          return groupItemCount;
        }
        return false;
      });
      return category.items.length;
    });
    window.scrollTo({ top: 0 });
    this.setState({
      categories: filteredCategories,
      searchKeyword,
      searchResults: {
        itemCount: filteredItemsCount,
        categoryCount: filteredCategories.length
      }
    });
  }

  onEnableSearchBar(value: boolean) {
    this.setState({ enableSearchbar: value, searchKeyword: "" });
    if (value == false) {
      document
        .getElementById("menu-search-wrapper")
        .classList.remove("searching");
    }
  }

  onSearchClose() {
    this.setState({ searchKeyword: "" }, () => this.onSearch(""));
    this.onEnableSearchBar(false);
  }

  render() {
    const { categories } = this.state;
    const { intl } = this.props;

    return (
      <div className="mobile-home-screen" id="home">
        <div className="menu-search" id="menu-search-wrapper">
          <div className="search-input-group">
            <div className="input-group-prepend mx-2">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <FormattedMessage
              id="search.enter_item_name"
              defaultMessage="Enter item name"
            >
              {(placeholder) => (
                <input
                  type="text"
                  id="search-input"
                  className="search-input pr-4"
                  value={this.state.searchKeyword || ""}
                  placeholder={placeholder}
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  onFocus={() => this.onEnableSearchBar(true)}
                  onBlur={() =>
                    !this.state.searchKeyword && this.onEnableSearchBar(false)
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    this.onSearch(e.target.value)
                  }
                />
              )}
            </FormattedMessage>
            <div className="input-group-prepend mx-2 mr-3">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.onSearchClose()}
              />
            </div>
          </div>
        </div>
        {this.props.outlet_id && !this.state.enableSearchbar ? (
          <HomeBannerMobile
            onCategoryClick={(category: any) =>
              this.onCategoryMenuClick(category)
            }
            onItemClick={(itemPosition: number) =>
              this.onItemClick(itemPosition)
            }
          />
        ) : null}
        {this.state.searchKeyword.length ? (
          this.state.searchResults.itemCount ? (
            <div className="search-results">
              <FormattedMessage
                id="search.result_found_in_category"
                defaultMessage="{itemCount, plural, one {# Result} other {# Results}} found in {categoryCount, plural, one {# Category} other {# Categories}}"
                values={{
                  itemCount: this.state.searchResults.itemCount,
                  categoryCount: this.state.searchResults.categoryCount,
                }}
              />
            </div>
          ) : (
            <div className="no-search-results">
              <FontAwesomeIcon className="icon" icon={faSearch} />
              <p className="message">
                <FormattedMessage
                  id="search.no_result_found"
                  defaultMessage="No result found matched for this keyword {searchKeyword}"
                  values={{
                    searchKeyword: `'${this.state.searchKeyword}'`,
                  }}
                />
              </p>
            </div>
          )
        ) : null}

        <div className="category-nav-wrapper">
          <nav className="horizontal-category" id="horizontal-category">
            <ul className="cat-navigation" id="cat-navigation">
              {categories &&
                categories.map((category: any, index: number) => {
                  return (
                    <li
                      className="category-entry"
                      key={index}
                      onClick={() => this.onCategoryMenuClick(category)}
                    >
                      <a
                        href={"#section-" + index}
                        className="category-entry-name"
                      >
                        {translatedName(category, intl.locale)}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>

        {!this.props.pageLoading &&
        !this.props.showAreaSelect &&
        !categories.length &&
        !this.state.searchKeyword ? (
          <NoMenuAvailable />
        ) : (
          <div
            id="menu-items"
            className={
              this.state.enableSearchbar
                ? "category-items searching"
                : "category-items"
            }
            style={
              this.props.cart.items.length
                ? { marginBottom: "90px" }
                : { marginBottom: 0 }
            }
          >
            {categories &&
              categories.map((category: any, index: number) => {
                return (
                  <section
                    key={index}
                    id={"section-" + index}
                    className={`menu-categories-mb with-item-${
                      category.items.length
                    }`}
                  >
                    <div
                      id={`cat-${category.id}`}
                      className="category-bar category-bar-name"
                      style={{ top: this.state.categoryBarPosition }}
                    >
                      {translatedName(category, intl.locale)}
                    </div>
                    {category.items.map((item: any, index: number) => {
                      return <MenuItemMobile menuItem={item} key={index} />;
                    })}
                  </section>
                );
              })}
          </div>
        )}

        {this.props.cart.items.length && this.props.storeOpen ? (
          <CartSnackbar />
        ) : null}
        <FooterMobile />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let menu = state.menu;
  let cart = state.cart;
  let company = state.company;
  let orderType = state.session.order_type;
  let pageLoading = state.loader.loading;
  let outlet_id = state.outlet.id;
  let storeOpen = state.storeTimings.open;
  let showAreaSelect = state.session.showAreaSelect;

  return {
    menu,
    cart,
    company,
    orderType,
    pageLoading,
    outlet_id,
    storeOpen,
  };
};

const mapDispatchToProps = {
  fetchHomeConfigurations: pageOperations.fetchHomeConfigurations,
  setCart: cartOperations.setCart,
  updateError: errorOperations.updateError,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HomeScreen));
