import * as types from "./types";

const fetchAreas = (areas: any) => ({
  type: types.FETCH_AREAS,
  payload: {
    request: {
      method: "get",
      url: "/delivery_areas"
    }
  }
});

const postalCodeSearchSG = (postalCode: string) => ({
  type: types.POSTAL_CODE_SEARCH_SG,
  payload: {
    client: "oneMapSG",
    request: {
      method: "get",
      url: "/commonapi/search?searchVal=" + postalCode +
           "&returnGeom=Y&getAddrDetails=Y&pageNum=1"
    }
  }
});

const setArea = (area: any) => ({
  type: types.SET_AREA,
  payload: area,
});

export default {
  fetchAreas,
  postalCodeSearchSG,
  setArea,
};
