import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomeScreen from "../../mobileScreens/homeScreen";
import OrderTypeScreen from "../../mobileScreens/orderTypeScreen";
import UserLogin from "../../mobileScreens/userLogin";
import orderDetailsScreen from "../../mobileScreens/orderDetailsScreen";
import MyProfileScreen from "../../mobileScreens/myProfileScreen";
import CheckoutScreen from "../../mobileScreens/checkoutScreen";
import trackOrderScreen from "../../mobileScreens/trackOrderScreen";
import PaymentProcessingMobile from "../../mobileScreens/paymentProcessingMobile";
import TopHeader from "../topHeader";
import pastOrdersScreen from "../../mobileScreens/pastOrdersScreen";
import TermsOfService from "../../mobileScreens/termsOfServiceScreen";
import RiderTrackingScreen from "../../mobileScreens/riderTrackingScreen";

interface IMobileAppProps {};

const MobileApp = (props: IMobileAppProps) => {
  return (
    <div>
      <TopHeader />
      <Switch>
        <Route exact path="/m" component={HomeScreen}></Route>
        <Route exact path="/m/area-select" component={OrderTypeScreen} />
        <Route exact path="/m/login" component={UserLogin} />
        <Route exact path="/m/order-details" component={orderDetailsScreen} />
        <Route exact path="/m/my-profile" component={MyProfileScreen} />
        <Route exact path="/m/past-orders" component={pastOrdersScreen} />
        <Route exact path="/m/checkout" component={CheckoutScreen} />
        <Route exact path="/m/trackorder" component={trackOrderScreen} />
        <Route exact path="/m/rider-tracking" component={RiderTrackingScreen} />
        <Route exact path="/m/terms-of-service" component={TermsOfService} />
        <Route
          exact
          path="/payment-processing"
          component={PaymentProcessingMobile}
        />
        <Redirect to="/m" />
      </Switch>
    </div>
  );
};

export default MobileApp;
