// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import AreaSelectScreen from "../areaSelectScreen";
// Icons, Images etc.
import Delivery from "../../images/delivery.svg";
import Pickup from "../../images/paperbag.svg";
import Lang from "../../images/lang-sel.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";


interface IOrderTypeScreenProps {
  onClose: any;
  company: any;
  mandatory: boolean;
}

function OrderTypeScreen(props: IOrderTypeScreenProps) {
  const [showAreaSelect, handleAreaSelect] = useState(false);
  const [orderOption, setOrderOption] = useState("delivery");
  const orderOptions = [
    {
      option_id: 1,
      option_name: "Delivery",
      option_img: Delivery,
      option_code: "global.delivery",
    },
    {
      option_id: 2,
      option_name: "Pickup",
      option_img: Pickup,
      option_code: "global.pickup",
    },
  ];

  useEffect(() => {
    if (props.company.modes_of_delivery != "delivery_and_pickup") {
      setOrderOption(props.company.modes_of_delivery);
      handleAreaSelect(true);
    }
  }, [props.company.modes_of_delivery]);

  const handleOrderType = async (orderType: string) => {
    await setOrderOption(orderType);
    handleAreaSelect(true);
    document.body.classList.remove("noscroll");
  };

  const onAreaSelectBack = () => {
    handleAreaSelect(false);
  };

  return (
    <div className="order-area-select">
      {showAreaSelect ? (
        <AreaSelectScreen
          onClose={() => props.onClose()}
          option={orderOption}
          handleOrderType={handleOrderType}
          onBackClick={onAreaSelectBack}
          mandatory={props.mandatory}
        />
      ) : (
        <div className="order-type-screen">
          <div className="order-types">
            <div className="type" onClick={() => handleOrderType("delivery")}>
              <img
                className="type-image"
                src={orderOptions[0].option_img}
                alt="orderOption"
              ></img>
              <p className="type-name">
                <FormattedMessage
                  id={orderOptions[0].option_code}
                  defaultMessage={orderOptions[0].option_name}
                />
              </p>
            </div>
            <div className="type" onClick={() => handleOrderType("pickup")}>
              <img
                className="type-image"
                src={orderOptions[1].option_img}
                alt="orderOption"
              ></img>
              <p className="type-name">
                <FormattedMessage
                  id={orderOptions[1].option_code}
                  defaultMessage={orderOptions[1].option_name}
                />
              </p>
            </div>
          </div>
          {!props.mandatory ? (
            <p className="back-button" onClick={() => props.onClose()}>
              <FormattedMessage
                id="global.back"
                defaultMessage="Back"
              />
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  return { company };
};

export default connect(mapStateToProps)(OrderTypeScreen);
