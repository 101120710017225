export const VERIFY_PAYMENT = "paymentTypes/VERIFY_PAYMENT";
export const VERIFY_PAYMENT_SUCCESS = "paymentTypes/VERIFY_PAYMENT_SUCCESS";
export const VERIFY_PAYMENT_FAIL = "paymentTypes/VERIFY_PAYMENT_FAIL";
export const BUILD_CC_AVENUE_PAYMENT_DATA = "paymentTypes/BUILD_CC_AVENUE_PAYMENT_DATA";
export const BUILD_CC_AVENUE_PAYMENT_DATA_SUCCESS = "paymentTypes/BUILD_CC_AVENUE_PAYMENT_DATA_SUCCESS";
export const BUILD_CC_AVENUE_PAYMENT_DATA_FAIL = "paymentTypes/BUILD_CC_AVENUE_PAYMENT_DATA_FAIL";
export const FETCH_MY_FATOORAH_PAYMENT_MODES = "paymentTypes/FETCH_MY_FATOORAH_PAYMENT_MODES";
export const FETCH_MY_FATOORAH_PAYMENT_MODES_SUCCESS = "paymentTypes/FETCH_MY_FATOORAH_PAYMENT_MODES_SUCCESS";
export const FETCH_MY_FATOORAH_PAYMENT_MODES_FAIL = "paymentTypes/FETCH_MY_FATOORAH_PAYMENT_MODES_FAIL";
export const BUILD_QPAY_PAYMENT_DATA = "paymentTypes/BUILD_QPAY_PAYMENT_DATA";
export const BUILD_QPAY_PAYMENT_DATA_SUCCESS = "paymentTypes/BUILD_QPAY_PAYMENT_DATA_SUCCESS";
export const BUILD_QPAY_PAYMENT_DATA_FAIL = "paymentTypes/BUILD_QPAY_PAYMENT_DATA_FAIL";
export const BUILD_I_PAY88_PAYMENT_DATA = "paymentTypes/BUILD_I_PAY88_PAYMENT_DATA";
export const BUILD_FISERV_PAYMENT_DATA = "paymentTypes/BUILD_FISERV_PAYMENT_DATA";
export const BUILD_FISERV_PAYMENT_DATA_SUCCESS = "paymentTypes/BUILD_FISERV_PAYMENT_DATA_SUCCESS";
export const BUILD_FISERV_PAYMENT_DATA_FAIL = "paymentTypes/BUILD_FISERV_PAYMENT_DATA_FAIL";
export const OMISE_PLACE_ORDER = "paymentTypes/OMISE_PLACE_ORDER";
export const BUILD_ADYEN_PAYMENT_METHODS = "paymentTypes/BUILD_ADYEN_PAYMENT_METHODS";
export const BUILD_ADYEN_PAYMENT_METHODS_FAIL = "paymentTypes/BUILD_ADYEN_PAYMENT_METHODS_FAIL";
export const CREATE_ADYEN_PAYMENT = "paymentTypes/CREATE_ADYEN_PAYMENT";
export const BUILD_MASTERCARD_SESSION = "paymentTypes/BUILD_MASTERCARD_SESSION";
export const BUILD_MASTERCARD_SESSION_SUCCESS = "paymentTypes/BUILD_MASTERCARD_SESSION_SUCCESS";
export const BUILD_MASTERCARD_SESSION_FAIL = "paymentTypes/BUILD_MASTERCARD_SESSION_FAIL";
export const INITIATE_MPGS_CARD_VERIFICATION = "paymentTypes/INITIATE_MPGS_CARD_VERIFICATION";
export const INITIATE_MPGS_CARD_VERIFICATION_SUCCESS = "paymentTypes/INITIATE_MPGS_CARD_VERIFICATION_SUCCESS";
export const INITIATE_MPGS_CARD_VERIFICATION_FAIL = "paymentTypes/INITIATE_MPGS_CARD_VERIFICATION_FAIL";
export const SUBMIT_MPGS_CARD_FORM_DATA = "paymentTypes/SUBMIT_MPGS_CARD_FORM_DATA";
export const SUBMIT_MPGS_CARD_FORM_DATA_SUCCESS = "paymentTypes/SUBMIT_MPGS_CARD_FORM_DATA_SUCCESS";
export const SUBMIT_MPGS_CARD_FORM_DATA_FAIL = "paymentTypes/SUBMIT_MPGS_CARD_FORM_DATA_FAIL";
export const VALIDATE_MPGS_APPLE_PAY_MERCHANT = "paymentTypes/VALIDATE_MPGS_APPLE_PAY_MERCHANT";
export const VALIDATE_MPGS_APPLE_PAY_MERCHANT_SUCCESS = "paymentTypes/VALIDATE_MPGS_APPLE_PAY_MERCHANT_SUCCESS";
export const VALIDATE_MPGS_APPLE_PAY_MERCHANT_FAIL = "paymentTypes/VALIDATE_MPGS_APPLE_PAY_MERCHANT_FAIL";
export const MAKE_MPGS_APPLE_PAY_PAYMENT = "paymentTypes/MAKE_MPGS_APPLE_PAY_PAYMENT";
export const MAKE_MPGS_APPLE_PAY_PAYMENT_SUCCESS = "paymentTypes/MAKE_MPGS_APPLE_PAY_PAYMENT_SUCCESS";
export const MAKE_MPGS_APPLE_PAY_PAYMENT_FAIL = "paymentTypes/MAKE_MPGS_APPLE_PAY_PAYMENT_FAIL";
export const SUBMIT_MPGS_GOOGLE_PAY_TOKEN = "paymentTypes/SUBMIT_MPGS_GOOGLE_PAY_TOKEN";
export const SUBMIT_MPGS_GOOGLE_PAY_TOKEN_SUCCESS = "paymentTypes/SUBMIT_MPGS_GOOGLE_PAY_TOKEN_SUCCESS";
export const SUBMIT_MPGS_GOOGLE_PAY_TOKEN_FAIL = "paymentTypes/SUBMIT_MPGS_GOOGLE_PAY_TOKEN_FAIL";
export const USER_CANCELLED_MPGS_APPLE_PAY_PAYMENT = "paymentTypes/USER_CANCELLED_MPGS_APPLE_PAY_PAYMENT";
export const USER_CANCELLED_MPGS_APPLE_PAY_PAYMENT_SUCCESS = "paymentTypes/USER_CANCELLED_MPGS_APPLE_PAY_PAYMENT_SUCCESS";
export const USER_CANCELLED_MPGS_APPLE_PAY_PAYMENT_FAIL = "paymentTypes/USER_CANCELLED_MPGS_APPLE_PAY_PAYMENT_FAIL";
export const AUTHENTICATE_MPGS_USER = "paymentTypes/AUTHENTICATE_MPGS_USER";
export const AUTHENTICATE_MPGS_USER_SUCCESS = "paymentTypes/AUTHENTICATE_MPGS_USER_SUCCESS";
export const AUTHENTICATE_MPGS_USER_FAIL = "paymentTypes/AUTHENTICATE_MPGS_USER_FAIL";
export const VERIFY_MPGS_GOOGLE_PAY_CARD = "paymentTypes/VERIFY_MPGS_GOOGLE_PAY_CARD";
export const VERIFY_MPGS_GOOGLE_PAY_CARD_SUCCESS = "paymentTypes/VERIFY_MPGS_GOOGLE_PAY_CARD_SUCCESS";
export const VERIFY_MPGS_GOOGLE_PAY_CARD_FAIL = "paymentTypes/VERIFY_MPGS_GOOGLE_PAY_CARD_FAIL";
export const VERIFY_MPGS_GOOGLE_PAY_USER = "paymentTypes/VERIFY_MPGS_GOOGLE_PAY_USER";
export const VERIFY_MPGS_GOOGLE_PAY_USER_SUCCESS = "paymentTypes/VERIFY_MPGS_GOOGLE_PAY_USER_SUCCESS";
export const VERIFY_MPGS_GOOGLE_PAY_USER_FAIL = "paymentTypes/VERIFY_MPGS_GOOGLE_PAY_USER_FAIL";
export const UPDATE_MPGS_PAYMENT_STATUS = "paymentTypes/UPDATE_MPGS_PAYMENT_STATUS";
export const UPDATE_MPGS_PAYMENT_STATUS_SUCCESS = "paymentTypes/UPDATE_MPGS_PAYMENT_STATUS_SUCCESS";
export const UPDATE_MPGS_PAYMENT_STATUS_FAIL = "paymentTypes/UPDATE_MPGS_PAYMENT_STATUS_FAIL";
export const INITIATE_STRIPE_CONNECT_TRANSACTION = "paymentTypes/INITIATE_STRIPE_CONNECT_TRANSACTION";
export const INITIATE_STRIPE_CONNECT_TRANSACTION_SUCCESS = "paymentTypes/INITIATE_STRIPE_CONNECT_TRANSACTION_SUCCESS";
export const INITIATE_STRIPE_CONNECT_TRANSACTION_FAIL = "paymentTypes/INITIATE_STRIPE_CONNECT_TRANSACTION_FAIL";
export const FETCH_STRIPE_CONNECT_PAYMENT_INTENT = "paymentTypes/FETCH_STRIPE_CONNECT_PAYMENT_INTENT";
export const FETCH_STRIPE_CONNECT_PAYMENT_INTENT_SUCCESS = "paymentTypes/FETCH_STRIPE_CONNECT_PAYMENT_INTENT_SUCCESS";
export const FETCH_STRIPE_CONNECT_PAYMENT_INTENT_FAIL = "paymentTypes/FETCH_STRIPE_CONNECT_PAYMENT_INTENT_FAIL";
export const GENERIC_PAYMENT_TYPE = "paymentTypes/GENERIC_PAYMENT_TYPE"
