// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import InlineLoader from "../../components/inlineLoader";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Redux Operations
import { savedCardsOperations } from "../../state/features/savedCards";
import { errorOperations } from "../../state/features/error";
// Helpers, Utils etc.
import { creditCardIcon } from "../../helpers/creditCardsHelper";

interface ISavedPaymentsProps {
  savedCards: any;
  fetchSavedCards: any;
  deleteSavedCard: any;
  updateError: any;
}

function SavedPayments(props: ISavedPaymentsProps) {
  const [loading, handleLoading] = useState(true);

  useEffect(() => {
    props.fetchSavedCards().then((response: any) => {
      handleLoading(false);
    });
  }, []);

  const reloadSavedCards = () => {
    handleLoading(true);
    props.fetchSavedCards().then(() => {
      handleLoading(false);
    });
  }

  function handleCardDeletion(card: any, paymentType: any) {
    props.deleteSavedCard(card.token, paymentType).then((response: any) => {
      reloadSavedCards();
    });
  }

  return (
    <div className="section saved-payments">
      <p className="section-heading">
        <FormattedMessage
          id="my_account.saved_payment_methods"
          defaultMessage="Saved Payment Methods"
        />
      </p>
      {loading ? (
        <InlineLoader />
      ) : props.savedCards && props.savedCards.length ? (
        props.savedCards.map((cardGroup: any) => {
          return (
            <div key={cardGroup.type_id}>
              {props.savedCards.length > 1 ? (
                <p className="card-type-heading">{cardGroup.name}</p>
              ) : false}
              {cardGroup.cards.map((card: any) => {
                return (
                  <div className="row" key={card.token}>
                    <div className="section nested col-lg-12 col-md-12 col-sm-12">
                      <div className="payment">
                        <div className="card-icon">
                          <FontAwesomeIcon
                            size="3x"
                            icon={creditCardIcon(card.scheme)}
                          />
                        </div>
                        <div className="card-number">{card.card_no}</div>
                        <button
                          className="small-button btn btn-sm btn-secondary"
                          onClick={() => handleCardDeletion(card, cardGroup.type)}
                        >
                          <FormattedMessage
                            id="global.delete"
                            defaultMessage="Delete"
                          />
                      </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })
      ) : (
        <div>
          <FormattedMessage
            id="my_account.no_cards_found"
            defaultMessage="No cards found"
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let savedCards = state.savedCards;
  return {
    savedCards
  };
};

const mapDispatchToProps = {
  fetchSavedCards: savedCardsOperations.fetchSavedCards,
  deleteSavedCard: savedCardsOperations.deleteSavedCard,
  updateError: errorOperations.updateError
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedPayments);

