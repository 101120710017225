import React, { useState, useEffect } from "react";

interface IIpay88Props {
  IPay88FormData: any;
};

function IPay88Form(props: IIpay88Props) {
  useEffect(() => {
    if (props.IPay88FormData && Object.keys(props.IPay88FormData).length) {
      document.getElementById("ipay88Transaction")?.submit();
    }
  }, [props.IPay88FormData])

  return (
    <form
      method="post"
      action={props.IPay88FormData.action_url}
      name="frmTransaction"
      id="ipay88Transaction"
    >
      <input
        name="MerchantCode"
        type="hidden"
        defaultValue={props.IPay88FormData.merchant_code}
      />
      <input
        name="PaymentId"
        type="hidden"
        defaultValue={props.IPay88FormData.paymentId}
      />
      <input
        name="RefNo"
        type="hidden"
        defaultValue={props.IPay88FormData.ref_no}
      />
      <input
        name="Amount"
        type="hidden"
        defaultValue={props.IPay88FormData.amount}
      />
      <input
        name="Currency"
        type="hidden"
        defaultValue={props.IPay88FormData.currency}
      />
      <input
        name="ProdDesc"
        type="hidden"
        defaultValue={props.IPay88FormData.prod_desc}
      />
      <input
        name="UserName"
        type="hidden"
        defaultValue={props.IPay88FormData.user_name}
      />
      <input
        name="UserEmail"
        type="hidden"
        defaultValue={props.IPay88FormData.user_email}
      />
      <input
        name="UserContact"
        type="hidden"
        defaultValue={props.IPay88FormData.user_contact}
      />
      <input
        name="Remark"
        type="hidden"
        defaultValue={props.IPay88FormData.remark}
      />
      <input
        name="Lang"
        type="hidden"
        defaultValue={props.IPay88FormData.lang}
      />
      <input
        name="SignatureType"
        type="hidden"
        defaultValue={props.IPay88FormData.signature_type}
      />
      <input
        name="Signature"
        type="hidden"
        defaultValue={props.IPay88FormData.signature}
      />
      <input
        name="ResponseURL"
        type="hidden"
        defaultValue={props.IPay88FormData.response_url}
      />
      <input
        name="BackendURL"
        type="hidden"
        defaultValue={props.IPay88FormData.backend_url}
      />
    </form>
  );
}

export default IPay88Form;
