// Packages
import React, { useEffect } from "react";
import { FormattedMessage } from 'react-intl';

interface IGeneralAnnouncementProps {
  onClose: () => void;
  announcement: string;
}

function GeneralAnnouncement(props: IGeneralAnnouncementProps) {
  return (
    <div className="general-announcement">
      <p className="announcement-message">
        {props.announcement}
      </p>
      <div className="close-button" onClick={() => props.onClose()}>
        <FormattedMessage
          id="global.close"
          defaultMessage="Close"
        />
      </div>
    </div>
  );
}
export default GeneralAnnouncement;
