// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// Components
import SelectInput from "../../mobileComponents/selectInput";
import PostalCodeSelector from "../../mobileComponents/postalCodeSelector";
import GoogleMapAreaSearch from "../../mobileComponents/googleMapAreaSearch";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronCircleRight,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { areasOperations } from "../../state/features/areas";
import { sessionOperations } from "../../state/features/session";

interface IAreaSelectProps {
  option: string;
  fetchAreas: any;
  areas: any;
  session: any;
  company: any;
  mandatory: boolean;
  zoneMapEnabled: boolean;
  onClose: any;
  handleOrderType: any;
  changeDeliveryArea: any;
  onBackClick: any;
  setSession: any;
}

function AreaSelectScreen(props: IAreaSelectProps) {
  const orderOptions = ["delivery", "pickup"];
  const [activeOption, setActiveOption] = useState("delivery");
  const [deliveryAreas, setDeliveryAreas] = useState([] as any);
  const [pickupAreas, setPickupAreas] = useState([] as any);
  const [selectedDeliveryArea, changeSelectedDeliveryArea] = useState(
    {} as any
  );
  const [selectedPickupArea, changeSelectedPickupArea] = useState({} as any);
  const [selectedPickupMode, changeSelectedPickupMode] = useState("in_store");
  const [noAreaSelectedError, setNoAreaSelectedError] = useState("");
  const [barHeight, setBarHeight] = useState(0);

  useEffect(() => {
    const actualHeight: any = window.innerHeight || 0;
    const elementHeight: any =
      document.getElementById("control-height")?.clientHeight || 0;

    setBarHeight(elementHeight - actualHeight)
  }, []);

  useEffect(() => {
    setActiveOption(props.option);
  }, [props.option]);

  useEffect(() => {
    props.fetchAreas();
  }, []);

  useEffect(() => {
    changeSelectedPickupMode(props.session.pickup_mode);
  }, [props.session.pickup_mode]);

  useEffect(() => {
    let delAreas = props.areas.delivery.map((area: any) => {
      area.label = area.name;
      area.value = area.id;
      return area;
    });
    setDeliveryAreas(delAreas);
    setPickupAreas(props.areas.pickup);
    let selectedOption;
    if (props.session.outlet_id) {
      selectedOption = props.areas.pickup.find((area: any) => {
        return area.outlet_id == props.session.outlet_id;
      });
    } else {
      selectedOption = props.areas.pickup[0];
    }
    selectedOption ? changeSelectedPickupArea(selectedOption) : false;

    if (!props.zoneMapEnabled) {
      selectedOption = delAreas.find((area: any) => {
        return area.id == props.session.area_id;
      });
      selectedOption ? changeSelectedDeliveryArea(selectedOption) : false;
    }
  }, [props.areas]);

  const handleDeliverySelection = (id: string, name: string = "") => {
    let area = deliveryAreas.find((area: any) => {
      return area.id == id;
    });

    if (name) {
      area.name = name;
    }
    setNoAreaSelectedError("");
    changeSelectedDeliveryArea(area);
  };

  const handlePickupSelection = (area: any) => {
    setNoAreaSelectedError("");
    changeSelectedPickupArea(area);
  };

  const handleDeliverySubmission = () => {
    if (selectedDeliveryArea.id) {
      let session = props.session;
      session.area_id = selectedDeliveryArea.id;
      session.showAreaSelect = false;
      delete session.pickup_mode;
      props.setSession(session);
      changeDeliveryArea(selectedDeliveryArea, null);
    } else {
      setNoAreaSelectedError("delivery");
    }
  };

  const handlePickupSubmission = () => {
    if (selectedPickupArea.outlet_id) {
      let session = props.session;
      session.outlet_id = selectedPickupArea.outlet_id;
      session.pickup_mode = selectedPickupMode;
      session.showAreaSelect = false;
      delete session.area_id;
      props.setSession(session);
      changeDeliveryArea(selectedPickupArea, selectedPickupMode);
    } else {
      setNoAreaSelectedError("pickup");
    }
  };

  const changeDeliveryArea = (area: any, pickupMode: any) => {
    props.changeDeliveryArea(area, props.option, pickupMode)
    .then((response: any) => {
      if (!response.error) {
        if (response.payload.data.outlet_changed) {
          location.reload();
        } else {
          props.handleOrderType(activeOption);
          document.body.classList.remove("noscroll");
          document.getElementsByClassName("header-action")[0].style.display = "";
          document.getElementById("home").style.display = "";
        }
      }
    });
  }

  const handleClose = () => {
    props.onClose();
    document.body.classList.add("noscroll");
  };

  const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
      <p
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
      </p>
    )
  );

  const openGMap = (lat: any, lng: any) => {
    window.open(`http://maps.google.com/maps?daddr=${lat},${lng}&amp;ll=`);
  };

  const onPickupModeChange = (event: any) => {
    changeSelectedPickupMode(event.target.name);
  }

  const backClick = () => {
    if (props.company.modes_of_delivery == "delivery_and_pickup") {
      props.onBackClick();
    } else {
      handleClose();
    }
  }

  const resetDeliveryAreaSelection = () => {
    changeSelectedDeliveryArea({});
  };

  const areaSelected = () => {
    return (
      (activeOption === "delivery" && selectedDeliveryArea?.id) ||
      (activeOption === "pickup" && selectedPickupArea?.id)
    );
  }

  return (
    <div className="area-selection-screen">
      <h1 className="header">
        {activeOption === "delivery" ? (
          props.zoneMapEnabled ? (
            <FormattedMessage
              id="area_selector.google_map.search_for_your_address"
              defaultMessage="Search for your address"
            />
          ) : (
            <FormattedMessage
              id="area_selector.area_dropdown.select_your_delivery_area"
              defaultMessage="Select your delivery area"
            />
          )
        ) : (
          <FormattedMessage
            id="pickup_selector.where_would_you_like_to_collect_your_order"
            defaultMessage="Where would you like to collect your order?"
          />
        )}
      </h1>
      {props.company.modes_of_delivery != "pickup" &&
        activeOption === "delivery" ? (
        <div className="delivery-area-select mb-5 mt-3">
          {props.zoneMapEnabled ? (
            <GoogleMapAreaSearch
              options={deliveryAreas}
              onChange={handleDeliverySelection}
              updateAreaError={(error: string) => setNoAreaSelectedError(error)}
              resetDeliveryAreaSelection={resetDeliveryAreaSelection}
            />
          ) : (
            <SelectInput
              fullWidth
              placeholder={
                <FormattedMessage
                  id="area_selector.area_dropdown.enter_delivery_area_here"
                  defaultMessage="Enter delivery area here"
                />
              }
              onChange={handleDeliverySelection}
              options={deliveryAreas}
              defaultValue={selectedDeliveryArea.id}
              autoFocus={true}
            />
          )}
        </div>
      ) : (
        <div className="pickup-area-select">
          <div className="area-list mt-4 mb-5">
            {pickupAreas.map((area: any, index: any) => {
              return (
                <div
                  className={
                    selectedPickupArea.outlet_id === area.outlet_id
                      ? "area-input selected"
                      : "area-input"
                  }
                  key={index}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    id={`${area.id}`}
                    onChange={() => handlePickupSelection(area)}
                    value="option2"
                    checked={area.outlet_id === selectedPickupArea.outlet_id}
                  />
                  <label
                    className="form-check-label w-100 area-label"
                    htmlFor={`${area.id}`}
                  >
                    <p className="area-name" title={area.name}>{area.name}</p>
                    <p className="area-address">{area.address}</p>
                    {selectedPickupArea.outlet_id === area.outlet_id && (
                      <React.Fragment>
                        <p
                          className="g-map-link"
                          onClick={() =>
                            openGMap(
                              selectedPickupArea.latitude,
                              selectedPickupArea.longitude
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="mr-1"
                            icon={faExternalLinkAlt}
                          />
                          <FormattedMessage
                            id="area_selector.area_dropdown.enter_delivery_area_here"
                            defaultMessage="View in Google Map"
                          />
                        </p>
                        {props.company.modes_of_pickup?.in_store ||
                         props.company.modes_of_pickup?.curbside ? (
                          <div className="pickup-methods">
                            <label>
                              <FormattedMessage
                                id="pickup_selector.select_your_preferred_pickup_method"
                                defaultMessage="Select your preferred pickup method"
                              />:
                            </label>
                            <div className="methods">
                              {props.company.modes_of_pickup.in_store ? (
                                <div>
                                  <input
                                    type="radio"
                                    id="in_store"
                                    name="in_store"
                                    checked={
                                      !selectedPickupMode ||
                                      selectedPickupMode == "in_store"
                                    }
                                    onChange={onPickupModeChange}
                                  />
                                  <label htmlFor="in_store">
                                    <FormattedMessage
                                      id="pickup_selector.in_store"
                                      defaultMessage="In-Store"
                                    />
                                  </label>
                                </div>
                              ) : null}
                              {props.company.modes_of_pickup.curbside ? (
                                <div>
                                  <input
                                    type="radio"
                                    id="curbside"
                                    name="curbside"
                                    checked={
                                      selectedPickupMode == "curbside"
                                    }
                                    onChange={onPickupModeChange}
                                  />
                                  <label htmlFor="curbside">
                                    <FormattedMessage
                                      id="pickup_selector.curbside"
                                      defaultMessage="Curbside"
                                    />
                                  </label>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    )}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {noAreaSelectedError ? (
        <div className="input-label text-center text-danger">
          {noAreaSelectedError == "address" ? (
            <FormattedMessage
              id="area_selector.google_map.address_not_serviceable"
              defaultMessage="This address is not serviceable"
            />
          ) : noAreaSelectedError == "delivery" ? (
            props.zoneMapEnabled ? (
              <FormattedMessage
                id="area_selector.google_map.select_to_continue"
                defaultMessage="Please enter your address to continue."
              />
            ) : (
              <FormattedMessage
                id="area_selector.area_dropdown.select_to_continue"
                defaultMessage="Please select a delivery area to continue."
              />
            )
          ) : null}
        </div>
      ): null}
      <div className="area-selection-footer next-button pt-3 pb-4" style={{ bottom: barHeight }}>
        <button
          className="button btn btn-primary btn-block"
          onClick={
            activeOption === "delivery"
              ? handleDeliverySubmission
              : handlePickupSubmission
          }
          disabled={!areaSelected()}
        >
          <FormattedMessage
            id="global.next"
            defaultMessage="Next"
          />
          <span className="ml-2">
            <FontAwesomeIcon icon={faChevronCircleRight} />
          </span>
        </button>
        {!props.mandatory ||
          props.company.modes_of_delivery == "delivery_and_pickup" ? (
          <p className="back-button" onClick={backClick}>
            <FormattedMessage
              id="global.back"
              defaultMessage="Back"
            />
          </p>
        ) : null}
      </div>
      <div id="control-height"></div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let session = state.session;
  let areas = state.areas;
  let zoneMapEnabled = state.company.configuration?.zone_mapping_enabled == true;

  return {
    company,
    session,
    areas,
    zoneMapEnabled,
  };
};

const mapDispatchToProps = {
  fetchAreas: areasOperations.fetchAreas,
  changeDeliveryArea: sessionOperations.changeDeliveryArea,
  setSession: sessionOperations.setSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaSelectScreen);
