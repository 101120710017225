import React, { useEffect } from "react";
import { connect } from "react-redux";

interface IQpayFormProps {
  qpayFormData: any;
  company: any;
  user: any;
  email: any;
  name: any;
  cardToken: any;
};

function QpayForm(props: IQpayFormProps) {
  useEffect(() => {
    if (props.qpayFormData && Object.keys(props.qpayFormData).length) {
      document.getElementById("qpayTransaction")?.submit();
    }
  }, [props.qpayFormData]);

  return (
    <form
      method="post"
      action={props.qpayFormData.url || ""}
      id="qpayTransaction"
    >
      <input name="action" type="hidden" value="capture" />
      <input
        name="gatewayId"
        type="hidden"
        value={props.qpayFormData.gatewayId || ""}
      />
      <input
        name="referenceId"
        type="hidden"
        value={props.qpayFormData.referenceId || ""}
      />
      <input
        name="amount"
        type="hidden"
        value={props.qpayFormData.amount || ""}
      />
      <input name="mode" type="hidden" value={props.qpayFormData.mode || ""} />
      <input
        name="description"
        type="hidden"
        value={props.qpayFormData.referenceId || ""}
      />
      <input
        name="returnUrl"
        type="hidden"
        value={props.qpayFormData.returnUrl || ""}
      />
      <input
        name="signatureFields"
        type="hidden"
        value={props.qpayFormData.signatureFields || ""}
      />
      <input
        name="signature"
        type="hidden"
        value={props.qpayFormData.signature || ""}
      />
      {props.qpayFormData.canSaveCards && props.cardToken ? (
        <input
          name="token"
          type="hidden"
          value={props.cardToken}
        />
      ) : (
        <React.Fragment>
          {props.qpayFormData.canSaveCards ? (
            <input name="tokenizeCardValue" type="hidden" value="1" />
          ) : false}
          <input
            name="currency"
            type="hidden"
            value={props.company.currency || ""}
          />
          <input
            name="name"
            type="hidden"
            value={props.name || props.user.name || ""}
          />
          <input name="address" type="hidden" value="address" />
          <input name="city" type="hidden" value="city" />
          <input name="state" type="hidden" value="state" />
          <input
            name="country"
            type="hidden"
            value={props.company.country_code || ""}
          />
          <input name="phone" type="hidden" value={props.user.phone_number || ""} />
          <input
            name="email"
            type="hidden"
            value={props.email || props.user.email || ""}
          />
        </React.Fragment>
      )}
    </form>
  );
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let user = state.user;

  return {
    company,
    user,
  };
};

export default connect(mapStateToProps)(QpayForm);
