import React, { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Loading from '../../images/loading.gif'
import 'bootstrap/js/dist/carousel.js'

interface IHomeBannerMobileProps {
  advertisements: any;
  onCategoryClick: any;
  onItemClick: any;
}

const HomeBannerMobile = (props: IHomeBannerMobileProps) => {
  useEffect(() => {
    $(".banner").carousel({
      interval: 5000,
      pause: false,
      ride: "carousel",
    });
    $(".indicator").first().addClass("active");
    $(".carousel-item").first().addClass("active");
  }, []);

  // Scroll to that category
  const categoryAction = (
    e: React.MouseEvent<Element, MouseEvent>,
    actionValue: any
  ) => {
    let category = { id: actionValue };
    props.onCategoryClick(category);
  };

  const getBannerConfigs = (banner: any) => {
    let actionKey = Object.keys(banner)[0];
    let imageUrl = banner[actionKey].image_url;
    let action = null;
    if (actionKey.includes("-")) {
      let actionType = actionKey.split("-")[0];
      let actionValue = actionKey.split("-")[1];
      if (actionType === "item") {
        action = (e: React.MouseEvent<Element, MouseEvent>) => {
          let item: any = document.getElementsByClassName(
            `add-to-cart-mb-${actionValue}`
          )[0];
          if (!item) {
            item = document.getElementsByClassName(
              `increment-item-mb-${actionValue}`
            )[0];
          }
          if (item) {
            item.classList.add("added-through-advertisement");
            item.click();
          }
        }
      }
      if (actionType === "category") {
        action = (e: React.MouseEvent<Element, MouseEvent>) =>
          categoryAction(e, actionValue);
      }
    }
    return {
      imageUrl: imageUrl,
      action: action,
    };
  };

  return (
    <div>
      {props.advertisements.length ? (
        <div className="banner carousel slide carousel-fade">
          <ol className="carousel-indicators">
            {props.advertisements.map((banner: any, index: number) => {
              return (
                <li
                  key={index}
                  className="indicator"
                  data-target=".banner"
                  data-slide-to={index}
                ></li>
              );
            })}
          </ol>
          <div className="carousel-inner">
            {props.advertisements.map((banner: any, index: number) => {
              return (
                <div className="carousel-item" key={index}>
                  <img
                    className="home-banner-mb"
                    src={
                      getBannerConfigs(banner).imageUrl
                        ? getBannerConfigs(banner).imageUrl
                        : Loading
                    }
                    onClick={(e) => {
                      Object.keys(banner)[0].includes("-")
                      ? getBannerConfigs(banner).action(e)
                      : null
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let completeAdvertisements = state.outlet.advertisements || {};
  let advertisements = completeAdvertisements['mobile'] || [];

  return { advertisements };
};

export default connect(mapStateToProps)(HomeBannerMobile);
