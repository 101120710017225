// Packages
import React, { useState, useEffect, useContext } from "react";
import {
  LoadScript,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
// Icons, Images etc.
import GmapMarker from "../../images/gmap-marker.svg";
// Contexts
import { LocaleContext } from "../../wrappers/localeWrapper";

declare global {
  interface Window {
    google: any;
  }
}

interface MinimisedDeliveryAddressMapWidgetProps {
  lat: number;
  lng: number;
  onAreaChange: any;
  mapApiKey: string;
  expandMap: any;
  addressMarked: any;
}

const mapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
  streetViewControl: false,
  gestureHandling: "none",
};

const LIBRARIES = ["places"];

function MinimisedDeliveryAddressMapWidget(props: MinimisedDeliveryAddressMapWidgetProps) {
  const [markerPosition, setMarkerPosition] = useState({} as any);

  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    let lat = props.lat || 0.0;
    let lng = props.lng || 0.0;
    setMarkerPosition({ lat: lat, lng: lng });
  }, [props.lat, props.lng]);

  const onMapScriptLoad = (map) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: markerPosition }, (responses: any) => {
      let streetName = responses[0].formatted_address;
      props.onAreaChange(streetName, markerPosition.lat, markerPosition.lng);
    });
  };

  return (
    <React.Fragment>
      {props.mapApiKey ? (
        <LoadScript
          googleMapsApiKey={props.mapApiKey}
          onLoad={onMapScriptLoad}
          libraries={LIBRARIES}
          language={localeContext.locale}
        >
          <GoogleMap
            mapContainerStyle={{
              height: "400px"
            }}
            center={markerPosition}
            zoom={17}
            options={mapOptions}
            onClick={props.expandMap}
          >
            {props.addressMarked ? (
              <Marker
                draggable={false}
                position={markerPosition}
                onClick={props.expandMap}
                icon={GmapMarker}
              />
            ) : null}
          </GoogleMap>
        </LoadScript>
      ) : null}
    </React.Fragment>
  );
}

export default React.memo(MinimisedDeliveryAddressMapWidget);
