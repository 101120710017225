const storeInitialState = {
  company: {},
  outlet: {},
  user: {},
  menu: {
    categories: [],
    taxes: [],
    surcharges: [],
    is_tax_inclusive: true,
  },
  session: {
    area_id: '',
    outlet_id: '',
    order_type: 'delivery',
  },
  orderInProgress: {},
  paymentTypes: [],
  storeTimings: {},
  cart: {
    items: [],
    subTotal: 0,
    total: 0,
    surchargeTotal: 0,
    discountTotal: 0,
    loyaltyTotal: 0,
    taxTotal: 0,
    surchargeBreakDown: [],
  },
  areas: {
    delivery: [],
    pickup: [],
    selectedArea: {},
  },
  discount: null,
  error: null,
  loyalty: {},
  loader: {
    loading: false,
  },
  pastOrders: [],
  savedCards: [],
  loyaltyConfig: {},
  alert: null,
  tips: [],
  recentOrder: null
}

export default storeInitialState;
