// Packages
import React from "react";
import { connect } from "react-redux";
import { Link, Route, NavLink } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
// Components
import Header from "../../components/header";
import MyProfile from "../../components/profile";
import PastOrders from "../../components/pastOrders";
import Loyalty from "../../components/loyalty";
import PageHeader from "../../components/pageHeader";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faStar, faListAlt } from "@fortawesome/free-regular-svg-icons";

interface IMyAccountScreenProps {
  hasLoyalty: boolean;
}

function MyAccountScreen(props: IMyAccountScreenProps) {
  return (
    <React.Fragment>
      <Header />
      <div className="my-account-screen">
        <div className="row mb-5">
          <div className="col-md-12">
            <PageHeader
              heading={
                <FormattedMessage
                  id="sidemenu.my_account"
                  defaultMessage="My Account"
                />
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="side-nav">
              <NavLink activeClassName="active" to="/my-account/profile">
                <span>
                  <FontAwesomeIcon icon={faUserCircle} />
                </span>
                <FormattedMessage
                  id="global.profile"
                  defaultMessage="Profile"
                />
              </NavLink>
              <NavLink activeClassName="active" to="/my-account/past-orders">
                <span>
                  <FontAwesomeIcon icon={faListAlt} />
                </span>
                <FormattedMessage
                  id="my_account.past_orders"
                  defaultMessage="Past Orders"
                />
              </NavLink>
              {props.hasLoyalty ? (
                <NavLink activeClassName="active" to="/my-account/loyalty">
                  <span>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                  <FormattedMessage
                    id="global.loyalty"
                    defaultMessage="Loyalty"
                  />
                </NavLink>
              ) : null}
            </div>
          </div>
          <div className="col-md-9">
            <Route path="/my-account/profile" component={MyProfile} />
            <Route path="/my-account/past-orders" component={PastOrders} />
            <Route path="/my-account/loyalty" component={Loyalty} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let hasLoyalty = state.loyaltyConfig["delivery"]?.enabled ||
                   state.loyaltyConfig["pickup"]?.enabled;

  return { hasLoyalty };
};

export default connect(mapStateToProps)(MyAccountScreen);
