import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
} from "@react-google-maps/api";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CoordinateLiteral } from "../../containers/riderTracking";
import { EnvContext } from "../envContext";

interface IRiderMapContainerProps {
  userCoords: CoordinateLiteral;
  riderCoords: CoordinateLiteral;
  status: string;
  riderName: string;
  setTrackingError: (status: boolean) => void;
}

const RiderMapContainer = (props: IRiderMapContainerProps) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const [riderEta, setRiderEta] = useState<string | undefined>(undefined);
  const { googleMapKey } = useContext(EnvContext);

  const mapRef = useRef<GoogleMap>();
  const onMapLoad = useCallback((map) => (mapRef.current = map), []);
  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
      zoomControl: true,
      zoomControlOptions: { position: 5.0 },
    }),
    []
  );
  const directionsOptions = useMemo<google.maps.DirectionsRendererOptions>(
    () => ({
      markerOptions: { clickable: false },
    }),
    []
  );
  useEffect(() => {
    if (mapLoaded) {
      calculateRoute(props.riderCoords, props.userCoords);
    }
  }, [props.riderCoords, mapLoaded]);

  const calculateRoute = (
    origin: CoordinateLiteral,
    destination: CoordinateLiteral
  ) => {
    const service = new google.maps.DirectionsService();
    service.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status == "OK" && result) {
          setDirections(result);
          setRiderEta(result.routes[0].legs[0].duration?.text);
          props.setTrackingError(false);
        } else {
          setTimeout(() => {
            props.setTrackingError(true);
          }, 300);
        }
      }
    );
  };

  return (
    <LoadScript
      googleMapsApiKey={googleMapKey}
      onLoad={() => setMapLoaded(true)}
      onError={() => setMapLoaded(false)}>
      <GoogleMap
        center={props.userCoords}
        zoom={15}
        mapContainerClassName="google-maps-container"
        options={mapOptions}
        onLoad={onMapLoad}
        >
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={directionsOptions}
          />
        )}
      </GoogleMap>
        <div className="rider-details-section">
          {props.status == "assigned" ? (
            <p className="rider-eta-info">Rider is assigned to your order</p>
          ) : props.status == "in_transit" ? (
            <p className="rider-eta-info">Rider will arrive in approx. {riderEta}</p>
          ) : props.status == "entered_customer_proximity" ? (
            <p className="rider-eta-info">Rider is arriving at your location</p>
          ) : false}
          {props.riderName ? (
            <p className="rider-details">Rider Name: {props.riderName}</p>
          ) : false}
        </div>
    </LoadScript>
  );
};

export default RiderMapContainer;
