import React from "react";

interface IModalCloseProps {
  onClick?: () => void;
  inCart?: boolean;
}

const ModalCloseIcon = (props: IModalCloseProps) => {
  return (
    <span
      className={props.inCart ? "modal-close inCart" : "modal-close"}
      onClick={props.onClick}
    >
      <i className='ico-times'/>
    </span>
  );
};
export default ModalCloseIcon;
