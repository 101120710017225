import React from "react";

interface ILoaderProps {
  message?: string;
}

const Loader = (props: ILoaderProps) => {
  return (
    <div className="loader">
      <div className="layout">
        <div className="loading" />
      </div>
      {props.message && (
        <div className="container">
          <p className="loader-message">
            {props.message}
          </p>
        </div>
      )}
    </div>
  );
};
export default Loader;
