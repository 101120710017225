// Packages
import React, { useEffect, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Components
import CreditCardSection from "../../components/creditCardSection";
import InlineLoader from "../inlineLoader";

interface ICardSectionWrapperProps {
  stripeKey: any,
  payload: any,
  paymentType: string,
  onCardSelect: any,
  showPointsEarnedPopup: any
  togglePlaceOrderButton: any
  toggleTipSuccessfulPopup: any
}

function CardSectionWrapper(props: ICardSectionWrapperProps) {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (props.stripeKey) {
      setStripePromise(loadStripe(props.stripeKey));
    }
  }, [props.stripeKey]);

  return (
    <React.Fragment>
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <CreditCardSection
            orderPayload={props.payload}
            paymentType={props.paymentType}
            onCardSelect={(cardData: any) => props.onCardSelect(cardData)}
            showPointsEarnedPopup={(showPopUp: any) => props.showPointsEarnedPopup(showPopUp)}
            togglePlaceOrderButton={(status: boolean) => props.togglePlaceOrderButton(status)}
            toggleTipSuccessfulPopup={(status: boolean) => props.toggleTipSuccessfulPopup(status)}
          />
        </Elements>
      ) : <InlineLoader />}
    </React.Fragment>
  )
}

export default CardSectionWrapper;
