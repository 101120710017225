import * as types from "./types";
import * as pageTypes from "../page/types";

const loyaltyReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case types.FETCH_LOYALTY_SUCCESS:
      return action.payload.data;
    case types.REDEEM_LOYALTY:
      return {
        ...state,
        ...action.payload
      };
    case types.REMOVE_LOYALTY:
      return {
        ...state,
        redeemedPoints: null,
        hasRedeemed: false,
      };
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_SUCCESS:
      return action.payload.data.loyalty;

    default: return state;
  }
};

const reducer = loyaltyReducer;

export default reducer;
