import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ModalCloseIcon from "../modalCloseIcon";

interface IMarketingAnnouncementMobileProps {
  onClose: any;
  announcement: string;
}

function MarketingAnnouncementMobile(props: IMarketingAnnouncementMobileProps) {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="mx-5"
      centered
      size="sm"
      style={{ zIndex: 1054 }}
    >
      <ModalCloseIcon onClick={handleClose} />
      <div className="marketing-announcement-mb">
        <p className="announcement">{props.announcement}</p>
      </div>
    </Modal>
  );
}
export default MarketingAnnouncementMobile;
