import React from "react";

interface IButtonProps {
  text: any;
  onClick?: any;
  className?: string;
  disable?: boolean;
}

const Button = (props: IButtonProps) => (
  <button
    disabled={props.disable}
    className={`btn ${props.className}`}
    onClick={props.onClick}
  >
    {props.text}
  </button>
);
export default Button;
