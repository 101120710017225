import * as types from "./types";
import * as sessionTypes from "../session/types";

const discountReducer = ( state = null, action: any ) => {
  switch( action.type ) {
    case types.APPLY_DISCOUNT:
      return action.payload;
    case types.REMOVE_DISCOUNT:
    case types.FETCH_DISCOUNT_FAIL:
    case sessionTypes.CLEAR_SESSION:
      return null;

    default: return state;
  }
};

const reducer = discountReducer;

export default reducer;
