import * as types from "./types";

const fetchDiscount = (discountCode: string) => ({
  type: types.FETCH_DISCOUNT,
  payload: {
    request: {
      method: "get",
      url: `/discounts/${encodeURIComponent(discountCode.trim())}`,
    }
  }
});

const applyDiscount = (discount: any) => ({
  type: types.APPLY_DISCOUNT,
  payload: discount,
});

const removeDiscount = () => ({
  type: types.REMOVE_DISCOUNT,
});

export default {
  fetchDiscount,
  applyDiscount,
  removeDiscount,
};
