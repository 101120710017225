import * as types from "./types";
import * as pageTypes from "../page/types";

const pastOrdersReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
      return action.payload.data.past_orders;
    case types.FETCH_PAST_ORDERS:
      return action.payload;

    default: return state;
  }
};

const reducer = pastOrdersReducer;

export default reducer;
