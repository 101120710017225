// Packages
import React, { useRef, useState, useEffect, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import {
  LoadScript,
  GoogleMap,
  Autocomplete,
  PolygonF,
} from "@react-google-maps/api";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import GmapMarker from "../../images/gmap-marker.svg";
// Contexts
import { LocaleContext } from "../../wrappers/localeWrapper";
// Redux Operations
import { RootState } from "../../state/store";

declare global {
  interface Window {
    google: any;
  }
}

interface ExpandedDeliveryAddressMapWidgetProps {
  lat: number;
  lng: number;
  onAreaChange: any;
  minimiseMap: any;
  mapApiKey: string;
  toggleAddressMarked: any;
  reportAreaServiceabilityError: () => void;
  removeAreaServiceabilityError: () => void;
}

const mapOptions = {
  fullscreenControl: false,
  zoomControl: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
  streetViewControl: false,
  gestureHandling: 'greedy'
};

const LIBRARIES = ["places"];

function ExpandedDeliveryAddressMapWidget(
  props: ExpandedDeliveryAddressMapWidgetProps
) {
  const mapRef = useRef();
  const searchBox = useRef();
  const [markerPosition, setMarkerPosition] = React.useState({} as any);
  const [autoComplete, setAutoComplete] = useState(null as any);
  const [isLocationServiceable, setIsLocationServiceable] = useState(true);
  const [polygonOptions, setPolygonOptions] = useState({} as any);

  const localeContext = useContext(LocaleContext);

  const { company } = useSelector((state: RootState) => state);
  const { selectedArea } = useSelector((state: RootState) => state.areas);
  
  const validateMarkedLocation = (lat: any, lng: any) => {
    if (props.source == "edit-address") {
      setIsLocationServiceable(true);
      return true;
    }

    let parsedPosition = new google.maps.LatLng(lat, lng);
    let zoneMapEnabled = company.configuration?.zone_mapping_enabled == true;
    let isServiceable = true;
    if (zoneMapEnabled && selectedArea?.mapped_zones?.length) {
      let zonePolygon = new google.maps.Polygon({
        paths: selectedArea.mapped_zones,
      });
      setTimeout(() => {
        isServiceable = google.maps.geometry.poly.containsLocation(
          parsedPosition,
          zonePolygon
        );
        setIsLocationServiceable(isServiceable);
      }, 200);
    } else {
      setIsLocationServiceable(true);
    }
    return isServiceable;
  };

  useEffect(() => {
    let lat = props.lat || 0.0;
    let lng = props.lng || 0.0;
    setMarkerPosition({ lat: lat, lng: lng });
  }, [props.lat, props.lng]);

  const onMapScriptLoad = (map) => {
    if (validateMarkedLocation(markerPosition.lat, markerPosition.lng)) {
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: markerPosition }, (responses: any) => {
        let streetName = responses[0].formatted_address;
        props.onAreaChange(streetName, markerPosition.lat, markerPosition.lng);
        props.toggleAddressMarked(true);
      });
    }
  };

  useEffect(() => {
    if (props.source == "new-address") {
      if (!isLocationServiceable) {
        props.reportAreaServiceabilityError();
      } else {
        props.removeAreaServiceabilityError();
      }
    }
  }, [isLocationServiceable, markerPosition]);

  useEffect(() =>{
    setPolygonOptions({
      fillColor: "#24ad54",
      fillOpacity: 0.3,
      strokeColor: "#24ad54",
      strokeOpacity: 1,
      strokeWeight: 2,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      paths: selectedArea?.mapped_zones || [],
      zIndex: 1,
    });
  }, [selectedArea]);

  const onMapLoad = useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const onMapClick = useCallback((e: any) => {
    var svgElem = document.getElementsByClassName("gmap-marker")[0]?.getSVGDocument();
    svgElem?.children[0]?.children[1]?.firstElementChild?.classList.add("dragging");
    svgElem?.children[0]?.children[2]?.classList.add("dragging");

    if (validateMarkedLocation(e.latLng.lat(), e.latLng.lng())) {
      updateArea(e.latLng.lat(), e.latLng.lng());
    }

    setTimeout(() => {
      svgElem?.children[0]?.children[1]?.firstElementChild?.classList.remove("dragging");
      svgElem?.children[0]?.children[2]?.classList.remove("dragging");
    }, 200);
  }, []);

  const onMapDragStart = useCallback((e: any) => {
    var svgElem = document.getElementsByClassName("gmap-marker")[0]?.getSVGDocument();
    svgElem?.children[0]?.children[1]?.firstElementChild?.classList.add("dragging");
    svgElem?.children[0]?.children[2]?.classList.add("dragging");
  }, []);

  const onMapDragEnd = useCallback((e: any) => {
    if (mapRef.current && validateMarkedLocation(mapRef.current.center.lat(), mapRef.current.center.lng())) {
      updateArea(mapRef.current.center.lat(), mapRef.current.center.lng());
    }
    var svgElem = document.getElementsByClassName("gmap-marker")[0]?.getSVGDocument();
    svgElem?.children[0]?.children[1]?.firstElementChild?.classList.remove("dragging");
    svgElem?.children[0]?.children[2]?.classList.remove("dragging");
  }, []);

  const onMapZoomChanged = useCallback((e: any) => {
    if (mapRef.current && validateMarkedLocation(mapRef.current.center.lat(), mapRef.current.center.lng())) {
      updateArea(mapRef.current.center.lat(), mapRef.current.center.lng());
    }
    setTimeout(() =>{
      var svgElem = document.getElementsByClassName("gmap-marker")[0]?.getSVGDocument();
      svgElem?.children[0]?.children[1]?.firstElementChild?.classList.remove("dragging");
      svgElem?.children[0]?.children[2]?.classList.remove("dragging");
    },200);
  }, []);

  const updateArea = (lat: any, lng: any) => {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: { lat: lat, lng: lng } }, (responses: any) => {
      if (responses && responses[0]) {
        let streetName = responses[0].formatted_address;
        props.onAreaChange(responses[0].formatted_address, lat, lng);
        document.getElementById('map-input').value = streetName;
      }
    });
    setMarkerPosition({ lat: lat, lng: lng });
    props.toggleAddressMarked(true);
  }

  const onAutoCompleteLoad = (searchAutoComplete: any) => {
    setAutoComplete(searchAutoComplete);
  };

  const onSearchBoxMounted = (ref: any) => {
    searchBox.current = ref;
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      let place = autoComplete.getPlace();
      let streetName = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();

      var svgElem = document.getElementsByClassName("gmap-marker")[0]?.getSVGDocument();
      svgElem?.children[0]?.children[1]?.firstElementChild?.classList.add("dragging");
      svgElem?.children[0]?.children[2]?.classList.add("dragging");

      if (validateMarkedLocation(lat, lng)) {
        props.onAreaChange(streetName, lat, lng);
        setMarkerPosition({ lat: lat, lng: lng });
        props.toggleAddressMarked(true);
      }

      setTimeout(() => {
        svgElem?.children[0]?.children[1]?.firstElementChild?.classList.remove("dragging");
        svgElem?.children[0]?.children[2]?.classList.remove("dragging");
      }, 200);
    }
  };

  return (
    <div className="expanded-map">
      <div className="not-serviceable" id="notServiceable">
        <FormattedMessage
          id="address.outside_delivery_range"
          defaultMessage="The selected location is outside our delivery range."
        />
      </div>
      {props.mapApiKey ? (
        <LoadScript
          googleMapsApiKey={props.mapApiKey}
          onLoad={onMapScriptLoad}
          libraries={LIBRARIES}
          language={localeContext.locale}
        >
          <div className="gmap-marker-wrapper">
            <object
              className="gmap-marker"
              type="image/svg+xml"
              data={GmapMarker}
            ></object>
          </div>
          <GoogleMap
            mapContainerStyle={{
              height: "100vh"
            }}
            center={markerPosition}
            zoom={17}
            options={mapOptions}
            onLoad={onMapLoad}
            onClick={onMapClick}
            onDragStart={onMapDragStart}
            onDragEnd={onMapDragEnd}
            onZoomChanged={onMapZoomChanged}
          >
            <PolygonF
              path={selectedArea?.mapped_zones || []}
              options={polygonOptions}
            />

            <Autocomplete
              onLoad={onAutoCompleteLoad}
              ref={onSearchBoxMounted}
              onPlaceChanged={onPlaceChanged}
            >
              <FormattedMessage
                id="googlemap.search_location_here"
                defaultMessage="Search your location here..."
              >
                {(placeholder) => (
                  <input
                    type="text"
                    id="map-input"
                    placeholder={placeholder}
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      marginLeft: "-120px",
                      top: "10px",
                    }}
                  />
                )}
              </FormattedMessage>
            </Autocomplete>
          </GoogleMap>
        </LoadScript>
      ) : null}
      <div className="back-checkout">
        <button
          className="btn btn-primary px-4 py-3 w-100"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            props.minimiseMap(e)
          }
          disabled={!isLocationServiceable}
        >
          <FormattedMessage
            id="googlemap.use_this_location"
            defaultMessage="Use This Location"
          />
        </button>
      </div>
    </div>
  );
}

export default React.memo(ExpandedDeliveryAddressMapWidget);
