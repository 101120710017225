import React, { useEffect } from "react";
import { orderInProgressOperations } from "../../state/features/orderInProgress";
import { connect } from "react-redux";
import { cartOperations } from "../../state/features/cart";

interface IOmiseCardSectionWrapperProps {
  omiseKey: any,
  payload: any,
  showPointsEarnedPopup: any
  togglePlaceOrderButton: any,
  currency: any
  placeOrder: any;
  setCart: any;
  companyDecimalPrecision: any;
}


function OmiseCardSectionWrapper(props: IOmiseCardSectionWrapperProps) {
  const { OmiseCard }: any = window

  useEffect(() => {
    OmiseCard.configure({
      publicKey: props.omiseKey
    });
  }, []);

  const checkoutClick = (event: any) => {
    event.preventDefault();
    OmiseCard.open({
      amount: props.payload.total_amount * (10**props.companyDecimalPrecision),
      currency: props.currency,
      defaultPaymentMethod: "credit_card",
      onCreateTokenSuccess: (nonce: any) => {
        if (nonce.startsWith("tokn_")) {
          props.payload.payment.transaction_id = nonce;
          props.payload.payment.ref_card_number = nonce;
          props.payload.payment.total_amount = props.payload.total_amount;
          props.placeOrder("payments/omise/v1/orders", props.payload)
            .then((response: any) => {
            if (!response.error) {
              let earnedLoyalty = null;
              localStorage.cartItems = JSON.stringify([]);
              props.setCart({ items: [], subTotal: 0, total: 0 });
              if (response.payload.data.order_details.earned_loyalty) {
                earnedLoyalty = response.payload.data.order_details.earned_loyalty;
              }
              props.showPointsEarnedPopup(earnedLoyalty);
            }
            props.togglePlaceOrderButton(true);
          });
        }
      }
    });
  }

  return (
    <div id="omiseCheckoutForm">
      <button type="submit" id="omiseCheckoutButton" className="hidden" onClick={checkoutClick}>Checkout</button>
    </div>
  )
}

const mapDispatchToProps = {
  placeOrder: orderInProgressOperations.placeOrder,
  setCart: cartOperations.setCart
}
export default connect(null,mapDispatchToProps) (OmiseCardSectionWrapper);