import BSN from "bootstrap.native";

const handleCategoryScrollSpy = (categoryScrollSpyObj: any) => {
  delete categoryScrollSpyObj.scrollSpyHandler;
  if (!categoryScrollSpyObj.scrollSpyHandler) {
    var categoryScrollSpyElement = document.getElementsByClassName('category-items')[0];
    if (categoryScrollSpyElement) {
      var categoryScrollTarget = document.getElementById('cat-navigation');
      var topHeaderSection = document.getElementById("top-header");
      var topHeaderSectionHeight = topHeaderSection ? topHeaderSection.clientHeight : 0;
      var catSection = document.getElementById("horizontal-category");
      var catSectionHeight = catSection ? catSection.clientHeight : 0;

      // To ensure the category header updates before the main heading reaches the top while scrolling,
      // we add an additional offset to the total offset calculation.
      var additionalOffset = 200;
      var offset = topHeaderSectionHeight + catSectionHeight + additionalOffset;
      categoryScrollSpyObj.scrollSpyHandler = new BSN.ScrollSpy(
        categoryScrollSpyElement,
        {
          target : categoryScrollTarget,
          offset: offset
        }
      );

      categoryScrollSpyElement.addEventListener('activate.bs.scrollspy', function() {
        if (window.document.getElementsByClassName("category-entry-name active").length > 0) {
          var activeCategory = window.document.getElementsByClassName("category-entry-name active")[0].offsetLeft;
          window.document.getElementById("horizontal-category")?.scrollTo({
            top: 0,
            left: activeCategory - 14
          });
        }
      }, false);
    }
  }
  return categoryScrollSpyObj;
}

export {
  handleCategoryScrollSpy,
}
