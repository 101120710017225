import * as types from "./types";

const updateOrder = (order: any) => ({
  type: types.UPDATE_ORDER,
  payload: order,
});

const placeOrder = (path: string, order_details: any) => ({
  type: types.PLACE_ORDER,
  payload: {
    request: {
      method: "post",
      url: path,
      data: { order_details: order_details },
    }
  }
});

export default {
  updateOrder,
  placeOrder
};
