import * as types from "./types";

const updateLoader = (loader: any) => ({
  type: types.UPDATE_LOADER,
  payload: loader,
});

const resetLoader = () => ({
  type: types.RESET_LOADER,
});

const showLoader = () => ({
  type: types.SHOW_LOADER,
});

const hideLoader = () => ({
  type: types.HIDE_LOADER,
});

export default {
  updateLoader,
  resetLoader,
  showLoader,
  hideLoader,
};
