import GooglePayButton from "@google-pay/button-react/dist";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";
import { useHistory } from "react-router-dom";
import { loaderOperations } from "../../../../state/features/loader";
import { formatDecimal } from "../../../../helpers/itemCalculations";
import { getEnvValueFor } from "../../../../helpers/utils";
import { usePusherContext } from "../../../../hooks/usePusherContext";
import { errorOperations } from "../../../../state/features/error";
import { useIntl } from "react-intl";
import HtmlRenderer from "../../../../components/paymentTypes/mastercardDirectPayment/mastercard3dsHtml/htmlRenderer";
import FullPageAuthMobile from "../fullpageAuth";

interface IGooglePayButtonProps {
  orderPayload: any;
  verifyGooglePayCard(tokenData: any): any;
  submitGooglePayTokenData(tokenData: any): any;
  showLoader(): void;
  hideLoader(): void;
  verifyGooglePayUser(tokenData: any): any;
  updateError(error: any): void;
  updateMPGSPaymentStatus(paymentReference: string, status: string): void;
}

function GooglePayButtonContainerMobile({
  orderPayload,
  verifyGooglePayCard,
  showLoader,
  hideLoader,
  verifyGooglePayUser,
  submitGooglePayTokenData,
  updateError,
  updateMPGSPaymentStatus,
}: IGooglePayButtonProps) {
  const { currency, country_code } = useSelector(
    (state: RootState) => state.company
  );
  const { user } = useSelector((state: RootState) => state);
  const { paymentTypes } = useSelector((state: RootState) => state);
  const { name: companyName } = useSelector(
    (state: RootState) => state.company
  );
  const history = useHistory();
  const googlePayEnv = getEnvValueFor("MPGS_GOOGLE_PAY_ENVIRONMENT");
  const pusher = usePusherContext();
  const [responseHtml, setResponseHtml] = useState<string | null>(null);
  const [authResponseHtml, setAuthResponseHtml] = useState<string | null>(null);
  const browserData = useRef<any | null>(null);
  const intl = useIntl();

  useEffect(() => {
    const getBrowserData = () => {
      const browser = navigator.userAgent;
      const browserDetails = {
        "3DSecureChallengeWindowSize": "FULL_SCREEN",
        acceptHeaders: "application/json",
        colorDepth: window.screen.colorDepth,
        javaEnabled: navigator.javaEnabled(),
        language: navigator.language,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        timeZone: new Date().getTimezoneOffset(),
      };

      const data = {
        browser,
        browserDetails,
      };
      browserData.current = data;
    };

    getBrowserData();
  }, []);

  const paymentData = useMemo(() => {
    let googlePayMerchantId = "";
    let gateWayMerchantId = "";
    const masterCardData = paymentTypes.find(
      (paymentType: any) => paymentType.payment_type === "mastercard"
    );
    if (masterCardData && Object.keys(masterCardData).length) {
      googlePayMerchantId = masterCardData?.google_pay_merchant_id ?? "";
      gateWayMerchantId = masterCardData?.merchant_id ?? "";
    }
    const totalAmount = orderPayload?.payment?.amount ?? 0;
    const tipsAmount =
      orderPayload?.payment?.tip ?? orderPayload?.tips_amount ?? 0;
    const payableAmount = totalAmount + tipsAmount;
    const paymentRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["MASTERCARD", "VISA"],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "mpgs",
              gatewayMerchantId: gateWayMerchantId,
            },
          },
        },
      ],
      merchantInfo: {
        merchantId: googlePayMerchantId,
        merchantName: companyName,
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPriceLabel: "Total",
        totalPrice: formatDecimal(payableAmount),
        currencyCode: currency,
        countryCode: country_code,
      },
    };
    return paymentRequest;
  }, [orderPayload, currency, country_code, paymentTypes, companyName]);

  const process3dsPayment = async (
    response: any,
    paymentReference: string,
    newPayload: any
  ) => {
    const responseData = response.payload?.data?.data;
    if (responseData && responseData?.authentication["3ds2"]?.methodSupported) {
      setResponseHtml(responseData?.authentication?.redirect?.html);
    }
    const proceedToNextStep = responseData.response?.gatewayRecommendation;
    if (proceedToNextStep === "PROCEED") {
      setTimeout(async () => {
        await authenticateUser(paymentReference, newPayload);
      }, 10000);
    } else {
      showErrorModal(intl.formatMessage({ id: "error.auth_failed" }));
    }
  };

  const authenticateUser = async (
    paymentReference: string,
    newPayload: any
  ) => {
    await handleAuthentication(paymentReference, newPayload);
  };

  const handleAuthentication = async (
    paymentReference: string,
    newPayload: any
  ) => {
    try {
      const deviceDetails = browserData.current;
      const browserDataPayload = { ...newPayload, device: deviceDetails };
      const authenticationResponse = await verifyGooglePayUser(
        browserDataPayload
      );
      const authenticationResponseData =
        authenticationResponse.payload?.data?.data;
      const proceedToNextStep =
        authenticationResponseData.response?.gatewayRecommendation;
      const frictionlessFlow =
        authenticationResponseData.authentication?.payerInteraction ===
        "NOT_REQUIRED";
      if (proceedToNextStep === "PROCEED" && frictionlessFlow) {
        await submitDataAndRedirect(newPayload, paymentReference);
      } else if (authenticationResponseData.authentication?.redirect?.html) {
        hideLoader();
        setAuthResponseHtml(
          authenticationResponseData.authentication.redirect.html
        );
        const authenticationStatus: any = await fetchAuthenticationStatus(
          paymentReference
        );
        if (authenticationStatus) {
          await submitDataAndRedirect(newPayload, paymentReference);
        } else {
          showErrorModal(intl.formatMessage({ id: "error.auth_failed" }));
        }
      } else {
        // Handle other cases or errors
        console.error(
          "Unexpected authentication response",
          authenticationResponseData
        );
        showErrorModal(
          intl.formatMessage({ id: "error.unexpected_auth_response" })
        );
      }
    } catch (error) {
      showErrorModal(intl.formatMessage({ id: "error.auth_error" }));
    }
  };

  const fetchAuthenticationStatus = async (paymentReference: string) => {
    const channel = pusher.subscribe(
      `mastercard_authentication_${paymentReference}`
    );
    return new Promise((resolve, reject) => {
      const successHandler = (data: any) => {
        clearTimeout(timeoutId);
        channel.unbind("authentication_completed", successHandler);
        channel.unbind("authentication_failed", failureHandler);
        resolve(data);
      };
      const failureHandler = (data: any) => {
        clearTimeout(timeoutId);
        channel.unbind("authentication_completed", successHandler);
        channel.unbind("authentication_failed", failureHandler);
        showErrorModal(intl.formatMessage({ id: "error.auth_failed" }));
        reject(new Error("Authentication failed"));
      };
      channel.bind("authentication_completed", successHandler);
      channel.bind("authentication_failed", failureHandler);

      const timeoutId = setTimeout(() => {
        console.error("Timeout waiting for authentication message");
        channel.unbind("authentication_completed", successHandler);
        channel.unbind("authentication_failed", failureHandler);
        updateMPGSPaymentStatus(paymentReference, "declined");
        showErrorModal(intl.formatMessage({ id: "error.auth_timeout" }));
      }, 120000); // 2 mins timeout
    });
  };

  const showErrorModal = (errorInfo: string) => {
    hideLoader();
    setAuthResponseHtml(null);
    setResponseHtml(null);
    updateError({
      title: intl.formatMessage({ id: "payment.payment_failed" }),
      message: intl.formatMessage({ id: "payment.unable_to_process_payment" }),
      redirectionUrl: "/m/order-details",
      errorInfo: errorInfo,
    });
  };

  const placeOrderError = () => {
    hideLoader();
    setAuthResponseHtml(null);
    setResponseHtml(null);
    updateError({
      title: intl.formatMessage({ id: "error.oops" }),
      message: intl.formatMessage({ id: "error.oops_something_went_wrong" }),
      redirectionUrl: "/m/order-details",
    });
  };

  const submitDataAndRedirect = async (
    payload: any,
    paymentReference: string
  ) => {
    setResponseHtml(null);
    setAuthResponseHtml(null);
    try {
      const submitDataResponse = await submitGooglePayTokenData(payload);
      if (!submitDataResponse.error) {
        hideLoader();
        history.push(
          `/payment-processing?reference=${paymentReference}&type=mastercard`
        );
      }
    } catch (error) {
      placeOrderError();
    }
  };

  const onLoadPaymentData = async (paymentData: any) => {
    const currentTime = new Date();
    const paymentReference = `${user.id}${
      user.company_id
    }${currentTime.getTime()}`;
    try {
      const newPayload = {
        token_data: paymentData,
        order_details: orderPayload,
        reference_id: paymentReference,
      };
      const response = await verifyGooglePayCard(newPayload);
      if (response.error) {
        let error_id = response.error?.response?.data?.id;
        if (error_id && error_id === "out_of_stock") {
          hideLoader();
          updateError({
            subTitleCode: "error.out_of_stock",
            messageCode: "error.out_of_stock",
            redirectionUrl: "/",
          });
        } else {
          showErrorModal(
            intl.formatMessage({ id: "error.card_verification_failed" })
          );
        }
      } else {
        const cardSupports3ds =
          response.payload?.data?.data?.transaction?.authenticationStatus ===
          "AUTHENTICATION_AVAILABLE";
        if (cardSupports3ds) {
          await process3dsPayment(response, paymentReference, newPayload);
        } else {
          hideLoader();
          await submitDataAndRedirect(newPayload, paymentReference);
        }
      }
    } catch (error) {
      showErrorModal(
        intl.formatMessage({ id: "error.card_verification_failed" })
      );
    }
  };

  const onError = (error: any) => {
    console.error(error);
  };

  const clickHandler = () => {
    showLoader();
  };

  return (
    <div className="my-3 w-100">
      <GooglePayButton
        environment={googlePayEnv}
        paymentRequest={paymentData}
        onLoadPaymentData={onLoadPaymentData}
        onError={onError}
        onCancel={() => hideLoader()}
        onClick={clickHandler}
        buttonSizeMode="fill"
        buttonType="pay"
        style={{ height: 40, width: "100%" }}
      />
      {responseHtml ? <HtmlRenderer htmlContent={responseHtml} /> : false}
      {authResponseHtml ? (
        <FullPageAuthMobile htmlContent={authResponseHtml} />
      ) : (
        false
      )}
    </div>
  );
}

const mapDispatchToProps = {
  showLoader: loaderOperations.showLoader,
  hideLoader: loaderOperations.hideLoader,
  verifyGooglePayCard: paymentTypeOperations.verifyMPGSGooglePayCard,
  verifyGooglePayUser: paymentTypeOperations.verifyMPGSGooglePayUser,
  submitGooglePayTokenData: paymentTypeOperations.submitMPGSGooglePayTokenData,
  updateError: errorOperations.updateError,
  updateMPGSPaymentStatus: paymentTypeOperations.updateMPGSPaymentStatus,
};

export default connect(null, mapDispatchToProps)(GooglePayButtonContainerMobile);
