// Packages
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Redux Operations
import { invoiceOperations } from "../../state/features/invoice";
// Helpers, Utils etc.
import { EMAIL_REGEX } from "../../helpers/constants";

interface IEmailInvoicePopupProps {
  orderNumber: string;
  onClose: any;
  sendInvoiceToNewEmail: any;
}

function EmailInvoicePopup(props: IEmailInvoicePopupProps) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("" as null | string);
  const [show, setShow] = useState(true);

  const emailRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (localStorage.userEmail && emailRef.current) {
      emailRef.current.value = localStorage.getItem("userEmail") as string;
      setEmail(emailRef.current.value);
    }
  }, []);

  const handleClose = () => {
    props.onClose();
  };

  const submitEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (EMAIL_REGEX.test(email)) {
      localStorage.setItem("userEmail", email);
      props.sendInvoiceToNewEmail(props.orderNumber, email);
      handleClose();
    } else {
      setEmailError("Please enter a valid email");
    }
  };

  const handleEmailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  return (
    <Modal show={show} dialogClassName="custom curved" onHide={handleClose} centered>
      <div className="email-invoice-popup px-5 py-3 mb-4 mt-4">
        <h1 className="header mb-4 my-3">
          <FormattedMessage
            id="registration.enter_email"
            defaultMessage="Enter your email address"
          />
        </h1>
        <form onSubmit={submitEmail}>
          <div className="form-group">
            <FormattedMessage
              id="registration.email.placeholder"
              defaultMessage="example@email.com"
            >
              {(placeholder) => (
                <input
                  type="text"
                  name="email"
                  ref={emailRef}
                  required
                  autoFocus
                  placeholder={placeholder.toString()}
                  className="email-input form-control"
                  onChange={handleEmailInput}
                />
              )}
            </FormattedMessage>
          </div>
          {emailError ? (
            <div className="input-label text-center text-danger">
              <FormattedMessage
                id="registration.email.validation"
                defaultMessage="Please enter a valid email address."
              />
            </div>
          ) : null}
          <button
            className="button btn btn-primary w-100 py-2 mt-3"
            type="submit"
          >
            <FormattedMessage
              id="global.continue"
              defaultMessage="Continue"
            />
            <span className="ml-3">
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </span>
          </button>
        </form>
      </div>
    </Modal>
  );
}

const mapDispatchToProps = {
  sendInvoiceToNewEmail: invoiceOperations.sendInvoiceToNewEmail,
};

export default connect(null, mapDispatchToProps)(EmailInvoicePopup);
