import React from "react";

interface IInlineLoaderProps {
  message?: string;
}

const InlineLoader = (props: IInlineLoaderProps) => {
  return (
    <div className="inline-loader">
      <div className="layout">
        <div className="loading" />
      </div>
      {props.message && (
        <div className="container">
          <p className="loader-message">
            {props.message}
          </p>
        </div>
      )}
    </div>
  );
};
export default InlineLoader;
