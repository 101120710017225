import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

interface IOtpTimerProps {
  setOtpTimeout: (value: boolean) => void;
}

function OtpTimer(props: IOtpTimerProps) {
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    let otpTimer = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    return () => clearInterval(otpTimer);
  });

  useEffect(() => {
    if (timer <= 0) {
      props.setOtpTimeout(true);
    }
  }, [timer]);

  return (
    <FormattedMessage
      id="registration.otp.resend_wait_seconds"
      defaultMessage="{countDownInSeconds}s to resend"
      values={{ countDownInSeconds: timer }}
    />
  );
}

export default OtpTimer;
