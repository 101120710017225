import * as pageTypes from "../page/types";
import { findRestaurantWorkingHours } from "../../../helpers/utils";

const storeTimingsReducer = ( state = { open: false }, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return (
        findRestaurantWorkingHours(action.payload.data.outlet.opening_hours, action.payload.data.outlet.utc_offset)
      );

    default: return state;
  }
};

const reducer = storeTimingsReducer;

export default reducer;
