// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
// Helpers, Utils etc.
import { deepCopyFunction } from "../../helpers/utils";
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  translatedName,
  translatedDescription,
} from "../../helpers/translations";

interface ICustomisationModalProps {
  onClose: void;
  modifierItem: any;
  currency: string;
  onModifierAdd: void;
}

interface ICustomisationModalState {
  show: boolean;
  modifierItem: any;
  cartDisabled: boolean;
  subTotal: any;
}

class CustomisationModal extends Component<
  ICustomisationModalProps,
  ICustomisationModalState
> {
  constructor(props: ICustomisationModalProps) {
    super(props);
    this.state = {
      show: true,
      modifierItem: {},
      cartDisabled: false,
      subTotal: 0,
    };
  }

  componentDidMount() {
    this.setState({
      modifierItem: this.transformedItem(this.props.modifierItem),
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.modifierItem !== this.state.modifierItem) {
      if (this.state.modifierItem) {
        this.setState({
          subTotal: this.calculateSubTotal(this.state.modifierItem),
          cartDisabled: this.checkIfCartIsDisabled(this.state.modifierItem),
        });
      }
    }
  }

  transformedItem = (item: any) => {
    item = deepCopyFunction(item);
    item.count = 1;
    item.groups = item.groups.map((group: any) => {
      let modifierCount = 0;
      group.items = group.items.map((item: any, index: number) => {
        item.selected = false;
        item.count = 0;
        if (item.is_default && !group.has_quantity_selector) {
          if (
            !group.maximum ||
            (group.maximum > 0 && modifierCount < group.maximum)
          ) {
            modifierCount++;
            item.selected = true;
            item.count++;
          }
        }
        return item;
      });
      let groupLimitReached = modifierCount >= group.maximum;
      group.items = group.items.map((item: any) => {
        item.disabled =
          group.maximum > 0 && groupLimitReached && !item.selected;
        return item;
      });
      if (group.has_quantity_selector) {
        group.type = "quantity_selector";
      } else if (group.minimum === 1 && group.maximum === 1) {
        group.type = "single_item";
      } else {
        group.type = "multiple_item";
      }
      group.minimumSelected = modifierCount >= group.minimum;
      return group;
    });
    return item;
  };

  calculateSubTotal = (item: any) => {
    let basePrice = item.unit_price;
    let total = item.groups.reduce((itemTotal: number, group: any) => {
      let groupTotal = group.items.reduce((amount: number, groupItem: any) => {
        if (groupItem.selected) {
          return amount + groupItem.final_price * groupItem.count;
        }
        return amount;
      }, 0);
      return itemTotal + groupTotal;
    }, basePrice);
    return total * item.count;
  };

  handleClose = () => {
    setTimeout(() => {
      this.props.onClose();
    }, 500);
    this.setState({ show: false });
    document.body.classList.remove("noscroll");
  };

  onCheckBoxClick = (group: any, item: any) => {
    let modifierCount = 0;
    let groupLimitReached = false;
    if (group.type === "multiple_item") {
      group.items = group.items.map((itemInGroup: any) => {
        if (itemInGroup.id === item.id) {
          itemInGroup.selected = !itemInGroup.selected;
          itemInGroup.count = itemInGroup.selected ? 1 : 0;
        }
        if (itemInGroup.selected) {
          modifierCount++;
        }
        return itemInGroup;
      });
      groupLimitReached = group.maximum === modifierCount;
      group.items = group.items.map((itemInGroup: any) => {
        itemInGroup.disabled =
          group.maximum > 0 && groupLimitReached && !itemInGroup.selected;
        return itemInGroup;
      });
    } else if (group.type === "single_item") {
      modifierCount = 1;
      group.items = group.items.map((itemInGroup: any) => {
        itemInGroup.selected = itemInGroup.id === item.id;
        itemInGroup.count = itemInGroup.id === item.id ? 1 : 0;
        return itemInGroup;
      });
    } else if (group.type === "quantity_selector") {
      let groupItems = group.items.map((itemInGroup: any) => {
        itemInGroup.selected = itemInGroup.count > 0;
        itemInGroup.count = itemInGroup.count || 0;
        modifierCount += itemInGroup.count;
        return itemInGroup;
      });
      groupLimitReached = group.maximum === modifierCount;
      group.items = group.items.map((itemInGroup: any) => {
        itemInGroup.disabled = group.maximum > 0 && groupLimitReached;
        return itemInGroup;
      });
    }
    group.minimumSelected = modifierCount >= group.minimum;
    this.updateItemWithModifierSelection(group);
  };

  updateItemWithModifierSelection = (group: any) => {
    let modifierItem = deepCopyFunction(this.state.modifierItem);
    modifierItem.groups = modifierItem.groups.map((modifierGroup: any) => {
      if (group.id === modifierGroup.id) {
        modifierGroup = group;
      }
      return modifierGroup;
    });
    this.setState({ modifierItem });
  };

  checkIfCartIsDisabled = (item: any) => {
    let canBeAddedToCart = item.groups.every((modifierGroup: any) => {
      return modifierGroup.minimumSelected;
    });
    return !canBeAddedToCart;
  };

  onModifierItemDecrement = (
    e: React.MouseEvent<HTMLButtonElement>,
    modifierGroup: any,
    item: any
  ) => {
    item.count = item.count - 1;
    this.onCheckBoxClick(modifierGroup, item);
  };

  onModifierItemIncrement = (
    e: React.MouseEvent<HTMLButtonElement>,
    modifierGroup: any,
    item: any
  ) => {
    item.count = item.count + 1;
    this.onCheckBoxClick(modifierGroup, item);
  };

  addModifiedItemToCart = (e: any) => {
    this.props.onModifierAdd(e, deepCopyFunction(this.state.modifierItem));
    document.body.classList.remove("noscroll");
  };

  decrementModifiedItemCount = () => {
    let modifierItem = deepCopyFunction(this.state.modifierItem);
    modifierItem.count = modifierItem.count - 1;
    this.setState({ modifierItem });
  };

  incrementModifiedItemCount = () => {
    let modifierItem = deepCopyFunction(this.state.modifierItem);
    modifierItem.count = modifierItem.count + 1;
    this.setState({ modifierItem });
  };

  changeModifiedItemCount = (count: any) => {
    let modifierItem = deepCopyFunction(this.state.modifierItem);
    modifierItem.count = Number(count || 0);
    this.setState({ modifierItem });
  };

  appliedModifierCount(group: any) {
    return group.items.reduce((count: number, item: any) => {
      return count + item.count;
    }, 0);
  }

  modifierSpecifications(minimum: number, maximum: number) {
    let specification = "";
    if (maximum > 0) {
      if (minimum == maximum) {
        specification = <FormattedMessage
                          id="menu.item.choose_minimum"
                          defaultMessage="Please choose {minimumCount}"
                          values={{
                            minimumCount: minimum,
                          }}
                        />;
      } else if (minimum > 0) {
        specification = <FormattedMessage
                          id="menu.item.choose_minimum_and_maximum"
                          defaultMessage="Please choose minimum of {minimumCount} and maximum of {maximumCount}"
                          values={{
                            minimumCount: minimum,
                            maximumCount: maximum,
                          }}
                        />;
      } else {
        specification = <FormattedMessage
                          id="menu.item.choose_maximum"
                          defaultMessage="Please choose maximum of {maximumCount}"
                          values={{
                            maximumCount: maximum,
                          }}
                        />;
      }
    } else if (minimum > 0) {
      specification = <FormattedMessage
                        id="menu.item.choose_minimum"
                        defaultMessage="Please choose {minimumCount}"
                        values={{
                          minimumCount: minimum,
                        }}
                      />;
    }
    return specification;
  }

  render() {
    const { cartDisabled, modifierItem, show } = this.state;
    const { currency, intl } = this.props;

    return (
      <div className="mobile-customisation-modal">
        <div
          className={modifierItem.image_url ? "item-img" : "item-img no-image"}
          style={{
            backgroundImage: `url(${modifierItem.image_url})`,
          }}
        ></div>
        <div className="closeModifier">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={this.props.onClose}
            size="2x"
            className="close-icon"
          />
        </div>
        <div className="customisation-details">
          <p className="item-name">
            {translatedName(modifierItem, intl.locale)}
          </p>
          <p className="item-description">
            {translatedDescription(modifierItem, intl.locale)}
          </p>
          {modifierItem.groups
            ? modifierItem.groups.map((modifierGroup: any, index: number) => {
                let specification = this.modifierSpecifications(
                  modifierGroup.minimum, modifierGroup.maximum
                );
                return (
                  <div className="item-group" key={index}>
                    <div className="group-header">
                      <div className="group-info">
                        <p className="group-name">
                          {translatedName(modifierGroup, intl.locale)}
                        </p>
                        {specification ? (
                          <p className="limit-info">
                            {specification}
                          </p>
                        ) : null}
                      </div>
                      {modifierGroup.maximum > 0 ? (
                        <p className="group-limit">
                          <FormattedMessage
                            id="menu.item.modifier_counter"
                            defaultMessage="{selectedCount} of {maximumCount}"
                            values={{
                              selectedCount: this.appliedModifierCount(modifierGroup),
                              maximumCount: modifierGroup.maximum
                            }}
                          />
                        </p>
                      ) : null}
                    </div>
                    {modifierGroup.items.map((item: any, index: number) => {
                      return (
                        <div className="modifier-item" key={index}>
                          <div className="modifier-icon">
                            {modifierGroup.type === "multiple_item" && (
                              <button
                                id={`${item.id}`}
                                className={
                                  item.selected
                                    ? "check-box selected"
                                    : "check-box"
                                }
                                onClick={() =>
                                  this.onCheckBoxClick(modifierGroup, item)
                                }
                                disabled={item.disabled ? true : false}
                              />
                            )}

                            {modifierGroup.type === "single_item" && (
                              <button
                                id={`${item.id}`}
                                className={
                                  item.selected
                                    ? "radio-button selected"
                                    : "radio-button"
                                }
                                onClick={() =>
                                  this.onCheckBoxClick(modifierGroup, item)
                                }
                              ></button>
                            )}
                          </div>
                          <label
                            htmlFor={
                              modifierGroup.has_quantity_selector
                                ? ""
                                : `${item.id}`
                            }
                            className="modifier-name"
                          >
                            {translatedName(item, intl.locale)}
                          </label>
                          {item.unit_price > 0 ? (
                            <p className="modifier-price">
                              +{currency} {formatDecimal(item.unit_price)}
                            </p>
                          ) : null}
                          {modifierGroup.type === "quantity_selector" && (
                            <div className="count-controller">
                              <p
                                className={
                                  item.count === 0 ? "icon disabled" : "icon"
                                }
                                onClick={(
                                  e: React.MouseEvent<HTMLButtonElement>
                                ) =>
                                  this.onModifierItemDecrement(
                                    e,
                                    modifierGroup,
                                    item
                                  )
                                }
                              >
                                <FontAwesomeIcon size='xs' icon={faMinus} />
                              </p>
                              <p className="count">{item.count}</p>
                              <p
                                className={
                                  item.disabled ? "icon disabled" : "icon"
                                }
                                onClick={(
                                  e: React.MouseEvent<HTMLButtonElement>
                                ) =>
                                  this.onModifierItemIncrement(
                                    e,
                                    modifierGroup,
                                    item
                                  )
                                }
                              >
                                <FontAwesomeIcon size='xs' icon={faPlus} />
                              </p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })
            : null}
        </div>
        <div className="modifier-add">
          <button
            className="btn btn-primary btn-block"
            onClick={(e: any) => this.addModifiedItemToCart(e)}
            disabled={cartDisabled}
          >
            <FormattedMessage
              id="menu.item.add_to_order"
              defaultMessage="Add to Order"
            />
            <span className="ml-3">
              {currency} {formatDecimal(this.state.subTotal)}
            </span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;

  return {
    currency,
  };
};

export default injectIntl(connect(mapStateToProps)(CustomisationModal));
