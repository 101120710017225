import * as pageTypes from "../page/types";

const paymentTypesReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_SUCCESS:
      return action.payload.data.payment_types;
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
      return (
        action.payload.data.payment_types.filter((paymentType: any) => {
          return !["cash_on_delivery", "card_on_delivery"].includes(paymentType.payment_type);
        })
      );

    default: return state;
  }
};

const reducer = paymentTypesReducer;

export default reducer;
