let countryCodes = [
  { label: "🇦🇫 +93", value: "93", code: "AF", name: "Afghanistan" },
  { label: "🇦🇽 +358", value: "358", code: "AX", name: "Åland Islands" },
  { label: "🇦🇱 +355", value: "355", code: "AL", name: "Albania" },
  { label: "🇩🇿 +213", value: "213", code: "DZ", name: "Algeria" },
  { label: "🇦🇸 +1", value: "1", code: "AS", name: "American Samoa" },
  { label: "🇦🇩 +376", value: "376", code: "AD", name: "Andorra" },
  { label: "🇦🇴 +244", value: "244", code: "AO", name: "Angola" },
  { label: "🇦🇮 +1", value: "1", code: "AI", name: "Anguilla" },
  { label: "🇦🇶 +672", value: "672", code: "AQ", name: "Antarctica" },
  { label: "🇦🇬 +1", value: "1", code: "AG", name: "Antigua and Barbuda" },
  { label: "🇦🇷 +54", value: "54", code: "AR", name: "Argentina" },
  { label: "🇦🇲 +374", value: "374", code: "AM", name: "Armenia" },
  { label: "🇦🇼 +297", value: "297", code: "AW", name: "Aruba" },
  { label: "🇦🇺 +61", value: "61", code: "AU", name: "Australia" },
  { label: "🇦🇹 +43", value: "43", code: "AT", name: "Austria" },
  { label: "🇦🇿 +994", value: "994", code: "AZ", name: "Azerbaijan" },
  { label: "🇧🇸 +1", value: "1", code: "BS", name: "Bahamas" },
  { label: "🇧🇭 +973", value: "973", code: "BH", name: "Bahrain" },
  { label: "🇧🇩 +880", value: "880", code: "BD", name: "Bangladesh" },
  { label: "🇧🇧 +1", value: "1", code: "BB", name: "Barbados" },
  { label: "🇧🇾 +375", value: "375", code: "BY", name: "Belarus" },
  { label: "🇧🇪 +32", value: "32", code: "BE", name: "Belgium" },
  { label: "🇧🇿 +501", value: "501", code: "BZ", name: "Belize" },
  { label: "🇧🇯 +229", value: "229", code: "BJ", name: "Benin" },
  { label: "🇧🇲 +1", value: "1", code: "BM", name: "Bermuda" },
  { label: "🇧🇹 +975", value: "975", code: "BT", name: "Bhutan" },
  { label: "🇧🇴 +591", value: "591", code: "BO", name: "Bolivia (Plurinational State of)" },
  { label: " +599", value: "599", code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { label: "🇧🇦 +387", value: "387", code: "BA", name: "Bosnia and Herzegovina" },
  { label: "🇧🇼 +267", value: "267", code: "BW", name: "Botswana" },
  { label: "🇧🇻 +47", value: "47", code: "BV", name: "Bouvet Island" },
  { label: "🇧🇷 +55", value: "55", code: "BR", name: "Brazil" },
  { label: "🇮🇴 +246", value: "246", code: "IO", name: "British Indian Ocean Territory" },
  { label: "🇧🇳 +673", value: "673", code: "BN", name: "Brunei Darussalam" },
  { label: "🇧🇬 +359", value: "359", code: "BG", name: "Bulgaria" },
  { label: "🇧🇫 +226", value: "226", code: "BF", name: "Burkina Faso" },
  { label: "🇧🇮 +257", value: "257", code: "BI", name: "Burundi" },
  { label: "🇰🇭 +855", value: "855", code: "KH", name: "Cambodia" },
  { label: "🇨🇲 +237", value: "237", code: "CM", name: "Cameroon" },
  { label: "🇨🇦 +1", value: "1", code: "CA", name: "Canada" },
  { label: "🇨🇻 +238", value: "238", code: "CV", name: "Cabo Verde" },
  { label: "🇰🇾 +1", value: "1", code: "KY", name: "Cayman Islands" },
  { label: "🇨🇫 +236", value: "236", code: "CF", name: "Central African Republic" },
  { label: "🇹🇩 +235", value: "235", code: "TD", name: "Chad" },
  { label: "🇨🇱 +56", value: "56", code: "CL", name: "Chile" },
  { label: "🇨🇳 +86", value: "86", code: "CN", name: "China" },
  { label: "🇨🇽 +61", value: "61", code: "CX", name: "Christmas Island" },
  { label: "🇨🇨 +61", value: "61", code: "CC", name: "Cocos (Keeling) Islands" },
  { label: "🇨🇴 +57", value: "57", code: "CO", name: "Colombia" },
  { label: "🇰🇲 +269", value: "269", code: "KM", name: "Comoros" },
  { label: "🇨🇬 +242", value: "242", code: "CG", name: "Congo" },
  { label: "🇨🇩 +243", value: "243", code: "CD", name: "Congo (Democratic Republic of the)" },
  { label: "🇨🇰 +682", value: "682", code: "CK", name: "Cook Islands" },
  { label: "🇨🇷 +506", value: "506", code: "CR", name: "Costa Rica" },
  { label: "🇨🇮 +225", value: "225", code: "CI", name: "Côte d'Ivoire" },
  { label: "🇭🇷 +385", value: "385", code: "HR", name: "Croatia" },
  { label: "🇨🇺 +53", value: "53", code: "CU", name: "Cuba" },
  { label: " +599", value: "599", code: "CW", name: "Curaçao" },
  { label: "🇨🇾 +357", value: "357", code: "CY", name: "Cyprus" },
  { label: "🇨🇿 +420", value: "420", code: "CZ", name: "Czech Republic" },
  { label: "🇩🇰 +45", value: "45", code: "DK", name: "Denmark" },
  { label: "🇩🇯 +253", value: "253", code: "DJ", name: "Djibouti" },
  { label: "🇩🇲 +1", value: "1", code: "DM", name: "Dominica" },
  { label: "🇩🇴 +1", value: "1", code: "DO", name: "Dominican Republic" },
  { label: "🇪🇨 +593", value: "593", code: "EC", name: "Ecuador" },
  { label: "🇪🇬 +20", value: "20", code: "EG", name: "Egypt" },
  { label: "🇸🇻 +503", value: "503", code: "SV", name: "El Salvador" },
  { label: "🇬🇶 +240", value: "240", code: "GQ", name: "Equatorial Guinea" },
  { label: "🇪🇷 +291", value: "291", code: "ER", name: "Eritrea" },
  { label: "🇪🇪 +372", value: "372", code: "EE", name: "Estonia" },
  { label: "🇪🇹 +251", value: "251", code: "ET", name: "Ethiopia" },
  { label: "🇫🇰 +500", value: "500", code: "FK", name: "Falkland Islands (Malvinas)" },
  { label: "🇫🇴 +298", value: "298", code: "FO", name: "Faroe Islands" },
  { label: "🇫🇯 +679", value: "679", code: "FJ", name: "Fiji" },
  { label: "🇫🇮 +358", value: "358", code: "FI", name: "Finland" },
  { label: "🇫🇷 +33", value: "33", code: "FR", name: "France" },
  { label: "🇬🇫 +594", value: "594", code: "GF", name: "French Guiana" },
  { label: "🇵🇫 +689", value: "689", code: "PF", name: "French Polynesia" },
  { label: "🇹🇫 +262", value: "262", code: "TF", name: "French Southern Territories" },
  { label: "🇬🇦 +241", value: "241", code: "GA", name: "Gabon" },
  { label: "🇬🇲 +220", value: "220", code: "GM", name: "Gambia" },
  { label: "🇬🇪 +995", value: "995", code: "GE", name: "Georgia" },
  { label: "🇩🇪 +49", value: "49", code: "DE", name: "Germany" },
  { label: "🇬🇭 +233", value: "233", code: "GH", name: "Ghana" },
  { label: "🇬🇮 +350", value: "350", code: "GI", name: "Gibraltar" },
  { label: "🇬🇷 +30", value: "30", code: "GR", name: "Greece" },
  { label: "🇬🇱 +299", value: "299", code: "GL", name: "Greenland" },
  { label: "🇬🇩 +1", value: "1", code: "GD", name: "Grenada" },
  { label: "🇬🇵 +590", value: "590", code: "GP", name: "Guadeloupe" },
  { label: "🇬🇺 +1", value: "1", code: "GU", name: "Guam" },
  { label: "🇬🇹 +502", value: "502", code: "GT", name: "Guatemala" },
  { label: "🇬🇬 +44", value: "44", code: "GG", name: "Guernsey" },
  { label: "🇬🇳 +224", value: "224", code: "GN", name: "Guinea" },
  { label: "🇬🇼 +245", value: "245", code: "GW", name: "Guinea-Bissau" },
  { label: "🇬🇾 +592", value: "592", code: "GY", name: "Guyana" },
  { label: "🇭🇹 +509", value: "509", code: "HT", name: "Haiti" },
  { label: "🇭🇲 +672", value: "672", code: "HM", name: "Heard Island and McDonald Islands" },
  { label: "🇻🇦 +39", value: "39", code: "VA", name: "Holy See" },
  { label: "🇭🇳 +504", value: "504", code: "HN", name: "Honduras" },
  { label: "🇭🇰 +852", value: "852", code: "HK", name: "Hong Kong" },
  { label: "🇭🇺 +36", value: "36", code: "HU", name: "Hungary" },
  { label: "🇮🇸 +354", value: "354", code: "IS", name: "Iceland" },
  { label: "🇮🇳 +91", value: "91", code: "IN", name: "India" },
  { label: "🇮🇩 +62", value: "62", code: "ID", name: "Indonesia" },
  { label: "🇮🇷 +98", value: "98", code: "IR", name: "Iran (Islamic Republic of)" },
  { label: "🇮🇶 +964", value: "964", code: "IQ", name: "Iraq" },
  { label: "🇮🇪 +353", value: "353", code: "IE", name: "Ireland" },
  { label: "🇮🇲 +44", value: "44", code: "IM", name: "Isle of Man" },
  { label: "🇮🇱 +972", value: "972", code: "IL", name: "Israel" },
  { label: "🇮🇹 +39", value: "39", code: "IT", name: "Italy" },
  { label: "🇯🇲 +1", value: "1", code: "JM", name: "Jamaica" },
  { label: "🇯🇵 +81", value: "81", code: "JP", name: "Japan" },
  { label: "🇯🇪 +44", value: "44", code: "JE", name: "Jersey" },
  { label: "🇯🇴 +962", value: "962", code: "JO", name: "Jordan" },
  { label: "🇰🇿 +7", value: "7", code: "KZ", name: "Kazakhstan" },
  { label: "🇰🇪 +254", value: "254", code: "KE", name: "Kenya" },
  { label: "🇰🇮 +686", value: "686", code: "KI", name: "Kiribati" },
  { label: "🇰🇵 +850", value: "850", code: "KP", name: "Korea (Democratic People's Republic of)" },
  { label: "🇰🇷 +82", value: "82", code: "KR", name: "Korea (Republic of)" },
  { label: "🇽🇰 +383", value: "383", code: "XK", name: "Kosovo" },
  { label: "🇰🇼 +965", value: "965", code: "KW", name: "Kuwait" },
  { label: "🇰🇬 +996", value: "996", code: "KG", name: "Kyrgyzstan" },
  { label: "🇱🇦 +856", value: "856", code: "LA", name: "Lao People's Democratic Republic" },
  { label: "🇱🇻 +371", value: "371", code: "LV", name: "Latvia" },
  { label: "🇱🇧 +961", value: "961", code: "LB", name: "Lebanon" },
  { label: "🇱🇸 +266", value: "266", code: "LS", name: "Lesotho" },
  { label: "🇱🇷 +231", value: "231", code: "LR", name: "Liberia" },
  { label: "🇱🇾 +218", value: "218", code: "LY", name: "Libya" },
  { label: "🇱🇮 +423", value: "423", code: "LI", name: "Liechtenstein" },
  { label: "🇱🇹 +370", value: "370", code: "LT", name: "Lithuania" },
  { label: "🇱🇺 +352", value: "352", code: "LU", name: "Luxembourg" },
  { label: "🇲🇴 +853", value: "853", code: "MO", name: "Macao" },
  { label: "🇲🇰 +389", value: "389", code: "MK", name: "Macedonia (the former Yugoslav Republic of)" },
  { label: "🇲🇬 +261", value: "261", code: "MG", name: "Madagascar" },
  { label: "🇲🇼 +265", value: "265", code: "MW", name: "Malawi" },
  { label: "🇲🇾 +60", value: "60", code: "MY", name: "Malaysia" },
  { label: "🇲🇻 +960", value: "960", code: "MV", name: "Maldives" },
  { label: "🇲🇱 +223", value: "223", code: "ML", name: "Mali" },
  { label: "🇲🇹 +356", value: "356", code: "MT", name: "Malta" },
  { label: "🇲🇭 +692", value: "692", code: "MH", name: "Marshall Islands" },
  { label: "🇲🇶 +596", value: "596", code: "MQ", name: "Martinique" },
  { label: "🇲🇷 +222", value: "222", code: "MR", name: "Mauritania" },
  { label: "🇲🇺 +230", value: "230", code: "MU", name: "Mauritius" },
  { label: "🇾🇹 +262", value: "262", code: "YT", name: "Mayotte" },
  { label: "🇲🇽 +52", value: "52", code: "MX", name: "Mexico" },
  { label: "🇫🇲 +691", value: "691", code: "FM", name: "Micronesia (Federated States of)" },
  { label: "🇲🇩 +373", value: "373", code: "MD", name: "Moldova (Republic of)" },
  { label: "🇲🇨 +377", value: "377", code: "MC", name: "Monaco" },
  { label: "🇲🇳 +976", value: "976", code: "MN", name: "Mongolia" },
  { label: "🇲🇪 +382", value: "382", code: "ME", name: "Montenegro" },
  { label: "🇲🇸 +1", value: "1", code: "MS", name: "Montserrat" },
  { label: "🇲🇦 +212", value: "212", code: "MA", name: "Morocco" },
  { label: "🇲🇿 +258", value: "258", code: "MZ", name: "Mozambique" },
  { label: "🇲🇲 +95", value: "95", code: "MM", name: "Myanmar" },
  { label: "🇳🇦 +264", value: "264", code: "NA", name: "Namibia" },
  { label: "🇳🇷 +674", value: "674", code: "NR", name: "Nauru" },
  { label: "🇳🇵 +977", value: "977", code: "NP", name: "Nepal" },
  { label: "🇳🇱 +31", value: "31", code: "NL", name: "Netherlands" },
  { label: " +599", value: "599", code: "AN", name: "Netherlands Antilles" },
  { label: "🇳🇨 +687", value: "687", code: "NC", name: "New Caledonia" },
  { label: "🇳🇿 +64", value: "64", code: "NZ", name: "New Zealand" },
  { label: "🇳🇮 +505", value: "505", code: "NI", name: "Nicaragua" },
  { label: "🇳🇪 +227", value: "227", code: "NE", name: "Niger" },
  { label: "🇳🇬 +234", value: "234", code: "NG", name: "Nigeria" },
  { label: "🇳🇺 +683", value: "683", code: "NU", name: "Niue" },
  { label: "🇳🇫 +672", value: "672", code: "NF", name: "Norfolk Island" },
  { label: "🇲🇵 +1", value: "1", code: "MP", name: "Northern Mariana Islands" },
  { label: "🇳🇴 +47", value: "47", code: "NO", name: "Norway" },
  { label: "🇴🇲 +968", value: "968", code: "OM", name: "Oman" },
  { label: "🇵🇰 +92", value: "92", code: "PK", name: "Pakistan" },
  { label: "🇵🇼 +680", value: "680", code: "PW", name: "Palau" },
  { label: "🇵🇸 +970", value: "970", code: "PS", name: "Palestine, State of" },
  { label: "🇵🇦 +507", value: "507", code: "PA", name: "Panama" },
  { label: "🇵🇬 +675", value: "675", code: "PG", name: "Papua New Guinea" },
  { label: "🇵🇾 +595", value: "595", code: "PY", name: "Paraguay" },
  { label: "🇵🇪 +51", value: "51", code: "PE", name: "Peru" },
  { label: "🇵🇭 +63", value: "63", code: "PH", name: "Philippines" },
  { label: "🇵🇳 +64", value: "64", code: "PN", name: "Pitcairn" },
  { label: "🇵🇱 +48", value: "48", code: "PL", name: "Poland" },
  { label: "🇵🇹 +351", value: "351", code: "PT", name: "Portugal" },
  { label: "🇵🇷 +1", value: "1", code: "PR", name: "Puerto Rico" },
  { label: "🇶🇦 +974", value: "974", code: "QA", name: "Qatar" },
  { label: "🇷🇴 +40", value: "40", code: "RO", name: "Romania" },
  { label: "🇷🇺 +7", value: "7", code: "RU", name: "Russian Federation" },
  { label: "🇷🇼 +250", value: "250", code: "RW", name: "Rwanda" },
  { label: "🇷🇪 +262", value: "262", code: "RE", name: "Réunion" },
  { label: "🇧🇱 +590", value: "590", code: "BL", name: "Saint Barthélemy" },
  { label: "🇸🇭 +290", value: "290", code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { label: "🇰🇳 +1", value: "1", code: "KN", name: "Saint Kitts and Nevis" },
  { label: "🇱🇨 +1", value: "1", code: "LC", name: "Saint Lucia" },
  { label: "🇲🇫 +590", value: "590", code: "MF", name: "Saint Martin (French part)" },
  { label: "🇵🇲 +508", value: "508", code: "PM", name: "Saint Pierre and Miquelon" },
  { label: "🇻🇨 +1", value: "1", code: "VC", name: "Saint Vincent and the Grenadines" },
  { label: "🇼🇸 +685", value: "685", code: "WS", name: "Samoa" },
  { label: "🇸🇲 +378", value: "378", code: "SM", name: "San Marino" },
  { label: "🇸🇹 +239", value: "239", code: "ST", name: "Sao Tome and Principe" },
  { label: "🇸🇦 +966", value: "966", code: "SA", name: "Saudi Arabia" },
  { label: "🇸🇳 +221", value: "221", code: "SN", name: "Senegal" },
  { label: "🇷🇸 +381", value: "381", code: "RS", name: "Serbia" },
  { label: "🇸🇨 +248", value: "248", code: "SC", name: "Seychelles" },
  { label: "🇸🇱 +232", value: "232", code: "SL", name: "Sierra Leone" },
  { label: "🇸🇬 +65", value: "65", code: "SG", name: "Singapore" },
  { label: " +1", value: "1", code: "SX", name: "Sint Maarten (Dutch part)" },
  { label: "🇸🇰 +421", value: "421", code: "SK", name: "Slovakia" },
  { label: "🇸🇮 +386", value: "386", code: "SI", name: "Slovenia" },
  { label: "🇸🇧 +677", value: "677", code: "SB", name: "Solomon Islands" },
  { label: "🇸🇴 +252", value: "252", code: "SO", name: "Somalia" },
  { label: "🇿🇦 +27", value: "27", code: "ZA", name: "South Africa" },
  { label: "🇸🇸 +211", value: "211", code: "SS", name: "South Sudan" },
  { label: "🇬🇸 +500", value: "500", code: "GS", name: "South Georgia and the South Sandwich Islands" },
  { label: "🇪🇸 +34", value: "34", code: "ES", name: "Spain" },
  { label: "🇱🇰 +94", value: "94", code: "LK", name: "Sri Lanka" },
  { label: "🇸🇩 +249", value: "249", code: "SD", name: "Sudan" },
  { label: "🇸🇷 +597", value: "597", code: "SR", name: "Suriname" },
  { label: "🇸🇯 +47", value: "47", code: "SJ", name: "Svalbard and Jan Mayen" },
  { label: "🇸🇿 +268", value: "268", code: "SZ", name: "Swaziland" },
  { label: "🇸🇪 +46", value: "46", code: "SE", name: "Sweden" },
  { label: "🇨🇭 +41", value: "41", code: "CH", name: "Switzerland" },
  { label: "🇸🇾 +963", value: "963", code: "SY", name: "Syrian Arab Republic" },
  { label: "🇹🇼 +886", value: "886", code: "TW", name: "Taiwan, Province of China" },
  { label: "🇹🇯 +992", value: "992", code: "TJ", name: "Tajikistan" },
  { label: "🇹🇿 +255", value: "255", code: "TZ", name: "Tanzania, United Republic of" },
  { label: "🇹🇭 +66", value: "66", code: "TH", name: "Thailand" },
  { label: "🇹🇱 +670", value: "670", code: "TL", name: "Timor-Leste" },
  { label: "🇹🇬 +228", value: "228", code: "TG", name: "Togo" },
  { label: "🇹🇰 +690", value: "690", code: "TK", name: "Tokelau" },
  { label: "🇹🇴 +676", value: "676", code: "TO", name: "Tonga" },
  { label: "🇹🇹 +1", value: "1", code: "TT", name: "Trinidad and Tobago" },
  { label: "🇹🇳 +216", value: "216", code: "TN", name: "Tunisia" },
  { label: "🇹🇷 +90", value: "90", code: "TR", name: "Turkey" },
  { label: "🇹🇲 +993", value: "993", code: "TM", name: "Turkmenistan" },
  { label: "🇹🇨 +1", value: "1", code: "TC", name: "Turks and Caicos Islands" },
  { label: "🇹🇻 +688", value: "688", code: "TV", name: "Tuvalu" },
  { label: "🇺🇬 +256", value: "256", code: "UG", name: "Uganda" },
  { label: "🇺🇦 +380", value: "380", code: "UA", name: "Ukraine" },
  { label: "🇦🇪 +971", value: "971", code: "AE", name: "United Arab Emirates" },
  { label: "🇬🇧 +44", value: "44", code: "GB", name: "United Kingdom of Great Britain and Northern Ireland" },
  { label: "🇺🇸 +1", value: "1", code: "US", name: "United States of America" },
  { label: " +1", value: "1", code: "UM", name: "United States Minor Outlying Islands" },
  { label: "🇺🇾 +598", value: "598", code: "UY", name: "Uruguay" },
  { label: "🇺🇿 +998", value: "998", code: "UZ", name: "Uzbekistan" },
  { label: "🇻🇺 +678", value: "678", code: "VU", name: "Vanuatu" },
  { label: "🇻🇪 +58", value: "58", code: "VE", name: "Venezuela (Bolivarian Republic of)" },
  { label: "🇻🇳 +84", value: "84", code: "VN", name: "Viet Nam" },
  { label: "🇻🇬 +1", value: "1", code: "VG", name: "Virgin Islands (British)" },
  { label: "🇻🇮 +1", value: "1", code: "VI", name: "Virgin Islands (U.S.)" },
  { label: "🇼🇫 +681", value: "681", code: "WF", name: "Wallis and Futuna" },
  { label: " +212", value: "212", code: "EH", name: "Western Sahara" },
  { label: "🇾🇪 +967", value: "967", code: "YE", name: "Yemen" },
  { label: "🇿🇲 +260", value: "260", code: "ZM", name: "Zambia" },
  { label: "🇿🇼 +263", value: "263", code: "ZW", name: "Zimbabwe" }
];

export default countryCodes
