import React, { useState, useEffect } from "react";
import Select from "react-select";
interface ISelectInputProps {
  options?: any;
  onChange?: any;
  defaultValue?: string;
  placeholder?: string;
  fullWidth?: boolean;
  isDisabled?: boolean;
  autoFocus?: boolean;
}
const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    outline: state.isFocused ? "none" : "none",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: "#ced4da",
    "&:hover": {
      borderColor: "#ced4da",
    },
  }),
};

function SelectInput(props: ISelectInputProps) {
  const [selectedOption, changeSelectedOption] = useState(null);

  useEffect(() => {
    if (props.defaultValue) {
      let defaultOption = props.options.find(
        (option: any) => option.value == props.defaultValue
      );
      changeSelectedOption(defaultOption);
    }
  }, [props.defaultValue]);

  const handleSelection = (e: any) => {
    changeSelectedOption(e);
    props.onChange(e.value);
  };

  return (
    <React.Fragment>
      {props.options.length ? (
        <Select
          isDisabled={props.isDisabled || !props.options.length ? true : false}
          options={props.options}
          onChange={handleSelection}
          styles={customStyles}
          className={
            props.fullWidth
              ? "select-input-container full-width"
              : "select-input-container"
          }
          classNamePrefix="select-input"
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme: any) => ({
            ...theme,
            borderRadius: 0,
          })}
          placeholder={props.placeholder && props.placeholder}
          value={selectedOption}
          autoFocus={props.autoFocus}
        ></Select>
      ) : (
        false
      )}
    </React.Fragment>
  );
}
export default SelectInput;
