import React from "react";
import paymentInProgress from "./payment-progress.svg";
import { FormattedMessage } from "react-intl";

export default function () {
  return (
    <div className="in-progress-banner">
      <img src={paymentInProgress} />
      <div className="banner-detials">
        <span className="banner-header">
          <FormattedMessage
            id="payment_in_progress_title_home"
            defaultMessage="Payment in progress"
          />
          {" ."}
        </span>
        <span className="banner-text">
          <FormattedMessage
            id="payment_in_progress_text_home"
            defaultMessage="Please wait while we are currently trying to charge your card."
          />
        </span>
      </div>
    </div>
  );
}
