export const FETCH_SAVED_CARDS = "savedCards/FETCH_SAVED_CARDS";
export const FETCH_SAVED_CARDS_SUCCESS = "savedCards/FETCH_SAVED_CARDS_SUCCESS";
export const FETCH_SAVED_CARDS_FAIL = "savedCards/FETCH_SAVED_CARDS_FAIL";

export const DELETE_SAVED_CARD = "savedCards/DELETE_SAVED_CARD";
export const DELETE_SAVED_CARD_SUCCESS = "savedCards/DELETE_SAVED_CARD_SUCCESS";
export const DELETE_SAVED_CARD_FAIL = "savedCards/DELETE_SAVED_CARD_FAIL";

export const FETCH_STRIPE_SAVED_CARDS = "savedCards/FETCH_STRIPE_SAVED_CARDS";
export const FETCH_STRIPE_SAVED_CARDS_SUCCESS = "savedCards/FETCH_STRIPE_SAVED_CARDS_SUCCESS";
export const FETCH_STRIPE_SAVED_CARDS_FAIL = "savedCards/FETCH_STRIPE_SAVED_CARDS_FAIL";

export const FETCH_STRIPE_CONNECT_SAVED_CARDS = "savedCards/FETCH_STRIPE_CONNECT_SAVED_CARDS";
export const FETCH_STRIPE_CONNECT_SAVED_CARDS_SUCCESS = "savedCards/FETCH_STRIPE_CONNECT_SAVED_CARDS_SUCCESS";
export const FETCH_STRIPE_CONNECT_SAVED_CARDS_FAIL = "savedCards/FETCH_STRIPE_CONNECT_SAVED_CARDS_FAIL";
