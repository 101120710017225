// Packages
import React, { useState, useEffect, useContext } from "react";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
// Contexts
import { LocaleContext } from "../../wrappers/localeWrapper";

declare global {
  interface Window {
    google: any;
  }
}

interface PickupAreaMapProps {
  lat: number;
  lng: number;
}

const MAP_OPTIONS = {
  fullscreenControl: false,
  zoomControl: false,
  disableDoubleClickZoom: true,
  mapTypeControl: false,
  streetViewControl: false,
};

function PickupAreaMap(props: PickupAreaMapProps) {
  const [mapApiKey, setMapApiKey] = useState("");
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);

  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    setLatitude(props.lat || 0.0);
    setLongitude(props.lng || 0.0);
  }, [props]);

  useEffect(() => {
    let element = document.getElementById("root") as any;
    setMapApiKey(element?.dataset.googleMapKey);
  }, []);

  return (
    <React.Fragment>
      {mapApiKey ? (
        <LoadScript
          googleMapsApiKey={mapApiKey}
          language={localeContext.locale}
        >
          <GoogleMap
            mapContainerStyle={{
              height: "100%",
              position: "absolute",
              width: "100%",
            }}
            center={{
              lat: latitude,
              lng: longitude - 0.001,
            }}
            zoom={16}
            options={MAP_OPTIONS}
            mapTypeId="roadmap"
          >
            <Marker
              position={{
                lat: latitude,
                lng: longitude,
              }}
            />
          </GoogleMap>
        </LoadScript>
      ) : null}
    </React.Fragment>
  );
}

export default React.memo(PickupAreaMap);
