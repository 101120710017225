// Packages
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Helpers, Utils etc.
import {
  translatedName,
} from "../../helpers/translations";

interface IRepeatItemPopupProps {
  repeatItem: any;
  onAddNew: any;
  onRepeat: any;
  onClose: any;
  currency: string;
}

function RepeatItemPopup(props: IRepeatItemPopupProps) {
  const [show, setShow] = useState(true);
  const [repeatItem, setRepeatItem] = useState({} as any);

  const intl = useIntl();

  useEffect(() => {
    setRepeatItem(props.repeatItem);
  }, [props.repeatItem]);

  const onAddNewClick = () => {
    setTimeout(() => {
      props.onAddNew();
    }, 500);
    setShow(false);
  };
  const onRepeatClick = () => {
    setTimeout(() => {
      props.onRepeat();
    }, 500);
    setShow(false);
  };

  const handleClose = () => {
    setTimeout(() => {
      props.onClose();
    }, 500);
    setShow(false);
  };

  const modfiers = (menuItem: any) =>
    menuItem.groups.map((group: any) => {
      let groups = group.items.map((item: any) => {
        let selected_item;
        if (item.selected === true) {
          selected_item = item;
        }
        let modifierName;
        if (selected_item) {
          modifierName = translatedName(selected_item, intl.locale);
        }
        return (
          selected_item && (
            <div className="modifier-item" key={selected_item.id}>
              -{"  "}
              {selected_item.count
                ? `${modifierName} x${selected_item.count}`
                : `${modifierName}`}
            </div>
          )
        );
      });
      return groups;
    });
  return (
    <Modal
      show={show}
      size="lg"
      onHide={handleClose}
      dialogClassName="custom"
      centered
    >
      <ModalCloseIcon onClick={handleClose} />
      <div className="repeat-item-popup">
        <div
          className="item-image"
          style={{ backgroundImage: `url(${repeatItem.image_url})` }}
        />
        <div className="item-details">
          <div className="details-header">
            <h1 className="item-name">
              {translatedName(repeatItem, intl.locale)}
            </h1>
            <p className="item-price">
              {props.currency} {repeatItem.sub_total}
            </p>
          </div>
          <div className="customisations">
            <div className="selected-customisations">
              {repeatItem.groups && modfiers(repeatItem)}
            </div>
            <div className="actions d-flex">
              <button
                className="btn btn-primary w-100 mr-3"
                onClick={onRepeatClick}
              >
                <FormattedMessage
                  id="menu.item.repeat_last"
                  defaultMessage="Repeat Last"
                />
              </button>
              <button
                className="btn btn-outline-primary w-100"
                onClick={onAddNewClick}
              >
                <FormattedMessage
                  id="menu.item.add_new"
                  defaultMessage="Add New"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RepeatItemPopup;
