import * as pageTypes from "../page/types"

const tipsReducer = (state: number[] = [], action: any) => {
  switch (action.type) {
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
      return action.payload.data.tips || {};
    default:
      return state;
  }
};

const reducer = tipsReducer

export default reducer;
