import actions from "./actions";

const fetchSavedCards = actions.fetchSavedCards;
const deleteSavedCard = actions.deleteSavedCard;
const fetchStripeSavedCards = actions.fetchStripeSavedCards;
const fetchStripeConnectSavedCards = actions.fetchStripeConnectSavedCards;

export default {
  fetchSavedCards,
  deleteSavedCard,
  fetchStripeSavedCards,
  fetchStripeConnectSavedCards,
};
