import React, { useState, useEffect } from "react";

interface IFiservProps {
  fiservFormData: any;
};

function FiservForm(props: IFiservProps) {
  useEffect(() => {
    if (props.fiservFormData && Object.keys(props.fiservFormData).length) {
      document.getElementById("fiservTransaction")?.submit();
    }
  }, [props.fiservFormData]);

  return (
    <form
      method="post"
      action={props.fiservFormData.action_url}
      name="frmTransaction"
      id="fiservTransaction"
    >
      <input
        name="hash_algorithm"
        type="hidden"
        value={props.fiservFormData.hash_algorithm}
      />
      <input
        name="checkoutoption"
        type="hidden"
        value={props.fiservFormData.checkout_option}
      />
      <input
        name="language"
        type="hidden"
        value={props.fiservFormData.language}
      />
      <input
        name="hashExtended"
        type="hidden"
        value={props.fiservFormData.hash_extended}
      />
      <input
        name="storename"
        type="hidden"
        value={props.fiservFormData.store_name}
      />
      <input
        name="timezone"
        type="hidden"
        value={props.fiservFormData.timezone}
      />
      <input
        name="txndatetime"
        type="hidden"
        value={props.fiservFormData.txn_date_time}
      />
      <input
        name="txntype"
        type="hidden"
        value={props.fiservFormData.txn_type}
      />
      <input
        name="chargetotal"
        type="hidden"
        value={props.fiservFormData.charge_total}
      />
      <input
        name="authenticateTransaction"
        type="hidden"
        value={props.fiservFormData.authenticate_transaction}
      />
      <input
        name="currency"
        type="hidden"
        value={props.fiservFormData.currency}
      />
      <input
        name="referencedSchemeTransactionId"
        type="hidden"
        value=""
      />
      <input
        name="responseFailURL"
        type="hidden"
        value={props.fiservFormData.response_fail_url}
      />
      <input
        name="responseSuccessURL"
        type="hidden"
        value={props.fiservFormData.response_success_url}
      />
      <input
        name="assignToken"
        type="hidden"
        value={props.fiservFormData.assign_token}
      />
    </form>
  );
}

export default FiservForm;
