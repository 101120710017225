import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import { errorOperations } from "../../state/features/error";
import { cartOperations } from "../../state/features/cart";
import AdyenCheckout from '@adyen/adyen-web';

interface IAdyenSectionWrapperProps {
  payload: any,
  buildAdyenPaymentMethods: any,
  paymentType: any,
  showPointsEarnedPopup: any,
  togglePlaceOrderButton: any
  createAdyenPayment: any,
  setCart: any,
  updateError: any
}

function AdyenSectionWrapper(props: IAdyenSectionWrapperProps) {
  const [adyenPaymentMethods,setAdyenPaymentMethods] = useState({} as any)
  
  useEffect(()=> {
    props.buildAdyenPaymentMethods(props.payload.total_amount).then((response: any) => {
      if (!response.error) {
        setAdyenPaymentMethods(response.payload.data)
      }
    });
  },[])

  useEffect(() => {
    let configuration
    if(Object.keys(adyenPaymentMethods).length > 0) {
      let configuration = {
        paymentMethodsResponse: adyenPaymentMethods,
        clientKey: props.paymentType.client_key,
        locale: props.paymentType.locale,
        environment: props.paymentType.environment,
        onSubmit: (state: any, dropin: any) => {
          props.createAdyenPayment(state.data.paymentMethod, props.payload)
            .then((response: any) => {
              if (!response.error) {
                if (response.payload.data.action) {
                  dropin.handleAction(response.payload.data.action);
                } else {
                  let earnedLoyalty = null;
                  localStorage.cartItems = JSON.stringify([]);
                  props.setCart({ items: [], subTotal: 0, total: 0 });
                  if (response.payload.data.order_details.earned_loyalty) {
                    earnedLoyalty = response.payload.data.order_details.earned_loyalty;
                  }
                  props.showPointsEarnedPopup(earnedLoyalty);
                }
              }
            props.togglePlaceOrderButton(true);
          });
        },
        onError: (state: any, dropin: any) => {
          props.updateError({
            title: "Oops!",
            message: "Something went wrong. Please try again later.",
          });
          location.href = "/"
        },
        paymentMethodsConfiguration: {
          card: {
            enableStoreDetails: false,
            hideCVC: false,
            name: 'Credit or debit card'
          }
        }
       };
      const checkout = new AdyenCheckout(configuration);
      const dropin = checkout.create('dropin', {
          openFirstPaymentMethod:false
      })
      dropin.mount('#dropin-container');
    }
  }, [adyenPaymentMethods]);

  return (
    <div id="dropin-container"></div>
  );
}

const mapDispatchToProps = {
  buildAdyenPaymentMethods: paymentTypeOperations.buildAdyenPaymentMethods,
  createAdyenPayment: paymentTypeOperations.createAdyenPayment,
  setCart: cartOperations.setCart,
  updateError: errorOperations.updateError,
}

export default connect(null, mapDispatchToProps) (AdyenSectionWrapper);
