import * as types from "./types";
import { combineReducers } from "redux";

const deliveryReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_AREAS_SUCCESS:
      return action.payload.data.delivery;

    default:
      return state;
  }
};

const pickupReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case types.FETCH_AREAS_SUCCESS:
      return action.payload.data.pickup;

    default: return state;
  }
};

const selectedAreaReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case types.SET_AREA:
      return action.payload;

    default: return state;
  }
};

const reducer = combineReducers({
  delivery: deliveryReducer,
  pickup: pickupReducer,
  selectedArea: selectedAreaReducer,
});

export default reducer;
