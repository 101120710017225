// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import DeliveryAreaMap from "../../components/deliveryAreaMap";
// Redux Operations
import { addressesOperations } from "../../state/features/addresses";

interface INewAddressWidgetProps {
  area: any;
  addressConfiguration: any;
  outlet: any;
  zoneMapEnabled: boolean;
  reloadAddresses: any;
  cancelNewAddress: any;
  createAddress: any;
  addresses: any;
}

interface INewAddressWidgetState {
  addressConfiguration: any;
  newAddress: any;
  newAddressDisabled: any;
}

class NewAddressWidget extends Component<
  INewAddressWidgetProps,
  INewAddressWidgetState
> {
  constructor(props: ICheckoutProps) {
    super(props);
    this.state = {
      addressConfiguration: {
        "1": props.intl.formatMessage({ id: "address.configuration.building_villa", defaultMessage: "Building / Villa" }),
        "2": props.intl.formatMessage({ id: "address.configuration.street", defaultMessage: "Street" }),
        "3": props.intl.formatMessage({ id: "address.configuration.flat_number", defaultMessage: "Flat Number" }),
        "4": props.intl.formatMessage({ id: "address.configuration.nearest_landmark", defaultMessage: "Nearest Landmark" }),
      },
      newAddress: {
        latitude: 0,
        longitude: 0,
        delivery_addresses: [
          { id: "1", value: "" },
          { id: "2", value: "" },
          { id: "3", value: "" },
          { id: "4", value: "" }
        ]
      },
      newAddressDisabled: false,
    };
  }

  componentDidMount() {
    this.findAddressConfiguration();
    this.findUserSelectedAddress();
  }

  findAddressConfiguration = () => {
    if (this.props.addressConfiguration) {
      this.setState({
        addressConfiguration: this.props.addressConfiguration
      });
    }
  }

  findUserSelectedAddress = () => {
    if (this.props.zoneMapEnabled && localStorage.selectedAddress && JSON.parse(localStorage.selectedAddress)) {
      let address = JSON.parse(localStorage.selectedAddress);
      let newAddress = this.state.newAddress;
      newAddress.latitude = address.lat;
      newAddress.longitude = address.lng;
      this.setState({ newAddress });
    }
  };

  addressLineValue = (id: string) => {
    let address = this.state.newAddress.delivery_addresses.find((line: any) => {
      return line.id == id;
    });
    return address.value;
  }

  createNewAddress = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.createAddress(this.state.newAddress).then((response: any) => {
      if (!response.error) {
        this.props.reloadAddresses();
      }
    });
  };

  changeAreaInMap = (addressLine: any, latitude: any, longitude: any) => {
    let address = this.state.newAddress;
    address.latitude = latitude;
    address.longitude = longitude;
    this.setState(
      { newAddress: address },
      () => this.handleAddressLineChange(addressLine, "2")
    )
  };

  handleAddressLineChange = (addressLine: string, id: string) => {
    let newAddress = this.state.newAddress;
    let addressLines = newAddress.delivery_addresses.filter((line: any) => {
      return line.id != id;
    });
    let addressLineObj = {
      id: id,
      value: addressLine,
    };
    addressLines.push(addressLineObj);
    newAddress.delivery_addresses = addressLines;
    this.setState({ newAddress });
  }

  reportAreaServiceabilityError = () => {
    let addressLine2 = document.getElementById("address_2");
    document.getElementById("address_2")?.setCustomValidity(this.props.intl.formatMessage({ id: "address.outside_delivery_range", defaultMessage: "The selected location is outside our delivery range." }));
    addressLine2.reportValidity();
    this.setState({ newAddressDisabled: true });
    document.getElementById("notServiceable")?.classList?.add("visible");
  }

  removeAreaServiceabilityError = () => {
    let addressLine2 = document.getElementById("address_2");
    document.getElementById("address_2")?.setCustomValidity("");
    this.setState({ newAddressDisabled: false });
    document.getElementById("notServiceable")?.classList?.remove("visible");
  }

  render() {
    const { area, outlet, addresses } = this.props;
    const {
      addressConfiguration,
      newAddress,
      newAddressDisabled,
    } = this.state;
    return (
      <div className="nested-section d-block">
        <h1 className="section-heading mb-4">
          <FormattedMessage
            id="address.add_address"
            defaultMessage="Add Address"
          />
        </h1>
        <form
          onSubmit={this.createNewAddress}
          onReset={this.props.cancelNewAddress}
        >
          <div className="form-group mb-4 mt-4">
            <label className="input-label bold mb-0">
              <FormattedMessage
                id="address.pin_exact_location"
                defaultMessage="Pin Exact Location"
              />
            </label>
            <div className="delivery-area-map mt-2">
              <div className="not-serviceable" id="notServiceable">
                <FormattedMessage
                  id="address.outside_delivery_range"
                  defaultMessage="The selected location is outside our delivery range."
                />
              </div>
              <DeliveryAreaMap
                lat={
                  parseFloat(
                    newAddress.latitude || area?.latitude ||
                    outlet.latitude || 0
                  )
                }
                lng={
                  parseFloat(
                    newAddress.longitude || area?.longitude ||
                    outlet.longitude || 0
                  )
                }
                onAreaChange={(name: any, lat: any, lng: any) => {
                  this.changeAreaInMap(name, lat, lng)
                }}
                reportAreaServiceabilityError={() => {
                  this.reportAreaServiceabilityError()
                }}
                removeAreaServiceabilityError={() => {
                  this.removeAreaServiceabilityError()
                }}
                source="new-address"
              />
            </div>
          </div>
          <div className="form-group mb-4">
            <label className="input-label bold mb-0">
              {addressConfiguration["2"]}{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              name="address_2"
              type="text"
              required
              className="form-control custom-input mt-2"
              value={this.addressLineValue("2")}
              id="address_2"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>
              ) => this.handleAddressLineChange(e.target.value, "2" )}
            ></input>
          </div>
          <div className="form-group mb-4">
            <label className="input-label bold mb-0">
              {addressConfiguration["1"]}{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              name="address_1"
              type="text"
              required
              className="form-control custom-input mt-2"
              value={this.addressLineValue("1")}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>
              ) => this.handleAddressLineChange(e.target.value, "1")}
            ></input>
          </div>
          <div className="form-group mb-4">
            <label className="input-label bold mb-0">
              {addressConfiguration["3"]}{" "}
              <span>(
                <FormattedMessage
                  id="address.optional"
                  defaultMessage="Optional"
                />
              )</span>
            </label>
            <input
              name="address_3"
              type="text"
              className="form-control custom-input mt-2"
              value={this.addressLineValue("3")}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>
              ) => this.handleAddressLineChange(e.target.value, "3")}
            ></input>
          </div>
          <div className="form-group mb-4">
            <label className="input-label bold mb-0">
              {addressConfiguration["4"]}{" "}
              <span>(
                <FormattedMessage
                  id="address.optional"
                  defaultMessage="Optional"
                />
              )</span>
            </label>
            <input
              name="address_4"
              type="text"
              className="form-control custom-input mt-2"
              value={this.addressLineValue("4")}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>
              ) => this.handleAddressLineChange(e.target.value, "4")}
            ></input>
          </div>
          <div className="d-flex justify-content-end">
          { addresses.length > 0 ? (
            <button type="reset" className="btn btn-secondary px-4 mr-2">
              <FormattedMessage
                id="global.cancel"
                defaultMessage="Cancel"
              />
            </button>
            ) : false}
            <button
              type="submit"
              className="btn btn-primary px-4"
              disabled={newAddressDisabled}
            >
              <FormattedMessage
                id="global.save"
                defaultMessage="Save"
              />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let addressConfiguration = state.company.address_configuration;
  let outlet = state.outlet;
  let area = state.areas.selectedArea;
  let addresses = state.addresses;
  let zoneMapEnabled = state.company.configuration?.zone_mapping_enabled == true;

  return {
    addressConfiguration,
    outlet,
    area,
    addresses,
    zoneMapEnabled,
  };
};

const mapDispatchToProps = {
  createAddress: addressesOperations.createAddress
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewAddressWidget));
