// Packages
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import cartImage from "../../images/cart-icon.png";
// Redux Operations
import { loaderOperations } from "../../state/features/loader";
// Helpers, Utils etc.
import {
  formatDecimal,
  calculateAmountsAndBreakdowns
} from "../../helpers/itemCalculations";

interface ICartSnackbarProps {
  company: any;
  cart: any;
  menu: any;
  orderType: any;
  showLoader: any;
}

interface ICartSnackbarState {
  bottom: any;
  cartItems: any;
  totalAmount: any;
}

function animateEffect(element: any) {
    element.classList.add("bounce");
    element.addEventListener("webkitAnimationEnd", function(){
      element.classList.remove("bounce");
    },false);
  }

function CartSnackbar(props: ICartSnackbarProps) {
  const [bottom, setBottom] = useState(-100);
  const [cartItems, setCartItems] = useState([] as any);
  const [cartCount, setCartCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    let amountBreakdowns: any = calculateAmountsAndBreakdowns(
      props.cart.items,
      props.menu,
      props.orderType,
      null
    );
    setTotalAmount(amountBreakdowns.subTotal);
    setCartItems(props.cart.items);
    let count = 0;
    props.cart.items.map((cartItem: any) => {
      count += cartItem.count;
    });
    animateEffect(document.getElementById("cart-count"));
    setCartCount(count);
  }, [props.cart]);

  useEffect(() => {
    setBottom(0);
  }, []);

  return (
    <Link
      to="/m/order-details"
      className="snack-bar-container"
      style={{ bottom: `${bottom}px` }}
      onClick={() => props.showLoader()}
    >
      <div className="snack-bar">
        <div className="icon">
          <img src={cartImage} />
          <p className="cart-count" id="cart-count">{cartCount}</p>
        </div>
        <div className="order-link">
          <FormattedMessage
            id="cart.view_orders"
            defaultMessage="View Order"
          />
        </div>
        <div className="cart-amount">
          {props.company.currency} {formatDecimal(totalAmount || 0)}
        </div>
      </div>
    </Link>
  );
}

const mapStateToProps = (state: any) => {
  let cart = state.cart;
  let company = state.company;
  let menu = state.menu;
  let orderType = state.session.orderType;
  return { cart, company, menu, orderType };
};

const mapDispatchToProps = {
  showLoader: loaderOperations.showLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSnackbar);
