import React, { useMemo } from "react";
import OrderPlacedPopup from "../../mobileComponents/orderPlacedPopup";
import { useCheckoutScreenContext } from "../../hooks/useCheckoutScreenContext";

interface ICheckoutPopupsProps {}

const CheckoutPopups = (props: ICheckoutPopupsProps) => {
  const {
    showOrderPlacedPopup,
    earnedLoyalty,
  } = useCheckoutScreenContext();

  return (
    <React.Fragment>
      {showOrderPlacedPopup ? (
        <OrderPlacedPopup earnedLoyalty={earnedLoyalty} />
      ) : null}
    </React.Fragment>
  );
};

export default CheckoutPopups;
