// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import PageHeader from "../pageHeader";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { errorOperations } from "../../state/features/error";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";

interface ILoyaltyProps {
  loyalty: any;
  currency: any;
  decimalPrecision: any;
  fetchLoyaltyConfigurations: any;
  updateError: any;
}

interface ILoyaltyState {
  pastOrders: any;
}

class Loyalty extends Component<ILoyaltyProps, ILoyaltyState> {
  constructor(props: ILoyaltyProps) {
    super(props);
    this.state = {
      pastOrders: []
    };

    this.props.fetchLoyaltyConfigurations().then((response: any) => {
      if (!response.error) {
        localStorage.precision = this.props.decimalPrecision;
        this.setState({ pastOrders: response.payload.data.past_orders });
      }
    });
  };

  dateFromString = (dateString: string) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  render() {
    const { loyalty, currency } = this.props;
    return (
      <div className="loyalty-page">
        <div className="loyalty-banner">
          <FormattedMessage
            id="my_account.you_currently_have_loyalty_points"
            defaultMessage="<pTag1>You currently have</pTag1><pTag2>{loyaltyPoints}</pTag2><pTag3>Loyalty {currency}</pTag3>"
            values={{
              loyaltyPoints: formatDecimal(loyalty.customer_loyalty || 0),
              currency: currency,
              pTag1: (text) => <p className="text-1">{text}</p>,
              pTag2: (text) => <p className="text-2">{text}</p>,
              pTag3: (text) => <p className="text-3">{text}</p>,
            }}
          />
        </div>
        {this.state.pastOrders.length ? (
          <React.Fragment>
            <p className="loyalty-history-header">
              <FormattedMessage
                id="my_account.loyalty_history"
                defaultMessage="Loyalty History"
              />
            </p>
            <table className="loyalty-history-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="global.date"
                      defaultMessage="Date"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="global.description"
                      defaultMessage="Description"
                    />
                  </th>
                  <th className="text-right">
                    <FormattedMessage
                      id="my_account.earned_or_redeemed"
                      defaultMessage="Earned/Redeemed"
                    />
                  </th>
                  <th className="text-right">
                    <FormattedMessage
                      id="my_account.available_points"
                      defaultMessage="Available Points"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.pastOrders.map((order: any) => {
                  return (
                    <tr key={order.order_number}>
                      <td>{this.dateFromString(order.created_at)}</td>
                      <td>
                        {order.order_details.earned_loyalty ? (
                          <FormattedMessage
                            id="my_account.earned_from_order"
                            defaultMessage="Earned from order #{orderNumber}"
                            values={{
                              orderNumber: order.order_number
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            id="my_account.redeemed_from_order"
                            defaultMessage="Redeemed from order #{orderNumber}"
                            values={{
                              orderNumber: order.order_number
                            }}
                          />
                        )}
                        </td>
                      <td className="text-right">
                        {order.order_details.earned_loyalty
                          ? formatDecimal(order.order_details.earned_loyalty)
                          : formatDecimal(order.order_details.redeemed_loyalty)}
                      </td>
                      <td className="text-right">
                        {formatDecimal(order.order_details.remaining_loyalty)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let loyalty = state.loyalty;
  let currency = state.company.currency;
  let decimalPrecision = state.company.decimal_precision;

  return { loyalty, currency, decimalPrecision };
};

const mapDispatchToProps = {
  fetchLoyaltyConfigurations: pageOperations.fetchLoyaltyConfigurations,
  updateError: errorOperations.updateError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Loyalty);
