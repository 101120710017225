import * as types from "./types";

const fetchHomeConfigurations = () => ({
  type: types.FETCH_HOME_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/home"
    }
  }
});

const fetchCheckoutConfigurations = () => ({
  type: types.FETCH_CHECKOUT_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/checkout"
    }
  }
});

const fetchTrackingConfigurations = () => ({
  type: types.FETCH_TRACKING_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/tracking"
    }
  }
});

const fetchProfileConfigurations = () => ({
  type: types.FETCH_PROFILE_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/profiles"
    }
  }
});

const fetchPastOrdersConfigurations = () => ({
  type: types.FETCH_PAST_ORDERS_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/past_orders"
    }
  }
});

const fetchLoyaltyConfigurations = () => ({
  type: types.FETCH_LOYALTY_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/loyalty"
    }
  }
});

const fetchPaymentProcessingConfigurations = (invoice_id: string, type: string) => {
  let queryParams = "?"
  if (invoice_id) {
    queryParams += `invoice_id=${invoice_id}`;
  }
  if (invoice_id && type) {
    queryParams += `&type=${type}`;
  }
  return ({
    type: types.FETCH_PAYMENT_PROCESSING_CONFIGURATIONS,
    payload: {
      request: {
        method: "get",
        url: `/pages/payment_processing${queryParams}`
      }
    }
  })
};

const fetchTermsOfServiceConfigurations = () => ({
  type: types.FETCH_TERMS_OF_SERVICE_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/terms_of_service"
    }
  }
});

const fetchRiderTrackingConfigurations = () => ({
  type: types.FETCH_RIDER_TRACKING_CONFIGURATIONS,
  payload: {
    request: {
      method: "get",
      url: "/pages/rider_tracking"
    }
  }
});

export default {
  fetchHomeConfigurations,
  fetchCheckoutConfigurations,
  fetchTrackingConfigurations,
  fetchProfileConfigurations,
  fetchPastOrdersConfigurations,
  fetchLoyaltyConfigurations,
  fetchPaymentProcessingConfigurations,
  fetchTermsOfServiceConfigurations,
  fetchRiderTrackingConfigurations
};
