// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  translatedName,
} from "../../helpers/translations";

interface IRepeatOrderPopupMobileProps {
  currency: any;
  onClose: () => void;
  onAddNew: any;
  onRepeat: any;
  repeatItem: any;
}

function RepeatOrderPopupMobile(props: IRepeatOrderPopupMobileProps) {
  const [show, setShow] = useState(true);

  const intl = useIntl();

  const onAddNewClick = () => {
    setTimeout(() => {
      props.onAddNew();
    }, 500);
    setShow(false);
  };

  const onRepeatClick = () => {
    setTimeout(() => {
      props.onRepeat();
    }, 500);
    setShow(false);
  };

  const handleClose = () => {
    setTimeout(() => {
      props.onClose();
    }, 500);
    setShow(false);
  };

  let customisations = (repeatItem: any) =>
    repeatItem.groups.map((group: any) => {
      let groups = group.items.map((item: any) => {
        let selected_item;
        if (item.selected === true) {
          selected_item = item;
        }
        return (
          selected_item && (
            <div className="customisation" key={selected_item.id}>
              {translatedName(selected_item, intl.locale)}
            </div>
          )
        );
      });
      return groups;
    });

  return (
    <Modal dialogClassName="bottom-modal" show={show}>
      <ModalCloseIcon onClick={handleClose} />
      <div className="repeat-order-mobile">
        <div className="order-item">
          <div
            className="order-image"
            style={{ backgroundImage: `url(${props.repeatItem.image_url})` }}
          ></div>
          <div className="order-details">
            <div className="item-name">
              {translatedName(props.repeatItem, intl.locale)}
            </div>
            <div className="item-price">
              {props.currency} {props.repeatItem.sub_total}
            </div>
            <div className="selected-customisations">
              {customisations(props.repeatItem)}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button className="btn button btn-primary w-50 mr-3" onClick={onRepeatClick}>
            <FormattedMessage
              id="menu.item.repeat_last"
              defaultMessage="Repeat Last"
            />
          </button>
          <button className="btn button btn-outline-primary w-50" onClick={onAddNewClick}>
            <FormattedMessage
              id="menu.item.add_new"
              defaultMessage="Add New"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;

  return {
    currency,
  };
};
export default connect(mapStateToProps)(RepeatOrderPopupMobile);
