// Packages
import React, { useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import SelectInput from "../../../selectInput";
// Contexts
import { EnvContext } from "../../../../components/envContext";
// Redux Operations
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";
import { loaderOperations } from "../../../../state/features/loader";
// Helpers, Utils etc.
import { creditCardIcon } from "../../../../helpers/creditCardsHelper";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard
} from "@fortawesome/free-solid-svg-icons";
import {
  useStripe,
} from '@stripe/react-stripe-js';
// Redux Operations
import { RootState } from "../../../../state/store";

interface IStripeConnectSavedCardsProps {
  orderPayload: any;
  savedCards: any;
  switchToNewCard: () => void;
  togglePlaceOrderButton: (status: boolean) => void;
  initiateStripeConnectTransaction: () => void;
  fetchStripeConnectPaymentIntent: () => void;
  hideLoader: () => void;
}

function StripeConnectSavedCards(props: IStripeConnectSavedCardsProps) {
  const stripe = useStripe();
  // Pusher ID
  const { pusherId } = useContext(EnvContext);
  // Redux data
  const { user } = useSelector((state: RootState) => state);
  // Translation handler
  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [returnURL, setReturnURL] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (props.savedCards.length) {
      props.togglePlaceOrderButton(true);
      setSelectedCard(props.savedCards[0]);
    } else {
      setSelectedCard(null);
    }
  }, [props.savedCards]);

  const handleStripeCardSelection = (card: any) => {
    setErrorMessage("");
    setSelectedCard(card);
  }

  const switchToNewCard = () => {
    setErrorMessage("");
    setSelectedCard(null);
    props.switchToNewCard();
  };

  useEffect(() => {
    if (clientSecret && returnURL) {
      // Confirm Payment
      confirmPayment();
    }

    return () => {};
  }, [clientSecret, returnURL]);

  const confirmPayment = async () => {
    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      clientSecret,
      confirmParams: {
        return_url: returnURL,
        payment_method: selectedCard.card_token,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
    setClientSecret("");
    setReturnURL("");
    props.togglePlaceOrderButton(true);
    props.hideLoader();
  }

  const initiateTransaction = () => {
    const pusher = new Pusher(pusherId, {});
    const currentTime = new Date();
    const paymentReference = `${user.id}${user.company_id}${currentTime.getTime()}`;
    const pusherChannelName = `private_stripe_connect_transaction_${paymentReference}`;
    const channel = pusher.subscribe(pusherChannelName);

    if (channel) {
      // Polls every few seconds if pusher message is not received
      var paymentIntentPeriodicCheckHandler = setInterval(function(){
        props.fetchStripeConnectPaymentIntent(paymentReference).then((response: any) => {
          if (!response.error) {
            if (response.payload.data?.client_secret && response.payload.data.reference_id == paymentReference) {
              setClientSecret(response.payload.data.client_secret);
              setReturnURL(response.payload.data.return_url);
            }
          } else {
            channel.unbind();
            clearInterval(paymentIntentPeriodicCheckHandler);
            clearInterval(paymentIntentTimeoutHandler);

            handleError({
              message: intl.formatMessage({ id: "error.oops_something_went_wrong", defaultMessage: "Oops! Something went wrong." })
            });
          }
        });
      }, 20000);

      // Stops polling and pusher after the timeout period is exceeded
      var paymentIntentTimeoutHandler = setInterval(function(){
        channel.unbind();
        clearInterval(paymentIntentPeriodicCheckHandler);
        clearInterval(paymentIntentTimeoutHandler);

        handleError({
          message: intl.formatMessage({ id: "error.oops_something_went_wrong", defaultMessage: "Oops! Something went wrong." })
        });
      }, 125000);

      channel.bind("payment_intent_created", (data: any) => {
        clearInterval(paymentIntentPeriodicCheckHandler);
        clearInterval(paymentIntentTimeoutHandler);

        if (data.client_secret && data.reference_id == paymentReference) {
          setClientSecret(data.client_secret);
          setReturnURL(data.return_url);
        }
      });

      channel.bind("payment_intent_failed", (data: any) => {
        clearInterval(paymentIntentPeriodicCheckHandler);
        clearInterval(paymentIntentTimeoutHandler);

        handleError(data.error);
      });

      let orderPayload = props.orderPayload;
      orderPayload.payment.payment_mode = "card";
      orderPayload.payment.payment_method_id = selectedCard.card_token;
      // orderPayload.payment.payment_method_types = [];

      props.initiateStripeConnectTransaction(orderPayload, paymentReference).then((response: any) => {
        if (response.error) {
          clearInterval(paymentIntentPeriodicCheckHandler);
          clearInterval(paymentIntentTimeoutHandler);
          pusher.unsubscribe(pusherChannelName);
          handleError(response.error);
        }
      });
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Reset Error message
    setErrorMessage("");

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      props.togglePlaceOrderButton(true);
      return;
    }

    if (!selectedCard) {
      // No card is selected
      props.togglePlaceOrderButton(true);
      return;
    }

    setLoading(true);

    await initiateTransaction();
  };

  return (
    <div className="mt-3 mb-3">
      <div className="stripe-connect-saved-cards-mobile">
        <strong>
          <FormattedMessage
            id="payment.saved_cards"
            defaultMessage="Saved Cards"
          />
        </strong>
        <div className="stripe-connect-saved-card">
          <ul>
            {props.savedCards.map((card: any, index: number) => {
              return (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name="stripe-connect-saved-card"
                      checked={card.card_token == selectedCard?.card_token}
                      onChange={() => handleStripeCardSelection(card)}
                    />
                    <div className="stripe-connect-saved-card-details">
                      <div>
                        <FontAwesomeIcon size="lg" icon={creditCardIcon(card.brand)} />
                        <span>
                          <span>&bull;&bull;&bull;&bull;</span>
                          <span>&bull;&bull;&bull;&bull;</span>
                          <span>&bull;&bull;&bull;&bull;</span>
                          <span>{card.card_last_4_digits}</span>
                        </span>
                      </div>
                      <div>
                        <span>
                          <FormattedMessage
                            id="payment.card.expires"
                            defaultMessage="Expires"
                          />&nbsp;
                          <span>{card.exp_month}/{card.exp_year}</span>
                        </span>
                      </div>
                    </div>
                  </label>
                </li>
              )
            })}
          </ul>
        </div>
        {errorMessage && <div className="error">{errorMessage}</div>}
      </div>

      <a className="switch-to-new-card" onClick={switchToNewCard}>
        <FontAwesomeIcon icon={faCreditCard} />&nbsp;
        <FormattedMessage
          id="payment.new_card_or_online_payment"
          defaultMessage="Use New Card / Online Payment"
        />
      </a>
      <form onSubmit={handleSubmit} className="hidden">
        <button
          type="submit"
          id="stripeConnectPayButton"
          className="hidden"
          disabled={!stripe || loading}
        />
      </form>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  let savedCards = state.savedCards;

  return {
    savedCards,
  };
};

const mapDispatchToProps = {
  initiateStripeConnectTransaction: paymentTypeOperations.initiateStripeConnectTransaction,
  fetchStripeConnectPaymentIntent: paymentTypeOperations.fetchStripeConnectPaymentIntent,
  hideLoader: loaderOperations.hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeConnectSavedCards);
