import actions from "./actions";

const fetchAreas = actions.fetchAreas;
const postalCodeSearchSG = actions.postalCodeSearchSG;
const setArea = actions.setArea;

export default {
  fetchAreas,
  postalCodeSearchSG,
  setArea,
};
