import * as types from "./types";
import * as pageTypes from "../page/types";
import * as userTypes from "../user/types";
import * as invoiceTypes from "../invoice/types";

const userReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS:
    case invoiceTypes.SEND_INVOICE_TO_NEW_EMAIL_SUCCESS:
      return action.payload.data.user;
    case userTypes.SIGN_IN_SUCCESS:
      return action.payload.data;
    case types.SET_USER:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
};

const reducer = userReducer;

export default reducer;
