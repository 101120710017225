// Packages
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Pusher from "pusher-js";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
// Components
import Header from "../../components/header";
import InlineLoader from "../../components/inlineLoader";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMotorcycle,
  faCashRegister,
  faMoneyBillWave,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import loyaltyImage from "../../images/loyalty.png";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { errorOperations } from "../../state/features/error";
import { orderInProgressOperations } from "../../state/features/orderInProgress";
import { loyaltyOperations } from "../../state/features/loyalty";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import trackingDetails from "../../helpers/orderTracking";
import { obtainEtaDisplayText } from "../../helpers/utils";
import {
  CARD_ON_DELIVERY,
  CASH_ON_DELIVERY,
} from "../../helpers/constants";
import {
  translatedName,
} from "../../helpers/translations";
import RiderTipsWidget from "../../components/riderTipsWidget";
import PaymentSelectPopup from "../../components/paymentSelectPopup";
import RiderTracking from "../riderTracking";

let pusher;

interface IOrderTrackingProps {
  company: any;
  order: any;
  loyaltyEnabled: boolean;
  loyaltyPoints: any;
  contactNumber: string;
  tipsEnabled: boolean;
  fetchTrackingConfigurations: any;
  updateOrder: any;
  updateError: any;
  fetchLoyalty: any;
}

interface IOrderTrackingState {
  loyaltyLoading: boolean;
  loyaltyError: string;
  redirectionUrl: string;
  customerArrivedEvent: any;
  riderAssignedEvent: any;
  tipCollectedEvent: any;
  riderTip: number | null;
  showRiderTipsWidget: boolean;
  showPaymentSelectPopup: boolean;
  showRiderTrackingPopup: boolean;
}

class OrderTracking extends Component<
  IOrderTrackingProps,
  IOrderTrackingState
> {
  constructor(props: IOrderTrackingProps) {
    super(props);
    this.state = {
      loyaltyLoading: true,
      loyaltyError: "",
      redirectionUrl: "",
      customerArrivedEvent: null,
      riderAssignedEvent: null,
      riderOnTheWayEvent: null,
      tipCollectedEvent: null,
      riderTip: null,
      showRiderTipsWidget: false,
      showPaymentSelectPopup: false,
      showRiderTrackingPopup: false,
    };

    this.props.fetchTrackingConfigurations().then((response: any) => {
      if (!response.error) {
        this.bindToPusherChannel(this.props.order.id);
        localStorage.precision = this.props.company.decimal_precision;

        this.props.fetchLoyalty().then((response: any) => {
          if (response.error) {
            this.setState({
              loyaltyError: "We're unable to fetch your loyalty points.",
            });
          }
          this.setState({ loyaltyLoading: false });
        });
      } else if (response.error.response.status == 404) {
        this.setState({ redirectionUrl: "/" });
      }
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.order !== this.props.order) {
      let customerArrivedEvent, riderAssignedEvent, riderOnTheWayEvent, tipCollectedEvent;
      let order = this.props.order;
      if (order.status_updates) {
        customerArrivedEvent = order.status_updates.find((statusUpdate: any) => {
          return statusUpdate.event == "marked_as_customer_arrived";
        });
        riderAssignedEvent = order.status_updates.find((statusUpdate: any) => {
          return statusUpdate.event == "rider_assigned";
        });
        riderOnTheWayEvent = order.status_updates.find((statusUpdate: any) => {
          return statusUpdate.event == "rider_on_the_way";
        });
        tipCollectedEvent = order.status_updates.find((statusUpdate: any) => {
          return statusUpdate.event == "tip_collected";
        });
        this.setState({
          customerArrivedEvent: customerArrivedEvent,
          riderAssignedEvent: riderAssignedEvent,
          riderOnTheWayEvent: riderOnTheWayEvent,
          tipCollectedEvent: tipCollectedEvent,
        });
      }
    }
    if (
      prevState.riderAssignedEvent !== this.state.riderAssignedEvent ||
      prevState.tipCollectedEvent !== this.state.tipCollectedEvent
    ) {
      if (![CASH_ON_DELIVERY, CARD_ON_DELIVERY].includes(this.props.order.payment.payment_type)) {
        this.setState({
          showRiderTipsWidget: this.props.tipsEnabled && this.state.riderAssignedEvent && !this.state.tipCollectedEvent
        });
      } else {
        showRiderTipsWidget: false
      }
    }
  }

  bindToPusherChannel(orderId: any) {
    let element = document.getElementById("root") as any;
    let pusherId = element?.dataset.pusherId;
    const pusher = new Pusher(pusherId, {});
    const channel = pusher.subscribe(`track_order_${orderId}`);
    if (channel) {
      channel.bind("status_updated", (data: any) => {
        let order = this.props.order;
        order.status = data.status;
        order.eta = data.eta;
        if (data.status_updates) {
          order.status_updates = data.status_updates;
        }
        this.props.updateOrder(order);
      });
    }
  }

  addRiderTip(tipValue: number) {
    this.setState({ riderTip: tipValue });
    this.setState({ showPaymentSelectPopup: true });
  }

  removeRiderTip() {
    this.setState({ riderTip: null });
  }

  render() {
    const {
      order,
      company,
      loyaltyEnabled,
      loyaltyPoints,
      contactNumber,
      intl,
    } = this.props;
    const {
      riderAssignedEvent,
      riderOnTheWayEvent,
    } = this.state;

    return (
      <React.Fragment>
        {this.state.riderTip && this.state.showPaymentSelectPopup && (
          <PaymentSelectPopup
            tipAmount={this.state.riderTip}
            onClose={() => this.setState({ showPaymentSelectPopup: false })}
          />
        )}
        {this.state.redirectionUrl ? (
          <Redirect to={this.state.redirectionUrl} />
        ) : (
          <React.Fragment>
            <Header />
            <div className="order-tracking-page">
              <div className="container">
                <div className="top">
                  <div className="tracking-section order-status-section">
                    <p className="order-number">
                      <FormattedMessage
                        id="tracking.order_number"
                        defaultMessage="Order number: {orderNumber}"
                        values={{
                          orderNumber: order.order_number
                        }}
                      />
                    </p>
                    <p className="order-status">
                      {order.status ? (
                        <FormattedMessage
                          id={`order.${order.order_type}.${order.status}.title`}
                          defaultMessage={trackingDetails[order.status][order.order_type].title}
                        />
                      ) : false}
                    </p>
                    <div className="status-image">
                      {order && order.status ? (
                        <img
                          src={
                            trackingDetails[order.status][order.order_type]
                              .image
                          }
                        />
                      ) : (
                        false
                      )}
                    </div>

                    {order.status != "rejected" ? (
                      <div className="status-progress">
                        <div
                          className={`progress ${
                            [
                              "unconfirmed",
                              "scheduled",
                              "confirmed",
                              "on_the_way",
                              "delivered",
                            ].includes(order.status)
                              ? "active"
                              : ""
                          }`}
                        ></div>
                        {order.scheduled_at ? (
                          <div
                            className={`progress ${
                              [
                                "scheduled",
                                "confirmed",
                                "on_the_way",
                                "delivered",
                              ].includes(order.status)
                                ? "active"
                                : ""
                            }`}
                          ></div>
                        ) : (
                          false
                        )}
                        <div
                          className={`progress ${
                            [
                              "confirmed",
                              "on_the_way",
                              "delivered",
                            ].includes(order.status)
                              ? "active"
                              : ""
                          }`}
                        ></div>
                        {order.order_type == "delivery" ? (
                          <div
                            className={`progress ${
                              ["on_the_way", "delivered"].includes(
                                order.status
                              )
                                ? "active"
                                : ""
                            }`}
                          ></div>
                        ) : null}
                        <div
                          className={`progress ${
                            order.status == "delivered" ? "active" : ""
                          }`}
                        ></div>
                      </div>
                    ) : (
                      <div className="status-progress">
                        <div className="progress active"></div>
                      </div>
                    )}
                    <p className={
                      `status-info ${
                        [
                          "scheduled",
                          "confirmed",
                          "on_the_way",
                        ].includes(order.status) ? "with-eta" : ""
                      }`
                    }>
                      {order.order_type == "delivery" && order.status == "on_the_way" && riderAssignedEvent ? (
                        <FormattedMessage
                          id="tracking.rider_on_the_way"
                          defaultMessage="<strong>{riderName}</strong> will be handling your delivery today."
                          values={{
                            riderName: riderAssignedEvent.rider_name,
                            strong: (text) => <strong>{text}</strong>,
                          }}
                        />
                      ) : order.status ? (
                        <FormattedMessage
                          id={`order.${order.order_type}.${order.status}.text`}
                          defaultMessage={trackingDetails[order.status][order.order_type].message}
                        />
                      ) : false}
                    </p>
                    {
                      [
                        "scheduled",
                        "confirmed",
                        "on_the_way",
                      ].includes(order.status) ? (
                        <p className="eta-info">
                          {order.order_type == "delivery" && order.status == "on_the_way" && riderAssignedEvent ? (
                            <FormattedMessage
                              id="tracking.rider_on_the_way_eta"
                              defaultMessage="Your food will arrive by <strong>{eta}</strong>."
                              values={{
                                eta: obtainEtaDisplayText(order.eta, intl.formatMessage),
                                strong: (text) => <strong>{text}</strong>,
                              }}
                            />
                          ) : order.order_type == "delivery" ? (
                            <FormattedMessage
                              id="tracking.your_estimated_food_arrival_is"
                              defaultMessage="Your food will arrive by <strong>{eta}</strong>"
                              values={{
                                eta: obtainEtaDisplayText(order.eta, intl.formatMessage),
                                strong: (text) => <strong>{text}</strong>,
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              id="tracking.your_estimated_pickup_time_is"
                              defaultMessage="Your estimated food pickup time is <strong>{eta}</strong>"
                              values={{
                                eta: obtainEtaDisplayText(order.eta, intl.formatMessage),
                                strong: (text) => <strong>{text}</strong>,
                              }}
                            />
                          )}
                        </p>
                    ) : null}

                    {order.order_type == "delivery" &&
                    order.status == "on_the_way" &&
                    riderOnTheWayEvent ? (
                      <button
                        className="button btn btn-primary px-4 arrived-btn"
                        onClick={() =>
                          this.setState({ showRiderTrackingPopup: true })
                        }>
                        <FormattedMessage
                          id="tracking.driver_live_tracker"
                          defaultMessage="Track Your Order"
                        />
                      </button>
                    ) : (
                      false
                    )}

                    {contactNumber ? (
                      <p className="contact-details">
                        <FormattedMessage
                          id="tracking.for_any_concerns_you_can_call"
                          defaultMessage="For questions on your order, you may call <spanTag> {contactNumber}</spanTag>"
                          values={{
                            contactNumber: contactNumber,
                            spanTag: (text) => <span className="number"> {text}</span>,
                          }}
                        />
                      </p>
                    ) : null}
                  </div>
                  <div className="tracking-section order-details-section">
                    <p className="order-details-heading">
                      <FormattedMessage
                        id="tracking.order_details"
                        defaultMessage="Order details"
                      />
                    </p>
                    <div className="tracking-sub-section delivery-location-details">
                      {order.order_type != "pickup" ? (
                        <FontAwesomeIcon
                          size="2x"
                          className="delivery-icon"
                          icon={faMotorcycle}
                        />
                      ) : null}

                      <div className="location-details">
                        <p className="name">{order.delivery_area}</p>
                        <p className="other-details">
                          {order.complete_address
                            ? order.complete_address
                                .map((line: any) => {
                                  return line.value;
                                })
                                .join(", ")
                            : false}
                        </p>
                      </div>
                    </div>
                    <div className="tracking-sub-section order-item-details">
                      <div className="order-items">
                        {order.items &&
                          order.items.map((item: any) => {
                            return (
                              <div className="item" key={item.id}>
                                <div className="item-count">
                                  {item.quantity}x
                                </div>
                                <div className="item-name">
                                  <div>
                                    {translatedName(item, intl.locale)}
                                  </div>
                                  {item.modifiers.length ? (
                                    <div className="modifiers">
                                      {item.modifiers.map(
                                        (modifier: any, index: number) => {
                                          return (
                                            <p
                                              className="modifier-item"
                                              key={index}
                                            >
                                              {translatedName(modifier, intl.locale)}
                                              {modifier.unit_price > 0 ? (
                                                <span className="price">
                                                  +{formatDecimal(modifier.unit_price)}{" "}
                                                  {company.currency}
                                                </span>
                                              ) : " "}
                                              {"x " + modifier.quantity}
                                            </p>
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="item-price">
                                  {company.currency}{" "}
                                  {formatDecimal(item.sub_total)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="total-price">
                        <label className="label">
                          <FormattedMessage
                            id="global.subtotal"
                            defaultMessage="Subtotal"
                          />
                        </label>
                        <p className="amount">
                          {company.currency} {formatDecimal(order.sub_total)}
                        </p>
                      </div>
                      {order.tips ? (
                        <div className="total-price">
                          <label className="label">
                            <FormattedMessage
                              id="global.tip"
                              defaultMessage="Tip"
                            />
                          </label>
                          <p className="amount">
                            {company.currency} {formatDecimal(order.tips)}
                          </p>
                        </div>
                      ) : null}
                      {order.surcharges_breakdown &&
                      order.surcharges_breakdown.length
                        ? order.surcharges_breakdown.map((surcharge: any) => {
                            return (
                              <div className="total-price" key={surcharge.name}>
                                <label className="label">
                                  {surcharge.name}
                                </label>
                                <p className="amount">
                                  {company.currency}{" "}
                                  {formatDecimal(surcharge.amount)}
                                </p>
                              </div>
                            );
                          })
                        : false}
                      {order.discounts && order.discounts.length ? (
                        <div className="total-price">
                          <label className="label">
                            {order.discounts[0].name}
                          </label>
                          <p className="amount">
                            -{company.currency}{" "}
                            {formatDecimal(order.discounts[0].amount)}
                          </p>
                        </div>
                      ) : (
                        false
                      )}
                      {order.redeemed_loyalty ? (
                        <div className="total-price">
                          <label className="label">
                            <FormattedMessage
                              id="global.loyalty"
                              defaultMessage="Loyalty"
                            />
                          </label>
                          <p className="amount">
                            -{company.currency}{" "}
                            {formatDecimal(order.redeemed_loyalty)}
                          </p>
                        </div>
                      ) : (
                        false
                      )}
                      {!order.is_tax_inclusive && order.tax_total > 0 ? (
                        <div className="total-price">
                          <label className="label">
                            <FormattedMessage
                              id="global.tax"
                              defaultMessage="Tax"
                            />
                          </label>
                          <p className="amount">
                            {company.currency} {formatDecimal(order.tax_total)}
                          </p>
                        </div>
                      ) : (
                        false
                      )}
                      <div className="total-price">
                        <label className="label">
                          <FormattedMessage
                            id="global.total"
                            defaultMessage="Total"
                          />
                        </label>
                        <p className="amount">
                          {company.currency} {formatDecimal(order.total_amount)}
                        </p>
                      </div>
                    </div>
                    {order.note?.user && order.note.user.split(" *** ")[0] ? (
                      <div className="tracking-sub-section order-notes">
                        {order.note.user.split(" *** ")[0]}
                      </div>
                    ) : null}
                    {order.pickup_mode == "curbside" &&
                    order.note?.meta ? (
                      <div className="tracking-sub-section pickup-mode-details">
                        <p className="pickup-mode">
                          <FormattedMessage
                            id="tracking.curbside_identification"
                            defaultMessage="Curbside Identification"
                          />
                        </p>
                        <p className="pickup-place">
                          {order.note.meta}
                        </p>
                      </div>
                    ) : null}
                    {order.payment ? (
                      <div className="tracking-sub-section payment-details">
                        <FontAwesomeIcon
                          icon={
                            [CASH_ON_DELIVERY]
                              .includes(order.payment.payment_type)
                                ? faMoneyBillWave
                                : faCreditCard
                          }
                          size="2x"
                          className="payment-icon"
                        />
                        <p className="payment-type">{order.payment.name}</p>
                      </div>
                    ) : null}
                  </div>
                </div>

                {order.status == "on_the_way" && this.state.showRiderTipsWidget && (
                  <RiderTipsWidget
                    subTotal={formatDecimal(order.sub_total)}
                    riderName={riderAssignedEvent.rider_name}
                    orderStatus={order.status}
                    removeRiderTip={this.removeRiderTip}
                    addRiderTip={(tip: number) => this.addRiderTip(tip)}
                  />
                )}

                {loyaltyEnabled ? (
                  <div className="tracking-section loyalty-points-section">
                    {this.state.loyaltyLoading ? (
                      <InlineLoader
                        message={
                          <FormattedMessage
                            id="loyalty.please_wait_while_we_process"
                            defaultMessage="Please wait while we process your loyalty points."
                          />
                        }
                      />
                    ) : this.state.loyaltyError ? (
                      <p>
                        <FormattedMessage
                          id="loyalty.unable_to_retrieve"
                          defaultMessage="We're unable to fetch your loyalty points."
                        />
                      </p>
                    ) : (
                      <React.Fragment>
                        <div className="loyalty-icon">
                          <img src={loyaltyImage} alt="loyalty" />
                        </div>
                        <div className="loyalty-details">
                          <div>
                            <p className="title">
                              <FormattedMessage
                                id="global.your_loyalty_earnings"
                                defaultMessage="Your Loyalty Earnings"
                              />
                            </p>
                            <p className="loyalty">
                              <FormattedMessage
                                id="tracking.you_have_loyalty_available"
                                defaultMessage="You have {loyaltyPoints} Loyalty {currency} available for redemption."
                                values={{
                                  currency: company.currency,
                                  loyaltyPoints: formatDecimal(loyaltyPoints),
                                }}
                              />
                            </p>
                          </div>
                        </div>
                        <div
                          className="learn-more"
                          onClick={() =>
                            (window.location.href = "/my-account/loyalty")
                          }
                        >
                          <FormattedMessage
                            id="tracking.uppercase.learn_more"
                            defaultMessage="LEARN MORE"
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                ) : null}
                {this.state.showRiderTrackingPopup && (
                  <RiderTracking
                    showPopup={this.state.showRiderTrackingPopup}
                    setShowPopup={(show: boolean) =>
                      this.setState({ showRiderTrackingPopup: show })
                    }
                  />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let order = state.orderInProgress;
  let loyaltyEnabled =
    state.loyaltyConfig.delivery?.enabled ||
    state.loyaltyConfig.pickup?.enabled;
  let loyaltyPoints = state.loyalty.customer_loyalty || 0;
  let contactNumber = state.outlet.call_now_message;
  let tipsEnabled = state.company.tip_settings?.has_tips && state.company.tip_settings?.type == "post_order";

  return {
    company,
    order,
    loyaltyEnabled,
    loyaltyPoints,
    contactNumber,
    tipsEnabled,
  };
};

const mapDispatchToProps = {
  fetchTrackingConfigurations: pageOperations.fetchTrackingConfigurations,
  updateOrder: orderInProgressOperations.updateOrder,
  updateError: errorOperations.updateError,
  fetchLoyalty: loyaltyOperations.fetchLoyalty,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderTracking));
