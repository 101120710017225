import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";

interface IOutOfStockWarning {
  outOfStockItems: any[];
}
const OutOfStockWarning = (props: IOutOfStockWarning) => {
  return (
    <>
      <div className="warning-box-mobile">
        <div className="warning-content">
          <p className="warning-header">
            <FormattedMessage
              id="menu.sold_out_header"
              defaultMessage="Sorry, {itemCount, plural, one {# item in your cart is} other {# items in your cart are}} sold out:"
              values={{ itemCount: props.outOfStockItems.length }}
            />
          </p>
          <div className="warning-items">
            {props.outOfStockItems.map((item) => {
              return <div>- {item.name}</div>;
            })}
          </div>
          <p className="regular-message">
            <FormattedMessage
              id="menu.sold_out_message"
              defaultMessage="Please remove {itemCount, plural, one {it} other {them}} from your cart to checkout."
              values={{ itemCount: props.outOfStockItems.length }}
            />
          </p>
        </div>
      </div>
    </>
  );
};

export default OutOfStockWarning;
