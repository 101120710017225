// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import PastOrderWidget from "../pastOrderWidget";
// Redux Operations
import { pageOperations } from "../../state/features/page";
import { errorOperations } from "../../state/features/error";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import { calculateAmountsAndBreakdowns } from "../../helpers/itemCalculations";
import { findItemsForReorder } from "../../helpers/cartFunctions";

interface IPastOrderProps {
  pastOrders: any;
  categories: any;
  orderType: any;
  fetchPastOrdersConfigurations: any;
  updateError: any;
  setCart: any;
}

interface IPastOrderState {
  name: string;
  showOrderDetailsPopup: boolean;
}

class PastOrders extends Component<IPastOrderProps, IPastOrderState> {
  constructor(props: IPastOrderProps) {
    super(props);
    this.state = {
      name: "",
      showOrderDetailsPopup: false,
    };

    this.props.fetchPastOrdersConfigurations();
  }

  dateFromString = (dateString: string) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  handleOrderDetailsPopup() {
    this.setState({ showOrderDetailsPopup: true });
  }

  onPopupClose() {
    this.setState({ showOrderDetailsPopup: false });
  }

  handleReorder = (pastOrder: any) => {
    let itemsForReorder = findItemsForReorder(
      pastOrder, this.props.categories, this.someItemsNotInMenuError
    );
    if (itemsForReorder.length !== 0) {
      this.props.setCart({ items: itemsForReorder });
      localStorage.cartItems = JSON.stringify(itemsForReorder);
      window.location.href = "/";
    }
  }

  someItemsNotInMenuError() {
    this.props.updateError({
      title: <FormattedMessage id="error.oops" defaultMessage="Oops!" />,
      message: <FormattedMessage id="error.items_not_in_menu" defaultMessage="Some items are not available in the current menu" />,
    });
  }

  render() {
    const { pastOrders } = this.props;
    return (
      <div>
        <div className="section">
          <div className="">
            <div className="">
              {pastOrders.length ? (
                pastOrders.map((order: any, index: number) => {
                  return (
                    <PastOrderWidget
                      key={index}
                      pastOrder={order}
                      reorderhandler={this.handleReorder}
                    />
                  )
                })
              ) : (
                <div className="section nested">
                  <div className="past-order">
                    <FormattedMessage
                      id="my_account.no_past_orders"
                      defaultMessage="No past orders!"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let pastOrders = state.pastOrders;
  let categories = state.menu.categories;
  let orderType = state.session.orderType;
  return {
    pastOrders,
    categories,
    orderType
  };
};

const mapDispatchToProps = {
  fetchPastOrdersConfigurations: pageOperations.fetchPastOrdersConfigurations,
  updateError: errorOperations.updateError,
  setCart: cartOperations.setCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastOrders);
