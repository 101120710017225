import React from "react";

interface IPageHeaderProps {
  heading: string;
}

function PageHeader(props: IPageHeaderProps) {
  return <h1>{props.heading}</h1>;
}
export default PageHeader;
