import * as types from "./types";

const updateAlert = (alert: any) => ({
  type: types.UPDATE_ALERT,
  payload: alert,
});

const resetAlert = () => ({
  type: types.RESET_ALERT,
});

export default {
  updateAlert,
  resetAlert,
};
