import * as pageTypes from "../page/types";

const outletReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS:
      return action.payload.data.outlet;

    default: return state;
  }
};

const reducer = outletReducer;

export default reducer;
