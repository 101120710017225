// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import CustomisationModal from "../customisationModal";
import GroupedItemModal from "../groupedItemModal";
import RepeatOrderMobile from "../repeatOrderMobile";
import AddedToCartPopupMobile from "../addedToCartPopupMobile";
// Icons, Images etc.
import NoImageAvailableIcon from "../../images/NoImageAvailableIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { errorOperations } from "../../state/features/error";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  addItemToCart,
  addModifiedItemToCart,
  updateItemWithCartIdInCart,
  updateItemWithIdInCart,
} from "../../helpers/cartFunctions";
import {
  translatedName,
  translatedDescription,
} from "../../helpers/translations";
import { isItemOutOfStock } from "../../helpers/utils";
import InfoIcon from "../../images/infoIcon";
import ItemDetailsPopup from "../itemDetailsPopup";

interface IMenuItemMobileProps {
  menuItem: any;
  currency: any;
  storeOpen: boolean;
  menu: any;
  cartItems: any;
  setCart: any;
  updateError: any;
  isGroupedItem?: boolean;
  parentItem?: any;
  closeGroupedItemMenu?: any;
  checkAndRemoveAdvertisementClass?: any;
  session: any;
}

interface IMenuItemMobileState {
  menuItem: any;
  showCustomisation: boolean;
  showRepeatItemPopup: boolean;
  showGroupedItemModal: boolean;
  repeatItem: any;
  cannotBeDecreased: boolean;
  showGroupItemRepeatPopup: boolean;
  showItemAddedPopup: boolean;
  isItemOutOfStock: boolean;
  showItemDetailsPopup: boolean;
  showReadMore: boolean;
}

class MenuItemMobile extends Component<
  IMenuItemMobileProps,
  IMenuItemMobileState
> {
  constructor(props: IMenuItemMobileProps) {
    super(props);
    this.state = {
      menuItem: {},
      showCustomisation: false,
      showRepeatItemPopup: false,
      showGroupedItemModal: false,
      repeatItem: {},
      cannotBeDecreased: false,
      showGroupItemRepeatPopup: false,
      showItemAddedPopup: false,
      isItemOutOfStock: false,
      showItemDetailsPopup: false,
      showReadMore: false,
    };
  }

  static getDerivedStateFromProps(nextProps: any, nextState: any) {
    if (nextProps.menuItem != nextState.menuItem) {
      return { menuItem: nextProps.menuItem };
    }
    return null;
  }

  componentDidMount() {
    this.checkForLongDescription();
    if (
      this.props?.menu.out_of_stock_items &&
      this.props.menu.out_of_stock_items.length > 0
    ) {
      this.setState({
        isItemOutOfStock: isItemOutOfStock(
          this.props.menuItem,
          this.props?.menu.out_of_stock_items
        ),
      });
    }
  }

  componentDidUpdate(prevProps:any, prevState: any) {
    if (prevState.menuItem !== this.state.menuItem) {
      this.checkForMultipleCustomisations();
      this.checkForLongDescription();
      if (
        this.props?.menu.out_of_stock_items &&
        this.props.menu.out_of_stock_items.length > 0
      ) {
        this.setState({
          isItemOutOfStock: isItemOutOfStock(
            this.props.menuItem,
            this.props?.menu.out_of_stock_items
          ),
        });
      }
    }
    if (prevProps.session.showAreaSelect !== this.props.session.showAreaSelect){
      setTimeout(() => {
        this.checkForLongDescription();
      }, 100);
    }
  }

  addItem = (e: React.MouseEvent<Element, MouseEvent>, item: any) => {
    if (this.props.isGroupedItem) {
      if (this.props.cartItems.length) {
        let cartItem = this.props.cartItems.find((e: any) => {
          return e.id === item.id;
        });
        if (cartItem) {
          item.count = cartItem.count + 1;
          updateItemWithIdInCart(
            item,
            this.props.cartItems,
            this.props.setCart
          );
        } else {
          item.count = 1;
          addItemToCart(item, this.props.cartItems, this.props.setCart);
        }
      } else {
        item.count = 1;
        addItemToCart(item, this.props.cartItems, this.props.setCart);
      }
    } else {
      item.count = 1;
      addItemToCart(item, this.props.cartItems, this.props.setCart);
    }
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
      this.setState({ showGroupItemRepeatPopup: false });
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  incrementCount = (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
    item.count = item.count + 1;
    updateItemWithIdInCart(item, this.props.cartItems, this.props.setCart);
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  decrementCount = (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
    item.count = item.count - 1;
    updateItemWithIdInCart(item, this.props.cartItems, this.props.setCart);
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  addModifiedItem(e: React.MouseEvent<HTMLButtonElement>, item: any) {
    addModifiedItemToCart(item, this.props.cartItems, this.props.setCart);
    this.setState({
      showCustomisation: false,
      showRepeatItemPopup: false,
    });
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  }

  repeatPreviousItem = (item: any, parentItemId = null) => {
    item.count = item.count + 1;
    updateItemWithCartIdInCart(item, this.props.cartItems, this.props.setCart);
    this.setState({
      showRepeatItemPopup: false,
      showCustomisation: false,
      showGroupItemRepeatPopup: false,
    });
    if (parentItemId) {
      this.checkAndRemoveAdvertisementClass(parentItemId, true);
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  closeGroupItemRepeatPopup = () => {
    this.setState({ showGroupItemRepeatPopup: false });
    document.body.classList.remove("noscroll");
    this.checkAndRemoveAdvertisementClass(this.state.menuItem.id, false);
  };

  openCustomisationMenu = (
    e: React.MouseEvent<Element, MouseEvent>,
    item: any
  ) => {
    this.setState({ showRepeatItemPopup: false, showCustomisation: true });
    document.body.classList.add("noscroll");
  };

  closeCustomisationMenu() {
    this.setState({
      showCustomisation: false,
      showRepeatItemPopup: false,
    });
    document.body.classList.remove("noscroll");
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, false
      );
    } else {
      this.checkAndRemoveAdvertisementClass(this.state.menuItem.id, false);
    }
  }

  openGroupedItemMenu = (
    e: React.MouseEvent<Element, MouseEvent>,
    item: any
  ) => {
    this.setState({ showGroupedItemModal: true });
    document.body.classList.add("noscroll");
  };

  handleGroupedItemModalClose() {
    this.setState({
      showGroupedItemModal: false,
      showGroupItemRepeatPopup: false,
    });
    document.body.classList.remove("noscroll");
    this.checkAndRemoveAdvertisementClass(this.state.menuItem.id, false);
  }

  onCustomisationRepeat = (item: any) => {
    let i = this.props.cartItems.length - 1;
    for (i; i >= 0; i--) {
      if (item.id === this.props.cartItems[i].id) {
        this.setState({ repeatItem: this.props.cartItems[i] });
        break;
      }
    }
    this.setState({ showRepeatItemPopup: true });
  };

  onGroupedItemRepeat = (item: any) => {
    let repeatItem: any = new Object();
    let i = this.props.cartItems.length - 1;
    for (i; i >= 0; i--) {
      for (let j = 0; j < item.items.length; j++) {
        if (item.items[j].id === this.props.cartItems[i].id) {
          repeatItem = this.props.cartItems[i];
        }
      }
      if (repeatItem.id) {
        this.setState({ repeatItem: this.props.cartItems[i] });
        break;
      }
    }
    this.setState({ showGroupItemRepeatPopup: true });
  };

  checkForMultipleCustomisations = () => {
    let customisationsCount = this.props.cartItems.reduce(
      (count: number, item: any) => {
        if (item.id == this.state.menuItem.id) {
          return count + 1;
        }
        return count;
      },
      0
    );
    if (
      customisationsCount > 1 ||
      (this.state.menuItem.items && this.state.menuItem.items.length)
    ) {
      this.setState({ cannotBeDecreased: true });
    }
  };

  closeRepeatItemPopup = () => {
    this.setState({ showRepeatItemPopup: false });
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, false
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(this.state.menuItem.id, false);
    }
  };

  showMultipleCustomisationError() {
    this.props.updateError({
      message:
        <FormattedMessage
          id="menu.item.multiple_customizations_added"
          defaultMessage="This item has multiple customizations added. Remove the correct item from the cart."
        />
    });
  }

  checkAndRemoveAdvertisementClass(item_id: any, showNotification: boolean) {
    let itemElem =
      document.getElementsByClassName(`add-to-cart-mb-${item_id}`)[0] ||
      document.getElementsByClassName(`increment-item-mb-${item_id}`)[0];
    if (itemElem.classList.contains("added-through-advertisement")) {
      itemElem.classList.remove("added-through-advertisement");
      if (showNotification) {
        this.toggleItemAddedPopup(true);
      }
    }
  }

  toggleItemAddedPopup(showItemAddedPopup: boolean) {
    this.setState({ showItemAddedPopup });
  }

  formatDescription = (description: string) => {
    let descriptionLines = description.split("\r\n");
    return (
      descriptionLines.map((word: string, index: any) => {
        return <div key={index}>{index ? <br /> : false}{word}</div>;
      })
    );
  }

  toggleItemDetailsPopup(status: boolean) {
    this.setState({ showItemDetailsPopup: status });
  }

  checkForLongDescription() {
    let itemId = this.state?.menuItem?.id;
    if (itemId) {
      let description = document.getElementById("item-description" + itemId);
      if (description && description?.scrollHeight-2 > description?.clientHeight) {
        this.setState({ showReadMore: true });
      } else {
        this.setState({ showReadMore: false });
      }
    } else {
      this.setState({ showReadMore: false });
    }
  }

  render() {
    const { menuItem } = this.state;
    const { intl } = this.props;

    let itemDescription = translatedDescription(menuItem, intl.locale)

    return (
      <div
        className={
          this.state.isItemOutOfStock ? "menu-item-mb-disabled" : "menu-item-mb"
        }
      >
        {this.state.showCustomisation ? (
          <CustomisationModal
            onClose={() => this.closeCustomisationMenu()}
            modifierItem={menuItem}
            onModifierAdd={(
              e: React.MouseEvent<HTMLButtonElement>,
              item: any
            ) => this.addModifiedItem(e, item)}
          />
        ) : null}
        {this.state.showGroupedItemModal ? (
          <GroupedItemModal
            groupedItem={menuItem}
            onClose={() => this.handleGroupedItemModalClose()}
            checkAndRemoveAdvertisementClass={(
              item_id: any,
              showNotification: boolean
            ) => {
              this.checkAndRemoveAdvertisementClass(item_id, showNotification);
            }}
          />
        ) : null}
        {this.state.showRepeatItemPopup && (
          <RepeatOrderMobile
            onClose={() => this.closeRepeatItemPopup()}
            onAddNew={(e: React.MouseEvent<HTMLButtonElement>) =>
              this.openCustomisationMenu(e, menuItem)
            }
            onRepeat={() => this.repeatPreviousItem(this.state.repeatItem)}
            repeatItem={this.state.repeatItem}
          />
        )}
        {this.state.showGroupItemRepeatPopup && (
          <RepeatOrderMobile
            onClose={() => this.closeGroupItemRepeatPopup()}
            onAddNew={(e: React.MouseEvent<HTMLButtonElement>) =>
              this.openGroupedItemMenu(e, menuItem)
            }
            onRepeat={() =>
              this.repeatPreviousItem(
                this.state.repeatItem,
                this.state.menuItem.id
              )
            }
            repeatItem={this.state.repeatItem}
          />
        )}
        {this.state.showItemAddedPopup && (
          <AddedToCartPopupMobile
            onClose={() => this.toggleItemAddedPopup(false)}
          />
        )}
        <ItemDetailsPopup
          storeOpen={this.props.storeOpen}
          itemDetails={menuItem}
          onClose={() => this.toggleItemDetailsPopup(false)}
          showItemDetailsPopup={this.state.showItemDetailsPopup}
        />
        <div
          className={
            this.props.menu.out_of_stock_items.includes(menuItem.id)
              ? "item-image-disabled"
              : "item-image"
          }
          style={{
            backgroundImage: `url(${
              menuItem.image_url ? menuItem.image_url : ""
            })`,
          }}
          onClick={() => {
            if (
              menuItem.image_url ||
              translatedDescription(menuItem, intl.locale)
            ) {
              this.toggleItemDetailsPopup(true);
            }
          }}
        >
          {menuItem.image_url ||
          translatedDescription(menuItem, intl.locale) ? (
            <span
              className="info-icon"
              onClick={() => this.toggleItemDetailsPopup(true)}
            >
              <InfoIcon />
            </span>
          ) : null}
        </div>
        <div className="item-details">
          <div className="item-name-description">
            <p className="name">{translatedName(menuItem, intl.locale)}</p>

            <div className="description" id="description-container">
              {itemDescription ? (
                <div id={"item-description" + menuItem.id}>{itemDescription}</div>
              ) : (
                false
              )}
              {this.state.showReadMore ? (
                <strong
                  className="readmore"
                  onClick={() => this.toggleItemDetailsPopup(true)}
                >
                  <FormattedMessage
                    id="menu.item.read_more"
                    defaultMessage="Read More"
                  />
                </strong>
              ) : (
                false
              )}
            </div>
          </div>
          <div className="item-price-action">
            <p className="price">
              {menuItem.unit_price
                ? this.props.currency + " " + formatDecimal(menuItem.unit_price)
                : null}
            </p>
            {!menuItem.count ||
            !this.props.storeOpen ||
            this.props.isGroupedItem ||
            this.state.isItemOutOfStock ? (
              <button
                className={`button-sm btn btn-sm btn-primary btn-block add-to-cart-mb-${menuItem.id}`}
                onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                  menuItem.items && menuItem.items.length
                    ? this.openGroupedItemMenu(e, menuItem)
                    : menuItem.groups.length >= 1
                    ? this.openCustomisationMenu(e, menuItem)
                    : this.addItem(e, menuItem);
                }}
                disabled={!this.props.storeOpen || this.state.isItemOutOfStock}
              >
                {this.state.isItemOutOfStock ? (
                  <>
                    <FormattedMessage
                      id="menu.out_of_stock"
                      defaultMessage="Out of Stock"
                    />
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <FormattedMessage
                      id="menu.item.add_to_order"
                      defaultMessage="Add to Order"
                    />
                  </>
                )}
              </button>
            ) : (
              <div className="count-controller">
                <p
                  className={`icon decrement-item-mb-${menuItem.id}`}
                  onClick={(e: any) => {
                    this.state.cannotBeDecreased
                      ? this.showMultipleCustomisationError()
                      : this.decrementCount(e, menuItem);
                  }}
                >
                  -
                </p>
                <p className="count">{menuItem.count}</p>
                <p
                  className={`icon increment-item-mb-${menuItem.id}`}
                  onClick={(e: any) => {
                    menuItem.items && menuItem.items.length
                      ? this.onGroupedItemRepeat(menuItem)
                      : menuItem.groups.length >= 1
                      ? this.onCustomisationRepeat(menuItem)
                      : this.incrementCount(e, menuItem);
                  }}
                >
                  +
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;
  let storeOpen = state.storeTimings.open;
  let menu = state.menu;
  let cartItems = state.cart.items || [];
  let session = state.session

  return {
    currency,
    storeOpen,
    menu,
    cartItems,
    session
  };
};

const mapDispatchToProps = {
  updateError: errorOperations.updateError,
  setCart: cartOperations.setCart,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MenuItemMobile));
