// Packages
import React, { useState, useEffect, useRef, useContext } from "react";
import { connect } from "react-redux";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
// Helpers, Utils etc.
import { deepCopyFunction } from "../../helpers/utils";
// Contexts
import { LocaleContext } from "../../wrappers/localeWrapper";

declare global {
  interface Window {
    google: any;
  }
}

interface IGoogleMapAreaSearchProps {
  options?: any;
  onChange?: any;
  updateAreaError: any;
  resetDeliveryAreaSelection: any;
}

const LIBRARIES = ["places", "geometry"];

function GoogleMapAreaSearch(props: IGoogleMapAreaSearchProps) {
  const searchBox = useRef({} as any);
  const [apiKey, setApiKey] = useState("");
  const [autoComplete, setAutoComplete] = useState(null as any);

  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    let element = document.getElementById("root") as any;
    setApiKey(element?.dataset.googleMapKey);
  }, []);

  useEffect(() => {
    props.resetDeliveryAreaSelection();
  }, []);

  const onAutoCompleteLoad = (searchAutoComplete: any) => {
    setAutoComplete(searchAutoComplete);
  };

  const onSearchBoxMounted = (ref: any) => {
    searchBox.current = ref;
    document.getElementById("address-search-input")?.focus();
  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      let place = autoComplete.getPlace();
      let address = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();

      findServiceableArea(lat, lng, address);
    }
  };

  const findServiceableArea = (lat: any, lng: any, address: any) => {
    let parsedPosition = new window.google.maps.LatLng(lat, lng);
    let areaOptions = deepCopyFunction(props.options);
    let serviceableArea = areaOptions.find((option: any) => {
      if (option.mapped_zones && option.mapped_zones.length) {
        let zonePolygon = new google.maps.Polygon({
          paths: option.mapped_zones,
        });
        return window.google.maps.geometry.poly.containsLocation(
          parsedPosition,
          zonePolygon
        );
      }
      return false;
    });
    if (serviceableArea) {
      props.onChange(serviceableArea.value, address);
      let selectedAddress = JSON.stringify({
        lat: lat,
        lng: lng,
        address: address,
      });
      localStorage.setItem("selectedAddress", selectedAddress);
    } else {
      props.resetDeliveryAreaSelection();
      props.updateAreaError("address");
    }
  };

  return (
    <React.Fragment>
      {apiKey ? (
        <LoadScript
          googleMapsApiKey={apiKey}
          libraries={LIBRARIES}
          language={localeContext.locale}
        >
          <Autocomplete
            onLoad={onAutoCompleteLoad}
            ref={onSearchBoxMounted}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              className="form-control custom-input"
              type="text"
              id="address-search-input"
              placeholder="eg: Chesterfield"
              onInput={() => props.updateAreaError("")}
            />
          </Autocomplete>
        </LoadScript>
      ) : null}
    </React.Fragment>
  );
}

export default connect(null)(GoogleMapAreaSearch);
