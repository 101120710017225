// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { FormattedMessage } from 'react-intl';
// Components
import SelectInput from "../selectInput";
import MobileInlineLoader from "../mobileInlineLoader";
// Redux Operations
import { errorOperations } from "../../state/features/error";
import { savedCardsOperations } from "../../state/features/savedCards";
import { orderInProgressOperations } from "../../state/features/orderInProgress";
import { cartOperations } from "../../state/features/cart";
import { tipOperations } from "../../state/features/tips";

interface ICreditCardSectionMobileProps {
  orderPayload: any;
  paymentType: string;
  updateError: any;
  savedCards: any;
  fetchStripeSavedCards: any;
  placeOrder: any;
  onCardSelect: any;
  setCart: any;
  showPointsEarnedPopup: any;
  togglePlaceOrderButton: any;
  toggleTipSuccessfulPopup: any;
}

function CreditCardSectionMobile(props: ICreditCardSectionMobileProps) {
  const [stripeCards, setStripeCards] = useState([] as any);
  const [showAddForm, setShowAddForm] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(true);

  useEffect(() => {
    // fetch saved cards
    props.fetchStripeSavedCards().then((result: any) => {
      if (result.payload.data.saved_cards.length) {
        let savedCards = result.payload.data.saved_cards.map(
          (savedCard: any) => {
            let card: any = {};
            card.label = "xxxx-xxxx-xxxx-" + savedCard.last4;
            card.value = savedCard;
            return card;
          }
        );
        setStripeCards(savedCards);
        props.onCardSelect(savedCards[0]?.value || {});
      } else {
        setShowAddForm(true);
      }
      setCardsLoading(false);
    });
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const toggleAddCard = () => {
    setShowAddForm(!showAddForm);
  };

  const handleCreditCardPayment = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);

    if (!result.error) {
      // Send the token to your server
      if (props.paymentType == "tip") {
        tipPaymentHandler(result.token, props.orderPayload);
      } else {
        placeOrderHandler(result.token, props.orderPayload);
      }
    } else {
      if (result.error.type == "invalid_request_error") {
        props.updateError({
          titleCode: "error.oops",
          messageCode: "error.unable_to_process_order",
        });
      } else {
        props.updateError({
          title: "Oops!",
          message: result.error.message,
        });
      }

      props.togglePlaceOrderButton(true);
    }
  };

  return (
    <div className="credit-card-section mt-2">
      {cardsLoading ? (
        <MobileInlineLoader />
      ) : (
        <div>
          {stripeCards.length ? (
            <div className="saved-cards">
              <div className="form-group mb-2">
                <label className="input-label custom mb-1">
                  <FormattedMessage
                    id="payment.card_on_file"
                    defaultMessage="Card on file"
                  />
                </label>
                <SelectInput
                  isDisabled={showAddForm}
                  options={stripeCards}
                  onChange={handleStripeCardSelection}
                  fullWidth
                  defaultValue={stripeCards[0]?.value}
                />
              </div>
              <p
                className="add-new-label my-2"
                style={{ color: "#2B82EB" }}
                onClick={toggleAddCard}
              >
                {showAddForm ? (
                  <FormattedMessage
                    id="payment.use_saved_cards"
                    defaultMessage="Use Saved Cards"
                  />
                ) : (
                  <FormattedMessage
                    id="payment.add_new_card"
                    defaultMessage="+ Add New Card"
                  />
                )}
              </p>
            </div>
          ) : null}
          {showAddForm ? (
            <div className="add-new-card mt-2">
              <div className="heading">
                {stripeCards.length ? (
                  <FormattedMessage
                    id="payment.new_card_details"
                    defaultMessage="New Card Details"
                  />
                ) : ""}
              </div>
              <form
                id="form-stripe-card-section"
                className="add-card-form"
                onSubmit={handleCreditCardPayment}
              >
                <div className="form-group">
                  <label className="input-label bold mb-2">
                    <FormattedMessage
                      id="payment.card.name"
                      defaultMessage="Card Holder Name"
                    />
                  </label>
                  <input
                    className="form-control custom-input mb-2"
                    type="text"
                  />
                  <label className="input-label bold mb-2">
                    <FormattedMessage
                      id="payment.card.number"
                      defaultMessage="Card Number"
                    />
                  </label>
                  <span className={`card_section ${errorCode}`}></span>
                  <CardNumberElement
                    className="form-control custom-input mb-2"
                    onChange={verifyCardNumber}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-50 mr-4">
                    <label className="input-label  bold mb-2">
                      <FormattedMessage
                        id="payment.card.validity"
                        defaultMessage="Validity"
                      />
                    </label>
                    <CardExpiryElement className="form-control custom-input mb-2" />
                  </div>
                  <div className="form-group w-50">
                    <label className="input-label w-49  bold mb-2">
                      <FormattedMessage
                        id="payment.card.cvv"
                        defaultMessage="CVV"
                      />
                    </label>
                    <CardCvcElement className="form-control custom-input mb-2" />
                  </div>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="defaultCheck1"
                  />
                  <label
                    className="form-check-label input-label bold"
                    htmlFor="defaultCheck1"
                  >
                    <FormattedMessage
                      id="payment.save_card_details"
                      defaultMessage="Save card details"
                    />
                  </label>
                </div>
                <button id="btn_card_section" type="submit" disabled={!stripe}>
                  <FormattedMessage
                    id="global.pay"
                    defaultMessage="Pay"
                  />
                </button>
              </form>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );

  function handleStripeCardSelection(card: any) {
    props.onCardSelect(card);
  }

  function verifyCardNumber(event: any) {
    if (event.error) {
      setErrorCode(event.error.code);
    }
  }

  async function placeOrderHandler(token: any, orderPayload: any) {
    orderPayload.payment.save_card = document.getElementById(
      "defaultCheck1"
    )?.checked;
    orderPayload.payment.is_saved_card = false;
    orderPayload.payment.transaction_id = token.id;
    orderPayload.payment.ref_card_number = token.id;
    orderPayload.payment.total_amount = orderPayload.total_amount;

    const response =
      props.placeOrder("payments/stripe/v1/orders", orderPayload)
      .then((response: any) => {
        if (!response.error) {
          let earnedLoyalty = null;
          localStorage.cartItems = JSON.stringify([]);
          props.setCart({ items: [], subTotal: 0, total: 0 });
          if (response.payload.data.order_details.earned_loyalty) {
            earnedLoyalty = response.payload.data.order_details.earned_loyalty;
          }
          props.showPointsEarnedPopup(earnedLoyalty);
        }
        props.togglePlaceOrderButton(true);
      });
  }

  async function tipPaymentHandler(token: any, payload: any) {
    payload.payment_details.save_card = document.getElementById(
      "defaultCheck1"
    )?.checked;
    payload.payment_details.is_saved_card = false;
    payload.payment_details.ref_card_number = token.id;
    payload.payment_details.total_amount = 0;

    // setShow(false);
    props.makeTipPayment("payments/stripe/v1/tips", payload).then((response: any) => {
      if (!response.error) {
        if (response.payload.data.payment_page_url) {
          window.location.href = response.payload.data.payment_page_url;
        } else {
          props.toggleTipSuccessfulPopup(true);
        }
      }
      // setShow(true);
      props.togglePlaceOrderButton(true);
    });
  }
}

const mapStateToProps = (state: any) => {
  let savedCards = state.savedCards;
  return {
    savedCards,
  };
};

const mapDispatchToProps = {
  fetchStripeSavedCards: savedCardsOperations.fetchStripeSavedCards,
  updateError: errorOperations.updateError,
  placeOrder: orderInProgressOperations.placeOrder,
  setCart: cartOperations.setCart,
  makeTipPayment: tipOperations.makeTipPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCardSectionMobile);
