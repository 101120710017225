import * as types from "./types";

const sendInvoiceToRegisteredEmail = (orderNumber: string) => ({
  type: types.SEND_INVOICE_TO_REGISTERED_EMAIL,
  payload: {
    request: {
      method: "post",
      url: `/invoices/${orderNumber}/email-invoice`,
      data: {
        order_number: orderNumber
      },
    },
  },
});

const sendInvoiceToNewEmail = (orderNumber: string, email: string) => ({
  type: types.SEND_INVOICE_TO_NEW_EMAIL,
  payload: {
    request: {
      method: "post",
      url: `/invoices/${orderNumber}/email-invoice`,
      data: {
        order_number: orderNumber,
        email: email,
      },
    },
  },
});

export default {
  sendInvoiceToRegisteredEmail,
  sendInvoiceToNewEmail,
};
