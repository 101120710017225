// Packages
import React from "react";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  translatedName,
} from "../../helpers/translations";

interface IOrderDetailsModalProps {
  pastOrderItem: any;
  handleReorder: any;
  onClose: any;
  currency: any;
}

const OrderDetailsModal = (props: IOrderDetailsModalProps) => {
  let subtotal = props.pastOrderItem.order_details.sub_total;
  let surcharges = props.pastOrderItem.order_details.surcharges_breakdown || [];
  let discounts = props.pastOrderItem.order_details.discounts || [];
  let redeemedLoyalty = props.pastOrderItem.order_details.redeemed_loyalty;
  let tax = props.pastOrderItem.order_details.tax_total;
  let taxInclusive = props.pastOrderItem.order_details.is_tax_inclusive;

  const intl = useIntl();

  const getDateTime = (dateString: any) => {
    let date = new Date(dateString).toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    let time = new Date(dateString).toLocaleTimeString("default", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .toLocaleUpperCase();
    let dateTime = date + " - " + time;
    return dateTime;
  };

  return (
    <div className="mobile-order-details">
      <div className="header">
        <p className="order-number">
          <FormattedMessage
            id="order.order_number_with_hash"
            defaultMessage="Order# {orderNumber}"
            values={{
              orderNumber: props.pastOrderItem.order_number,
            }}
          />
        </p>
        <p className="date-time">
          {getDateTime(props.pastOrderItem.created_at)}
        </p>
      </div>
      <div className="content">
        <div className="order-details-table">
          <div className="table-header">
            <p className="header-name header-qty">
              <FormattedMessage
                id="global.short.quantity"
                defaultMessage="Qty"
              />
            </p>
            <p className="header-name header-item">
              <FormattedMessage
                id="global.item"
                defaultMessage="Item"
              />
            </p>
            <p className="header-name header-amt">
              <FormattedMessage
                id="global.amount"
                defaultMessage="Amount"
              />
            </p>
          </div>
          <div className="order-details">
            {props.pastOrderItem.order_details.items.map(
              (item: any, index: any) => {
                return (
                  <div className="pastorder-item" key={index}>
                    <div className="order">
                      <p className="item-qty">{item.quantity}x</p>
                      <p className="item-name">
                        {translatedName(item, intl.locale)}
                      </p>
                      <p className="item-amount">
                        {props.currency} {formatDecimal(item.sub_total)}
                      </p>
                    </div>
                    {item.modifiers ? (
                      <div className="modifiers">
                        {item.modifiers.map((modifier: any) => {
                          return (
                            <p className="modifier-item" key={modifier.id}>
                              {translatedName(modifier, intl.locale)}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className="footer">
          <div className="order-summary">
            <div className="summary-text">
              <p className="subtotal-text">
                <FormattedMessage
                  id="global.subtotal"
                  defaultMessage="Subtotal"
                />
              </p>
              <p className="subtotal-value">{formatDecimal(subtotal)}</p>
            </div>
            {surcharges.map((surcharge: any, index: any) => {
              return (
                <div className="summary-text" key={index}>
                  <p>
                    <FormattedMessage
                      id="global.surcharge"
                      defaultMessage="Surcharge"
                    />
                  </p>
                  <p>{formatDecimal(surcharge.amount)}</p>
                </div>
              );
            })}
            {discounts.map((discount: any, index: any) => {
              return (
                <div className="summary-text" key={index}>
                  <p>{discount.name}</p>
                  <p>-{formatDecimal(discount.amount)}</p>
                </div>
              );
            })}
            {redeemedLoyalty > 0 ? (
              <div className="summary-text">
                <p>
                  <FormattedMessage
                    id="global.loyalty"
                    defaultMessage="Loyalty"
                  />
                </p>
                <p>-{formatDecimal(redeemedLoyalty)}</p>
              </div>
            ) : null}
            {!taxInclusive && tax > 0 ? (
              <div className="summary-text">
                <p>
                  <FormattedMessage
                    id="global.tax"
                    defaultMessage="Tax"
                  />
                </p>
                <p>{formatDecimal(tax)}</p>
              </div>
            ) : null}
            <div className="summary-text total">
              <p>
                <FormattedMessage
                  id="global.total"
                  defaultMessage="Total"
                />
              </p>
              <p>
                {formatDecimal(props.pastOrderItem.order_details.total_amount)}
              </p>
            </div>
          </div>
          <div className="option">
            <button
              className="reorder button btn btn-primary"
              onClick={() => props.handleReorder(props.pastOrderItem)}
            >
              <FormattedMessage
                id="my_account.re_order"
                defaultMessage="Re-Order"
              /> <FontAwesomeIcon icon={faChevronCircleRight} size="sm" />
            </button>
            <button
              className="cancel button btn btn-primary"
              onClick={() => props.onClose()}
            >
              <FormattedMessage
                id="global.cancel"
                defaultMessage="Cancel"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;

  return {
    currency,
  };
};

export default connect(mapStateToProps, null)(OrderDetailsModal);
