// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
import SelectInput from "../selectInput";
// Helpers, Utils etc.
import { findUnschedulableItems } from "../../helpers/categorySchedule";
import { translatedName } from "../../helpers/translations";

interface IScheduleOrdersPopupProps {
  onClose: any;
  for: string;
  defaultDeliveryTime: any;
  defaultEta: any;
  openingHours: any;
  confirmScheduleDate: any;
  convertTo24Hours: () => void;
  minimumEtaMinutes: any;
  hasLeadTime: boolean;
  cart: any;
  menu: any;
}

interface IScheduleOrdersPopupState {
  dates: any;
  times: any;
  defaultDate: any;
  defaultTime: any;
  selectedDate: any;
  selectedTime: any;
  showSchedule: boolean;
  timeConfig: any;
  unschedulableItems: [];
}

class ScheduleOrdersPopup extends Component<
  IScheduleOrdersPopupProps,
  IScheduleOrdersPopupState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      dates: [],
      times: [],
      defaultDate: "",
      defaultTime: "",
      selectedDate: {},
      selectedTime: "",
      showSchedule: true,
      timeConfig: { weekday: "short", month: "short", day: "numeric" },
      unschedulableItems: [],
    };
  }

  componentDidMount() {
    let startingDate = this.findScheduleStartDateTime();
    let dates = this.findScheduleDates(startingDate);
    let times = this.findScheduleTimes(dates[0]);
    let defaultDate = dates[0]?.value;
    let defaultTime = times[0]?.value;
    this.setState({
      dates: dates,
      times: times,
      defaultDate: defaultDate,
      defaultTime: defaultTime,
    });
    this.checkForUnschedulableItems(defaultDate, defaultTime);
  }

  findScheduleStartDateTime() {
    let scheduleDate = new Date(this.props.defaultDeliveryTime.getTime());
    let scheduleTimeInMinutes =
      parseInt(scheduleDate.getHours()) * 60 +
      parseInt(scheduleDate.getMinutes());

    let workingHours = this.workingHoursForDay(scheduleDate.getDay());
    let openingSession = workingHours[0].from.split(':');
    let closingSession = workingHours[workingHours.length - 1].to.split(':');

    let openingTimeInMinutes =
      parseInt(openingSession[0]) * 60 +
      parseInt(openingSession[1]) +
      parseInt(this.props.defaultEta);
    let closingTimeInMinutes =
      parseInt(closingSession[0]) * 60 + parseInt(closingSession[1]);

    if (scheduleTimeInMinutes < openingTimeInMinutes) {
      // When store is about to open, set starting schedule time to today's opening time
      scheduleDate.setHours(0);
      scheduleDate.setMinutes(openingTimeInMinutes);
    } else if (scheduleTimeInMinutes > closingTimeInMinutes) {
      // When store is closed, set starting schedule time to next day's opening time
      scheduleDate.setDate(scheduleDate.getDate() + 1);
      scheduleDate.setHours(0);
      scheduleDate.setMinutes(openingTimeInMinutes);
    }

    // Rounded up times
    let scheduleDateInMinutes = scheduleDate.getMinutes() % 60;
    if (scheduleDateInMinutes > 30 && scheduleDateInMinutes <= 59) {
      scheduleDate = this.roundUpTime(scheduleDate, 60);
    } else if (scheduleDateInMinutes >= 0 && scheduleDateInMinutes <= 30) {
      scheduleDate = this.roundUpTime(scheduleDate, 30);
    }

    return scheduleDate;
  }

  findScheduleDates(startingDate: any) {
    let dates = new Array();
    for (let i = 0; i <= 6; i++) {
      let date = new Date(startingDate.getTime());
      dates.push({
        value: date,
        label: date.toLocaleDateString(undefined, this.state.timeConfig),
      });
      startingDate.setDate(startingDate.getDate() + 1);
    }
    return dates;
  }

  findScheduleTimes(selectedDate: any) {
    let defaultDeliveryDate = this.props.defaultDeliveryTime;
    let defaultDeliveryTime = defaultDeliveryDate.getTime();
    let defaultDateLabel = defaultDeliveryDate.toLocaleDateString(
      undefined,
      this.state.timeConfig
    );
    let selectedDeliveryDate = selectedDate.value;
    let selectedDeliveryTime = selectedDeliveryDate.getTime();
    let dayTimeSlots, slotStartTime, slotEndTime;
    if (selectedDate.label === defaultDateLabel) {
      dayTimeSlots = this.workingHoursForDay(defaultDeliveryDate.getDay());
      slotStartTime = new Date(defaultDeliveryTime);
      slotEndTime = new Date(defaultDeliveryTime);
    } else {
      dayTimeSlots = this.workingHoursForDay(selectedDeliveryDate.getDay());
      slotStartTime = new Date(selectedDeliveryTime);
      slotEndTime = new Date(selectedDeliveryTime);
    }
    let completeTimeSlots = [] as any;
    // Loop through a particular days's working hours and find correct session
    for (let i = 0; i < dayTimeSlots.length; i++) {
      let timeSlots = [];
      let splittedStartTime = dayTimeSlots[i].from.split(":");
      let splittedEndTime = dayTimeSlots[i].to.split(":");

      slotStartTime.setHours(splittedStartTime[0]);
      slotStartTime.setMinutes(splittedStartTime[1]);
      slotEndTime.setHours(splittedEndTime[0]);
      slotEndTime.setMinutes(splittedEndTime[1]);

      let slotStartTimeValue = slotStartTime.getTime();
      let slotEndTimeValue = slotEndTime.getTime();
      let currentTimeInMinutes =
        parseInt(defaultDeliveryDate.getHours()) * 60 +
        parseInt(defaultDeliveryDate.getMinutes());
      let openingTimeInMinutes =
        parseInt(splittedStartTime[0]) * 60 +
        parseInt(splittedStartTime[1]) +
        parseInt(this.props.defaultEta);
      let endTimeInMinutes =
        parseInt(splittedEndTime[0]) * 60 + parseInt(splittedEndTime[1]);
      let roundedTime, deliveryTimeMinutes, deliveryDate;
      if (
        selectedDate.label === defaultDateLabel &&
        currentTimeInMinutes > openingTimeInMinutes
      ) {
        deliveryTimeMinutes = defaultDeliveryDate.getMinutes();
        deliveryDate = defaultDeliveryDate;
      } else {
        deliveryTimeMinutes = parseInt(splittedStartTime[1]) +
          parseInt(this.props.defaultEta);
        deliveryDate = new Date();
        deliveryDate.setHours(splittedStartTime[0]);
        deliveryDate.setMinutes(deliveryTimeMinutes);
      }
      deliveryTimeMinutes = deliveryTimeMinutes % 60;
      if (deliveryTimeMinutes > 30 && deliveryTimeMinutes <= 59) {
        roundedTime = this.roundUpTime(deliveryDate, 60);
      } else if (deliveryTimeMinutes >= 0 && deliveryTimeMinutes <= 30) {
        roundedTime = this.roundUpTime(deliveryDate, 30);
      }
      let startTime =
        parseInt(roundedTime.getHours()) * 60 +
        parseInt(roundedTime.getMinutes());
      let endTime = endTimeInMinutes;
      timeSlots = this.buildTimeSlots(startTime, endTime);
      completeTimeSlots = completeTimeSlots.concat(timeSlots);
    }
    return completeTimeSlots;
  }

  workingHoursForDay(day: number) {
    let workingHours = this.props.openingHours;
    let dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayName = dayNames[day];

    return workingHours[dayName];
  }

  buildTimeSlots(startTime: any, endTime: any) {
    let timeInterval = 30;
    let timeArray = [];
    for (let i = 0; startTime < endTime; i++) {
      let hours = Math.floor(startTime / 60);
      let minutes = startTime % 60;
      let amOrPm = hours < 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      let time =
        ("0" + hours).slice(-2) +
        ":" +
        ("0" + minutes).slice(-2) +
        " " +
        amOrPm;
      timeArray.push({ value: time, label: time });
      startTime = startTime + timeInterval;
    }
    return timeArray;
  }

  roundUpTime(timeStamp: any, roundUpTime: any) {
    let timeToReturn = new Date(timeStamp);

    timeToReturn.setMilliseconds(
      Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
    );
    if (roundUpTime === 60) {
      timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 60) * 60);
    } else if (roundUpTime === 30) {
      timeToReturn.setMinutes(Math.ceil(timeToReturn.getMinutes() / 30) * 30);
    }

    return timeToReturn;
  }

  handleClose() {
    setTimeout(() => {
      this.props.onClose();
    }, 500);
    this.setState({ showSchedule: false });
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    this.props.confirmScheduleDate(
      this.state.defaultDate,
      this.state.defaultTime
    );
    this.handleClose();
  }

  changeDate = (date: any) => {
    let label = date.toLocaleDateString(undefined, this.state.timeConfig);
    let dateObj = { label: label, value: date };
    let times = this.findScheduleTimes(dateObj);
    let defaultTime = times[0]?.value;
    this.setState({
      times: times,
      defaultDate: date,
      defaultTime: defaultTime,
    });
    this.checkForUnschedulableItems(date, defaultTime);
  };

  changeTime = (time: any) => {
    this.setState({ defaultTime: time });
    this.checkForUnschedulableItems(this.state.defaultDate, time);
  };

  checkForUnschedulableItems = (expectedDate: any, expectedTime: any) => {
    let currentTime = new Date();
    let expectedMinutes = parseInt(currentTime.getMinutes()) + this.props.minimumEtaMinutes;
    currentTime.setMinutes(expectedMinutes);

    let convertedTime = this.props.convertTo24Hours(expectedTime);
    let convertedDate = expectedDate.toLocaleString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    let expectedDeliveryTime = new Date(convertedDate + " " + convertedTime);

    let scheduledTime = new Date(expectedDeliveryTime.getTime());
    if (this.props.hasLeadTime || expectedDeliveryTime.getTime() > currentTime.getTime()) {
      scheduledTime.setMinutes(scheduledTime.getMinutes() - parseInt(this.props.defaultEta));

      let itemsThatCantBeScheduled = findUnschedulableItems(scheduledTime, this.props.cart.items, this.props.menu.categories);
      this.setState({ unschedulableItems: itemsThatCantBeScheduled });
    } else {
      this.setState({ unschedulableItems: [] });
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <Modal
        show={this.state.showSchedule}
        centered
        dialogClassName="custom curved"
        backdrop="static"
      >
        <ModalCloseIcon onClick={() => this.handleClose()} />
        <div className="schedule-orders-popup">
          <div className="header">
            {this.props.for == "Delivery" ? (
              <FormattedMessage
                id="schedule.delivery"
                defaultMessage="Schedule Delivery"
              />
            ) : (
              <FormattedMessage
                id="schedule.pickup"
                defaultMessage="Schedule Pickup"
              />
            )}
          </div>

          {this.state.unschedulableItems.length ? (
            <div className="schedule-orders-warning">
              <div className="warning-header">
                <FormattedMessage
                  id="error.oops"
                  defaultMessage="Oops!"
                />
              </div>
              <div className="warning-body">
                <p>
                  <FormattedMessage
                    id="error.order_cannot_be_scheduled"
                    defaultMessage="We're sorry for the inconvenience, but your order includes items that cannot be scheduled for the selected date and time:"
                  />
                </p>

                <ul>
                  {this.state.unschedulableItems.map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        {translatedName(item, intl.locale)}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          ) : null}

          <form
            className="form"
            onReset={() => this.handleClose()}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              this.handleSubmit(e);
            }}
          >
            <div className="d-flex mb-4">
              <div className="form-group mb-2 w-100 mr-3">
                <label className="input-label custom bold mb-3">
                  {this.props.for == "Delivery" ? (
                    <FormattedMessage
                      id="schedule.delivery_date"
                      defaultMessage="Delivery Date"
                    />
                  ) : (
                    <FormattedMessage
                      id="schedule.pickup_date"
                      defaultMessage="Pickup Date"
                    />
                  )}
                </label>
                <SelectInput
                  options={this.state.dates}
                  defaultValue={this.state.defaultDate}
                  onChange={this.changeDate}
                  fullWidth
                />
              </div>
              <div className="form-group mb-2 w-100">
                <label className="input-label custom bold mb-3">
                  {this.props.for == "Delivery" ? (
                    <FormattedMessage
                      id="schedule.delivery_time"
                      defaultMessage="Delivery Time"
                    />
                  ) : (
                    <FormattedMessage
                      id="schedule.pickup_time"
                      defaultMessage="Pickup Time"
                    />
                  )}
                </label>
                <SelectInput
                  options={this.state.times}
                  defaultValue={this.state.defaultTime}
                  onChange={this.changeTime}
                  fullWidth
                />
              </div>
            </div>

            <div className="actions">
              <button className="button btn btn-outline-primary mr-3 w-100" type="reset">
                <FormattedMessage
                  id="global.back"
                  defaultMessage="Back"
                />
              </button>
              <button
                className="button btn btn-primary w-100"
                type="submit"
                disabled={this.state.defaultTime && !this.state.unschedulableItems.length ? false : true}
              >
                <FormattedMessage
                  id="global.submit"
                  defaultMessage="Submit"
                />
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  let cart = state.cart;
  let menu = state.menu;

  return {
    cart,
    menu,
  };
};

export default injectIntl(connect(mapStateToProps)(ScheduleOrdersPopup));
