import React from "react";
import paymentInProgress from "./payment-progress.svg";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default function () {
  const location = useLocation();

  return (
    <div className="in-progress-banner-mobile" id="in-progress-status">
      <img src={paymentInProgress} />
      <div className="banner-detials">
        <span className="banner-header">
          {location.pathname == "/m" ? (
            <FormattedMessage
              id="payment_in_progress_title_home"
              defaultMessage="Payment in progress"
            />
          ) : (
            <FormattedMessage
              id="payment_in_progress_title_others"
              defaultMessage="Another Payment is in progress"
            />
          )}
        </span>
        <span className="banner-text">
          {location.pathname == "/m" ? (
            <FormattedMessage
              id="payment_in_progress_text_home"
              defaultMessage="Please wait while we are currently trying to charge your card."
            />
          ) : (
            <FormattedMessage
              id="payment_in_progress_text_others"
              defaultMessage="Please wait while we complete the payment. If this you believe this is an error, please contact us."
            />
          )}
        </span>
      </div>
    </div>
  );
}
