import Pusher from "pusher-js";
import React, { createContext, useMemo } from "react";
import * as PusherTypes from "pusher-js";

export const PusherContext = createContext({} as PusherTypes.default);

interface IPusherProviderProps {
  pusherId: any;
  children: any;
}

export const PusherProvider = (props: IPusherProviderProps) => {
  const pusher = useMemo(() => {
    if (!props.pusherId) {
      console.log("[PUSHER] ERROR: Pusher ID is undefined");
      return undefined;
    }
    return new Pusher(props.pusherId, {});
  }, [props.pusherId]);

  return (
    <PusherContext.Provider value={pusher}>
      {props.children}
    </PusherContext.Provider>
  );
};
