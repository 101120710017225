import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";
import { usePusherContext } from "../../../../hooks/usePusherContext";
import { RootState } from "../../../../state/store";
import { useCheckoutScreenContext } from "../../../../hooks/useCheckoutScreenContext";
import { formatDecimal } from "../../../../helpers/itemCalculations";
import { errorOperations } from "../../../../state/features/error";

interface IApplePayButtonProps {
  validateMerchant(
    paymentURL: string,
    orderPayload: any,
    reference: string
  ): any;
  makeApplePayPayment(paymentData: any, paymentReferenceId: string): any;
  userCancelledApplePayPayment(referenceId: string): void;
  orderPayload: any;
  updateError(error:any): void;
}

const ApplePayButton = ({
  validateMerchant,
  makeApplePayPayment,
  userCancelledApplePayPayment,
  orderPayload,
  updateError,
}: IApplePayButtonProps) => {
  const [supportsApplePay, setSupportsApplePay] = useState(false);

  const { currency, country_code } = useSelector(
    (state: RootState) => state.company
  );
  const { user } = useSelector((state: RootState) => state);
  const pusher = usePusherContext();
  const { handlePaymentSuccess, handlePaymentFailure, updateEarnedLoyalty } =
    useCheckoutScreenContext();

  useEffect(() => {
    checkApplePaySupport();
  }, []);

  const payableAmount = useMemo(() => {
    if (orderPayload) {
      const paymentAmount = orderPayload?.payment?.amount ?? 0;
      const tipAmount =
        orderPayload?.payment?.tip ?? orderPayload?.tips_amount ?? 0;
      return paymentAmount + tipAmount;
    }
    return 0;
  }, [orderPayload]);

  const checkApplePaySupport = async () => {
    if (window?.ApplePaySession) {
      try {
        //checks if device supports Apple Pay
        await window.ApplePaySession.canMakePayments();
        setSupportsApplePay(true);
      } catch (error) {
        console.error("Apple Pay is not supported on this device:", error);
      }
    }
  };

  /**
   * Retrieves the merchant verification details for Apple Pay.
   * @param validationURL - The validation URL received from the Apple Pay session.
   * @param orderPayload - The payload of the order.
   * @param paymentReference - The reference for the payment.
   * @param channel - The Pusher channel used for real-time updates.
   * @returns The merchant verification details.
   */
  const getMerchantVerificationDetails = async (
    validationURL: string,
    orderPayload: any,
    paymentReference: any,
    channel: any
  ) => {
    let validationResponse = await validateMerchant(
      validationURL,
      orderPayload,
      paymentReference
    );

    if (validationResponse?.error) {
      let error_id = validationResponse.error?.response?.data?.id;
        if (error_id && error_id === "out_of_stock") {
          updateError({
            subTitleCode: "error.out_of_stock",
            messageCode: "error.out_of_stock",
            redirectionUrl: "/",
          });
          throw new Error("Sold out items in cart");
        }
    }

    if (!validationResponse?.payload?.data?.error) {
      return new Promise((resolve, reject) => {
        const handler = (data: any) => {
          channel.unbind("merchant_session_created", handler);
          resolve(data);
        };
        channel.bind("merchant_session_created", handler);

        //timeout
        setTimeout(() => {
          reject(
            new Error(
              "Timeout waiting for validateMPGSApplePayMerchant message"
            )
          );
        }, 60000); // 60 seconds timeout
      });
    } else {
      throw new Error("Validation failed");
    }
  };

  /**
   * Retrieves the transaction status for an Apple Pay payment.
   * @param paymentData - The payment data received from the Apple Pay session.
   * @param channel - The Pusher channel used for real-time updates.
   * @returns The transaction status, either "success" or "failure".
   */
  const getTransactionStatus = async (
    paymentData: any,
    channel: any,
    paymentReference: string
  ) => {
    try {
      const transactionStatusDetails = await makeApplePayPayment(
        paymentData,
        paymentReference
      );

      if (transactionStatusDetails?.payload?.data?.error) {
        throw new Error("Transaction failed");
      }

      return new Promise((resolve, reject) => {
        try {
          const handlePaymentSucceeded = (data: any) => {
            channel.unbind("payment_succeeded", handlePaymentSucceeded);
            channel.unbind("payment_failed", handlePaymentFailed);
            resolve(data);
          };

          const handlePaymentFailed = (data: any) => {
            channel.unbind("payment_succeeded", handlePaymentSucceeded);
            channel.unbind("payment_failed", handlePaymentFailed);
            resolve(data);
          };

          // Ensure channel is initialized
          if (!channel) {
            throw new Error("Channel is not initialized");
          }

          // Bind to payment_succeeded event
          try {
            channel.bind("payment_succeeded", handlePaymentSucceeded);
          } catch (error) {
            reject(error);
          }

          // Bind to payment_failed event
          try {
            channel.bind("payment_failed", handlePaymentFailed);
          } catch (error) {
            reject(error);
          }

          // Timeout
          setTimeout(() => {
            channel.unbind("payment_succeeded", handlePaymentSucceeded);
            channel.unbind("payment_failed", handlePaymentFailed);
            reject(
              new Error("Timeout waiting for applepay_txn_status message")
            );
          }, 60000); // 60 seconds timeout
        } catch (error) {
          reject(error);
        }
      }).catch((error) => {
        return "failure";
      });
    } catch (error) {
      return "failure";
    }
  };

  const handleApplePay = async (event: any) => {
    event.preventDefault();
    const currentTime = new Date();
    const paymentReference = `${user.id}${
      user.company_id
    }${currentTime.getTime()}`;
    const pusherChannelName = `private_mastercard_transaction_${paymentReference}`;
    const channel = pusher.subscribe(pusherChannelName);
    let paymentRequest = {
      countryCode: country_code,
      currencyCode: currency,
      supportedNetworks: ["visa", "masterCard", "amex", "discover"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: "Your Merchant Name",
        type: "final",
        amount: formatDecimal(payableAmount),
      },
    };

    let session = new ApplePaySession(3, paymentRequest);

    if (channel) {
      /**
       * Interval handler for Apple Pay payment processing.
       * This handler sets up event listeners for merchant validation, session cancellation,
       * and payment authorization. It initiates the Apple Pay session after a specified interval.
       */
      session.onvalidatemerchant = async (event) => {
        try {
          const validationURL = event.validationURL;
          const merchantValidationDetails: any =
            await getMerchantVerificationDetails(
              validationURL,
              orderPayload,
              paymentReference,
              channel
            );
          let merchantSession = merchantValidationDetails?.merchant_session;
          if (Object.keys(merchantSession).length) {
            session.completeMerchantValidation(merchantSession);
          } else {
            throw new Error("Merchant validation details are empty");
          }
        } catch (error) {
          if (session) {
            session.abort(); // Abort the session in case of an error
          }
          channel.unsubscribe();
          clearTimeout(applePaymentHandlerTimeout);
        }
      };

      session.oncancel = (event) => {
        // Handle the event when the user cancels the Apple Pay session
        userCancelledApplePayPayment(paymentReference);
        channel.unsubscribe();
        clearTimeout(applePaymentHandlerTimeout);
      };

      session.onpaymentauthorized = async (event) => {
        try {
          const paymentData = event.payment;
          const transactionData: any = await getTransactionStatus(
            paymentData,
            channel,
            paymentReference
          );
          let transactionStatus = "failed";
          if (transactionData.remote_transaction_id) {
            transactionStatus = "success";
          }
          if (
            transactionData.earned_loyalty &&
            transactionData.earned_loyalty > 0
          ) {
            updateEarnedLoyalty(transactionData.earned_loyalty.toString());
          }
          if (transactionStatus === "success") {
            clearTimeout(applePaymentHandlerTimeout);
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            channel.unsubscribe();
            handlePaymentSuccess();
          } else {
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            channel.unsubscribe();
            handlePaymentFailure();
            clearTimeout(applePaymentHandlerTimeout);
          }
        } catch (error) {
          session.completePayment(ApplePaySession.STATUS_FAILURE); // Complete the payment with a failure status in case of an error
          channel.unsubscribe();
          clearTimeout(applePaymentHandlerTimeout);
        }
      };
      session.begin();

      const applePaymentHandlerTimeout = setTimeout(() => {
        channel.unsubscribe();
        if (session) {
          session.abort(); // Abort the session in case of an error
        }
      }, 180000);
    }
  };

  return (
    <div>
      {supportsApplePay ? (
        <button onClick={handleApplePay} id="MPGSApplePayButton" className="hidden"></button>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  validateMerchant: paymentTypeOperations.validateMPGSApplePayMerchant,
  makeApplePayPayment: paymentTypeOperations.makeMPGSApplePayPayment,
  userCancelledApplePayPayment: paymentTypeOperations.userCancelledMPGSApplePayPayment,
  updateError: errorOperations.updateError,
};

export default connect(null, mapDispatchToProps)(ApplePayButton);
