import React from "react";

export default function InfoIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7" clipPath="url(#clip0_2516_3211)">
        <path
          d="M6 0.1875C2.79007 0.1875 0.1875 2.79101 0.1875 6C0.1875 9.21087 2.79007 11.8125 6 11.8125C9.20993 11.8125 11.8125 9.21087 11.8125 6C11.8125 2.79101 9.20993 0.1875 6 0.1875ZM6 2.76562C6.54366 2.76562 6.98438 3.20634 6.98438 3.75C6.98438 4.29366 6.54366 4.73438 6 4.73438C5.45634 4.73438 5.01563 4.29366 5.01563 3.75C5.01563 3.20634 5.45634 2.76562 6 2.76562ZM7.3125 8.71875C7.3125 8.87407 7.18657 9 7.03125 9H4.96875C4.81343 9 4.6875 8.87407 4.6875 8.71875V8.15625C4.6875 8.00093 4.81343 7.875 4.96875 7.875H5.25V6.375H4.96875C4.81343 6.375 4.6875 6.24907 4.6875 6.09375V5.53125C4.6875 5.37593 4.81343 5.25 4.96875 5.25H6.46875C6.62407 5.25 6.75 5.37593 6.75 5.53125V7.875H7.03125C7.18657 7.875 7.3125 8.00093 7.3125 8.15625V8.71875Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2516_3211">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
