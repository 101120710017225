import React, { useState, useEffect } from "react";

interface ICcAvenueProps {
  CCAvenueFormData: any;
};

function CcAvenueForm(props: ICcAvenueProps) {
  const [actionURL, setActionURL] = useState("");
  const [encRequest, setEncRequest] = useState("");
  const [accessCode, setAccessCode] = useState("");

  useEffect(() => {
    if (props.CCAvenueFormData && Object.keys(props.CCAvenueFormData).length) {
      document.getElementById("ccavenueTransaction")?.submit();
    }
  }, [props.CCAvenueFormData])

  return (
    <form
      method="post"
      action={props.CCAvenueFormData.action_url}
      name="frmTransaction"
      id="ccavenueTransaction"
    >
      <input
        name="encRequest"
        type="hidden"
        defaultValue={props.CCAvenueFormData.encRequest}
      />
      <input
        name="access_code"
        type="hidden"
        value={props.CCAvenueFormData.access_code}
      />
    </form>
  );
}

export default CcAvenueForm;
