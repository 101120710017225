// Packages
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import OrderDetailsModal from "../../mobileComponents/orderDetailsModal";
import EmailInvoicePopup from "../../mobileComponents/emailInvoicePopup";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { errorOperations } from "../../state/features/error";
import { pageOperations } from "../../state/features/page";
import { cartOperations } from "../../state/features/cart";
import { loyaltyOperations } from "../../state/features/loyalty";
import { loaderOperations } from "../../state/features/loader";
import { invoiceOperations } from "../../state/features/invoice";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import { findItemsForReorder } from "../../helpers/cartFunctions";

interface IPastOrdersScreenProps {
  pastOrders: any;
  fetchPastOrdersConfigurations: any;
  updateError: any;
  currency: any;
  loyalty: any;
  decimalPrecision: any;
  categories: any;
  userEmail: string;
  fetchLoyalty: any;
  setCart: any;
  showLoader: any;
  sendInvoiceToRegisteredEmail: any;
}

function PastOrdersScreen(props: IPastOrdersScreenProps) {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [currentModalItem, setCurrentModalItem] = useState(null);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState(null as any);

  useEffect(() => {
    props.fetchPastOrdersConfigurations();
    props.fetchLoyalty();
  }, []);

  const handleReorder = (pastOrder: any) => {
    let itemsForReorder = findItemsForReorder(
      pastOrder, props.categories, someItemsNotInMenuError
    );
    if (itemsForReorder.length !== 0) {
      props.setCart({ items: itemsForReorder });
      localStorage.cartItems = JSON.stringify(itemsForReorder);
      props.showLoader()
      window.location.href = "/";
    }
  }

  const someItemsNotInMenuError = () => {
    props.updateError({
      title: <FormattedMessage id="error.oops" defaultMessage="Oops!" />,
      message: <FormattedMessage id="error.items_not_in_menu" defaultMessage="Some items are not available in the current menu" />,
    });
  }

  const showOrderDetailsModal = (pastOrder: any) => {
    setCurrentModalItem(pastOrder);
    setShowOrderDetails(true);
  }

  const handleCloseOrderDetailsModal = () => {
    setShowOrderDetails(false)
  }

  const currentTime = () => {
    return new Date().toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      year: "numeric"
    });
  };

  const onSendInvoiceEmailClick = (orderNumber: string) => {
    setSelectedOrderNumber(orderNumber);
    if (props.userEmail) {
      props.sendInvoiceToRegisteredEmail(orderNumber);
    } else {
      setShowEmailPopup(true);
    }
  };

  const hideEmailPopup = () => {
    setSelectedOrderNumber(null);
    setShowEmailPopup(false);
  }

  return (
    <React.Fragment>
      {showOrderDetails ? (
        <OrderDetailsModal
          pastOrderItem={currentModalItem}
          handleReorder={handleReorder}
          onClose={handleCloseOrderDetailsModal}
        />
      ) : (
        <div className="past-orders-screen">
          <div className="main-heading">
            <FormattedMessage
              id="my_account.past_orders"
              defaultMessage="Past Orders"
            />
          </div>
          {props.pastOrders.length && !showOrderDetails ? (
            <div className="past-orders-table">
              <div className="table-header">
                <p className="header-name">
                  <FormattedMessage
                    id="order.order_number"
                    defaultMessage="Order number"
                  />
                </p>
                <p className="header-name text-center">
                  <FormattedMessage
                    id="global.amount"
                    defaultMessage="Amount"
                  />
                </p>
                <p className="header-name text-center">
                  <FormattedMessage
                    id="my_account.points_earned"
                    defaultMessage="Points earned"
                  />
                </p>
                <p className="header-name empty-container"></p>
              </div>
              <div className="past-orders">
                {props.pastOrders.map((pastOrder: any, index: any) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="order-table-contents">
                        <div className="order">
                        <p
                          className="order-number"
                          onClick={() => showOrderDetailsModal(pastOrder)}
                        >
                          {pastOrder.order_number}
                        </p>
                        <p className="order-amount">
                          {props.currency}{" "}
                          {formatDecimal(pastOrder.order_details.total_amount)}
                        </p>
                        <p className="points-earned">
                          {formatDecimal(
                            pastOrder.order_details.earned_loyalty || 0
                          )}
                        </p>
                        <button
                          className="btn reorder"
                          onClick={() => handleReorder(pastOrder)}
                        >
                          <FormattedMessage
                            id="my_account.re_order"
                            defaultMessage="Re-Order"
                          />
                        </button>
                        <div className="dropdown">
                          <div
                            type="button"
                            id="invoiceDropDownButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="dropdown-icon"
                          >
                            <FontAwesomeIcon
                              className="menu-icon"
                              icon={faEllipsisV}
                            />
                          </div>
                          <div className="dropdown-menu dropdown-menu-right invoice-dropdown" aria-labelledby="invoiceDropDownButton">
                            <div className="arrow"></div>
                            <Link to={`/invoices/${pastOrder.order_number}.pdf?download=true`} target="_blank" className="dropdown-item invoice-dropdown-item">
                              <FormattedMessage
                                id="my_account.invoice.download"
                                defaultMessage="Download Invoice"
                              />
                            </Link>
                            <button
                              className="dropdown-item invoice-dropdown-item"
                              onClick={() => onSendInvoiceEmailClick(pastOrder.order_number)}
                            >
                              <FormattedMessage
                                id="my_account.invoice.send_via_email"
                                defaultMessage="Send Invoice via Email"
                              />
                            </button>
                          </div>
                        </div>
                        </div>

                      
                      </div>
                      {showEmailPopup && selectedOrderNumber == pastOrder.order_number ? (
                        <EmailInvoicePopup
                          orderNumber={pastOrder.order_number}
                          onClose={hideEmailPopup}
                        />
                      ) : false}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="no-past-order text-center">
              <FormattedMessage
                id="my_account.no_past_orders"
                defaultMessage="No past orders!"
              />
            </div>
          )}
          <div className="loyalty-card">
            <div className="loyalty-points">
              <FormattedMessage
                id="loyalty.points"
                defaultMessage="<pointsTag>{points}</pointsTag><labelTag>loyalty {currency} points</labelTag>"
                values={{
                  points: formatDecimal(props.loyalty.customer_loyalty || 0),
                  currency: props.currency,
                  pointsTag: (text) => <p className="point">{text}</p>,
                  labelTag: (text) => <p className="label">{text}</p>,
                }}
              />
            </div>
            <p className="updated">
              <FormattedMessage
                id="loyalty.updated_as_of"
                defaultMessage="Updated as of {timeStamp}"
                values={{
                  timeStamp: currentTime(),
                }}
              />
            </p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
const mapStateToProps = (state: any) => {
  let pastOrders = state.pastOrders;
  let currency = state.company.currency;
  let loyalty = state.loyalty;
  let decimalPrecision = state.company.decimal_precision;
  let categories = state.menu.categories;
  let userEmail = state.user.email;

  return {
    pastOrders,
    currency,
    loyalty,
    decimalPrecision,
    categories,
    userEmail,
  };
};

const mapDispatchToProps = {
  fetchPastOrdersConfigurations: pageOperations.fetchPastOrdersConfigurations,
  updateError: errorOperations.updateError,
  fetchLoyalty: loyaltyOperations.fetchLoyalty,
  setCart: cartOperations.setCart,
  showLoader: loaderOperations.showLoader,
  sendInvoiceToRegisteredEmail: invoiceOperations.sendInvoiceToRegisteredEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(PastOrdersScreen);
