// Packages
import React, { useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Redirect } from "react-router-dom";
import Pusher from "pusher-js";
import { useIntl, FormattedMessage } from 'react-intl';
// Redux Operations
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";
import { loaderOperations } from "../../../../state/features/loader";
// Contexts
import { EnvContext } from "../../../envContext";
// Redux Operations
import { RootState } from "../../../../state/store";

interface IStripeConnectCheckoutFormProps {
  orderPayload: any;
  togglePlaceOrderButton: (status: boolean) => void;
  initiateStripeConnectTransaction: () => void;
  fetchStripeConnectPaymentIntent: () => void;
  hideLoader: () => void;
}

function StripeConnectCheckoutForm(props: IStripeConnectCheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  // Pusher ID
  const { pusherId } = useContext(EnvContext);
  // Redux data
  const { user } = useSelector((state: RootState) => state);
  // Translation handler
  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [returnURL, setReturnURL] = useState("");

  const paymentElementOptions = {
    // Add options here
    // Refer documentation: https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options
  };

  useEffect(() => {
    if (clientSecret && returnURL) {
      // Confirm Payment
      confirmPayment();
    }

    return () => {};
  }, [clientSecret, returnURL]);

  const confirmPayment = async () => {
    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnURL,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error.message);
    setPaymentReference("");
    setClientSecret("");
    setReturnURL("");
    props.togglePlaceOrderButton(true);
    props.hideLoader();
  }

  const initiateTransaction = () => {
    const pusher = new Pusher(pusherId, {});
    const currentTime = new Date();
    const paymentReference = `${user.id}${user.company_id}${currentTime.getTime()}`;
    const pusherChannelName = `private_stripe_connect_transaction_${paymentReference}`;
    const channel = pusher.subscribe(pusherChannelName);

    if (channel) {
      // Polls every few seconds if pusher message is not received
      var paymentIntentPeriodicCheckHandler = setInterval(function(){
        props.fetchStripeConnectPaymentIntent(paymentReference).then((response: any) => {
          if (!response.error) {
            if (response.payload.data?.client_secret && response.payload.data.reference_id == paymentReference) {
              setClientSecret(response.payload.data.client_secret);
              setReturnURL(response.payload.data.return_url);
            }
          } else {
            channel.unbind();
            clearInterval(paymentIntentPeriodicCheckHandler);
            clearInterval(paymentIntentTimeoutHandler);

            handleError({
              message: intl.formatMessage({ id: "error.oops_something_went_wrong", defaultMessage: "Oops! Something went wrong." })
            });
          }
        });
      }, 20000);

      // Stops polling and pusher after the timeout period is exceeded
      var paymentIntentTimeoutHandler = setInterval(function(){
        channel.unbind();
        clearInterval(paymentIntentPeriodicCheckHandler);
        clearInterval(paymentIntentTimeoutHandler);

        handleError({
          message: intl.formatMessage({ id: "error.oops_something_went_wrong", defaultMessage: "Oops! Something went wrong." })
        });
      }, 125000);

      channel.bind("payment_intent_created", (data: any) => {
        clearInterval(paymentIntentPeriodicCheckHandler);
        clearInterval(paymentIntentTimeoutHandler);

        if (data.client_secret && data.reference_id == paymentReference) {
          setClientSecret(data.client_secret);
          setReturnURL(data.return_url);
        }
      });

      channel.bind("payment_intent_failed", (data: any) => {
        clearInterval(paymentIntentPeriodicCheckHandler);
        clearInterval(paymentIntentTimeoutHandler);

        handleError(data.error);
      });

      let orderPayload = props.orderPayload;
      orderPayload.payment.payment_mode = paymentMode;
      // orderPayload.payment.payment_method_types = [];

      props.initiateStripeConnectTransaction(orderPayload, paymentReference).then((response: any) => {
        if (response.error) {
          clearInterval(paymentIntentPeriodicCheckHandler);
          clearInterval(paymentIntentTimeoutHandler);
          pusher.unsubscribe(pusherChannelName);
          handleError(response.error);
        }
      });
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Reset Error message
    setErrorMessage("");

    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      props.togglePlaceOrderButton(true);
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    await initiateTransaction();
  };

  const onPaymentElementChange = (event: any) => {
    if (event.value) {
      setPaymentMode(event.value.type);
      setErrorMessage("");
      props.togglePlaceOrderButton(true);
    }
  }

  const onPaymentElementsLoaderStart = () => {
    props.togglePlaceOrderButton(false);
  }

  const onPaymentElementsReady = () => {
    props.togglePlaceOrderButton(true);
  }

  return (
    <form onSubmit={handleSubmit} className="mt-3 mb-3 stripe-connect-new-card-form">
      <PaymentElement
        onChange={onPaymentElementChange}
        options={paymentElementOptions}
        onLoaderStart={onPaymentElementsLoaderStart}
        onReady={onPaymentElementsReady}
      />
      <button
        type="submit"
        id="stripeConnectPayButton"
        className="hidden"
        disabled={!stripe || loading}
      >
        <FormattedMessage
          id="global.pay"
          defaultMessage="Pay"
        />
      </button>
      {errorMessage && <div className="error">{errorMessage}</div>}
    </form>
  );
}

const mapDispatchToProps = {
  initiateStripeConnectTransaction: paymentTypeOperations.initiateStripeConnectTransaction,
  fetchStripeConnectPaymentIntent: paymentTypeOperations.fetchStripeConnectPaymentIntent,
  hideLoader: loaderOperations.hideLoader,
};

export default connect(null, mapDispatchToProps)(StripeConnectCheckoutForm);
