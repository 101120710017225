import actions from "./actions";

const fetchLoyalty = actions.fetchLoyalty;
const redeemLoyalty = actions.redeemLoyalty;
const removeLoyalty = actions.removeLoyalty;

export default {
  fetchLoyalty,
  redeemLoyalty,
  removeLoyalty,
};
