import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import { RootState } from "../../state/store";

interface ITipsWidgetProps {
  setTipAmount: () => void;
}

const TipsWidget = (props: ITipsWidgetProps) => {
  const [tipValues, setTipValues] = useState([] as any);
  const [selectedTip, setSelectedTip] = useState({} as any);
  const [customTipValue, setCustomTipValue] = useState("" as any);
  const [showCustomTipContainer, setShowCustomTipContainer] = useState(false);

  const { currency } = useSelector((state: RootState) => state.company);
  const { subTotal } = useSelector((state: RootState) => state.cart);
  const { tips } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (Object.keys(tips).length > 0) {
      let tipsArray = tips.values.map((tip: any, index: number) => {  
          return { type: tips.type, value: tip };
        })
        setTipValues(tipsArray);
    }
  }, [tips]);

  useEffect(() => {
    if(props.removeTip) {
      setSelectedTip({});
      setShowCustomTipContainer(false);
    }
  },[props.removeTip]);

  useEffect(() => {
    if (Object.keys(selectedTip).length > 0) {
      if (selectedTip.type === "percentage") {
        props.setTipAmount(formatDecimal(selectedTip.value / 100 * subTotal));
      } else {
        props.setTipAmount(formatDecimal(selectedTip.value));
      } 
    }
   },[selectedTip]);

  const setCustomTip = (e: any) => {
    let regExp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (!e.target.value) {
      setCustomTipValue("");
      return;
    } else if (!regExp.test(e.target.value)) {
      return;
    }
    setCustomTipValue(e.target.value);
  };

  const addCustomTip = () => {
    if (customTipValue) {
      let tipValue = customTipValue;
      setSelectedTip({
        type: "value",
        value: parseFloat(formatDecimal(tipValue)),
      });
      props.setTipAmount(formatDecimal(customTipValue));
      setShowCustomTipContainer(false)
    }
  }

  const removeCustomTip = () => {
    setCustomTipValue("");
    setSelectedTip({});
    props.setTipAmount(formatDecimal(0));
    setShowCustomTipContainer(false);
  }

  const toggleTip = (newTip: any) => {
    if (selectedTip == newTip) {
      setSelectedTip({});
      props.setTipAmount(formatDecimal(0));
    } else {
      setSelectedTip(newTip);
    }
  }

  const renderTipsTiles = (tip: any) => {
    if (tip.type === "percentage") {
      return (
        <div>
          <p>{tip.value + "%"}</p>
          <p>
            {formatDecimal((tip.value / 100) * formatDecimal(subTotal))}
          </p>
        </div>
      );
    } else {
        return (
          <div>
            <p></p>
            <p>
              {tip.value + ' ' + currency}
            </p>
          </div>
        );
    }
  }

  return (
    <div className="tip-section">
      <div className="section-heading-tip px-1 pt-2">Add Tip</div>
      {showCustomTipContainer ? (
        <div className="custom-tip-container">
          <p className="custom-tip-title">Enter Custom Amount</p>
          <div className="input-section-wrapper">
            <p className="currency">{currency}</p>
            <input
              type="text"
              value={customTipValue}
              className="custom-tip-input"
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCustomTip(e)
              }
            />
            <button className="btn-submit-tip" onClick={addCustomTip}>
              Apply
            </button>
          </div>
          <p className="remove-tip-label" onClick={removeCustomTip}>
            Remove Tip
          </p>
        </div>
      ) : (
        <div className="tip-container">
          <div className="tip-tiles-container">
            {tipValues.map((tip: any, index: number) => {
              return (
                <div
                  className={
                    selectedTip === tip ? "tip-tile selected" : "tip-tile"
                  }
                  key={index}
                  onClick={() => toggleTip(tip)}>
                  {renderTipsTiles(tip)}
                </div>
              );
            })}
            <div
              className={`tip-tile custom-tip ${
                selectedTip.type === "value" ? "selected" : ""
              }`}
              onClick={() => setShowCustomTipContainer(true)}>
              Custom Amount
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TipsWidget;
