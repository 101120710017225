import {
  faCreditCard,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCcVisa,
  faCcMastercard,
  faCcJcb,
  faCcDiscover,
  faCcAmex,
  faCcDinersClub,
} from "@fortawesome/free-brands-svg-icons";

const creditCardIcon = (scheme: any) => {
  switch (scheme.toLowerCase()) {
    case "visa":
      return faCcVisa;
    case "mastercard":
      return faCcMastercard;
    case "amex":
    case "american express":
      return faCcAmex;
    case "discover":
      return faCcDiscover;
    case "jcb":
      return faCcJcb;
    case "diners club":
      return faCcDinersClub;
    default:
      return faCreditCard
  }
}

export {
  creditCardIcon,
};
