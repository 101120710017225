// Packages
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ProgressBar, Dropdown } from "react-bootstrap";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import Header from "../../components/header";
import OrderPlacedPopup from "../../components/orderPlacedPopup";
import DeliveryAreaMap from "../../components/deliveryAreaMap";
import NewAddressWidget from "../../components/newAddressWidget";
import Discount from "../../components/discount";
import LoyaltyWidget from "../../components/loyaltyWidget";
import CcAvenueForm from "../../components/ccAvenueForm";
import QpayForm from "../../components/qpayForm";
import QpayCardSectionWrapper from "../../components/qpayCardSectionWrapper";
import IPay88Form from "../../components/iPay88Form";
import FiservForm from "../../components/fiservForm";
import InlineLoader from "../../components/inlineLoader";
import CartItemsWidget from "../../components/cartItemsWidget";
import ScheduleOrdersPopup from "../../components/scheduleOrdersPopup";
import SelectInput from "../../components/selectInput";
import CardSectionWrapper from "../../components/cardSectionWrapper";
import OmiseCardSectionWrapper from "../../components/omiseCardSectionWrapper";
import AdyenSectionWrapper from "../../components/adyenSectionWrapper";
import TipsWidget from "../../components/tipsWidget";
import StripeConnectWrapper from "../../components/paymentTypes/stripeConnect/wrapper";
import MastercardDirectPaymentWrapper from "../../components/paymentTypes/mastercardDirectPayment/wrapper";
import MasterCardSectionWrapper from "../../components/masterCardSectionWrapper";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronCircleRight,
  faMotorcycle,
  faMapMarker,
  faCheck,
  faCaretDown,
  faMoneyBillWave,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import NoImageAvailableIcon from "../../images/NoImageAvailableIcon.png";
import SampleMap from "../../images/map.png";
// Redux Operations
import { cartOperations } from "../../state/features/cart";
import { pageOperations } from "../../state/features/page";
import { errorOperations } from "../../state/features/error";
import { addressesOperations } from "../../state/features/addresses";
import { orderInProgressOperations } from "../../state/features/orderInProgress";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import { areasOperations } from "../../state/features/areas";
// Helpers, Utils etc.
import {
  calculateAmountsAndBreakdowns,
  formatDecimal,
} from "../../helpers/itemCalculations";
import {
  orderPayload,
} from "../../helpers/orderPayloadBuilder";
import { outOfStockItemsList, validateCartItems } from "../../helpers/cartFunctions";
import { obtainEtaDisplayText } from "../../helpers/utils";
import {
  EMAIL_REGEX,
  NAME_REGEX,
  PAYMENT_PAID,
  MASTERCARD_PAYMENT_TYPE,
} from "../../helpers/constants";
import PaymentInProgressBanner from "../../mobileComponents/paymentInProgressBanner";
import GenericPaymentTypeWrapper from "../../components/genericPaymentTypeWrapper";
import CheckoutScreenProvider from "../../providers/desktop/checkoutScreenProvider";
import { CheckoutScreenContext } from "../../contexts/checkoutScreenContext";
import CheckoutCtaButton from "./ctaButton";
import CheckoutPopups from "./popups";

interface ICheckoutProps {
  company: any;
  outlet: any;
  menu: any;
  session: any;
  cart: any;
  paymentTypes: any;
  orderType: any;
  discount: any;
  loyalty: any;
  addresses: any;
  user: any;
  loyaltyConfig: any;
  hasLoyalty: boolean;
  storeOpen: boolean;
  location: any;
  selectedArea: any;
  tipsEnabled: boolean;
  zoneMapEnabled: boolean;
  setCart: any;
  fetchCheckoutConfigurations: any;
  placeOrder: any;
  updateError: any;
  fetchAddresses: any;
  modesOfPickup: any;
  pickupModeFromSession: any;
  buildCCAvenuePaymentData: any;
  buildFiservPaymentData: any;
  fetchMyFatoorahPaymentModes: any;
  buildQpayPaymentData: any;
  setArea: any;
  buildIPay88PaymentData: any;
  recentOrder: any;
  buildGenericPaymentsPayload: any;
}

interface ICheckoutState {
  steps: any;
  activeStepId: number;
  progress: number;
  showPlaceOrderPopup: boolean;
  paymentTypes: any;
  selectedAddress: any;
  orderNotes: string;
  selectedPaymentType: any;
  selectedPaymentTypeTitle: any;
  addressLoading: any;
  showAddAddress: boolean;
  showScheduleOrderPopup: boolean;
  minimumEtaMinutes: any;
  hasLeadTime: boolean;
  deliveryEta: any;
  displayEta: string;
  scheduledTime: any;
  defaultDeliveryTime: any;
  stripeOrderPayload: any;
  stripeSelectedCard: any;
  pickupMode: string;
  metaNotes: string;
  earnedLoyalty: any;
  CCAvenueFormData: any;
  fiservFormData: any;
  qpayFormData: any;
  paymentDetails: any;
  userName: string;
  userEmail: string;
  myFatoorahPaymentModes: any;
  myFatoorahPaymentModesLoading: any;
  myFatoorahSelectedPaymentMode: any;
  IPay88FormData: any;
  placeOrderEnabled: boolean;
  cardToken: any;
  tipAmount: any;
  showTips: boolean;
  usableAddresses: any;
  outOfStockItems: any;
  genericPaymentTypeSelected: boolean;
}

const SCHEDULE_BUFFER_TIME_IN_MINS = 15;

class Checkout extends Component<ICheckoutProps, ICheckoutState> {
  static contextType?: React.Context<any> | undefined = CheckoutScreenContext;
  constructor(props: ICheckoutProps) {
    super(props);
    this.state = {
      steps: [
        { id: 1, title: "Delivery Location", active: true, completed: false, translationId: "checkout.delivery_location" },
        { id: 2, title: "Payment", active: false, completed: false, translationId: "global.payment" },
      ],
      activeStepId: 1,
      progress: 0,
      showPlaceOrderPopup: false,
      paymentTypes: [],
      orderNotes: "",
      selectedPaymentType: "",
      selectedPaymentTypeTitle: "",
      addressLoading: true,
      showAddAddress: false,
      selectedAddress: {},
      showScheduleOrderPopup: false,
      minimumEtaMinutes: 0,
      hasLeadTime: false,
      deliveryEta: new Date(),
      displayEta: "",
      scheduledTime: null,
      defaultDeliveryTime: null,
      stripeOrderPayload: {},
      stripeSelectedCard: {},
      pickupMode: "",
      metaNotes: "",
      earnedLoyalty: null,
      CCAvenueFormData: {},
      fiservFormData: {},
      qpayFormData: {},
      paymentDetails: {},
      userName: "",
      userEmail: "",
      myFatoorahPaymentModes: [],
      myFatoorahPaymentModesLoading: true,
      myFatoorahSelectedPaymentMode: "",
      IPay88FormData: {},
      placeOrderEnabled: true,
      cardToken: null,
      tipAmount: null,
      showTips: false,
      outOfStockItems: [],
      genericPaymentTypeSelected: false
    };

    this.props.fetchCheckoutConfigurations().then((response: any) => {
      if (response.error && response.error.response.status == 401) {
        this.props.updateError({
          title: "Oops!",
          message: response.error.response.data.error,
          redirectionUrl: "/",
        });
      } else {
        this.findDeliveryArea();
        this.updateItemsInCart(
          validateCartItems(
            this.props.menu.categories,
            JSON.parse(localStorage.cartItems || "[]"),
            this.itemsNotInMenuError
          )
        );
        localStorage.precision = this.props.company.decimal_precision;
        if (this.props.orderType == "pickup") {
          this.findLocationAddress();
        } else {
          this.fetchAddresses();
        }
        this.findMinimumEtaAndLeadTime();
        this.findSelectedModeOfPickup();
      }
    });
  }

  componentDidMount() {
    this.getProgressStatus(this.state.activeStepId);
    this.verifyPaymentStatus();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.paymentTypes !== this.props.paymentTypes) {
      this.setState(
        { paymentTypes: this.props.paymentTypes },
        () => this.handlePaymentType(this.props.paymentTypes[0])
      )
    }
    if (this.props.session.showAreaSelect !== prevProps.session.showAreaSelect) {
      this.verifyCurrentSession();
    }

    if (this.state.tipAmount !== prevState.tipAmount) {
      this.updateItemsInCart(this.props.cart.items);
    }

    if (
      this.state.selectedPaymentType !== prevState.selectedPaymentType ||
      this.props.tipsEnabled !== prevProps.tipsEnabled ||
      this.props.orderType !== prevProps.orderType
    ) {
      if (
        this.props.tipsEnabled &&
        !["cash_on_delivery", "card_on_delivery"].includes(
          this.state.selectedPaymentType
        ) &&
        this.props.orderType === "delivery"
      ) {
        this.setState({ showTips: true });
      } else {
        this.setState({ showTips: false, tipAmount: null, });
      }
    }

    if (
      JSON.stringify(prevProps.cart.items) !=
        JSON.stringify(this.props.cart.items) ||
      JSON.stringify(prevProps.menu.out_of_stock_items) !=
        JSON.stringify(this.props.menu.out_of_stock_items)
    ) {
      this.setState({
        outOfStockItems: outOfStockItemsList(
          this.props.cart.items,
          this.props.menu.out_of_stock_items
        ),
      });
    }
  }

  userDetailsForGenericPayments() {
    return {
      id: this.props.user.sapaad_customer_id,
      name: this.props.user.name,
      email: this.props.user.email,
      phone: this.props.user.phone_number
    }
  }

  paymentDetailsForGenericPayments() {
    const paymentType = this.props.paymentTypes.find((i: any) => i.payment_type == this.state.selectedPaymentType)
    return {
      id: paymentType.sapaad_payment_type_id,
      name: paymentType.partner_name,
      type: paymentType.payment_type
    }
  }

  verifyCurrentSession() {
    if (this.props.session.showAreaSelect) {
      this.props.updateError({
        titleCode: "error.oops",
        messageCode: "error.unable_to_process_order",
        redirectionUrl: "/",
      });
    }
  }

  updateItemsInCart(cartItems: any) {
    let cart = calculateAmountsAndBreakdowns(
      cartItems,
      this.props.menu,
      this.props.orderType,
      null,
      null,
      this.props.selectedArea,
      this.props.outlet.delivery_charge_id,
      this.state.tipAmount,
    ) as any;
    cart.items = cartItems;

    this.props.setCart(cart);
    if (!cartItems?.length) {
      this.props.updateError({
        title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
        message: this.props.intl.formatMessage({ id: "error.cart_is_empty", defaultMessage: "Your cart is empty! Please add items from the menu." }),
        redirectionUrl: "/",
      });
    }
  }

  itemsNotInMenuError = () => {
    this.props.updateError({
      title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
      message: this.props.intl.formatMessage({ id: "error.items_in_cart_not_in_menu", defaultMessage: "Items in cart are not part of current available menu." }),
    });
  }

  getProgressStatus(activeStep: number) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let totalSteps = this.state.steps.length;
    let progress = 100 / (totalSteps - activeStep + 1);
    this.setState({ progress }, () => {
      this.updateSteps(this.state.activeStepId);
    });
  }

  verifyPaymentStatus() {
    let urlParams = new URLSearchParams(this.props.location.search);
    let paymentStatus = urlParams.get("payment_status");
    let message = urlParams.get("message");

    if (paymentStatus == "failed") {
      this.setState({
        paymentDetails: {
          status: paymentStatus,
          message: message
        }
      });
    }
  }

  findDeliveryArea() {
    let area;
    if (this.props.orderType == "pickup") {
      area = {
        name: this.props.outlet.name,
        address: this.props.outlet.address,
      };
    } else {
      area = this.props.outlet.delivery_areas.find((area: any) => {
        return area.id == this.props.session.area_id;
      });
      if (this.props.zoneMapEnabled && this.props.session.area_name) {
        area.name = this.props.session.area_name;
      }
    }
    this.props.setArea(area);
  }

  findLocationAddress() {
    let selectedAddress = {
      lines: [{ id: 1, value: this.props.selectedArea?.address }],
    };
    this.setState({ selectedAddress });
  }

  updateSteps(stepId: number) {
    let steps = this.state.steps;
    let currentStep = steps[stepId - 1];
    let lastStep = steps[stepId - 2];
    let nextStep = steps[stepId];
    currentStep.active = true;
    currentStep.completed = false;
    steps[stepId - 1] = currentStep;
    if (lastStep !== undefined) {
      lastStep.completed = true;
      lastStep.active = false;
      steps[stepId - 2] = lastStep;
    }
    if (nextStep !== undefined) {
      nextStep.active = false;
      nextStep.completed = false;
      steps[stepId] = nextStep;
    }
    this.setState({ steps });
  }

  onBackClick = () => {
    window.location.href = "/";
  };

  onStepBack() {
    if (this.state.activeStepId === 1) {
      window.location.href = "/";
    } else {
      this.setState({ activeStepId: this.state.activeStepId - 1 }, () =>
        this.getProgressStatus(this.state.activeStepId)
      );
      this.setState({ placeOrderEnabled: true });
    }
  }

  onStepNext() {
    if (!this.props.user.name && !NAME_REGEX.test(this.state.userName)) {
      return this.reportUserNameError();
    }
    if (this.state.activeStepId === 2) {
      if (this.paymentRequirementsFulfilled()) {
        this.handlePaymentDetailsConfirm();
      } else {
        this.reportPaymentRequirementsError();
      }
    } else {
      if (
        (this.props.orderType == "delivery" &&
        this.props.addresses.length === 0) || this.state.showAddAddress
      ) {
        this.props.updateError({
          title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
          message: this.props.intl.formatMessage({ id: "error.save_location_before_proceeding", defaultMessage: "Please save your location details before proceeding." }),
        });
      } else if (
        this.props.orderType == "delivery" &&
        !this.state.selectedAddress.id
      ) {
        this.props.updateError({
          title: this.props.intl.formatMessage({ id: "error.oops", defaultMessage: "Oops!" }),
          message: this.props.intl.formatMessage({ id: "error.select_an_address", defaultMessage: "Please select an address to continue" }),
        });
      } else {
        this.setState({ activeStepId: this.state.activeStepId + 1 }, () =>
          this.getProgressStatus(this.state.activeStepId)
        );
      }
    }
  }

  reportUserNameError() {
    let nameInput = document.getElementById("user_name");
    let containerPosition = nameInput?.closest(".container")?.offsetTop || 0;
    let headerHeight = document.getElementById("top-bar")?.clientHeight || 0;
    window.scrollTo({ top: containerPosition - headerHeight });
    this.updateInputValidationMessage("user_name", this.props.intl.formatMessage({ id: "validation.user_name", defaultMessage: "Please enter a valid name." }));
    nameInput.reportValidity();
  }

  reportPaymentRequirementsError() {
    if (!EMAIL_REGEX.test(this.state.userEmail)) {
      let emailInput = document.getElementById("user_email");
      let containerPosition = emailInput?.closest(".payment-methods")?.offsetTop || 0;
      let headerHeight = document.getElementById("top-bar")?.clientHeight || 0;
      window.scrollTo({ top: containerPosition - headerHeight });
      this.updateInputValidationMessage("user_email", this.props.intl.formatMessage({ id: "validation.email_address", defaultMessage: "Please enter a valid email address." }));
      emailInput.reportValidity();
    }
  }

  updateInputValidationMessage(inputFieldID: string, message: string) {
    document.getElementById(inputFieldID)?.setCustomValidity(message);
  }

  paymentRequirementsFulfilled() {
    if (["qpay", "pay_u", "i_pay88"].includes(this.state.selectedPaymentType)) {
      return this.props.user.email || EMAIL_REGEX.test(this.state.userEmail);
    }
    return true;
  }

  showPointsEarnedPopup(earnedLoyalty: any) {
    this.setState({
      showPlaceOrderPopup: true,
      earnedLoyalty: earnedLoyalty,
    });
  }

  handleStripeCardSelection(stripeCard: any) {
    this.setState({ stripeSelectedCard: stripeCard });
  }

  handlePaymentDetailsConfirm() {
    this.togglePlaceOrderButton(false);
    let payload = orderPayload(this.state, this.props);
    let placeOrderPath;
    if (this.state.genericPaymentTypeSelected) {
      var genericCheckoutButton = document.getElementById("genericPaymentsCheckoutButton");
      genericCheckoutButton?.click();
      return;
    }
    switch (this.state.selectedPaymentType) {
      case "cash_on_delivery":
        placeOrderPath = "orders/cash_on_delivery";
        break;
      case "card_on_delivery":
        placeOrderPath = "orders/card_on_delivery";
        break;
      case "stripe":
        payload.payment.payment_status = PAYMENT_PAID;
        payload.payment.save_card = false;
        payload.payment.is_saved_card = false;
        payload.payment.transaction_id = null;
        payload.payment.ref_card_number = null;
        payload.payment.total_amount = 0;

        let new_card_form = document.getElementById("btn_card_section");
        if (new_card_form != null) {
          // payment with new card
          this.setState(
            { stripeOrderPayload: payload },
            () => new_card_form?.click()
          );
          return;
        } else {
          // payment with already saved card
          payload.payment.save_card = false;
          payload.payment.is_saved_card = true;
          payload.payment.transaction_id = this.state.stripeSelectedCard.id;
          payload.payment.ref_card_number = this.state.stripeSelectedCard.id;
          payload.payment.total_amount = payload.total_amount
          placeOrderPath = "payments/stripe/v1/orders";
        }
        break;
      case "paytabs":
        placeOrderPath = "payments/paytabs/v2.1/payment-page";
        break;
      case "pay_u":
        placeOrderPath = "payments/pay_u/v1/payment_pages";
        break;
      case "my_fatoorah":
        placeOrderPath = "payments/my_fatoorah/v1/payment_pages";
        payload.myFatoorahSelectedPaymentMode = this.state.myFatoorahSelectedPaymentMode
        break;
      case "cc_avenue":
        this.props.buildCCAvenuePaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ CCAvenueFormData: response.payload.data });
          }
        });
        return;
      case "dpo":
        placeOrderPath = "payments/dpo/v1/payment_pages";
        break;
      case "qpay":
        payload.cardToken = this.state.cardToken;
        this.props.buildQpayPaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ qpayFormData: response.payload.data });
          }
        });
        return;
      case "i_pay88":
        this.props.buildIPay88PaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ IPay88FormData: response.payload.data });
          }
        });
        return;
      case "fiserv":
        this.props.buildFiservPaymentData(payload).then((response: any) => {
          if (response.error) {
            this.handleOutofStockError(response);
          } else {
            this.setState({ fiservFormData: response.payload.data });
          }
        });
        return;
      case "omise":
        var btnOmise = document.getElementById("omiseCheckoutButton");
        btnOmise?.click();
        return;
      case "adyen":
        var adyenBtn = document.getElementsByClassName('adyen-checkout__button--pay')[0];
        adyenBtn?.click();
        return;
      case "stripe_connect":
        var stripeConnectBtn = document.getElementById("stripeConnectPayButton");
        stripeConnectBtn?.click();
        return;    
      case MASTERCARD_PAYMENT_TYPE:
        let mastercardCheckoutBtn =
          document.getElementById("MPGSCardCheckoutButton") ||
          document.getElementById("MPGSApplePayButton") ||
          document.getElementById("mastercardCheckoutButton");
        mastercardCheckoutBtn?.click();
        return;
    }

    this.props.placeOrder(placeOrderPath, payload).then((response: any) => {
      if (!response.error) {
        if (response.payload.data.payment_page_url) {
          window.location.href = response.payload.data.payment_page_url;
        } else {
          let earnedLoyalty = null;
          localStorage.cartItems = JSON.stringify([]);
          this.props.setCart({ items: [], subTotal: 0, total: 0 });
          if (response.payload.data.order_details.earned_loyalty) {
            earnedLoyalty = response.payload.data.order_details.earned_loyalty;
          }
          this.showPointsEarnedPopup(earnedLoyalty);
        }
      }
      this.togglePlaceOrderButton(true);
    });
  }

  handleOutofStockError(response: any) {
    if (response.error) {
      let error_id = response?.error?.response?.data?.id;
      if (error_id && error_id === "out_of_stock") {
        this.props.updateError({
          subTitleCode: "error.out_of_stock",
          messageCode: "error.out_of_stock",
          redirectionUrl: "/",
        });
      }
    }
  }

  handlePlaceOrderPopupClose() {
    this.setState({ showPlaceOrderPopup: false });
  }

  handleOrderNotes(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.setState({ orderNotes: e.target.value });
  }

  handleMyFatoorahPaymentModes(paymentType: any) {
    this.props.fetchMyFatoorahPaymentModes(this.props.cart.total).then((response: any) => {
      if (!response.error) {
        this.setState({
          myFatoorahPaymentModes: response.payload.data,
          myFatoorahPaymentModesLoading: false,
          myFatoorahSelectedPaymentMode: response.payload.data[0]?.id,
        });
      }
    })
  }

  handlePaymentType(paymentType: any) {
    this.setState({
      selectedPaymentType: paymentType.payment_type,
      selectedPaymentTypeTitle: paymentType.title,
    });

    if (paymentType.generic_integration) {
      this.setState({ genericPaymentTypeSelected: true })
    } else {
      this.setState({ genericPaymentTypeSelected: false })
    }

    if (paymentType.payment_type == "my_fatoorah" && this.state.myFatoorahSelectedPaymentMode == "") {
      this.handleMyFatoorahPaymentModes(paymentType);
    };
  }

  handleAddAddress() {
    this.setState({ showAddAddress: true });
  }

  cancelAddAddress() {
    this.setState({ showAddAddress: false });
  }

  fetchAddresses() {
    this.setState(
      {
        showAddAddress: false,
        addressLoading: true,
      },
      () => {
        this.props.fetchAddresses().then(() => {
          let addresses = this.props.addresses;
          let usableAddresses = [] as any;
          if (addresses.length && this.props.selectedArea.id) {
            usableAddresses = addresses.filter((address: any) => {
              return (
                address.sapaad_delivery_area_id == this.props.selectedArea.id
              );
            });
            if (usableAddresses.length) {
              this.selectAddress(usableAddresses[0], true);
            }
          }
          this.setState({
            addressLoading: false,
            usableAddresses
          });
        });
      }
    );
  }

  selectAddress(address: any, addressUsable: boolean) {
    if (!addressUsable) {
      return;
    }
    let selectedAddress = {
      id: address.address_id,
      lines: address.delivery_addresses,
      area_id: address.sapaad_delivery_area_id,
      outlet_id: address.sapaad_location_id,
      latitude: address.latitude,
      longitude: address.longitude,
    };
    this.setState({ selectedAddress: selectedAddress });
  }

  onScheduleOrderClick() {
    this.setState({ showScheduleOrderPopup: true });
  }

  handleScheduleOrderClose() {
    this.setState({ showScheduleOrderPopup: false });
  }

  findMinimumEtaAndLeadTime() {
    let minimumEtaMinutes;
    let leadTime = Number(this.props.company.lead_time || 0);
    let defaultEta = Number(this.props.company.default_eta);
    let hasLeadTime = leadTime > 0;

    // Minimum time in minutes to deliver order is
    // Lead time + Default ETA (ie. Preparation + Delivery Time)
    if (hasLeadTime) {
      minimumEtaMinutes = leadTime + defaultEta;
    } else {
      minimumEtaMinutes = defaultEta;
    }
    this.setState({ minimumEtaMinutes, hasLeadTime }, () =>
      this.findExpectedDeliveryTime(null, null, true)
    );
  }

  findExpectedDeliveryTime(
    expectedDate: any,
    expectedTime: any,
    isDefault: boolean
  ) {
    let expectedDeliveryTime, displayEta;
    let minimumEtaMinutes = this.state.minimumEtaMinutes;
    let hasLeadTime = this.state.hasLeadTime;
    let defaultEta = Number(this.props.company.default_eta);

    let currentTime = new Date();
    let currentDateString = currentTime.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
    let expectedMinutes = Number(currentTime.getMinutes()) + minimumEtaMinutes;
    currentTime.setMinutes(expectedMinutes);

    if (isDefault) {
      expectedDeliveryTime = currentTime;
      this.setState({ defaultDeliveryTime: currentTime });
    } else {
      let convertedTime = this.convertTo24Hours(expectedTime);
      let convertedDate = expectedDate.toLocaleString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      expectedDeliveryTime = new Date(convertedDate + " " + convertedTime);
    }

    let scheduledTime = new Date(expectedDeliveryTime.getTime());
    if (hasLeadTime || expectedDeliveryTime.getTime() > currentTime.getTime()) {
      scheduledTime.setMinutes(scheduledTime.getMinutes() - defaultEta);
      // Including buffer time
      let scheduledTimeWithBuffer = new Date(scheduledTime.getTime());
      scheduledTimeWithBuffer.setMinutes(
        scheduledTime.getMinutes() - SCHEDULE_BUFFER_TIME_IN_MINS
      );
      if (scheduledTimeWithBuffer.getTime() >= new Date().getTime()) {
        scheduledTime = scheduledTimeWithBuffer;
      }
      this.setState({ scheduledTime });
    } else {
      expectedDeliveryTime = currentTime;
      this.setState({ scheduledTime: null });
    }

    displayEta = obtainEtaDisplayText(expectedDeliveryTime, this.props.intl.formatMessage);
    this.setState({
      displayEta: displayEta,
      deliveryEta: expectedDeliveryTime,
    });
  }

  confirmScheduleDate(date: any, time: any) {
    this.findExpectedDeliveryTime(date, time, false);
  }

  convertTo24Hours(time12h: any) {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }

  findSelectedModeOfPickup() {
    if (
      this.props.orderType == "pickup" &&
      (this.props.modesOfPickup?.in_store || this.props.modesOfPickup?.curbside)
    ) {
      this.setState({
        pickupMode: this.props.pickupModeFromSession || "in_store",
      });
    }
  }

  emailBoxShouldShow(paymentType: any, selectedPaymentType: any, email: any) {
    if (!email) {
      return (
        (paymentType.payment_type == "pay_u" &&  selectedPaymentType == "pay_u") ||
        (paymentType.payment_type == "qpay" &&  selectedPaymentType == "qpay") ||
        (paymentType.payment_type == "i_pay88" &&  selectedPaymentType == "i_pay88")
      );
    }
    return false;
  }

  onPickupModeChange(event: any) {
    let metaNotes = this.state.metaNotes;
    if (event.target.name != "curbside") {
      metaNotes = "";
    }
    this.setState({ pickupMode: event.target.name, metaNotes: metaNotes });
  }

  updateMetaNotes(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.setState({ metaNotes: e.target.value });
  }

  changeName(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.updateInputValidationMessage("user_name", "");
    this.setState({ userName: e.target.value });
  }

  changeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    this.updateInputValidationMessage("user_email", "");
    this.setState({ userEmail: e.target.value.trim() });
  }

  setMyFatoorahPaymentMode(paymentModeId: any) {
    this.setState({ myFatoorahSelectedPaymentMode: paymentModeId });
  }

  togglePlaceOrderButton(status: boolean) {
    this.setState({ placeOrderEnabled: status });
  }

  updateCardToken(cardToken: any) {
    this.setState({ cardToken });
  }

  setTipAmount(tipAmount: any) {
    this.setState({ tipAmount: parseFloat(tipAmount) });
  }

  updateTotalWithoutTip(total: number) {
    if (this.state.tipAmount > 0) {
      total -= this.state.tipAmount
    }
    return total
  }

  render() {
    const {
      orderType,
      cart,
      discount,
      loyalty,
      menu,
      modesOfPickup,
      user,
      selectedArea,
    } = this.props;
    const {
      pickupMode,
      paymentDetails,
      tipAmount,
      showTips,
      usableAddresses,
      outOfStockItems
    } = this.state;
    const currency = this.props.company.currency;
    return (
      <React.Fragment>
        <CheckoutScreenProvider>
        <Header />
        <div className="checkout-screen">
          <div className="top-nav">
            <div className="text-center title">
              {orderType === "pickup" ? (
                <FormattedMessage
                  id="checkout.uppercase.pickup_details"
                  defaultMessage="PICKUP DETAILS"
                />
              ) : (
                <FormattedMessage
                  id="checkout.uppercase.delivery_details"
                  defaultMessage="DELIVERY DETAILS"
                />
              )}
            </div>
          </div>
          <div className="container">
            <div className="checkout-steps">
              <div className="steps-title">
                <div className="title">
                  {orderType === "pickup" ? (
                    <FormattedMessage
                      id="checkout.pickup_location"
                      defaultMessage="Pickup Location"
                    />
                  ) : (
                    <FormattedMessage
                      id="checkout.delivery_location"
                      defaultMessage="Delivery Location"
                    />
                  )}
                </div>
                <div className="title">
                  <FormattedMessage
                    id={this.state.steps[1].translationId}
                    defaultMessage={this.state.steps[1].title}
                  />
                </div>
              </div>
              <div className="progress-steps">
                <ProgressBar now={this.state.progress} />
                <ul className="inline-list progress-indicator">
                  {this.state.steps.map((step: any) => {
                    return (
                      <li
                        key={step.id}
                        className={
                          step.active || step.completed
                            ? "step"
                            : "step inactive"
                        }
                      >
                        {step.active && ""}
                        {step.completed && <FontAwesomeIcon icon={faCheck} />}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            {this.state.activeStepId === 1 && (
              <div className="section">
                {paymentDetails.status == "failed" ? (
                  <div className="form-group mb-4 ">
                    <div className="payment-failure">
                      <p className="error-header">
                        <FormattedMessage
                          id="payment.payment_failed"
                          defaultMessage="Payment Failed"
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="payment.unable_to_process_payment"
                          defaultMessage="Sorry, we were unable to process your payment. Your card was not charged. Please try again or use a different payment method."
                        />
                      </p>
                      <p>{paymentDetails.message}</p>
                    </div>
                  </div>
                ) : null}

                <div className="form-group mb-4 ">
                  <label className="input-label bold mb-0">
                    <FormattedMessage
                      id="checkout.your_name"
                      defaultMessage="Your Name"
                    />: {user.name ? user.name : ""}
                  </label>
                  {!user.name ? (
                    <div className="form-group mb-4">
                      <FormattedMessage
                        id="checkout.enter_your_name_here"
                        defaultMessage="Enter your name here..."
                      >
                        {(placeholder) => (
                          <input
                            id="user_name"
                            name="user_name"
                            type="text"
                            placeholder={placeholder}
                            required
                            className="form-control custom-input mt-2"
                            value={this.state.userName}
                            onChange={(event: any) =>
                              this.changeName(event)
                            }
                          ></input>
                        )}
                      </FormattedMessage>
                    </div>
                  ) : null}
                </div>
                <div className="form-group mb-4 ">
                  <label className="input-label bold mb-0">
                    {orderType === "pickup" ? (
                      <FormattedMessage
                        id="checkout.restaurant_address"
                        defaultMessage="Restaurant Address"
                      />
                    ) : (
                      <FormattedMessage
                        id="checkout.delivery_location_city_zone"
                        defaultMessage="Delivery Location (City/Zone)"
                      />
                    )}
                  </label>
                  <div className="nested-section">
                    <FontAwesomeIcon
                      size="2x"
                      icon={orderType === "pickup" ? faMapMarker : faMotorcycle}
                      className="scooter-icon mr-3"
                    />
                    <div
                      className={
                        selectedArea?.address
                          ? "section-message withAddress"
                          : "section-message noAddress"
                      }
                    >
                      <p className="message-title">{selectedArea?.name}</p>
                      {selectedArea?.address ? (
                        <p className="message-info">
                          {selectedArea?.address}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {orderType === "pickup" &&
                  (modesOfPickup?.in_store || modesOfPickup?.curbside) ? (
                    <div>
                      <div className="form-group mb-4">
                        <label className="input-label bold mb-0">
                          <FormattedMessage
                            id="checkout.pickup_method"
                            defaultMessage="Pickup Method"
                          />:
                        </label>
                        <div className="nested-section">
                          <div className="pickup-methods">
                            <form>
                              {modesOfPickup.in_store ? (
                                <div className="form-check method">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="in_store"
                                      name="in_store"
                                      checked={
                                        !pickupMode || pickupMode == "in_store"
                                      }
                                      onChange={(event: any) => {
                                        this.onPickupModeChange(event);
                                      }}
                                    />
                                    <span></span>
                                    <FormattedMessage
                                      id="pickup_selector.in_store"
                                      defaultMessage="In-Store"
                                    />
                                  </label>
                                </div>
                              ) : null}
                              {modesOfPickup.curbside ? (
                                <div className="form-check method">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="curbside"
                                      name="curbside"
                                      checked={pickupMode == "curbside"}
                                      onChange={(event: any) => {
                                        this.onPickupModeChange(event);
                                      }}
                                    />
                                    <span></span>
                                    <FormattedMessage
                                      id="pickup_selector.curbside"
                                      defaultMessage="Curbside"
                                    />
                                  </label>
                                </div>
                              ) : null}
                            </form>
                          </div>
                        </div>
                      </div>
                      {pickupMode == "curbside" ? (
                        <div className="form-group mb-4">
                          <label className="input-label bold mb-1">
                            <FormattedMessage
                              id="checkout.curbside_details"
                              defaultMessage="Curbside Details"
                            />
                          </label>
                          <br />
                          <label className="input-label mb-0">
                            <FormattedMessage
                              id="checkout.curbside_notes"
                              defaultMessage="Details to easily recognise you (eg. Plate Number, car colour)"
                            />
                          </label>
                          <textarea
                            className="form-control custom-input textarea mt-2"
                            value={this.state.metaNotes}
                            onChange={(event: any) =>
                              this.updateMetaNotes(event)
                            }
                          ></textarea>
                        </div>
                      ) : null}
                    </div>
                  ) : orderType === "delivery" ? (
                    <div className="delivery-address-details">
                      <div>
                        <label className="input-label bold mb-0">
                          <FormattedMessage
                            id="address.delivery_address"
                            defaultMessage="Delivery Address"
                          />
                        </label>
                        {this.state.addressLoading ? (
                          <InlineLoader />
                        ) : (
                          <div>
                            {usableAddresses.length
                              ? usableAddresses.map((address: any) => {
                                  const addressLines =
                                    address.delivery_addresses;
                                  const area = addressLines[1].value
                                    ? `, ${addressLines[1].value}`
                                    : "";
                                  const landMark = addressLines[3].value
                                    ? `, ${addressLines[3].value}`
                                    : "";
                                  return (
                                    <div
                                      key={address.address_id}
                                      className={
                                         address.address_id == this.state.selectedAddress.id
                                            ? "nested-section saved-address selected"
                                            : "nested-section saved-address"
                                      }
                                      onClick={() =>
                                        this.selectAddress(
                                          address,
                                          true
                                        )
                                      }
                                    >
                                      <div className="area-details">
                                        <p className="area">
                                          {addressLines[0].value + area}
                                        </p>
                                        <p className="street">
                                          {addressLines[2].value + landMark}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })
                              : false}
                            {usableAddresses.length && !this.state.showAddAddress ? (
                              <p
                                className="add-new-address"
                                onClick={() => this.handleAddAddress()}
                              >
                                <FormattedMessage
                                  id="address.enter_new_address"
                                  defaultMessage="Enter new address"
                                />
                              </p>
                            ) : (
                              false
                            )}
                          </div>
                        )}
                      </div>
                      {(!this.state.addressLoading && !usableAddresses.length) ||
                      this.state.showAddAddress ? (
                        <NewAddressWidget
                          reloadAddresses={() => this.fetchAddresses()}
                          cancelNewAddress={() => this.cancelAddAddress()}
                        />
                      ) : (
                        false
                      )}
                    </div>
                  ) : null}
                </div>
                <div>
                  <div className="form-group mb-4">
                    <label className="input-label bold mb-0">
                      {orderType === "pickup" ? (
                        <FormattedMessage
                          id="checkout.expected_pickup_time"
                          defaultMessage="Expected Pickup Time"
                        />
                      ) : (
                        <FormattedMessage
                          id="checkout.expected_delivery_time"
                          defaultMessage="Expected Delivery Time"
                        />
                      )}:
                    </label>
                    <p className="expected-delivery-label mt-2">
                      {this.props.company.scheduled_order_enabled ? (
                        <React.Fragment>
                          <span className="change-schedule">
                            {this.state.displayEta}
                          </span>
                          <a
                            href="javascript:;"
                            onClick={() => this.onScheduleOrderClick()}
                            className="ml-2"
                          >
                            <FormattedMessage
                              id="checkout.schedule_for_later"
                              defaultMessage="Schedule for later"
                            />
                          </a>
                        </React.Fragment>
                      ) : (
                        <span>{this.state.displayEta}</span>
                      )}
                    </p>
                  </div>
                  {this.state.showScheduleOrderPopup ? (
                    <ScheduleOrdersPopup
                      onClose={() => this.handleScheduleOrderClose()}
                      for={orderType === "pickup" ? "Pickup" : "Delivery"}
                      defaultEta={this.props.company.default_eta}
                      openingHours={this.props.outlet.opening_hours}
                      defaultDeliveryTime={this.state.defaultDeliveryTime}
                      confirmScheduleDate={(date: any, time: any) =>
                        this.confirmScheduleDate(date, time)
                      }
                      minimumEtaMinutes={this.state.minimumEtaMinutes}
                      hasLeadTime={this.state.hasLeadTime}
                      convertTo24Hours={
                        (timeToConvert: any) => this.convertTo24Hours(timeToConvert)
                      }
                    />
                  ) : null}
                  <div className="form-group mb-4">
                    <label className="input-label bold mb-0">
                      <FormattedMessage
                        id="checkout.order_notes_optional"
                        defaultMessage="Order Notes <spanTag>(Optional)</spanTag>"
                        values={{
                          spanTag: (text) => <span>{text}</span>
                        }}
                      />
                    </label>
                    <FormattedMessage
                      id={
                        orderType === "delivery"
                        ? "checkout.any_special_request_on_the_preparation_or_delivery_of_your_food"
                        : "checkout.any_special_request_on_the_preparation"
                      }
                      defaultMessage={
                        orderType === "delivery"
                        ? "Any special requests on the preparation or delivery of your food?"
                        : "Any special requests on the preparation?"
                      }
                    >
                      {(placeholder) => (
                        <textarea
                          name="delivery-notes"
                          required
                          placeholder={placeholder}
                          className="form-control custom-input textarea mt-2"
                          value={this.state.orderNotes}
                          onChange={this.handleOrderNotes.bind(this)}
                        ></textarea>
                      )}
                    </FormattedMessage>
                  </div>
                </div>
              </div>
            )}
            {this.state.activeStepId === 2 && (
              <div>
                <div className="section">
                  <CartItemsWidget
                    cart={cart}
                    currency={currency}
                    outOfStockItems={this.props.menu.out_of_stock_items}
                  />
                  <div
                    className={
                      this.props.hasLoyalty
                        ? "discounts-loyalty"
                        : "discounts-loyalty no-loyalty"
                    }
                  >
                    <Discount />
                    {this.props.hasLoyalty ? <LoyaltyWidget /> : null}
                  </div>

                  <div className="amount-details">
                    <div className="amount subtotal">
                      <p className="label">
                        <FormattedMessage
                          id="global.subtotal"
                          defaultMessage="Subtotal"
                        />
                      </p>
                      <p className="value">{formatDecimal(cart.subTotal)}</p>
                    </div>
                    {cart.surchargeBreakDown && cart.surchargeBreakDown.length
                      ? cart.surchargeBreakDown.map((surcharge: any, index: number) => {
                          return (
                            <div className="amount charges" key={index}>
                              <p className="label">{surcharge.name}</p>
                              <p className="value">
                                {formatDecimal(surcharge.amount)}
                              </p>
                            </div>
                          );
                        })
                      : false}
                    {discount && (
                      <div className="amount discounts">
                        <p className="label">{discount.discount_name}</p>
                        <p className="value">
                          -{formatDecimal(discount.discountedAmount)}
                        </p>
                      </div>
                    )}
                    {loyalty && loyalty.redeemedPoints ? (
                      <div className="amount discounts">
                        <p className="label">
                          <FormattedMessage
                            id="global.loyalty"
                            defaultMessage="Loyalty"
                          />
                        </p>
                        <p className="value">
                          -{formatDecimal(loyalty.redeemedPoints)}
                        </p>
                      </div>
                    ) : (
                      false
                    )}
                    {!menu.is_tax_inclusive && cart.taxTotal > 0 ? (
                      <div className="amount tax">
                        <p className="label">
                          <FormattedMessage
                            id="global.tax"
                            defaultMessage="Tax"
                          />
                        </p>
                        <p className="value">{formatDecimal(cart.taxTotal)}</p>
                      </div>
                    ) : null}
                    <div className="amount total">
                      <p className="label">
                        <FormattedMessage
                          id="global.total"
                          defaultMessage="Total"
                        />
                      </p>
                      <p className="value">
                        {currency} {formatDecimal(this.updateTotalWithoutTip(cart.total))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <h1 className="section-heading">
                    <FormattedMessage
                      id="checkout.payment_methods"
                      defaultMessage="Payment Methods"
                    />
                  </h1>
                  <div className="payment-methods">
                    <div>
                      {this.state.paymentTypes.map((paymentType: any) => {
                        return (
                          <div
                            key={paymentType.id}
                            className="form-check method"
                            onClick={() => this.handlePaymentType(paymentType)}
                          >
                            <label
                              className="form-check-label"
                              htmlFor={paymentType.payment_type}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                id={paymentType.payment_type}
                                checked={
                                  this.state.selectedPaymentType ==
                                  paymentType.payment_type
                                }
                                readOnly
                              />
                              <FontAwesomeIcon
                                icon={
                                  paymentType.payment_type == "cash_on_delivery"
                                    ? faMoneyBillWave
                                    : faCreditCard
                                }
                                size="sm"
                              />
                              <span>{paymentType.title}</span>
                            </label>

                            {paymentType.payment_type == "my_fatoorah" &&
                            this.state.selectedPaymentType == "my_fatoorah" && (
                              <div className="payment-subsection">
                                {this.state.myFatoorahPaymentModesLoading ? (
                                  <div className="loader-container">
                                    <InlineLoader />
                                  </div>
                                ) : (
                                  <div className="selections">
                                    {this.state.myFatoorahPaymentModes.length > 1 ? (
                                      <small>
                                        <FormattedMessage
                                          id="checkout.choose_payment_mode"
                                          defaultMessage="Choose your payment mode"
                                        />
                                      </small>
                                    ) : null}
                                    {this.state.myFatoorahPaymentModes.map((myFatoorahPaymentMode: any) => {
                                      return(
                                        <ul key={myFatoorahPaymentMode.id} >
                                          <li>
                                            <label>
                                              <input
                                                type="radio"
                                                name="myFatoorahSelectedPaymentMode"
                                                onClick={() =>
                                                  this.setMyFatoorahPaymentMode(myFatoorahPaymentMode.id)
                                                }
                                                checked={
                                                  this.state.myFatoorahSelectedPaymentMode ==
                                                  myFatoorahPaymentMode.id
                                                }
                                              />
                                              {myFatoorahPaymentMode.title}
                                            </label>
                                          </li>
                                        </ul>
                                      )
                                    })}
                                  </div>
                                )}
                              </div>
                            )}

                            {paymentType.payment_type == "stripe" &&
                              this.state.selectedPaymentType == "stripe" && (
                                <CardSectionWrapper
                                  stripeKey={paymentType.public_key}
                                  payload={this.state.stripeOrderPayload}
                                  onCardSelect={(cardData: any) =>
                                    this.handleStripeCardSelection(cardData)
                                  }
                                  showPointsEarnedPopup={(earnedLoyalty: any) =>
                                    this.showPointsEarnedPopup(earnedLoyalty)
                                  }
                                  togglePlaceOrderButton={(status: boolean) =>
                                    this.togglePlaceOrderButton(status)
                                  }
                                />
                              )}

                            {paymentType.payment_type == "stripe_connect" &&
                              this.state.selectedPaymentType == "stripe_connect" && (
                              <StripeConnectWrapper
                                orderPayload={orderPayload(this.state, this.props)}
                                togglePlaceOrderButton={(status: boolean) =>
                                  this.togglePlaceOrderButton(status)
                                }
                              />
                            )}

                            {paymentType.payment_type == "qpay" &&
                            this.state.selectedPaymentType == "qpay" &&
                            paymentType.can_save_cards && (
                              <QpayCardSectionWrapper
                                updateCardToken={(token: any) =>
                                  this.updateCardToken(token)
                                }
                              />
                            )}

                            {paymentType?.payment_type === MASTERCARD_PAYMENT_TYPE &&
                              this.state.selectedPaymentType === MASTERCARD_PAYMENT_TYPE && (
                                paymentType?.direct_payment_enabled ?
                                  <MastercardDirectPaymentWrapper
                                    payload={orderPayload(this.state, this.props)}
                                    togglePlaceOrderButton={(status: boolean) =>
                                      this.togglePlaceOrderButton(status)
                                    }
                                    googlePayEnabled={paymentType?.google_pay_enabled}
                                    applePayEnabled={paymentType?.apple_pay_enabled}
                                  />
                                : 
                                  <MasterCardSectionWrapper
                                    orderPayload={orderPayload(this.state, this.props)}
                                    togglePlaceOrderButton={(status: boolean) =>
                                      this.togglePlaceOrderButton(status)
                                    }
                                  />
                              )}

                            {paymentType.payment_type == "omise" &&
                              this.state.selectedPaymentType == "omise" && (
                                <OmiseCardSectionWrapper
                                  omiseKey={paymentType.public_key}
                                  payload={orderPayload(this.state, this.props)}
                                  currency={currency}
                                  showPointsEarnedPopup={(earnedLoyalty: any) =>
                                    this.showPointsEarnedPopup(earnedLoyalty)
                                  }
                                  togglePlaceOrderButton={(status: boolean) =>
                                    this.togglePlaceOrderButton(status)
                                  }
                                  companyDecimalPrecision={ this.props.company.decimal_precision }
                                />
                            )}

                            {paymentType.payment_type == "adyen" &&
                              this.state.selectedPaymentType == "adyen" && (
                              <div className="payment-subsection adyen">
                                <div className="selections">
                                  <AdyenSectionWrapper
                                    payload={orderPayload(this.state, this.props)}
                                    paymentType={paymentType}
                                    showPointsEarnedPopup={(earnedLoyalty: any) =>
                                      this.showPointsEarnedPopup(earnedLoyalty)
                                    }
                                    togglePlaceOrderButton={(status: boolean) =>
                                      this.togglePlaceOrderButton(status)
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            {this.emailBoxShouldShow(paymentType,
                            this.state.selectedPaymentType, user.email) ? (
                              <div className="customer-details-section">
                                <div className="row">
                                  <div className="col-lg-8 col-md-10 col-sm-12">
                                    <div className="add-customer-details">
                                      <div className="heading"></div>
                                      <div className="form-group add-customer-form">
                                        <label className="input-label bold mb-2">
                                          <FormattedMessage
                                            id="global.email"
                                            defaultMessage="Email"
                                          />
                                        </label>
                                        <input
                                          className="form-control custom-input mb-2"
                                          type="text"
                                          value={this.state.userEmail}
                                          onChange={(event: any) =>
                                            this.changeEmail(event)
                                          }
                                          id="user_email"
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {paymentType.generic_integration && (
                              <GenericPaymentTypeWrapper
                                orderPayload={orderPayload(
                                  this.state,
                                  this.props
                                )}
                                togglePlaceOrderButton={(status: boolean) =>
                                  this.togglePlaceOrderButton(status)
                                }
                                buildGenericPaymentsPayload={
                                  this.props.buildGenericPaymentsPayload
                                }
                                userDetails={this.userDetailsForGenericPayments()}
                                paymentDetails={this.paymentDetailsForGenericPayments()}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {this.state.selectedPaymentType == "cc_avenue" && (
                      <CcAvenueForm
                        CCAvenueFormData={this.state.CCAvenueFormData}
                      />
                    )}
                    {this.state.selectedPaymentType == "qpay" && (
                      <QpayForm
                        qpayFormData={this.state.qpayFormData}
                        email={this.state.userEmail}
                        name={this.state.userName}
                        cardToken={this.state.cardToken}
                      />
                    )}
                    {this.state.selectedPaymentType == "i_pay88" && (
                      <IPay88Form
                        IPay88FormData={this.state.IPay88FormData}
                      />
                    )}
                    {this.state.selectedPaymentType == "fiserv" && (
                      <FiservForm
                        fiservFormData={this.state.fiservFormData}
                      />
                    )}
                  </div>
                </div>
                {showTips ? (
                  <div className="tip-section section">
                    <div className="section-heading">Would you like to leave some tip?</div>
                    <TipsWidget
                      setTipAmount={(tip: any) => this.setTipAmount(tip)}
                      />
                    {showTips && tipAmount > 0 && (
                      <div className="breakdown-with-tip">
                        <div className="title">Updated Total including tip:</div>
                      <div className="amount-details">
                        <div className="amount subtotal">
                          <p className="label">
                            <FormattedMessage
                              id="global.subtotal"
                              defaultMessage="Subtotal"
                            />
                          </p>
                          <p className="value">{formatDecimal(cart.subTotal)}</p>
                        </div>
                        <div className="amount charges">
                          <p className="label">Tip</p>
                          <p className="value">
                            {formatDecimal(tipAmount)}
                          </p>
                        </div>
                        {cart.surchargeBreakDown && cart.surchargeBreakDown.length
                          ? cart.surchargeBreakDown.map((surcharge: any, index: number) => {
                              return (
                                <div className="amount charges" key={index}>
                                  <p className="label">
                                    <FormattedMessage
                                      id="global.surcharge"
                                      defaultMessage="Surcharge"
                                    />
                                  </p>
                                  <p className="value">
                                    {formatDecimal(surcharge.amount)}
                                  </p>
                                </div>
                              );
                            })
                          : false}
                        {discount && (
                          <div className="amount discounts">
                            <p className="label">{discount.discount_name}</p>
                            <p className="value">
                              -{formatDecimal(discount.discountedAmount)}
                            </p>
                          </div>
                        )}
                        {loyalty && loyalty.redeemedPoints ? (
                          <div className="amount discounts">
                            <p className="label">
                              <FormattedMessage
                                id="global.loyalty"
                                defaultMessage="Loyalty"
                              />
                            </p>
                            <p className="value">
                              -{formatDecimal(loyalty.redeemedPoints)}
                            </p>
                          </div>
                        ) : (
                          false
                        )}
                        {!menu.is_tax_inclusive && cart.taxTotal > 0 ? (
                          <div className="amount tax">
                            <p className="label">
                              <FormattedMessage
                                id="global.tax"
                                defaultMessage="Tax"
                              />
                            </p>
                            <p className="value">{formatDecimal(cart.taxTotal)}</p>
                          </div>
                        ) : null}
                        <div className="amount total">
                          <p className="label">
                            <FormattedMessage
                              id="global.total"
                              defaultMessage="Total"
                            />
                          </p>
                          <p className="value">
                            {currency} {formatDecimal(cart.total)}
                          </p>
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                ) : null}
                {this.props.recentOrder ? (
                  <div className="in-progress-banner-wrapper-desktop">
                    <PaymentInProgressBanner />
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <div className="step-actions">
            <CheckoutCtaButton
              placeOrderEnabled={this.state.placeOrderEnabled}
              outOfStockItems={this.state.outOfStockItems}
              recentOrder={this.props.recentOrder}
              orderPayload={this.state.activeStepId === 2 ? orderPayload(this.state, this.props) : {}}
              storeOpen={this.props.storeOpen}
              cartItems={this.props.cart.items}
              activeStep={this.state.activeStepId}
              clickAction={() => this.onStepNext()}
              backAction={() => this.onStepBack()}
            />
          </div>
          {this.state.showPlaceOrderPopup && (
            <OrderPlacedPopup earnedLoyalty={this.state.earnedLoyalty} />
          )}
          <CheckoutPopups />
        </div>
        </CheckoutScreenProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let outlet = state.outlet;
  let menu = state.menu;
  let session = state.session;
  let orderType = state.session.order_type;
  let paymentTypes = state.paymentTypes;
  let cart = state.cart;
  let discount = state.discount;
  let loyalty = state.loyalty;
  let addresses = state.addresses;
  let user = state.user;
  let modesOfPickup = state.company.modes_of_pickup;
  let pickupModeFromSession = state.session.pickup_mode;
  let loyaltyConfig = state.loyaltyConfig;
  let hasLoyalty = loyaltyConfig[orderType]?.enabled;
  let storeOpen = state.storeTimings.open;
  let selectedArea = state.areas.selectedArea;
  let tipsEnabled = state.company.tip_settings?.has_tips && state.company.tip_settings?.type == "pre_order";
  let zoneMapEnabled = state.company.configuration?.zone_mapping_enabled == true;
  let recentOrder = state.recentOrder;

  return {
    company,
    outlet,
    menu,
    session,
    cart,
    paymentTypes,
    orderType,
    discount,
    loyalty,
    addresses,
    user,
    modesOfPickup,
    pickupModeFromSession,
    loyaltyConfig,
    hasLoyalty,
    storeOpen,
    selectedArea,
    tipsEnabled,
    zoneMapEnabled,
    recentOrder
  };
};

const mapDispatchToProps = {
  fetchCheckoutConfigurations: pageOperations.fetchCheckoutConfigurations,
  setCart: cartOperations.setCart,
  placeOrder: orderInProgressOperations.placeOrder,
  updateError: errorOperations.updateError,
  fetchAddresses: addressesOperations.fetchAddresses,
  buildCCAvenuePaymentData: paymentTypeOperations.buildCCAvenuePaymentData,
  buildFiservPaymentData: paymentTypeOperations.buildFiservPaymentData,
  setArea: areasOperations.setArea,
  fetchMyFatoorahPaymentModes: paymentTypeOperations.fetchMyFatoorahPaymentModes,
  buildQpayPaymentData: paymentTypeOperations.buildQpayPaymentData,
  buildIPay88PaymentData: paymentTypeOperations.buildIPay88PaymentData,
  buildGenericPaymentsPayload: paymentTypeOperations.buildGenericPaymentsPayload,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Checkout));
