// Packages
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Images
import Delivery from "../../images/delivery.svg";
import Pickup from "../../images/paperbag.svg";

interface OnboardingPopupProps {
  selectOrderType: any;
}

function OnboardingPopup(props: OnboardingPopupProps) {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      dialogClassName="custom curved"
      centered
      backdrop="static"
    >
      <div className="onboarding-popup">
        <h1>
          <FormattedMessage
            id="onboarding.how_would_you_like_to_get_your_order"
            defaultMessage="How would you like to get your order"
          />?
        </h1>
        <div className="order-types">
          <div
            className="type"
            onClick={() => props.selectOrderType("delivery")}
          >
            <object
              className="icon"
              type="image/svg+xml"
              data={Delivery}
              color="yellow"
            ></object>
            <p className="type-name">
              <FormattedMessage
                id="global.delivery"
                defaultMessage="Delivery"
              />
            </p>
          </div>
          <div
            className="type"
            onClick={() => props.selectOrderType("pickup")}
          >
            <object
              className="icon"
              type="image/svg+xml"
              data={Pickup}
              color="yellow"
            ></object>
            <p className="type-name">
              <FormattedMessage
                id="global.pickup"
                defaultMessage="Pickup"
              />
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default OnboardingPopup;
