// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Icons, Images etc.
import Success from "../../images/success-animation.gif";
// Redux Operations
import { alertOperations } from "../../state/features/alert";

interface IAlertPopupProps {
  alert: any;
  resetAlert: any;
}

function AlertPopup(props: IAlertPopupProps) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(props.alert ? true : false);
  }, [props.alert])

  const handleClose = () => {
    if (props.alert.redirectionUrl) {
      location.href = props.alert.redirectionUrl;
    } else {
      setShow(false);
      setTimeout(() => {
        props.resetAlert();
      }, 500);
    }
  };

  const alertTitle = (titleCode: string) => {
    switch (titleCode) {
      // Add cases for other alert title codes
      default: "";
    }
  }

  const alertMessage = (alertCode: string) => {
    switch (alertCode) {
      // Add cases for other alert codes
      case "success.invoice_sent_to_email":
        return <FormattedMessage
          id="success.invoice_sent_to_email"
          defaultMessage="Invoice successfully sent to your email."
        />;
      default: {
        return <FormattedMessage
          id="success.default_message"
          defaultMessage="Success!"
        />;
      }
    }
  }

  return (
    <Modal show={show} dialogClassName="custom curved mx-2" centered backdrop="static">
      <div className="alert-popup-mobile">
        {props.alert.type == 'success' ? (
          <img className='image' src={Success} />
        ) : false}

        {props.alert.titleCode ? (
          <p className="alert-title">{alertTitle(props.alert.titleCode)}</p>
        ) : props.alert.title ? (
          <p className="alert-title">{props.alert.title}</p>
        ) : false}

        {props.alert.messageCode ? (
          <p className="alert-message">{alertMessage(props.alert.messageCode)}</p>
        ) : props.alert.message ? (
          <p className="alert-message">{props.alert.message}</p>
        ) : false}

        <button
          className="button btn btn-primary btn-block"
          onClick={handleClose}
        >
          <FormattedMessage
            id="global.close"
            defaultMessage="Close"
          />
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let alert = state.alert;

  return {
    alert,
  };
};

const mapDispatchToProps = {
  resetAlert: alertOperations.resetAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertPopup);
