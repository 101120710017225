export const FETCH_HOME_CONFIGURATIONS = "page/FETCH_HOME_CONFIGURATIONS";
export const FETCH_HOME_CONFIGURATIONS_SUCCESS = "page/FETCH_HOME_CONFIGURATIONS_SUCCESS";
export const FETCH_HOME_CONFIGURATIONS_FAIL = "page/FETCH_HOME_CONFIGURATIONS_FAIL";
export const FETCH_CHECKOUT_CONFIGURATIONS = "page/FETCH_CHECKOUT_CONFIGURATIONS";
export const FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS = "page/FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS";
export const FETCH_CHECKOUT_CONFIGURATIONS_FAIL = "page/FETCH_CHECKOUT_CONFIGURATIONS_FAIL";
export const FETCH_TRACKING_CONFIGURATIONS = "page/FETCH_TRACKING_CONFIGURATIONS";
export const FETCH_TRACKING_CONFIGURATIONS_SUCCESS = "page/FETCH_TRACKING_CONFIGURATIONS_SUCCESS";
export const FETCH_TRACKING_CONFIGURATIONS_FAIL = "page/FETCH_TRACKING_CONFIGURATIONS_FAIL";
export const FETCH_PROFILE_CONFIGURATIONS = "page/FETCH_PROFILE_CONFIGURATIONS";
export const FETCH_PROFILE_CONFIGURATIONS_SUCCESS = "page/FETCH_PROFILE_CONFIGURATIONS_SUCCESS";
export const FETCH_PROFILE_CONFIGURATIONS_FAIL = "page/FETCH_PROFILE_CONFIGURATIONS_FAIL";
export const FETCH_PAST_ORDERS_CONFIGURATIONS = "page/FETCH_PAST_ORDERS_CONFIGURATIONS";
export const FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS = "page/FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS";
export const FETCH_PAST_ORDERS_CONFIGURATIONS_FAIL = "page/FETCH_PAST_ORDERS_CONFIGURATIONS_FAIL";
export const FETCH_LOYALTY_CONFIGURATIONS = "page/FETCH_LOYALTY_CONFIGURATIONS";
export const FETCH_LOYALTY_CONFIGURATIONS_SUCCESS = "page/FETCH_LOYALTY_CONFIGURATIONS_SUCCESS";
export const FETCH_LOYALTY_CONFIGURATIONS_FAIL = "page/FETCH_LOYALTY_CONFIGURATIONS_FAIL";
export const FETCH_PAYMENT_PROCESSING_CONFIGURATIONS = "page/FETCH_PAYMENT_PROCESSING_CONFIGURATIONS";
export const FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_SUCCESS = "page/FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_SUCCESS";
export const FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_FAIL = "page/FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_FAIL";
export const FETCH_SIGN_IN_CONFIGURATIONS = "page/FETCH_SIGN_IN_CONFIGURATIONS";
export const FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS = "page/FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS";
export const FETCH_SIGN_IN_CONFIGURATIONS_FAIL = "page/FETCH_SIGN_IN_CONFIGURATIONS_FAIL";
export const FETCH_ORDER_DETAILS_CONFIGURATIONS = "page/FETCH_ORDER_DETAILS_CONFIGURATIONS";
export const FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS = "page/FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS";
export const FETCH_ORDER_DETAILS_CONFIGURATIONS_FAIL = "page/FETCH_ORDER_DETAILS_CONFIGURATIONS_FAIL";
export const FETCH_TERMS_OF_SERVICE_CONFIGURATIONS = "page/FETCH_TERMS_OF_SERVICE_CONFIGURATIONS";
export const FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_SUCCESS = "page/FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_SUCCESS";
export const FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_FAIL = "page/FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_FAIL";
export const FETCH_RIDER_TRACKING_CONFIGURATIONS = "page/FETCH_RIDER_TRACKING_CONFIGURATIONS";
export const FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS = "page/FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS";
export const FETCH_RIDER_TRACKING_CONFIGURATIONS_FAIL = "page/FETCH_RIDER_TRACKING_CONFIGURATIONS_FAIL";
