// Packages
import React, { useEffect } from "react";
import { FormattedMessage } from 'react-intl';

interface IGeneralAnnouncementMobileProps {
  onClose: any;
  announcement: string;
}

function GeneralAnnouncementMobile(props: IGeneralAnnouncementMobileProps) {
  useEffect(() => {
    updateComponentHeights();
  }, [props.announcement]);

  const updateComponentHeights = (resetAnnouncment = false) => {
    let headerHeight,
      announcementHeight,
      statusBarHeight,
      inProgressStatusHeight;
    headerHeight = document.getElementById("header")?.clientHeight || 0;
    announcementHeight =
      document.getElementById("general-annoucement")?.clientHeight || 0;
    statusBarHeight = document.getElementById("status-bar")?.clientHeight || 0;
    inProgressStatusHeight =
      document.getElementById("in-progress-status")?.clientHeight || 0;
    if (resetAnnouncment) {
      announcementHeight = 0;
    }
    let categoryBarPosition: any =
      headerHeight +
      announcementHeight +
      statusBarHeight +
      inProgressStatusHeight;
    document.documentElement.style.setProperty(
      "--top-position",
      `${categoryBarPosition}px`
    );
  };
  return (
    <div className="general-annoucement-mobile" id='general-annoucement'>
      <div>
        <p className="announcement" id='annoucement'>{props.announcement}</p>
        <p className="close-button" id='close' onClick={() => props.onClose()}>
          <FormattedMessage
            id="global.close"
            defaultMessage="Close"
          />
        </p>
      </div>
    </div>
  );
}
export default GeneralAnnouncementMobile;
