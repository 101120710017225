import actions from "./actions";

const setSession = actions.setSession;
const changeDeliveryArea = actions.changeDeliveryArea;
const clearSession = actions.clearSession;

export default {
  setSession,
  changeDeliveryArea,
  clearSession,
};
