import React, { useState, useEffect } from "react";
import Select from "react-select";
import countryCodes from "../../helpers/countryCodes";

interface IDialingCodeSelectorProps{
  onChange: any;
  dialingCode: string;
  countryCode: string;
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    outline: state.isFocused ? "none" : "none",
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: "#ced4da",
    "&:hover": {
      borderColor: "#ced4da",
    },
  }),
};

function DialingCodeSelector(props:IDialingCodeSelectorProps){
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    let defaultCountry = countryCodes.find((countryCode: any) => {
      return countryCode.code == props.countryCode;
    });
    if (defaultCountry) {
      props.onChange(defaultCountry.value);
      setDefaultOption(defaultCountry);
    } else {
      props.onChange(countryCodes[0].value);
      setDefaultOption(countryCodes[0]);
    }
  }, [props.countryCode]);

  const handleChange = (e) => {
    props.onChange(e.value);
    setSelectedOption(e);
  };

  return (
    <React.Fragment>
      {defaultOption ? (
        <Select
          isDisabled={false}
          options={countryCodes}
          styles={customStyles}
          className="select-input-container"
          classNamePrefix="select-input"
          components={{
            IndicatorSeparator: () => null,
          }}
          theme={(theme: any) => ({
            ...theme,
            borderRadius: 0,
          })}
          onChange={handleChange}
          value={(selectedOption == null) ? defaultOption : selectedOption}
        >
        </Select>
      ) : (
        false
      )}
    </React.Fragment>
  )
}
export default DialingCodeSelector
