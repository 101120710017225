import * as types from "./types";

const fetchLoyalty = () => ({
  type: types.FETCH_LOYALTY,
  payload: {
    request: {
      method: "get",
      url: "/users/loyalty_points"
    }
  }
});

const redeemLoyalty = (points: any) => ({
  type: types.REDEEM_LOYALTY,
  payload: {
    redeemedPoints: points,
    hasRedeemed: parseFloat(points) > 0,
  },
});

const removeLoyalty = () => ({
  type: types.REMOVE_LOYALTY,
});

export default {
  fetchLoyalty,
  redeemLoyalty,
  removeLoyalty,
};
