// Packages
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { injectIntl } from 'react-intl';
// Components
import MenuItem from "../menuItem";
import ModalCloseIcon from "../modalCloseIcon";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
// Helpers, Utils etc.
import { deepCopyFunction } from "../../helpers/utils";
import {
  translatedName,
} from "../../helpers/translations";

interface IGroupedItemPopupProps {
  groupedItem: any;
  checkAndRemoveAdvertisementClass: any;
  onClose: any;
}
interface IGroupedItemPopupState {
  show: boolean;
  groupedItem: any;
  hide: boolean;
}

class GroupedItemPopup extends Component<
  IGroupedItemPopupProps,
  IGroupedItemPopupState
> {
  constructor(props: IGroupedItemPopupProps) {
    super(props);
    this.state = {
      show: true,
      groupedItem: {},
      hide: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: IGroupedItemPopupProps,
    nextState: IGroupedItemPopupState
  ) {
    if (nextProps.groupedItem !== nextState.groupedItem) {
      return { groupedItem: nextProps.groupedItem };
    }
    return null;
  }

  closeGroupedItemMenu = () => {
    setTimeout(() => {
      this.props.onClose();
    }, 500);
    this.setState({ show: false });
  };

  hideGroupedItemMenu = (state: any) => {
    this.setState({ hide: !state });
  };

  render() {
    const { groupedItem, show } = this.state;
    const { intl } = this.props;

    return (
      <Modal
        show={show}
        size="xl"
        dialogClassName={
          this.state.hide ? "custom curved invisible" : "custom curved"
        }
        onHide={this.closeGroupedItemMenu}
        centered
      >
        <ModalCloseIcon onClick={this.closeGroupedItemMenu} />
        <div className="grouped-item-popup">
          <div className="header">
            {translatedName(groupedItem, intl.locale)}
          </div>
          <div className="row">
            {groupedItem.items.map((item: any, index: number) => {
              return (
                <div key={index} className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <MenuItem
                    hideGroupedItemMenu={(state: any) =>
                      this.hideGroupedItemMenu(state)
                    }
                    closeGroupedItemMenu={() => this.closeGroupedItemMenu()}
                    isGroupedItem={true}
                    parentItem={groupedItem}
                    item={item}
                    checkAndRemoveAdvertisementClass={
                      this.props.checkAndRemoveAdvertisementClass
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(GroupedItemPopup);
