import * as types from "./types";
import * as sessionTypes from "../session/types"

const cartReducer = (state = {}, action: any) => {
  switch (action.type) {
    case types.SET_CART:
      return  {
        ...state,
        ...action.payload,
      }
    case sessionTypes.CLEAR_SESSION:
      return { items: [], subTotal: 0, total: 0 };
    default:
      return state;
  }
};

const reducer = cartReducer;

export default reducer;
