export const FETCH_ADDRESSES = "address/FETCH_ADDRESSES";
export const FETCH_ADDRESSES_SUCCESS = "address/FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAIL = "address/FETCH_ADDRESSES_FAIL";
export const CREATE_ADDRESS = "address/CREATE_ADDRESS";
export const CREATE_ADDRESS_SUCCESS = "address/CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAIL = "address/CREATE_ADDRESS_FAIL";
export const UPDATE_ADDRESS = "address/UPDATE_ADDRESS";
export const UPDATE_ADDRESS_SUCCESS = "address/UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAIL = "address/UPDATE_ADDRESS_FAIL";
export const DELETE_ADDRESS = "address/DELETE_ADDRESS";
export const DELETE_ADDRESS_SUCCESS = "address/DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "address/DELETE_ADDRESS_FAIL";
