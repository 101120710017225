import * as types from "./types";

const fetchSavedCards = () => ({
  type: types.FETCH_SAVED_CARDS,
  payload: {
    request: {
      method: "get",
      url: "/users/saved_cards",
    }
  }
});

const deleteSavedCard = (token: any, paymentType: any) => ({
  type: types.DELETE_SAVED_CARD,
  payload: {
    request: {
      method: "delete",
      url: `/users/saved_cards/${encodeURIComponent(token)}`,
      data: {
        payment_type: paymentType
      }
    }
  }
});

const fetchStripeSavedCards = () => ({
  type: types.FETCH_STRIPE_SAVED_CARDS,
  payload: {
    request: {
      method: "get",
      url: "/users/stripe_cards",
    }
  }
});

const fetchStripeConnectSavedCards = () => ({
  type: types.FETCH_STRIPE_CONNECT_SAVED_CARDS,
  payload: {
    request: {
      method: "get",
      url: "/sapaad_payments/stripe_connect/v1/cards",
    }
  }
});

export default {
  fetchSavedCards,
  deleteSavedCard,
  fetchStripeSavedCards,
  fetchStripeConnectSavedCards,
};
