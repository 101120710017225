import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Modal, Dropdown } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCaretDown,
  faLanguage
} from "@fortawesome/free-solid-svg-icons";
import {
  LocaleContext,
  SUPPORTED_LOCALES,
} from "../../wrappers/localeWrapper";
import Image from "../../images/marketing-banner-image.jpg";

interface ILocaleDropdownProps {
  localeData: string;
};

function LocaleDropdown(props: ILocaleDropdownProps) {
  const [supportedLocales, setSupportedLocales] = useState([] as any);
  const localeContext = useContext(LocaleContext);

  useEffect(() => {
    if(props.localeData){
      setSupportedLocales(props.localeData.locales);
    }
  }, [props.localeData]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        as="div"
        className="d-flex mx-4 align-items-center locale-button"
        id="locale-button"
      >
        <FontAwesomeIcon icon={faLanguage} />
        <p className="mx-2">{localeContext.locale.toLocaleUpperCase()}</p>
        <FontAwesomeIcon icon={faCaretDown} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="locale-dropdown">
        <div>
          <div className="arrow"></div>
          {supportedLocales?.map((supportedLocale: any, index: any) => {
            return (
              <Dropdown.Item
                key={index}
                className="dropdown-option"
                onClick={() => localeContext.updateLocale(supportedLocale)}
              >
                {SUPPORTED_LOCALES[supportedLocale].name}
              </Dropdown.Item>
            )
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state: any) => {
  let localeData = state.company.locale_data;

  return { localeData };
};

export default connect(mapStateToProps)(LocaleDropdown);
