// Packages
import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import { connect, useSelector } from "react-redux";
import Pusher from "pusher-js";
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
// Components
import RiderMapContainerMobile from "../../mobileComponents/riderMapContainerMobile";
import MobileInlineLoader from "../../mobileComponents/mobileInlineLoader";
// Redux Operations
import { RootState } from "../../state/store";
import { EnvContext } from "../../components/envContext";
import { pageOperations } from "../../state/features/page";

export type CoordinateLiteral = { lat: number; lng: number };

interface IRiderTrackingScreenProps {
  fetchRiderTrackingConfigurations: any;
}

const RiderTrackingScreen = (props: IRiderTrackingScreenProps) => {
  const { orderInProgress, outlet } = useSelector((state: RootState) => state);

  const [configLoaded, setConfigLoaded] = useState(false);
  const [trackerLoaded, setTrackerLoaded] = useState(false);
  const [riderName, setRiderName] = useState("");
  const [originCoords, setOriginCoords] = useState({});
  const [destinationCoords, setDestinationCoords] = useState({});
  const [status, setStatus] = useState("");
  const [trackingError, setTrackingError] = useState(false);

  const { pusherId } = useContext(EnvContext);
  const pusher = new Pusher(pusherId, {});

  useEffect(() => {
    props.fetchRiderTrackingConfigurations().then((response: any) => {
      if (response.error) {
        setTrackingError(true);
        setTrackerLoaded(true);
      } else {
        setConfigLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    if (configLoaded && orderInProgress.id) {
      if (orderInProgress.source_coordinates?.latitude && orderInProgress.destination_coordinates?.latitude) {
        setOriginCoords({ lat: orderInProgress.source_coordinates?.latitude, lng: orderInProgress.source_coordinates?.longitude });
        setDestinationCoords({ lat: orderInProgress.destination_coordinates?.latitude, lng: orderInProgress.destination_coordinates?.longitude });
      }
      if (orderInProgress.tracking_data?.status) {
        if (status != orderInProgress.tracking_data.status) {
          setStatus(orderInProgress.tracking_data.status);
        }
        if (["in_transit", "entered_customer_proximity"].includes(orderInProgress.tracking_data.status)) {
          setOriginCoords({ lat: orderInProgress.tracking_data.geo_coordinates?.lat, lng: orderInProgress.tracking_data.geo_coordinates?.lng });
        }
        if (orderInProgress.tracking_data.rider_details?.rider_name) {
          setRiderName(orderInProgress.tracking_data.rider_details?.rider_name);
        }
      }

      setTrackerLoaded(true);
      bindToPusherChannel(orderInProgress.id);
    }
  }, [configLoaded, orderInProgress.id]);

  useEffect(() => {
    if (configLoaded) {
      if (originCoords.lat && originCoords.lng && destinationCoords.lat && destinationCoords.lng) {
        setTrackingError(false);
      } else {
        setTrackingError(true);
      }
    }
  }, [configLoaded, originCoords, destinationCoords]);

  const bindToPusherChannel = (orderId: any) => {
    const channel = pusher.subscribe(`track_order_${orderId}`);
    if (channel) {
      channel.bind("rider_geo_coordinates_updated", (data: any) => {
        if (data.lat && data.lng) {
          setOriginCoords({ lat: data.lat, lng: data.lng });
          setTrackingError(false);
        }
        if (data.status) {
          setStatus(data.status);
        }
        if (data.rider_name) {
          setRiderName(data.rider_name);
        }
      });
      channel.bind("status_updated", (data: any) => {
        if (data.status == "delivered") {
          location.href = "/m/trackorder";
        }
      });
    }
  }

  return (
    <div className="rider-tracking-container-mobile">
      {!trackerLoaded ? (
        <div className="tracking-details-error">
          <MobileInlineLoader />
        </div>
      ) : trackingError ? (
        <div className="tracking-details-error">
          <div className="mb-2">
            <FormattedMessage
              id="error.fetch_tracking_details"
              defaultMessage="Error fetching tracking details."
            />
          </div>
          <div>
            <FormattedMessage
              id="error.try_again_later"
              defaultMessage="Please try again later."
            />
          </div>
          <Link to="/m/trackorder" className="btn btn-primary">
            <FormattedMessage
              id="global.close"
              defaultMessage="Close"
            />
          </Link>
        </div>
      ) : (
        <RiderMapContainerMobile
          userCoords={destinationCoords}
          riderCoords={originCoords}
          status={status}
          riderName={riderName}
          setTrackingError={(status: boolean) => setTrackingError(status)}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  fetchRiderTrackingConfigurations: pageOperations.fetchRiderTrackingConfigurations,
};

export default connect(null, mapDispatchToProps)(RiderTrackingScreen);
