import { createStore, combineReducers, applyMiddleware } from "redux";
import * as reducers from "./features";
import storeInitialState from "./storeInitialState";
import { multiClientMiddleware } from 'redux-axios-middleware';
import { axiosClient, oneMapSGClient } from "./middlewares";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const rootReducer = combineReducers(reducers);

const store = createStore(
  rootReducer,
  storeInitialState as any,
  composeWithDevTools(
    applyMiddleware(
      multiClientMiddleware({
        default: { client: axiosClient },
        oneMapSG: { client: oneMapSGClient }
      })
    )
  )
);

export type RootState = ReturnType<typeof store.getState>;

export { store };
