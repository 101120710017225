import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from "../pageHeader";
import SavedAddresses from "../savedAddresses";
import SavedPayments from "../savedPayments";
import { pageOperations } from "../../state/features/page";

interface IMyProfileProps {
  fetchProfileConfigurations: any;
}

interface IMyProfileState {}

class MyProfile extends Component<IMyProfileProps, IMyProfileState> {
  constructor(props: IMyProfileProps) {
    super(props);
    this.state = {};

    this.props.fetchProfileConfigurations();
  }

  render() {
    return (
      <div className="my-profile-page">
        <SavedAddresses />
        <SavedPayments />
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchProfileConfigurations: pageOperations.fetchProfileConfigurations,
};

export default connect(null, mapDispatchToProps)(MyProfile);
