// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from 'react-intl';
// Components
import MobileInlineLoader from "../mobileInlineLoader";
// Redux Operations
import { discountOperations } from "../../state/features/discount";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import {
  calculateAmountsAndBreakdowns,
  formatDecimal,
} from "../../helpers/itemCalculations";

interface IDiscountWidgetMobileProps {
  discount: any;
  cart: any;
  currency: string;
  menu: any;
  orderType: any;
  loyalty: any;
  selectedArea: any;
  deliveryChargeId: any;
  fetchDiscount: any;
  applyDiscount: any;
  removeDiscount: any;
  setCart: any;
}

function DiscountWidgetMobile(props: IDiscountWidgetMobileProps) {
  const [discountCode, setDiscountCode] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [showDiscountInput, setShowDiscountInput] = useState(false);
  const [discountsLoading, setDiscountLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (props.discount) {
      validateDiscount(props.discount);
    }
  }, [props.cart.subTotal, props.cart.totalBeforeDiscount]);

  const onDiscountCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountError("");
    setDiscountCode(e.target.value);
  };

  const removeDiscount = () => {
    setDiscountError("");
    setDiscountCode("");
    props.removeDiscount();
    updateCart(null);
  };

  const applyDiscount = () => {
    setDiscountLoading(true);
    props.fetchDiscount(discountCode).then((response: any) => {
      if (response.error) {
        setDiscountError(
          intl.formatMessage({ id: "error.invalid_discount_code", defaultMessage: "Invalid Discount code" })
        );
      } else {
        validateDiscount(response.payload.data);
      }
      setDiscountLoading(false);
    });
  };

  const validateDiscount = (discount: any) => {
    let maxdiscountableAmount = parseFloat(props.cart.subTotal);
    if (discount.on_total) {
      maxdiscountableAmount += parseFloat(props.cart.surchargeSubTotal);
    }

    let discountAmount;
    if (discount.discount_type == "PERCENTAGE") {
      discountAmount =
        (maxdiscountableAmount * parseFloat(discount.discount_rate)) / 100;
    } else {
      discountAmount = parseFloat(discount.discount_rate);
    }

    if (discountAmount > maxdiscountableAmount) {
      setDiscountError(
        intl.formatMessage(
          { id: "discount.validation.use_only_if_above_amount", defaultMessage: "This coupon can only be used for orders above {currency} {amount}!" },
          { currency: props.currency, amount: formatDecimal(discountAmount) }
        )
      );
      props.removeDiscount();
      updateCart(null);
      return;
    } else if (maxdiscountableAmount < parseFloat(discount.min_order_amount)) {
      setDiscountError(
        intl.formatMessage(
          { id: "discount.validation.use_only_if_above_amount", defaultMessage: "This coupon can only be used for orders above {currency} {amount}!" },
          { currency: props.currency, amount: formatDecimal(discount.min_order_amount) }
        )
      );
      props.removeDiscount();
      updateCart(null);
      return;
    }

    discount.discountedAmount = discountAmount;
    discount.maxdiscountableAmount = maxdiscountableAmount;
    props.applyDiscount(discount);
    updateCart(discount);
    setDiscountError("");
  };

  const updateCart = (discount: any) => {
    let cart = calculateAmountsAndBreakdowns(
      props.cart.items,
      props.menu,
      props.orderType,
      discount,
      props.loyalty,
      props.selectedArea,
      props.deliveryChargeId,
    ) as any;
    cart.items = props.cart.items;

    props.setCart(cart);
  };

  return (
    <div className="widget-mobile discount-widget-mobile">
      {discountsLoading ? (
        <MobileInlineLoader />
      ) : props.discount ? (
        <div className="apply-coupon-section">
          <div className="apply-coupon">
            <p className="input-label mb-0">
              <span className="loyalty-ponits">
                <FormattedMessage
                  id="discount.coupon_applied"
                  defaultMessage="{discountName} <spanTag>coupon code applied</spanTag>"
                  values={{
                    discountName: props.discount.discount_name,
                    spanTag: (text) => <span className="label">{text}</span>,
                  }}
                />
              </span>
              <br />
              {props.discount.discount_type == "PERCENTAGE" ? (
                <FormattedMessage
                  id="discount.percentage_off"
                  defaultMessage="{discountRate}% off"
                  values={{
                    discountRate: formatDecimal(props.discount.discount_rate),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="discount.value_off"
                  defaultMessage="{currency} {discountRate} off"
                  values={{
                    currency: props.currency,
                    discountRate: formatDecimal(props.discount.discount_rate),
                  }}
                />
              )}
            </p>
            <p className="enter-coupon" onClick={removeDiscount}>
              <FormattedMessage
                id="discount.remove_coupon"
                defaultMessage="Remove Coupon"
              />
            </p>
          </div>
        </div>
      ) : (
        <div className="apply-coupon-section">
          <div className="apply-coupon">
            {showDiscountInput && (
              <a href="javascript:;"
                  onClick={() => setShowDiscountInput(false)}
                  className="closeCouponWidget">
                    &times;
              </a>
            )}
            <p
              className={
                showDiscountInput ? "input-label mb-1" : "input-label mb-0"
              }
            >
              <FormattedMessage
                id="discount.do_you_have_a_coupon_code"
                defaultMessage="Do you have a coupon code?"
              />
            </p>
            {!showDiscountInput && (
              <p
                className="enter-coupon"
                onClick={() => setShowDiscountInput(true)}
              >
                <FormattedMessage
                  id="discount.enter_code"
                  defaultMessage="Enter code"
                />
              </p>
            )}
          </div>
          {showDiscountInput && (
            <div className="form-group d-flex mb-0">
              <FormattedMessage
                id="discount.enter_coupon_code"
                defaultMessage="Enter coupon code"
              >
                {(placeholder) => (
                  <input
                    name="discount_code"
                    type="text"
                    required
                    className="form-control custom-input mr-2 discount-input"
                    placeholder={placeholder}
                    autoComplete="off"
                    value={discountCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onDiscountCodeChange(e)
                    }
                    onFocus={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onDiscountCodeChange(e)
                    }
                    dir="auto"
                  ></input>
                )}
              </FormattedMessage>
              <button
                className="btn btn-outline-primary px-4"
                onClick={applyDiscount}
                disabled={!discountCode || discountError != ""}
              >
                <FormattedMessage
                  id="global.apply"
                  defaultMessage="Apply"
                />
              </button>
            </div>
          )}
          {( showDiscountInput && discountError) && (
            <strong className="input-label mt-3 mb-0 text-danger">{discountError}</strong>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let discount = state.discount;
  let cart = state.cart;
  let currency = state.company.currency;
  let menu = state.menu;
  let orderType = state.session.order_type;
  let loyalty = state.loyalty.redeemedPoints;
  let selectedArea = state.areas.selectedArea;
  let deliveryChargeId = state.outlet.delivery_charge_id;

  return {
    discount,
    cart,
    currency,
    menu,
    orderType,
    loyalty,
    selectedArea,
    deliveryChargeId,
  };
};

const mapDispatchToProps = {
  fetchDiscount: discountOperations.fetchDiscount,
  applyDiscount: discountOperations.applyDiscount,
  removeDiscount: discountOperations.removeDiscount,
  setCart: cartOperations.setCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountWidgetMobile);
