const translatedName = (item: any, currentLocale: string) => {
  if (item.translations) {
    return item.translations[currentLocale]?.name || item.name;
  }

  return item.name;
};

const translatedDescription = (item: any, currentLocale: string) => {
  if (item.translations) {
    return item.translations[currentLocale]?.description || item.short_description || item.description || "";
  }

  return item.short_description || item.description || "";
};

export {
  translatedName,
  translatedDescription,
};
