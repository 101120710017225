// Packages
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
// Components
import InlineLoader from "../../components/inlineLoader";
import SavedAddressWidgetMobile from "../savedAddressWidgetMobile";
// Redux Operations
import { addressesOperations } from "../../state/features/addresses";

interface ISavedAddressesMobileProps {
  addresses: any;
  fetchAddresses: any;
}

function SavedAddressesMobile(props: ISavedAddressesMobileProps) {
  const [loading, handleLoading] = useState(true);

  useEffect(() => {
    reloadAddresses();
  }, []);

  const reloadAddresses = () => {
    handleLoading(true);
    props.fetchAddresses().then(() => {
      handleLoading(false);
    });
  };

  return (
    <div className="my-profile-section saved-address-list">
      <div className="my-profile-section-header">
        <h2 className="header">
          <FormattedMessage
            id="my_account.saved_addresses"
            defaultMessage="Saved Addresses"
          />
        </h2>
      </div>

      {loading ? (
        <InlineLoader />
      ) : props.addresses && props.addresses.length ? (
        props.addresses.map((address: any, index: any) => {
          return (
            <SavedAddressWidgetMobile
              key={index}
              address={address}
              reloadAddresses={() => reloadAddresses()}
            />
          );
        })
      ) : (
        <div className="no-address">
          <FormattedMessage
            id="my_account.no_address_found"
            defaultMessage="No addresses found."
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let addresses = state.addresses;

  return { addresses };
};

const mapDispatchToProps = {
  fetchAddresses: addressesOperations.fetchAddresses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedAddressesMobile);
