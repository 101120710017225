import React, { useEffect } from "react";

interface IHtmlRendererProps {
  htmlContent: string;
}

const HtmlRenderer: React.FC<IHtmlRendererProps> = ({ htmlContent }) => {
  useEffect(() => {
    const scriptElement = document.getElementById(
      "initiate-authentication-script"
    );
    if (scriptElement) {
      const newScript = document.createElement("script");
      newScript.text = scriptElement.text;
      document.body.appendChild(newScript);
    }
  }, [htmlContent]);

  return (
    <div className="hide-3ds-auth">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default HtmlRenderer;
