// Packages
import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FormattedMessage } from 'react-intl';
// Components
import InlineLoader from "../../../inlineLoader";
import SelectInput from "../../../selectInput";
import StripeConnectCheckoutForm from "../checkoutForm";
import StripeConnectSavedCards from "../savedCards";
// Contexts
import { EnvContext } from "../../../envContext";
// Redux Operations
import { savedCardsOperations } from "../../../../state/features/savedCards";
import { paymentTypeOperations } from "../../../../state/features/paymentTypes";

interface IStripeConnectWrapperProps {
  cartTotal: any;
  currencyCode: any;
  orderPayload: any;
  savedCards: any;
  togglePlaceOrderButton: (status: boolean) => void;
  fetchStripeConnectSavedCards: () => void;
}

function StripeConnectWrapper(props: IStripeConnectWrapperProps) {
  const [stripePromise, setStripePromise] = useState(null);
  const [stripeOptions, setStripeOptions] = useState(null);

  const [showNewCard, setShowNewCard] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(true);

  // Stripe Publishable Key
  const { stripeConnectPublishableKey } = useContext(EnvContext);

  const paymentElementAppearance = {
    // Add customisations here
    // Refer documentation: https://stripe.com/docs/elements/appearance-api
  };

  useEffect(() => {
    // fetch saved cards
    fetchSavedCards();
    props.togglePlaceOrderButton(false);
  }, []);

  const fetchSavedCards = async () => {
    // Wait for saved cards to be retrieved
    await props.fetchStripeConnectSavedCards();
    
    setCardsLoading(false);
  };

  useEffect(() => {
    // fetch saved cards
    if (!cardsLoading) {
      if (!props.savedCards.length) {
        setShowNewCard(true);
      } else {
        props.togglePlaceOrderButton(true);
      }
    }
  }, [cardsLoading, props.savedCards]);

  useEffect(() => {
    if (stripeConnectPublishableKey) {
      setStripePromise(loadStripe(stripeConnectPublishableKey));
    }
  }, [stripeConnectPublishableKey]);

  useEffect(() => {
    setStripeOptions({
      mode: 'payment',
      amount: parseInt(props.cartTotal * 100),
      currency: props.currencyCode.toLowerCase(),
      appearance: paymentElementAppearance,
    });
  }, [props.cartTotal]);

  const switchToNewCard = () => {
    setShowNewCard(true);
  };

  const switchToSavedCards = () => {
    setShowNewCard(false);
  };

  const newCardSection = (
    <div className="mt-3 mb-3 stripe-connect-new-card">
      <div className="heading">
        {stripePromise && stripeOptions ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeConnectCheckoutForm
              orderPayload={props.orderPayload}
              togglePlaceOrderButton={(status: boolean) => props.togglePlaceOrderButton(status)}
            />
          </Elements>
        ) : (
          <InlineLoader />
        )}
        {props.savedCards.length ? (
          <a className="switch-to-saved-cards" onClick={switchToSavedCards}>
            <FormattedMessage
              id="payment.use_saved_cards"
              defaultMessage="Use Saved Cards"
            />
          </a>
        ) : null}
      </div>
    </div>
  );

  const savedCardsSection = (
    stripePromise && stripeOptions ? (
      <Elements stripe={stripePromise} options={stripeOptions}>
        <StripeConnectSavedCards
          orderPayload={props.orderPayload}
          switchToNewCard={switchToNewCard}
          togglePlaceOrderButton={(status: boolean) => props.togglePlaceOrderButton(status)}
        />
      </Elements>
    ) : (
      <InlineLoader />
    )
  );

  return (
    <div className="stripe-connect-wrapper">
      {cardsLoading ? (
        <InlineLoader />
      ) : props.savedCards.length && !showNewCard ? (
        savedCardsSection
      ) : (
        newCardSection
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  let cartTotal = state.cart.total;
  let currencyCode = state.company.currency?.toLowerCase();
  let savedCards = state.savedCards;

  return {
    cartTotal,
    currencyCode,
    savedCards,
  };
};

const mapDispatchToProps = {
  fetchStripeConnectSavedCards: savedCardsOperations.fetchStripeConnectSavedCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(StripeConnectWrapper);
