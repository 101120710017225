import React from "react";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import { connect } from "react-redux";
import { errorOperations } from "../../state/features/error";

interface IMasterCardSectionWrapperProps {
  orderPayload: any;
  buildMastercardSession: () => void; 
  togglePlaceOrderButton: (status: boolean) => void;
  updateError: any;
}

function MasterCardSectionWrapper(props: IMasterCardSectionWrapperProps) {

  const checkoutClick = (event: any) => {
    props.buildMastercardSession(props.orderPayload).then((response: any) => {
      if (!response.error && response.payload.data.session_id) {
        ConfigureAndMoveToPaymentPage(response.payload.data.session_id);
      } else {
        props.togglePlaceOrderButton(true);
      }
    });
  };

  const ConfigureAndMoveToPaymentPage = async (sessionId: any) => {
    await Checkout.configure({
      session: {
        id: sessionId
      },
    });
    Checkout.showPaymentPage();
  };

  return (
    <div id="mastercardCheckoutForm">
      <button type="submit" id="mastercardCheckoutButton" className="hidden" onClick={checkoutClick}></button>
    </div>
  );
}

const mapDispatchToProps = {
  buildMastercardSession: paymentTypeOperations.buildMastercardSession,
  updateError: errorOperations.updateError,
};

export default connect(null, mapDispatchToProps)(MasterCardSectionWrapper);
