import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../../containers/home";
import Checkout from "../../containers/checkout";
import OrderTracking from "../../containers/orderTracking";
import MyAccountScreen from "../../containers/myAccount";
import PaymentProcessing from "../../containers/paymentProcessing";
import TermsOfService from "../../containers/termsOfService";
import RiderTracking from "../../containers/riderTracking";


interface IAppProps {}

const App = (props: IAppProps) => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/trackorder" component={OrderTracking} />
        <Route exact path="/my-account/profile" component={MyAccountScreen} />
        <Route exact path="/my-account/past-orders" component={MyAccountScreen} />
        <Route exact path="/my-account/loyalty" component={MyAccountScreen} />
        <Route exact path='/payment-processing' component={PaymentProcessing}/>
        <Route exact path='/terms-of-service' component={TermsOfService}/>
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default App;
