// Packages
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Icons, Images etc.
import Success from "../../images/success-animation.gif";

interface IAddedToCartPopupProps {
  onClose: any;
}

function AddedToCartPopup(props: IAddedToCartPopupProps) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  return (
    <Modal show={show} dialogClassName="custom curved" centered>
      <div className="added-to-cart-popup">
        <img className='image' src={Success} />
        <p className="success-message">
          <FormattedMessage
            id="menu.item.added_to_cart"
            defaultMessage="Item successfully added to cart."
          />
        </p>
        <button
          className="button btn btn-primary btn-block w-100"
          onClick={handleClose}
        >
          <FormattedMessage
            id="global.close"
            defaultMessage="Close"
          />
        </button>
      </div>
    </Modal>
  );
}
export default AddedToCartPopup;
