// Packages
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import loyaltyImageAnimation from "../../images/placeorder-loyalty.svg";
import placeOrderAnimation from "../../images/food-delivery-noloyalty.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";

interface IOrderPlacedPopupProps {
  currency: any;
  earnedLoyalty: any;
}

function OrderPlacedPopup(props: IOrderPlacedPopupProps) {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      dialogClassName="custom modal-w80"
      centered
      backdrop="static"
    >
      <div className="order-placed-popup">
        {Number(props.earnedLoyalty) ? (
          <React.Fragment>
            <div className="loyalty-icon">
              <object type="image/svg+xml" data={loyaltyImageAnimation}>
              </object>
            </div>
            <p className="loyalty-message">
              <FormattedMessage
                id="order.thank_you_for_placing_an_order"
                defaultMessage="Thank you for placing an order with us!"
              />
            </p>
            <p className="loyalty-earnings">
              <FormattedMessage
                id="order.you_have_earned_loyalty"
                defaultMessage="You have earned <spanTag>{earnedLoyalty} Loyalty {currency}</spanTag> from your order"
                values={{
                  earnedLoyalty: formatDecimal(props.earnedLoyalty),
                  currency: props.currency,
                  spanTag: (text) => <span>{text}</span>,
                }}
              />
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="loyalty-icon">
              <object type="image/svg+xml" data={placeOrderAnimation}></object>
            </div>
            <p className="loyalty-message">
              <FormattedMessage
                id="order.thank_you_for_placing_an_order"
                defaultMessage="Thank you for placing an order with us!"
              />
            </p>
          </React.Fragment>
        )}
        <button
          onClick={() => window.location.href = "/trackorder"}
          className="button btn w-100 btn-primary"
        >
          <FormattedMessage
            id="order.track_order"
            defaultMessage="Track Order"
          />
          <span className="ml-2">
            <FontAwesomeIcon icon={faChevronCircleRight} />
          </span>
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;

  return { currency };
};

export default connect(mapStateToProps)(OrderPlacedPopup);
