import * as types from "./types";
import * as invoiceTypes from "../invoice/types";

const emailInvoiceAlert = () => {
  return {
    type: "success",
    messageCode: "success.invoice_sent_to_email",
  };
}

const alertReducer = (state = null, action: any) => {
  switch (action.type) {
    case types.UPDATE_ALERT:
      return action.payload;
    case types.RESET_ALERT:
      return null;
    case invoiceTypes.SEND_INVOICE_TO_REGISTERED_EMAIL_SUCCESS:
    case invoiceTypes.SEND_INVOICE_TO_NEW_EMAIL_SUCCESS:
      return emailInvoiceAlert();

    default:
      return state;
  }
};

const reducer = alertReducer;

export default reducer;
