import {
  faCreditCard,
  faMoneyBillWave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  EMAIL_REGEX,
  PAYMENT_PAID,
  MASTERCARD_PAYMENT_TYPE,
} from "../../helpers/constants";
import {
  orderPayload,
} from "../../helpers/orderPayloadBuilder";
import { paymentTypeOperations } from "../../state/features/paymentTypes";
import { tipOperations } from "../../state/features/tips";
import { RootState } from "../../state/store";
import AdyenSectionWrapper from "../../components/adyenSectionWrapper";
import CardSectionWrapper from "../cardSectionWrapper";
import CcAvenueForm from "../ccAvenueForm";
import FiservForm from "../fiservForm";
// import GeideaCardSectionWrapper from "../geideaCardSectionWrapper";
import InlineLoader from "../inlineLoader";
import IPay88Form from "../iPay88Form";
import MyFatoorahPaymentSelectionWrapper from "../../components/myFatoorahPaymentSelectionWrapper";
import OmiseCardSectionWrapper from "../../components/omiseCardSectionWrapper";
import QpayCardSectionWrapper from "../qpayCardSectionWrapper";
import MasterCardSectionWrapper from "../../components/masterCardSectionWrapper";
import QpayForm from "../qpayForm";
import checkIcon from "../../images/check.png";

const initialState = {
  orderPayload: {},
  stripeSelectedCard: {},
  cardToken: null,
  userName: "",
  userEmail: "",
  selectedPaymentType: null,
  selectedPaymentTypeTitle: null,
  myfatoorahPaymentModeId: null,
};

const paymentSelectReducer = (
  state: typeof initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "SET_CARD_TOKEN":
      return { ...state, cardToken: action.payload };
    case "SET_STRIPE_SELECTED_CARD":
      return { ...state, stripeSelectedCard: action.payload };
    case "SET_USER_EMAIL":
      return { ...state, userEmail: action.payload };
    case "SET_SELECTED_PAYMENT_TYPE":
      return { ...state, selectedPaymentType: action.payload };
    case "SET_SELECTED_PAYMENT_TYPE_TITLE":
      return { ...state, selectedPaymentTypeTitle: action.payload };
    case "SET_ORDER_PAYLOAD":
      return { ...state, orderPayload: action.payload };
    case "SET_MY_FATOORAH_PAYMENT_MODE_ID":
      return { ...state, myfatoorahPaymentModeId: action.payload };
    default:
      return state;
  }
};

interface IPaymentSelectPopupMobileProps {
  tipAmount: number;
  order: any;
  onClose: () => any;
  buildCCAvenuePaymentData: (data: any) => any;
  buildQpayPaymentData: (data: any) => any;
  buildIPay88PaymentData: (data: any) => any;
  buildFiservPaymentData: (data: any) => any;
  createGeideaTempOrder: (data: any) => any;
  makeTipPayment: (data: any) => any;
}

const PaymentSelectPopupMobile = (props: IPaymentSelectPopupMobileProps) => {
  const [show, setShow] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>({});
  const [disablePlaceOrderCta, setDisablePlaceOrderCta] = useState(true);
  const { currency, decimal_precision } = useSelector(
    (state: RootState) => state.company
  );
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const { user, paymentTypes } = useSelector((state: RootState) => state);
  const [state, dispatch] = useReducer(paymentSelectReducer, initialState);
  const [riderAssignedEvent, setRiderAssignedEvent] = useState(null as any);

  useEffect(() => {
    if (props.order.status_updates) {
      let riderAssignedEvent;
      riderAssignedEvent = props.order.status_updates.find((statusUpdate: any) => {
        return statusUpdate.event == "rider_assigned";
      });
      setRiderAssignedEvent(riderAssignedEvent);
    }
  }, [props.order.status_updates]);

  const placeOrder = () => {
    if (paymentRequirementsFulfilled()) {
      handlePaymentDetailsConfirm();
    } else {
      // TODO
      // reportPaymentRequirementsError();
    }
  };

  const handlePaymentDetailsConfirm = () => {
    setDisablePlaceOrderCta(true);
    let paymentDetails = {
      save_card: false,
      is_saved_card: false,
      tips: props.tipAmount,
      ref_card_number: null,
      payment_type_id: selectedPaymentMethod?.sapaad_payment_type_id,
      name: selectedPaymentMethod?.title,
      total_amount: 0
    }
    let payload = {
      order_id: props.order.id,
      payment_details: paymentDetails,
      rider_id: riderAssignedEvent.rider_id
    };
    let tipPaymentPath;
    switch (selectedPaymentMethod?.payment_type) {
      case "stripe":
        let new_card_form = document.getElementById("btn_card_section");
        if (new_card_form != null) {
          // payment with new card
          payload.payment_details.payment_status = PAYMENT_PAID;
          payload.payment_details.save_card = false;
          payload.payment_details.is_saved_card = false;
          payload.payment_details.transaction_id = null;
          payload.payment_details.ref_card_number = null;
          payload.payment_details.total_amount = 0;

          dispatch({ type: "SET_ORDER_PAYLOAD", payload });
          setTimeout(() => {
            new_card_form?.click();
          }, 500);
          return;
        } else {
          // payment with already saved card
          payload.payment_details.save_card = false;
          payload.payment_details.is_saved_card = true;
          payload.payment_details.transaction_id = state.stripeSelectedCard.id;
          payload.payment_details.ref_card_number = state.stripeSelectedCard.id;
          payload.payment_details.total_amount = 0;
          tipPaymentPath = "payments/stripe/v1/tips";
        }
        break;
      // TODO
      // case "paytabs":
      //   tipPaymentPath = "payments/v2/paytabs/payment_page";
      //   break;
      // case "pay_u":
      //   tipPaymentPath = "payments/pay_u/v1/payment_pages";
      //   payload.user_name = state.userName || user.name;
      //   payload.user_email = state.userEmail || user.email;
      //   break;
      // case "my_fatoorah":
      //   tipPaymentPath = "payments/my_fatoorah/v1/payment_pages";
      //   payload.myFatoorahSelectedPaymentMode = state.myfatoorahPaymentModeId;
      //   break;
      // case "cc_avenue":
      //   props.buildCCAvenuePaymentData(payload).then((response: any) => {
      //     if (!response.error) {
      //       dispatch({
      //         type: "SET_ORDER_PAYLOAD",
      //         payload: response.payload.data,
      //       });
      //     }
      //   });
      //   return;
      // case "dpo":
      //   tipPaymentPath = "payments/dpo/v1/payment_pages";
      //   break;
      // case "qpay":
      //   payload.cardToken = state.cardToken;
      //   props.buildQpayPaymentData(payload).then((response: any) => {
      //     if (!response.error) {
      //       dispatch({
      //         type: "SET_ORDER_PAYLOAD",
      //         payload: response.payload.data,
      //       });
      //     }
      //   });
      //   return;
      // case "i_pay88":
      //   props.buildIPay88PaymentData(payload).then((response: any) => {
      //     if (!response.error) {
      //       dispatch({
      //         type: "SET_ORDER_PAYLOAD",
      //         payload: response.payload.data,
      //       });
      //     }
      //   });
      //   return;
      // case "fiserv":
      //   props.buildFiservPaymentData(payload).then((response: any) => {
      //     if (!response.error) {
      //       dispatch({
      //         type: "SET_ORDER_PAYLOAD",
      //         payload: response.payload.data,
      //       });
      //     }
      //   });
      //   return;
      // case "omise":
      //   let btnOmise = document.getElementById("omiseCheckoutButton");
      //   if (btnOmise != null) {
      //     dispatch({
      //       type: "SET_ORDER_PAYLOAD",
      //       payload,
      //     });
      //     setTimeout(() => {
      //       btnOmise?.click();
      //     }, 500);
      //   }
      //   return;
      // case "adyen":
      //   var adyenBtn = document.getElementsByClassName(
      //     "adyen-checkout__button--pay"
      //   )[0];
      //   adyenBtn?.click();
      //   return;
      // case "geidea":
      //   payload.payment.card_token = state.cardToken;
      //   if (state.cardToken) {
      //     tipPaymentPath = "payments/geidea/v1/orders";
      //     break;
      //   } else {
      //     props.createGeideaTempOrder(payload).then((response: any) => {
      //       if (!response.error) {
      //         var btnGeidea = document.getElementById("newCardCheckoutButton");
      //         dispatch({
      //           type: "SET_ORDER_PAYLOAD",
      //           payload: response.payload.data,
      //         });
      //         setTimeout(() => {
      //           btnGeidea?.click();
      //         }, 500);
      //       }
      //     });
      //   }
      //   return;
      default:
        setDisablePlaceOrderCta(false);
        return;
    }
    placeOrderEndpoint(tipPaymentPath, payload);
  };

  const placeOrderEndpoint = (path: string, payload: any) => {
    setShow(false);
    props.makeTipPayment(path, payload).then((response: any) => {
      if (!response.error) {
        if (response.payload.data.payment_page_url) {
          window.location.href = response.payload.data.payment_page_url;
        } else {
          setPaymentCompleted(true);
        }
      }
      setShow(true);
      setDisablePlaceOrderCta(false);
    });
  };

  const onPaymentMethodSelect = (paymentMethod: any) => {
    if (selectedPaymentMethod.payment_type != paymentMethod.payment_type) {
      if (paymentMethod.payment_type === "adyen") {
        dispatch({
          type: "SET_ORDER_PAYLOAD",
          payload: orderPayload(state, props),
        });
      } else {
        dispatch({
          type: "SET_ORDER_PAYLOAD",
          payload: {},
        });
      }
    }
    setSelectedPaymentMethod(paymentMethod);
    setDisablePlaceOrderCta(false);
    dispatch({
      type: "SET_SELECTED_PAYMENT_TYPE",
      payload: paymentMethod.payment_type,
    });
    dispatch({
      type: "SET_SELECTED_PAYMENT_TYPE_TITLE",
      payload: paymentMethod.title,
    });
  };

  const paymentRequirementsFulfilled = () => {
    if (["qpay", "pay_u", "i_pay88"].includes(state?.selectedPaymentType?.payment_type)) {
      return user.email || EMAIL_REGEX.test(state?.userEmail);
    }
    return true;
  };

  const emailBoxShouldShow = (
    paymentType: any,
    selectedPaymentType: any,
    email: any
  ) => {
    if (!email) {
      return (
        (paymentType.payment_type == "pay_u" &&
          selectedPaymentType == "pay_u") ||
        (paymentType.payment_type == "qpay" && selectedPaymentType == "qpay") ||
        (paymentType.payment_type == "i_pay88" &&
          selectedPaymentType == "i_pay88")
      );
    }
    return false;
  };

  return (
    <Modal dialogClassName="payment-select-mobile bottom-modal" show={show}>
      {!paymentCompleted ? (
        <Fragment>
          <div className="popup-header">
            <span className="title">Select your payment method</span>
          </div>
          <div className="payment-select-container">
            <div className="payment-methods">
              {paymentTypes.map((item, index) => (
                <div
                  key={index}
                  className={`form-check method ${
                    selectedPaymentMethod?.payment_type === item.payment_type
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => onPaymentMethodSelect(item)}>
                  <label
                    className="form-check label"
                    htmlFor={item.payment_type}>
                    <input
                      className="form-check-input"
                      type="radio"
                      id={item.payment_type}
                      checked={selectedPaymentMethod?.payment_type === item.payment_type}
                      readOnly
                    />
                    <FontAwesomeIcon
                      icon={
                        item.payment_type == "cash_on_delivery"
                          ? faMoneyBillWave
                          : faCreditCard
                      }
                      size="sm"
                    />
                    <span>{item.title}</span>
                  </label>
                  {selectedPaymentMethod?.payment_type === "my_fatoorah" &&
                    item.payment_type === "my_fatoorah" && (
                      <MyFatoorahPaymentSelectionWrapper
                        tipAmount={props.tipAmount}
                        selectedPaymentMode={(paymentModeId) =>
                          dispatch({
                            type: "SET_MY_FATOORAH_PAYMENT_MODE_ID",
                            payload: paymentModeId,
                          })
                        }
                      />
                    )}

                  {selectedPaymentMethod?.payment_type === "stripe" &&
                    item.payment_type === "stripe" && (
                      <CardSectionWrapper
                        stripeKey={item?.public_key}
                        payload={state.orderPayload}
                        onCardSelect={(cardData: any) =>
                          dispatch({
                            type: "SET_STRIPE_SELECTED_CARD",
                            payload: cardData,
                          })
                        }
                        showPointsEarnedPopup={(earnedLoyalty: any) => {}}
                        togglePlaceOrderButton={(status: boolean) =>
                          setDisablePlaceOrderCta(!status)
                        }
                        paymentType="tip"
                        toggleTipSuccessfulPopup={(status: boolean) =>
                          setPaymentCompleted(status)
                        }
                      />
                    )}

                  {selectedPaymentMethod?.payment_type === "qpay" &&
                    item.payment_type === "qpay" &&
                    item.can_save_cards && (
                      <QpayCardSectionWrapper
                        updateCardToken={(token: any) =>
                          dispatch({ type: "SET_CARD_TOKEN", payload: token })
                        }
                      />
                    )}

                    {selectedPaymentMethod?.payment_type === MASTERCARD_PAYMENT_TYPE &&
                    item.payment_type === MASTERCARD_PAYMENT_TYPE && (
                      <MasterCardSectionWrapper
                        orderPayload={orderPayload(this.state, this.props)}
                        togglePlaceOrderButton={(status: boolean) =>
                          setDisablePlaceOrderCta(!status)
                        }
                      />
                    )}

                  {selectedPaymentMethod?.payment_type === "omise" &&
                    item.payment_type === "omise" && (
                      <OmiseCardSectionWrapper
                        omiseKey={item.public_key}
                        payload={state.orderPayload}
                        currency={currency}
                        showPointsEarnedPopup={(earnedLoyalty: any) => {}}
                        togglePlaceOrderButton={(status: boolean) =>
                          setDisablePlaceOrderCta(!status)
                        }
                        companyDecimalPrecision={decimal_precision}
                      />
                    )}

                  {selectedPaymentMethod?.payment_type === "adyen" &&
                    item.payment_type === "adyen" && (
                      <div className="payment-subsection adyen">
                        <div className="selections">
                          <AdyenSectionWrapper
                            payload={state.orderPayload}
                            paymentType={item}
                            showPointsEarnedPopup={(earnedLoyalty: any) => {}}
                            togglePlaceOrderButton={(status: boolean) =>
                              setDisablePlaceOrderCta(!status)
                            }
                          />
                        </div>
                      </div>
                    )}

                  {/*{selectedPaymentMethod?.payment_type === "adyen" &&
                    item.payment_type === "adyen" && (
                      <GeideaCardSectionWrapper
                        geideaKey={item.public_key}
                        geideaFormData={state.orderPayload}
                        updateCardToken={(token: any) =>
                          dispatch({ type: "SET_CARD_TOKEN", payload: token })
                        }
                        cardToken={state.cardToken}
                        togglePlaceOrderButton={(status: boolean) =>
                          setDisablePlaceOrderCta(!status)
                        }
                      />
                    )}*/}

                  {emailBoxShouldShow(
                    item,
                    selectedPaymentMethod?.payment_type,
                    user.email
                  ) ? (
                    <div className="customer-details-section-mb mt-2">
                      <div className="add-customer-details-mb mt-2">
                        <div className="heading"></div>
                        <div className="form-group add-customer-form-mb">
                          <React.Fragment>
                            <label className="input-label bold mb-2">
                              <FormattedMessage
                                id="global.email"
                                defaultMessage="Email"
                              />
                            </label>
                            <input
                              className="form-control custom-input mb-2"
                              type="text"
                              value={state.userEmail}
                              onChange={(event: any) =>
                                dispatch({
                                  type: "SET_USER_EMAIL",
                                  payload: event.target.value,
                                })
                              }
                              id="user_email"
                              required
                            />
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            {selectedPaymentMethod?.payment_type == "cc_avenue" && (
              <CcAvenueForm CCAvenueFormData={state.orderPayload} />
            )}
            {selectedPaymentMethod?.payment_type == "qpay" && (
              <QpayForm
                qpayFormData={state.orderPayload}
                email={state.userEmail}
                name={state.userName}
                cardToken={state.cardToken}
              />
            )}
            {selectedPaymentMethod?.payment_type == "i_pay88" && (
              <IPay88Form IPay88FormData={state.orderPayload} />
            )}
            {selectedPaymentMethod?.payment_type == "fiserv" && (
              <FiservForm fiservFormData={state.orderPayload} />
            )}
          </div>

          <div className="popup-footer">
            <button
              className="btn btn-secondary"
              onClick={() => props.onClose()}>
              Cancel
            </button>
            <button
              disabled={disablePlaceOrderCta}
              className="btn btn-primary"
              onClick={() => placeOrder()}>
              Pay Now
            </button>
          </div>
        </Fragment>
      ) : (
        <div className="payment-complete-container">
          <img src={checkIcon} alt="success" />
          <span className="title">Payment Successfull</span>
          <span className="text">
            <FormattedMessage
              id="payment.rider_will_receive_you_tip"
              defaultMessage="The rider will receive your tip. Thank you for your generosity!"
            />
          </span>
          <button className="btn btn-primary" onClick={() => props.onClose()}>
            Close
          </button>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  let order = state.orderInProgress;

  return {
    order,
  };
};

const mapDispatchToProps = {
  buildCCAvenuePaymentData: paymentTypeOperations.buildCCAvenuePaymentData,
  buildQpayPaymentData: paymentTypeOperations.buildQpayPaymentData,
  buildIPay88PaymentData: paymentTypeOperations.buildIPay88PaymentData,
  buildFiservPaymentData: paymentTypeOperations.buildFiservPaymentData,
  createGeideaTempOrder: paymentTypeOperations.createGeideaTempOrder,
  makeTipPayment: tipOperations.makeTipPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSelectPopupMobile);
