import actions from "./actions";
import mobileActions from "./mobileActions";

const fetchHomeConfigurations = actions.fetchHomeConfigurations;
const fetchCheckoutConfigurations = actions.fetchCheckoutConfigurations;
const fetchTrackingConfigurations = actions.fetchTrackingConfigurations;
const fetchProfileConfigurations = actions.fetchProfileConfigurations;
const fetchPastOrdersConfigurations = actions.fetchPastOrdersConfigurations;
const fetchLoyaltyConfigurations = actions.fetchLoyaltyConfigurations;
const fetchPaymentProcessingConfigurations = actions.fetchPaymentProcessingConfigurations;
const fetchTermsOfServiceConfigurations = actions.fetchTermsOfServiceConfigurations;
const fetchSignInConfigurations = mobileActions.fetchSignInConfigurations;
const fetchOrderDetailsConfigurations = mobileActions.fetchOrderDetailsConfigurations;
const fetchRiderTrackingConfigurations = actions.fetchRiderTrackingConfigurations;

export default {
  fetchHomeConfigurations,
  fetchCheckoutConfigurations,
  fetchTrackingConfigurations,
  fetchProfileConfigurations,
  fetchPastOrdersConfigurations,
  fetchPaymentProcessingConfigurations,
  fetchLoyaltyConfigurations,
  fetchSignInConfigurations,
  fetchOrderDetailsConfigurations,
  fetchTermsOfServiceConfigurations,
  fetchRiderTrackingConfigurations,
};
