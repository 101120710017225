import * as pageTypes from "../page/types";

const companyReducer = ( state = {}, action: any ) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TRACKING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PROFILE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAST_ORDERS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_LOYALTY_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_PAYMENT_PROCESSING_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_SIGN_IN_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_TERMS_OF_SERVICE_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_RIDER_TRACKING_CONFIGURATIONS_SUCCESS:
      return action.payload.data.company;

    default: return state;
  }
};

const reducer = companyReducer;

export default reducer;
