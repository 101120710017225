import * as pageTypes from "../page/types";

const recentOrderReducer = (state = [], action: any) => {
  switch( action.type ) {
    case pageTypes.FETCH_HOME_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_CHECKOUT_CONFIGURATIONS_SUCCESS:
    case pageTypes.FETCH_ORDER_DETAILS_CONFIGURATIONS_SUCCESS:
      return action.payload.data.recent_order || null;

    default: return state;
  }
}

const reducer = recentOrderReducer

export default reducer;
