// Packages
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

interface NoMenuPopupProps {
  onLocationChangeClick: any;
}

function NoMenuPopup(props: NoMenuPopupProps) {
  const [show, setShow] = useState(true);

  return (
    <Modal
      show={show}
      centered
      dialogClassName="custom curved"
      backdrop="static"
    >
      <div className="no-menu-popup">
        <FontAwesomeIcon className="icon" icon={faTimesCircle} />
        <p className="message">
          <FormattedMessage
            id="no_menu.for_location"
            defaultMessage="No menu available for this location."
          />
        </p>
        <p className="cta">
          <FormattedMessage
            id="no_menu.choose_different_location"
            defaultMessage="Please choose a different location by <spanTag>clicking here.</spanTag>"
            values={{
              spanTag: (text) => <span onClick={() => props.onLocationChangeClick()}>{text}</span>,
            }}
          />
        </p>
      </div>
    </Modal>
  );
}
export default NoMenuPopup;
