// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import ExpandedDeliveryAddressMapWidget from "../expandedDeliveryAddressMapWidget";
import MinimisedDeliveryAddressMapWidget from "../minimisedDeliveryAddressMapWidget";
// Redux Operations
import { addressesOperations } from "../../state/features/addresses";

interface INewAddressWidgetMobileProps {
  area: any;
  addressConfiguration: any;
  outlet: any;
  zoneMapEnabled: boolean;
  reloadAddresses: any;
  cancelNewAddress: any;
  createAddress: any;
}

interface INewAddressWidgetMobileState {
  addressConfiguration: any;
  newAddress: any;
  showExpanded: boolean;
  mapApiKey: string;
  addressMarked: boolean;
  newAddressDisabled: any;
}

class NewAddressWidgetMobile extends Component<
  INewAddressWidgetMobileProps,
  INewAddressWidgetMobileState
> {
  constructor(props: INewAddressWidgetMobileProps) {
    super(props);
    this.state = {
      addressConfiguration: {
        "1": props.intl.formatMessage({ id: "address.configuration.building_villa", defaultMessage: "Building / Villa" }),
        "2": props.intl.formatMessage({ id: "address.configuration.street", defaultMessage: "Street" }),
        "3": props.intl.formatMessage({ id: "address.configuration.flat_number", defaultMessage: "Flat Number" }),
        "4": props.intl.formatMessage({ id: "address.configuration.nearest_landmark", defaultMessage: "Nearest Landmark" }),
      },
      newAddress: {
        latitude: 0,
        longitude: 0,
        delivery_addresses: [
          { id: "1", value: "" },
          { id: "2", value: "" },
          { id: "3", value: "" },
          { id: "4", value: "" },
        ],
      },
      showExpanded: false,
      mapApiKey: "",
      addressMarked: false,
      newAddressDisabled: false,
    };
  }

  componentDidMount() {
    this.findAddressConfiguration();
    this.findUserSelectedAddress();
    this.findMapApiKey();
  }

  findAddressConfiguration = () => {
    if (this.props.addressConfiguration) {
      this.setState({
        addressConfiguration: this.props.addressConfiguration,
      });
    }
  };

  findUserSelectedAddress = () => {
    if (this.props.zoneMapEnabled && localStorage.selectedAddress && JSON.parse(localStorage.selectedAddress)) {
      let address = JSON.parse(localStorage.selectedAddress);
      let newAddress = this.state.newAddress;
      newAddress.latitude = address.lat;
      newAddress.longitude = address.lng;
      this.setState({ newAddress });
    }
  };

  findMapApiKey = () => {
    let element = document.getElementById("root") as any;
    this.setState({ mapApiKey: element?.dataset.googleMapKey });
  };

  addressLineValue = (id: string) => {
    let address = this.state.newAddress.delivery_addresses.find((line: any) => {
      return line.id == id;
    });
    return address.value;
  };

  createNewAddress = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.createAddress(this.state.newAddress).then((response: any) => {
      if (!response.error) {
        this.props.reloadAddresses();
        document.body.classList.remove("noscroll");
      }
    });
  };

  changeAreaInMap = (addressLine: any, latitude: any, longitude: any) => {
    let address = this.state.newAddress;
    address.latitude = latitude;
    address.longitude = longitude;
    this.setState({ newAddress: address }, () =>
      this.handleAddressLineChange(addressLine, "2")
    );
  };

  handleAddressLineChange = (addressLine: string, id: string) => {
    let newAddress = this.state.newAddress;
    let addressLines = newAddress.delivery_addresses.filter((line: any) => {
      return line.id != id;
    });
    let addressLineObj = {
      id: id,
      value: addressLine,
    };
    addressLines.push(addressLineObj);
    newAddress.delivery_addresses = addressLines;
    this.setState({ newAddress });
  };

  minimiseMap = () => {
    this.setState({ showExpanded: false });
  };

  expandMap = () => {
    this.setState({ showExpanded: true });
  };

  toggleAddressMarked = (addressMarked: boolean) => {
    this.setState({ addressMarked });
  }

  reportAreaServiceabilityError = () => {
    document.getElementById("notServiceable")?.classList.add("visible");
    this.setState({ newAddressDisabled: true });
  }

  removeAreaServiceabilityError = () => {
    document.getElementById("notServiceable")?.classList.remove("visible");
    this.setState({ newAddressDisabled: false });
  }

  render() {
    const { area, outlet } = this.props;
    const {
      addressConfiguration,
      showExpanded,
      mapApiKey,
      newAddress,
      addressMarked,
      newAddressDisabled,
    } = this.state;
    const latitude = parseFloat(
      newAddress.latitude || area.latitude || outlet.latitude || 0
    )
    const longitude = parseFloat(
      newAddress.longitude || area.longitude || outlet.longitude || 0
    )

    return (
      <div className="newAddressContainer">
        {showExpanded && mapApiKey ? (
          <ExpandedDeliveryAddressMapWidget
            lat={latitude}
            lng={longitude}
            onAreaChange={(name: any, lat: any, lng: any) => {
              this.changeAreaInMap(name, lat, lng);
            }}
            minimiseMap={(e: React.MouseEvent<HTMLButtonElement>) =>
              this.minimiseMap(e)
            }
            mapApiKey={mapApiKey}
            toggleAddressMarked={(addressMarked: boolean) => {
              this.toggleAddressMarked(addressMarked)
            }}
            reportAreaServiceabilityError={() => {
              this.reportAreaServiceabilityError()
            }}
            removeAreaServiceabilityError={() => {
              this.removeAreaServiceabilityError()
            }}
            source="new-address"
          />
        ) : null}
        <form
          onSubmit={this.createNewAddress}
          onReset={this.props.cancelNewAddress}
        >
          <div
            className={
              showExpanded
                ? "form-group mb-4 delivery-area-map-wrapper expanded"
                : "form-group mb-4 delivery-area-map-wrapper"
            }
          >
            <label className="input-label bold mb-2">
              <FormattedMessage
                id="address.pin_exact_location"
                defaultMessage="Pin Exact Location"
              />
            </label>
            <div
              className={
                showExpanded ? "delivery-area-map full" : "delivery-area-map"
              }
            >
              <div
                className={
                  addressMarked
                  ? "initial-message hide"
                  : "initial-message show"
                }
                onClick={this.expandMap}
              >
                <FormattedMessage
                  id="address.tap_to_pin_for_delivery"
                  defaultMessage="Tap here to pin your exact location. This will be very helpful for the delivery drivers."
                />
              </div>

              {!showExpanded && mapApiKey ? (
                <MinimisedDeliveryAddressMapWidget
                  lat={latitude}
                  lng={longitude}
                  addressMarked ={addressMarked}
                  onAreaChange={(name: any, lat: any, lng: any) => {
                    this.changeAreaInMap(name, lat, lng);
                  }}
                  expandMap={() => this.expandMap()}
                  mapApiKey={mapApiKey}
                />
              ) : null}
            </div>
          </div>

          <div className={addressMarked ? "form-group mb-4" : "form-group mb-4 disabled"}>
            <label className="input-label bold mb-2">
              {addressConfiguration["2"]} <span className="text-danger">*</span>
            </label>
            <input
              name="address_2"
              type="text"
              required
              className="form-control custom-input"
              value={this.addressLineValue("2") || ""}
              id="address_2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.handleAddressLineChange(e.target.value, "2")
              }
            ></input>
          </div>
          <div className={addressMarked ? "form-group mb-4" : "form-group mb-4 disabled"}>
            <label className="input-label bold mb-2">
              {addressConfiguration["1"]} <span className="text-danger">*</span>
            </label>
            <input
              name="address_1"
              type="text"
              required
              className="form-control custom-input"
              value={this.addressLineValue("1") || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.handleAddressLineChange(e.target.value, "1")
              }
            ></input>
          </div>
          <div className={addressMarked ? "form-group mb-4" : "form-group mb-4 disabled"}>
            <label className="input-label bold mb-2">
              {addressConfiguration["3"]}{" "}
              <span>(
                <FormattedMessage
                  id="address.optional"
                  defaultMessage="Optional"
                />
              )</span>
            </label>
            <input
              name="address_3"
              type="text"
              className="form-control custom-input"
              value={this.addressLineValue("3") || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.handleAddressLineChange(e.target.value, "3")
              }
            ></input>
          </div>
          <div className={addressMarked ? "form-group mb-4" : "form-group mb-4 disabled"}>
            <label className="input-label bold mb-2">
              {addressConfiguration["4"]}{" "}
              <span>(
                <FormattedMessage
                  id="address.optional"
                  defaultMessage="Optional"
                />
              )</span>
            </label>
            <input
              name="address_4"
              type="text"
              className="form-control custom-input"
              value={this.addressLineValue("4") || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.handleAddressLineChange(e.target.value, "4")
              }
            ></input>
          </div>

          <div
            className={
              showExpanded
                ? "d-flex footer-actions new-address-cta-wrapper map-shown"
                : "d-flex footer-actions new-address-cta-wrapper "
            }
          >
            <button
              type="reset"
              className="button btn btn-outline-primary w-100 px-4 mr-2"
            >
              <FormattedMessage
                id="global.cancel"
                defaultMessage="Cancel"
              />
            </button>
            <button
              type="submit"
              className="butoon btn btn-primary w-100 px-4"
              disabled={newAddressDisabled}
            >
              <FormattedMessage
                id="global.save"
                defaultMessage="Save"
              />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let addressConfiguration = state.company.address_configuration;
  let outlet = state.outlet;
  let area = state.areas.selectedArea;
  let zoneMapEnabled = state.company.configuration?.zone_mapping_enabled == true;

  return {
    addressConfiguration,
    outlet,
    area,
    zoneMapEnabled,
  };
};

const mapDispatchToProps = {
  createAddress: addressesOperations.createAddress,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewAddressWidgetMobile));
