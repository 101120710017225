import * as types from "./types";

const savedCardsReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case types.FETCH_SAVED_CARDS_SUCCESS:
    case types.FETCH_STRIPE_SAVED_CARDS_SUCCESS:
    case types.FETCH_STRIPE_CONNECT_SAVED_CARDS_SUCCESS:
      return action.payload.data.saved_cards;

    default: return state;
  }
};

const reducer = savedCardsReducer;

export default reducer;
