// Packages
import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Icons, Images etc.
import Image from "../../images/marketing-banner-image.jpg";

interface IMarketingAnnouncementProps {
  onClose: () => void;
  announcement: string;
}

function MarketingAnnouncement(props: IMarketingAnnouncementProps) {
  return (
    <Modal
      show={true}
      dialogClassName="custom announcement modal-w80"
      onHide={() => props.onClose()}
      centered
    >
      <div className="marketing-announcement">
        <div
          className="header-image"
          style={{ backgroundImage: `url(${Image})` }}
        ></div>
        <div className="announcement-message-container">
          <div>
            <div className="annoucement-message">
              {props.announcement}
            </div>
            <button
              onClick={() => props.onClose()}
              className="button btn btn-primary px-4"
            >
              <FormattedMessage
                id="global.close"
                defaultMessage="Close"
              />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default MarketingAnnouncement;
