// Packages
import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
// Redux Operations
import { sessionOperations } from "../../state/features/session";

interface INoMenuAvailableProps {
  session: any;
  setSession: any;
}

function NoMenuAvailable(props: INoMenuAvailableProps) {
  const onLocationChangeClick = () => {
    let session = props.session as any;
    session.showAreaSelect = true;
    props.setSession(session);
  };

  return (
    <div className="no-menu-available">
      <FontAwesomeIcon className="icon" icon={faTimesCircle} />
      <p className="message">
        <FormattedMessage
          id="no_menu.for_location"
          defaultMessage="No menu available for this location."
        />
      </p>
      <p className="cta">
        <FormattedMessage
          id="no_menu.choose_different_location"
          defaultMessage="Please choose a different location by <spanTag>clicking here.</spanTag>"
          values={{
            spanTag: (text) => <span onClick={onLocationChangeClick}>{text}</span>,
          }}
        />
      </p>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let session = state.session;

  return { session };
};

const mapDispatchToProps = {
  setSession: sessionOperations.setSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoMenuAvailable);
