// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import HomeBanner from "../../components/homeBanner";
import MenuItem from "../../components/menuItem";
import OrdersDropdown from "../../components/ordersDropdown";
import Header from "../../components/header";
import GeneralAnnouncement from "../../components/generalAnnouncement";
import MarketingAnnouncement from "../../components/marketingAnnouncement";
import NoMenuPopup from "../../components/noMenuPopup";
import Footer from "../../components/footer";
// Icons, Images etc.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingBag,
  faBars,
  faCaretDown,
  faSearch,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import cartImage from "../../images/cart-icon-red.png";
// Redux Operations
import { cartOperations } from "../../state/features/cart";
import { pageOperations } from "../../state/features/page";
import { errorOperations } from "../../state/features/error";
import { sessionOperations } from "../../state/features/session";
// Helpers, Utils etc.
import { handleCategoryScrollSpy } from "./utils";
import { deepCopyFunction } from "../../helpers/utils";
import { validateCartItems } from "../../helpers/cartFunctions";
import {
  translatedName,
} from "../../helpers/translations";

interface IHomeProps {
  company: any;
  outlet: any;
  menu: any;
  categories: any;
  cart: any;
  session: any;
  storeOpen: any;
  pageLoading: boolean;
  setCart: any;
  fetchHomeConfigurations: any;
  updateError: any;
  setSession: any;
  error: any;
}

interface IHomeState {
  openCategoryDropdown: boolean;
  categories: any;
  showMarketingAnnouncement: boolean;
  showGeneralAnnouncement: boolean;
  topHeight: any;
  dropdownFocused: boolean;
  searchResults: any;
  showCartDropdown: boolean;
  searchKeyword: string;
  categoryScrollSpyObj: any;
}

declare global {
  interface Window {
    isInitialLoad: any;
  }
}

class Home extends Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);
    this.state = {
      openCategoryDropdown: false,
      categories: [],
      showMarketingAnnouncement: false,
      showGeneralAnnouncement: false,
      topHeight: "",
      dropdownFocused: false,
      showCartDropdown: false,
      searchResults: { itemCount: 0, categoryCount: 0 },
      searchKeyword: "",
      categoryScrollSpyObj: {},
    };

    window.isInitialLoad = true;

    this.props.fetchHomeConfigurations().then(() => {
      if (this.props.company.announcement) {
        this.setState({ showGeneralAnnouncement: true });
      }
      if (this.props.outlet.marketing_banner) {
        this.setState({ showMarketingAnnouncement: true });
      }
      let categories = this.fetchUpdatedCategories(this.props.categories);
      this.setState(
        { categories: categories },
        () =>
          this.updateItemsInCart(
            validateCartItems(
              this.state.categories,
              JSON.parse(localStorage.cartItems || "[]"),
              this.itemsNotInMenuError
            )
          )
      );
      let topHeight = document.getElementById("top-bar")?.clientHeight;
      this.setState({ topHeight });
      localStorage.precision = this.props.company.decimal_precision;
      let categoryScrollSpyObj = handleCategoryScrollSpy(
        this.state.categoryScrollSpyObj
      );
      this.setState({ categoryScrollSpyObj });
    });
  }

  componentWillUnmount() {
    if (this.state.categoryScrollSpyObj.scrollSpyHandler) {
      this.state.categoryScrollSpyObj.scrollSpyHandler.dispose();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.cart !== this.props.cart) {
      let updatedCategories = this.fetchUpdatedCategories(
        this.state.categories
      );
      this.setState({ categories: updatedCategories });
    }
  }

  fetchUpdatedCategories(categories: any) {
    let countInCart: number;
    categories = deepCopyFunction([...categories]);
    categories = categories.map((category: any) => {
      category.items = category.items.map((categoryItem: any) => {
        countInCart = this.props.cart.items
          .filter((cartItem: any) => {
            return categoryItem.id === cartItem.id;
          })
          .reduce((count: number, cartItem: any) => {
            return (count += cartItem.count);
          }, 0);
        if (categoryItem.items?.length) {
          categoryItem.items = categoryItem.items.map((itemInGroup: any) => {
            let countItemInGroupInCart = 0;
            countInCart = this.props.cart.items
              .filter((cartItem: any) => {
                return itemInGroup.id === cartItem.id;
              })
              .reduce((count: number, cartItem: any) => {
                countItemInGroupInCart += cartItem.count;
                return (count += cartItem.count);
              }, countInCart);
            itemInGroup.count = countItemInGroupInCart;
            return itemInGroup;
          });
        }
        categoryItem.count = countInCart;
        return categoryItem;
      });
      return category;
    });
    return categories;
  }

  onCategoryClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: any) {
    e.stopPropagation();
    let element = document.getElementById("category-block-" + id);
    let position =
      element!.getBoundingClientRect()!.top + window.pageYOffset - 135;
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
  }

  updateItemsInCart = (cartItems: any) => {
    this.props.setCart({ items: cartItems });
  };

  itemsNotInMenuError = () => {
    this.props.updateError({
      title: <FormattedMessage id="error.oops" defaultMessage="Oops!" />,
      message: <FormattedMessage id="error.items_in_cart_not_in_menu" defaultMessage="Items in cart are not part of current available menu." />,
    });
  }

  onSearch(e: React.ChangeEvent<HTMLInputElement>) {
    let searchKeyword = e.target.value;
    let filteringCategories = this.fetchUpdatedCategories(
      this.props.categories
    );
    let filteredItemsCount = 0;
    let filteredCategories = filteringCategories.filter((category: any) => {
      category.items = category.items.filter((item: any) => {
        if (item.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
          filteredItemsCount++;
          return true;
        }
        if (item.items?.length) {
          let groupItemCount =
            item.items.reduce((count: number, itemInGroup: any) => {
              if (
                itemInGroup
                  .name.toLowerCase().includes(searchKeyword.toLowerCase())
              ) {
                count++;
              }
              return count;
            }, 0);
          filteredItemsCount += groupItemCount;
          return groupItemCount;
        }
        return false;
      });
      return category.items.length;
    });
    window.scrollTo({ top: 0 });
    this.setState({
      categories: filteredCategories.length ? filteredCategories : [],
      searchKeyword,
      searchResults: {
        itemCount: filteredItemsCount,
        categoryCount: filteredCategories.length
      }
    });
  }

  onDropdownToggle(e: any) {
    if (e) {
      let element: any = document.getElementById("category-nav");
      let position =
        element!.getBoundingClientRect()!.top + window.pageYOffset - 140;
      if (position < 330) {
        window.scrollTo({
          top: element?.offsetTop - 70,
          behavior: "smooth",
        });
      }
      this.setState({ showCartDropdown: true });
    } else {
      this.setState({ showCartDropdown: false });
    }
  }

  onLocationChangeClick = () => {
    let session = this.props.session as any;
    session.showAreaSelect = true;
    session.mandatory = false;
    this.props.setSession(session);
  };

  onCategoryMenuClick(category: any) {
    var topHeaderSection = document.getElementById("top-nav");
    var topHeaderSectionHeight = topHeaderSection
      ? topHeaderSection.clientHeight
      : 0;

    var catSection = document.getElementById("category-nav");
    var catSectionHeight = catSection ? catSection.clientHeight : 0;

    var offset = topHeaderSectionHeight + catSectionHeight;
    let categoryScroller = document.getElementById("section-" + category.id);

    let position = categoryScroller.getBoundingClientRect().top - offset + 2;

    window.scrollBy({
      top: position,
      behavior: "smooth",
    });

    var elemPos = 0;
  }

  render() {
    const { categories } = this.state;
    const { intl } = this.props;

    let cartCount = 0;
    this.props.cart.items.map((cartItem: any) => {
      cartCount += cartItem.count || 0;
    });

    return (
      <React.Fragment>
        <div className="fake-header"></div>
        {this.state.showGeneralAnnouncement && (
          <GeneralAnnouncement
            onClose={() => this.setState({ showGeneralAnnouncement: false })}
            announcement={this.props.company.announcement}
          />
        )}
        {this.state.showMarketingAnnouncement &&
        !this.props.session.showAreaSelect &&
        !this.props.pageLoading &&
        !this.props.error &&
        categories.length ? (
          <MarketingAnnouncement
            onClose={() => this.setState({ showMarketingAnnouncement: false })}
            announcement={this.props.outlet.marketing_banner}
          />
        ) : null}
        <Header />
        <div className="home-page">
          {this.props.outlet.id ? (
            <HomeBanner
              onCategoryClick={(
                e: React.MouseEvent<Element, MouseEvent>,
                categoryId: any
              ) => this.onCategoryClick(e, categoryId)}
            />
          ) : null}
          {!this.props.pageLoading &&
          !this.props.session.showAreaSelect &&
          !categories.length &&
          !this.state.searchKeyword ? (
            <NoMenuPopup onLocationChangeClick={this.onLocationChangeClick} />
          ) : (
            <React.Fragment>
              <nav
                id="category-nav"
                className="navbar navbar-expand-lg category-bar"
              >
                <div
                  className="collapse navbar-collapse horizontal-cat-bar"
                  id="horizontal-cat-bar"
                >
                  <ul
                    className="navbar-nav align-items-center my-auto mr-auto mt-2 cat-navigation"
                    id="cat-navigation"
                  >
                    {categories &&
                      categories.map((category: any, index: number) => {
                        return (
                          <li
                            className="category-entry"
                            key={index}
                            onClick={() => this.onCategoryMenuClick(category)}
                          >
                            <a
                              href={"#section-" + category.id}
                              className="category-entry-name"
                            >
                              {translatedName(category, intl.locale)}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div
                  id="category-nav-search"
                  className="navbar navbar-expand-lg"
                >
                  <ul className="navbar-nav my-auto mr-3 mt-2 align-items-center">
                    <li className="nav-item nav-option ml-4 d-flex align-items-center search-input-wrapper">
                      <div className="input-group">
                        <div className="input-group-prepend m-auto">
                          <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <FormattedMessage
                          id="global.search"
                          defaultMessage="Search"
                        >
                          {(placeholder) => (
                            <input
                              type="text"
                              className="navbar-search"
                              placeholder={placeholder}
                              aria-label="Example text with button addon"
                              aria-describedby="button-addon1"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                this.onSearch(e)
                              }
                            />
                          )}
                        </FormattedMessage>
                      </div>
                    </li>
                  </ul>
                </div>
                <Dropdown
                  drop="down"
                  show={
                    this.props.cart.items.length > 0
                      ? this.state.showCartDropdown
                      : false
                  }
                  onToggle={(e: any) => this.onDropdownToggle(e)}
                >
                  <Dropdown.Toggle
                    id="header-cart-button"
                    className="cart-icon"
                    disabled={cartCount <= 0 || !this.props.storeOpen}
                  >
                    <img className="icon" src={cartImage} />
                    <span className="count">
                      {" "}
                      {cartCount > 0 ? cartCount : "0"}{" "}
                      <FormattedMessage
                        id="global.items"
                        defaultMessage="Items"
                      />
                    </span>
                    <FontAwesomeIcon size="1x" icon={faCaretDown} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu flip={false} className="orders-dropdown">
                    <OrdersDropdown />
                  </Dropdown.Menu>
                </Dropdown>
              </nav>
              <div className="container-fluid category-items">
                {this.state.searchKeyword.length ? (
                  this.state.searchResults.itemCount ? (
                    <div className="search-results">
                      <FormattedMessage
                        id="search.result_found_in_category"
                        defaultMessage="{itemCount, plural, one {# Result} other {# Results}} found in {categoryCount, plural, one {# Category} other {# Categories}}"
                        values={{
                          itemCount: this.state.searchResults.itemCount,
                          categoryCount: this.state.searchResults.categoryCount,
                        }}
                      />
                    </div>
                  ) : (
                    <div className="no-search-results">
                      <FontAwesomeIcon className="search-icon" icon={faSearch} />
                      <p className="message">
                        <FormattedMessage
                          id="search.no_result_found"
                          defaultMessage="No result found matched for this keyword {searchKeyword}"
                          values={{
                            searchKeyword: `'${this.state.searchKeyword}'`,
                          }}
                        />
                      </p>
                    </div>
                  )
                ) : null}

                {categories &&
                  categories.map((category: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <section
                          key={index}
                          id={`section-${category.id}`}
                          className={`menu-categories-mb with-item-${
                            category.items.length
                          }`}
                        >
                          <div
                            id={`nav-head-${category.id}`}
                            className="category-title"
                          >
                            {translatedName(category, intl.locale)}
                          </div>
                          <div
                            id={`category-block-${category.id}`}
                            className="row mt-3 category-block-container"
                          >
                            {category.items.map((menuItem: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                  className="col-xl-4 col-lg-6 col-lg-6 col-md-6 col-sm-6"
                                >
                                  <MenuItem item={menuItem} />
                                </div>
                              );
                            })}
                          </div>
                        </section>
                      </React.Fragment>
                    );
                  })}
              </div>
            </React.Fragment>
          )}
        </div>
        <Footer/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => {
  let company = state.company;
  let outlet = state.outlet;
  let menu = state.menu || {};
  let categories = state.menu.categories || [];
  let session = state.session;
  let storeOpen = state.storeTimings.open;
  let cart = state.cart;
  let pageLoading = state.loader.loading;
  let error = state.error;

  return {
    company,
    outlet,
    cart,
    menu,
    categories,
    session,
    storeOpen,
    pageLoading,
    error,
  };
};

const mapDispatchToProps = {
  fetchHomeConfigurations: pageOperations.fetchHomeConfigurations,
  setCart: cartOperations.setCart,
  updateError: errorOperations.updateError,
  setSession: sessionOperations.setSession,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home));
