import React, { useEffect } from "react";

interface IFullPageAuthProps {
  htmlContent: string;
}

const FullPageAuthMobile: React.FC<IFullPageAuthProps> = ({ htmlContent }) => {
  useEffect(() => {
    const scriptElement = document.getElementById("authenticate-payer-script");
    if (scriptElement) {
      const newScript = document.createElement("script");
      newScript.text = scriptElement.text;
      document.body.appendChild(newScript);
    }
    const iframe = document.getElementById("redirectTo3ds1Frame");
    if (iframe) {
      iframe.style.height = "100vh";
    }
  }, [htmlContent]);

  return (
    <div className="full-page-3ds-auth-mobile">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default FullPageAuthMobile;
