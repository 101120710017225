import actions from "./actions";

const fetchDiscount = actions.fetchDiscount;
const removeDiscount = actions.removeDiscount;
const applyDiscount = actions.applyDiscount;

export default {
  fetchDiscount,
  applyDiscount,
  removeDiscount,
};
